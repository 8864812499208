import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const CardMonthCell = ({ className, current, disabled, muted, featured, children, onClick }) => (
  <div className={classNames(className, { disabled, current, muted, featured })} onClick={onClick}>
    {children}
  </div>
);

CardMonthCell.propTypes = {
  className: PropTypes.string,
  current: PropTypes.bool,
  disabled: PropTypes.bool,
  featured: PropTypes.bool,
  muted: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func
};

CardMonthCell.defaultProps = {
  className: undefined,
  current: false,
  disabled: false,
  featured: false,
  muted: false,
  children: undefined,
  onClick: undefined
};

export default CardMonthCell;
