import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import messages from './classManagementMessages';

class ClassManagementWeekDaysTab extends Component {
  // support controlled values
  static getDerivedStateFromProps(props, state) {
    const { weekDay } = props;

    if (weekDay !== state.prevWeekDay) {
      return {
        prevWeekDay: weekDay,
        currentWeekDay: weekDay,
      };
    }
    return null;
  }
  state = {
    currentWeekDay: this.props.weekDay || 2,
  };

  selectWeekDay = weekDay => () => {
    console.log('selecting', weekDay);
    this.setState(
      {
        currentWeekDay: weekDay,
      },
      () => {
        if (this.props.onSelectWeekDay) {
          this.props.onSelectWeekDay(this.state.currentWeekDay);
        }
      },
    );
  };

  render() {
    const { currentWeekDay } = this.state;
    const { activeWeekDays, weekDays } = this.props;

    return (
      <ul className="inline">
        {weekDays.map(weekDay => (
          <li
            key={weekDay}
            className={classNames({
              selected: weekDay === currentWeekDay,
              active: activeWeekDays.includes(weekDay),
            })}
            onClick={this.selectWeekDay(weekDay)}
          >
            <FormattedMessage {...messages[`day0${weekDay}`]} />
          </li>
        ))}
      </ul>
    );
  }
}

ClassManagementWeekDaysTab.propTypes = {
  activeWeekDays: PropTypes.arrayOf(PropTypes.number),
  weekDays: PropTypes.arrayOf(PropTypes.number),
};

ClassManagementWeekDaysTab.defaultProps = {
  activeWeekDays: [],
  weekDays: Array(7)
    .fill()
    .map((_, index) => index + 1),
};

export default ClassManagementWeekDaysTab;
