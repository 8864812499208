// @flow

import { apiTask, watchApiTask } from 'app/sagas'; 
import { authApi, apiCi, api } from 'app/api';
import { fork, take, all } from 'redux-saga/effects';
import * as actions from './configurationActions';

export const uploadTask = watchApiTask(actions.UPLOAD, authApi.post, '/uploadUserPhoto');
export const removePhotoTask = watchApiTask(actions.REMOVE_PHOTO, api.post, '/users/removeUserPhoto.php');


export const saveTask = apiTask(
  actions.SAVE,
  api.post,
  '/users/updateUserInfo.php',
);
export function* watchSave() {
  while (true) {
    const { payload } = yield take(actions.SAVE);
    yield fork(saveTask, payload);
  }
}



export function* sagas(): Iterable<T> {
  yield all([fork(watchSave), fork(uploadTask), fork(removePhotoTask)]);
}
