import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'sm-ui';
import messages from './classManagementMessages';

const ClassManagementRemoveCourseModal = ({ onClose, onConfirm, ...props }) => (
  <div>
    <Modal
      icon="pe-7s-trash"
      onClose={onClose}
      {...props}
      title={<FormattedMessage {...messages.deleteGroup} />}
      message={<FormattedMessage {...messages.deleteGroupMessage} />}
      buttons={[
        {
          id: 1,
          label: <FormattedMessage {...messages.cancel} />,
          onClick: onClose,
        },
        {
          id: 2,
          label: <FormattedMessage {...messages.delete} />,
          onClick: onConfirm,
          primary: true,
        },
      ]}
    />
  </div>
);

ClassManagementRemoveCourseModal.propTypes = {};

export default ClassManagementRemoveCourseModal;
