import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { Modal, Input, Checkbox, Alert, Radio } from 'sm-ui';
import * as helpers from './calendarHelpers';
import messages from './calendarMessages';

const initialState = {
  data: {
    date: '',
    automaticReassign: false,
    hour: '',
  },
};

class CalendarReassignModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      hours: helpers.createHours(),
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { defaultDate, defaultHour } = props;
    if (defaultDate !== state.prevDefaultDate || defaultHour !== state.prevDefaultHour) {
      return {
        prevDefaultDate: defaultDate,
        prevDefaultHour: defaultHour,
        data: {
          ...state.data,
          date: defaultDate ? defaultDate.toISOString().slice(0, 10) : '',
          hour: defaultHour || '',
        },
      };
    }
    return null;
  }

  resetState = () => {
    const { defaultDate, defaultHour } = this.props;
    this.setState({
      ...initialState,
      data: {
        ...initialState.data,
        date: defaultDate ? defaultDate.toISOString().slice(0, 10) : '',
        hour: defaultHour || '',
      },
    });
  };

  close = () => {
    this.resetState();
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  handleChangeAutomaticReassign = event => {
    const { checked } = event.target;
    this.setState(({ data }) => ({ data: { ...data, automaticReassign: checked } }));
  };

  handleChangeHour = event => {
    const { value } = event.target;
    this.setState(({ data }) => ({ data: { ...data, hour: value } }));
  };

  handleChangeDate = event => {
    const { value } = event.target;
    this.setState(({ data }) => ({ data: { ...data, date: value } }));
  };

  submit = async () => {
    await this.props.onSubmit(this.state.data);
    this.resetState();
  };

  renderErrors() {
    const { error } = this.props;
    if (!error || !error.code) return null;
    return (
      <div>
        <Alert type="danger" className="text-left">
          <FormattedMessage
            {...messages[`errorReassignCode${error.code}`] || messages.errorReassignCode500}
            values={error.info || {}}
          />
        </Alert>
      </div>
    );
  }
  render() {
    const { int, onClose, intl, error, reassignSuggestedDate, ...props } = this.props;
    const {
      data: { date, automaticReassign, hour },
      hours,
    } = this.state;

    const buttons = [
      {
        id: 1,
        label: intl.formatMessage(messages.reassignModalCancelButton),
        onClick: this.close,
        disabled: false,
      },
      {
        id: 2,
        label: intl.formatMessage(messages.reassignModalReassignButton),
        onClick: this.submit,
        primary: true,
        disabled: !date,
      },
    ];

    const errors = this.renderErrors();

    return (
      <Modal
        title={intl.formatMessage(messages.reassignModalTitle)}
        icon="pe-7s-repeat"
        message={intl.formatMessage(messages.reassignModalMessage)}
        modalClassName="modal-md"
        messageClass="big"
        onClose={this.close}
        buttons={buttons}
        {...props}
      >
        <div>
          {errors}
          <Input type="date" onChange={this.handleChangeDate} value={date} />
          <div className="mt-3 mb-1">
            <FormattedMessage {...messages.noneHour}>
              {txt => (
                <Radio
                  inline
                  value=""
                  checked={hour === ''}
                  onChange={this.handleChangeHour}
                  label={txt}
                />
              )}
            </FormattedMessage>
            {hours.map(h => (
              <FormattedMessage {...messages.hourLabel}>
                {txt => (
                  <Radio
                    key={h}
                    inline
                    type="radio"
                    label={txt + h}
                    value={h}
                    checked={parseInt(h, 0) === parseInt(hour, 0)}
                    onChange={this.handleChangeHour}
                  />
                )}
              </FormattedMessage>
            ))}
          </div>
          <div className="mt-1 mb-1">
            <Checkbox
              label={intl.formatMessage(messages.reassignModalAutomaticReassignment)}
              onChange={this.handleChangeAutomaticReassign}
              checked={automaticReassign}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

export default compose(injectIntl)(CalendarReassignModal);
