import { take, fork, all, call } from 'redux-saga/effects';
import { apiTask } from 'app/sagas';
import { api } from 'app/api';
import * as actions from './classManagementActions';

// GET COURSES
export const getCoursesTask = apiTask(
  actions.GET_COURSES,
  api.post,
  '/planner-plans/getCourses.php',
);

// ADD NEW COURSE
export const addCourseTask = apiTask(
  actions.ADD_COURSE,
  api.post,
  '/planner-plans/addClassManagementCourse.php',
);

// EDIT COURSE
export const editCourseTask = apiTask(
  actions.EDIT_COURSE,
  api.post,
  '/planner-plans/editClassManagementCourse.php',
);

// DELETE COURSE
export const deleteCourseTask = apiTask(
  actions.DELETE_COURSE,
  api.post,
  '/planner-plans/deleteClassManagementCourse.php',
);

// GET HOUR
export const getPlanCoursesHoursTask = apiTask(
  actions.GET_PLAN_COURSES_HOUR,
  api.post,
  '/planner-plans/getPlanCoursesHours.php',
);

// ADD HOUR
export const addHourTask = apiTask(
  actions.ADD_HOUR,
  api.post,
  '/planner-plans/addPlanCoursesHours.php',
);

// DELETE HOUR
export const deleteHourTask = apiTask(
  actions.DELETE_HOUR,
  api.post,
  '/planner-plans/deletePlanCoursesHours.php',
);

export const schedulePlanTask = apiTask(
  actions.SCHEDULE_PLAN,
  api.post,
  '/planner-plans/schedulePlan.php',
);

export function* watchClassManagement() {
  while (yield take(actions.GET_COURSES)) {
    yield fork(getCoursesTask);
  }
}

export function* watchAddCourse() {
  while (true) {
    const { payload, meta } = yield take(actions.ADD_COURSE);
    const { data } = yield call(addCourseTask, payload, { meta });
    if (data) {
      yield fork(getPlanCoursesHoursTask, payload);
    }
  }
}

export function* watchSchedulePlan() {
  while (true) {
    const { payload } = yield take(actions.SCHEDULE_PLAN);
    yield fork(schedulePlanTask, payload);
  }
}

export function* watchEditCourse() {
  while (true) {
    const { payload, meta } = yield take(actions.EDIT_COURSE);
    const { data } = yield call(editCourseTask, payload, { meta });
    if (data) {
      yield fork(getPlanCoursesHoursTask, payload);
    }
  }
}

export function* watchDeleteCourse() {
  while (true) {
    const { payload, meta } = yield take(actions.DELETE_COURSE);
    const { data } = yield call(deleteCourseTask, payload, { meta });
    if (data) {
      yield fork(getPlanCoursesHoursTask, payload);
    }
  }
}

export function* watchGetPlanCoursesHours() {
  while (true) {
    const { payload } = yield take(actions.GET_PLAN_COURSES_HOUR);
    yield fork(getPlanCoursesHoursTask, payload);
  }
}

export function* watchAddHour() {
  while (true) {
    const { payload, meta } = yield take(actions.ADD_HOUR);
    const { data } = yield call(addHourTask, payload, { meta });
    if (data) {
      yield fork(getPlanCoursesHoursTask, payload);
    }
  }
}

export function* watchDeleteHour() {
  while (true) {
    const { payload, meta } = yield take(actions.DELETE_HOUR);
    const { data } = yield call(deleteHourTask, payload, { meta });
    if (data) {
      yield fork(getPlanCoursesHoursTask, payload);
    }
  }
}

export function* sagas() {
  yield all([
    fork(watchClassManagement),
    fork(watchAddCourse),
    fork(watchSchedulePlan),
    fork(watchEditCourse),
    fork(watchDeleteCourse),
    fork(watchGetPlanCoursesHours),
    fork(watchAddHour),
    fork(watchDeleteHour),
  ]);
}
