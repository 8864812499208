import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { injectIntl } from 'react-intl';

import messages from './appMessages';

class AppPageTitle extends Component {
  componentDidMount() {
    this.updateTitle();
  }
  componentDidUpdate(prevProps) {
    if (this.props.user && !prevProps.user) {
      this.updateTitle();
    }
  }

  updateTitle = () => {
    const title = this.props.intl.formatMessage(messages.title);
    document.title = title;
  };

  render() {
    return null;
  }
}

const mapStateToProps = ({ app }) => ({ user: app.user });

AppPageTitle.propTypes = {
  user: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

export default compose(
  connect(mapStateToProps),
  injectIntl,
)(AppPageTitle);
