import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'register.title',
    defaultMessage: 'Class Planner',
  },
  nameField: {
    id: 'register.field.name',
    defaultMessage: 'First name',
  },
  surnameField: {
    id: 'register.field.surname',
    defaultMessage: 'Surname',
  },
  emailField: {
    id: 'register.field.email',
    defaultMessage: 'E-mail',
  },
  emailConfirmField: {
    id: 'register.field.emailConfirm',
    defaultMessage: 'Confirm e-mail',
  },
  passwordField: {
    id: 'register.field.password',
    defaultMessage: 'Password',
  },
  passwordConfirmField: {
    id: 'register.field.passwordConfirm',
    defaultMessage: 'Confirm password',
  },
  countryField: {
    id: 'register.field.country',
    defaultMessage: 'Country',
  },
  cityField: {
    id: 'register.field.city',
    defaultMessage: 'City',
  },
  stateField: {
    id: 'register.field.state',
    defaultMessage: 'State',
  },
  schoolField: {
    id: 'register.field.school',
    defaultMessage: 'Type your school name',
  },
  profileField: {
    id: 'register.field.profile',
    defaultMessage: 'Select your profile type',
  },
  teachingTypes: {
    id: 'register.field.teachingType',
    defaultMessage: 'Select your teaching type',
  },
  gradeField: {
    id: 'register.field.grade',
    defaultMessage: 'Select the classes you coordinate',
  },
  teacher: {
    id: 'register.field.teacher',
    defaultMessage: 'Teacher',
  },
  manager: {
    id: 'register.field.manager',
    defaultMessage: 'Coordinator',
  },
  private: {
    id: 'register.field.private',
    defaultMessage: 'Private',
  },
  public: {
    id: 'register.field.public',
    defaultMessage: 'Public',
  },
  submit: {
    id: 'register.submit',
    defaultMessage: 'Submit',
  },
  signin: {
    id: 'register.signin',
    defaultMessage: 'Sign in',
  },
  signup: {
    id: 'register.signup',
    defaultMessage: 'Sign up',
  },
  mustAcceptTerms: {
    id: 'register.error.mustAcceptTerms',
    defaultMessage: "Must Accept Terms and Conditions",
  },
  errorDontMatch: {
    id: 'register.error.dontmatch',
    defaultMessage: "Field don't match",
  },
  errorRequired: {
    id: 'register.error.required',
    defaultMessage: 'Required field.',
  },
  errorInvalidEmail: {
    id: 'register.error.invalidEmail',
    defaultMessage: 'Invalid email.',
  },
  errorCode1: {
    id: 'register.error.code1',
    defaultMessage: 'There is an user already registered with this e-mail',
  },
  errorCode401: {
    id: 'register.error.code401',
    defaultMessage: 'Invalid user and/or password',
  },
  errorCode500: {
    id: 'register.error.code500',
    defaultMessage: 'Service unavailable. Try again later',
  },
  country: {
    id: 'register.country',
    defaultMessage: 'Country',
  },
  state: {
    id: 'register.state',
    defaultMessage: 'State',
  },
  city: {
    id: 'register.city',
    defaultMessage: 'City',
  },
  school: {
    id: 'register.school',
    defaultMessage: 'School',
  },
  autocompletePlaceholder: {
    id: 'register.autocompletePlaceholder',
    defaultMessage: 'Search for school...',
  },
  selectCountryPlaceholder: {
    id: 'register.selectCountryPlaceholder',
    defaultMessage: 'Select your country',
  },
  selectCityPlaceholder: {
    id: 'register.selectCityPlaceholder',
    defaultMessage: 'Select your city',
  },
  selectStatePlaceholder: {
    id: 'register.selectStatePlaceholder',
    defaultMessage: 'Select your state',
  },
  searchSchoolPlaceholder: {
    id: 'register.selectSchoolPlaceholder',
    defaultMessage: 'Search for school...',
  },
  noSchoolSelected: {
    id: 'register.noSchoolSelected',
    defaultMessage: 'No school selected yet.',
  },
  noSchoolFounded: {
    id: 'register.noSchoolFounded',
    defaultMessage: 'No school founded.',
  },
  errorCode6: {
    id: 'register.errorCode6',
    defaultMessage: 'This school has already been associated.',
  },
  errorCodeFallback: {
    id: 'register.errorCodeFallback',
    defaultMessage: 'Error',
  },
  optionCountry14: {
    id: 'register.option.country.14',
    defaultMessage: 'Brazil',
  },
  optionCountry15: {
    id: 'register.option.country.15',
    defaultMessage: 'Colombia',
  },
  optionCountryFallback: {
    id: 'register.option.country.fallback',
    defaultMessage: '-',
  },
  saved: {
    id: 'register.saved',
    defaultMessage: 'Registered with success.',
  },
  login: {
    id: 'register.login',
    defaultMessage: 'Login',
  },
  optionGroupTitle: {
    id: 'register.options.group.title',
    defaultMessage: 'Groups',
  },
  save: {
    id: 'register.save',
    defaultMessage: 'Save',
  },
  passCriterio: {
    id: "register.passCriterio",
    defaultMessage: "The minimum criteria for setting the password are:",
  },
  caracterNumber: {
    id: "register.caracterNumber",
    defaultMessage: "Minimum number of 8 characters",
  },
  letterUppercase: {
    id: "register.letterUppercase",
    defaultMessage: "Capital letters, from A to Z",
  },
  letterLowercase: {
    id: "register.letterLowercase",
    defaultMessage: "Lower case letters, from a to z",
  },
  number: {
    id: "register.number",
    defaultMessage: "Use of numeric characters from 0 to 9",
  },
  specialCaracter: {
    id: "register.specialCaracter",
    defaultMessage: "Use of special characters (for example, ",
  },
  errorStrong: {
    id: "register.errorStrong",
    defaultMessage: "The password does not meet the minimum requirements",
  },
  readTerms: {
    id: "register.readTerms",
    defaultMessage: "I read and accept the <a style='color:blue; text-decoration:underline' href='http://linkte.me/politicaprivacidadeapp' target='_blank'>conditions of use</a> and the <a style='color:blue; text-decoration:underline' href='http://linkte.me/politicaprivacidadeapp' target='_blank'>privacy policy</a>.",
  },
  receiveCommercial: {
    id: "register.receiveCommercial",
    defaultMessage: "I wish to receive commercial communications from the SM Group.",
  },
});
