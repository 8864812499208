/* eslint-disable */
import React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from '../plannerMessages';

import './MenuUpload.scss';

const MenuUpload = ({ isOpen, onMouseLeave, onClick }) => (
  <React.Fragment>
    {isOpen ? (
      <div className="element-modal" onMouseLeave={onMouseLeave}>
        <div className="element-modal-btn" onClick={onClick} style={{ padding: '2px' }}>
          <FormattedMessage {...messages.upload} />
        </div>
      </div>
    ) : null}
  </React.Fragment>
);

export default MenuUpload;
