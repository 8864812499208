import React, { Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withCalendar } from '../CalendarContext';
import moment from 'moment';

import './WeekCalendarHeader.scss';

const today = new Date();

const WeekCalendarHeader = ({ dates, currentDate, onChangeDate }) => (
  <Fragment>
    <div className="d-none d-md-block" />
    {dates.map(d => (
      <div
        className={classNames('col-label', {
          current: moment(d).isSame(currentDate, 'day'),
          weekend: [6, 0].includes(moment(d).weekday()),
        })}
        onClick={() => onChangeDate(d)}
        key={d}
      >
        {/* <pre>{currentDate.toString()}</pre> */}
        <span className="week d-none d-md-block">{moment(d).format('dddd')}</span>
        <span className="week d-block d-md-none">{moment(d).format('ddd')}</span>
        <span className="day">{d.getDate()}</span>
      </div>
    ))}
  </Fragment>
);

WeekCalendarHeader.propTypes = {
  dates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
};

WeekCalendarHeader.defaultProps = {
  dates: [],
};

export default withCalendar(WeekCalendarHeader);
