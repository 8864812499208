import React, { Component } from 'react';
import classNames from 'classnames';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  withCalendar,
  withAppointmentDraggableTarget,
  getCellAppointments
} from '../CalendarContext';
import MonthAppointment from './MonthAppointment';

import './MonthCalendarCell.scss';

const today = new Date();

class DayCalendarRow extends Component {
  renderCell() {
    const { isOver, canDrop, date, appointments } = this.props;
    return (
      <div
        className={classNames('month-calendar-cell', {
          current: moment(date).isSame(today, 'date'),
          over: isOver && canDrop,
          'no-drop': !canDrop && isOver
        })}
      >
        <div className="week-name">{moment(date).format('ddd')}</div>
        <div className="week-day">{date.getDate()}</div>
        {getCellAppointments(date, appointments).map(appointment => (
          <MonthAppointment {...appointment} key={appointment.id} />
        ))}
      </div>
    );
  }
  render() {
    const { connectDragTarget } = this.props;
    return connectDragTarget && connectDragTarget(this.renderCell());
  }
}

DayCalendarRow.propTypes = {
  isOver: PropTypes.bool.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  canDrop: PropTypes.bool.isRequired,
  connectDragTarget: PropTypes.func.isRequired,
  moment: PropTypes.func.isRequired
};

export default compose(
  withCalendar,
  withAppointmentDraggableTarget
)(DayCalendarRow);
