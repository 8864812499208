import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CardMenu, CardMenuItem } from 'sm-ui';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import messages from '../scheduleMessages';
import CardAppointmentTitle from './CardAppointmentTitle';
import CalendarEventType from './calendarEventType';
import * as helpers from './calendarHelpers';

import './CardAppointment.scss';

class CardAppointment extends Component {
  state = { isOpen: false };
  handleRequestStatus = () => {
    if (this.props.onRequestStatusAppointment) {
      this.props.onRequestStatusAppointment(this.props);
    }
  };
  handleRequestRemove = () => {
    if (this.props.onRequestRemoveAppointment) {
      this.props.onRequestRemoveAppointment(this.props);
    }
  };
  handleRequestDetails = () => {
    if (this.props.onRequestDetailsAppointment) {
      this.props.onRequestDetailsAppointment(this.props);
    }
  };
  handleRequestReassign = () => {
    if (this.props.onRequestReassignAppointment) {
      this.props.onRequestReassignAppointment(this.props);
    }
  };

  handleOpen = () => {
    this.setState({ isOpen: true });
  };

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { isDragging, title, discipline, content, type, status } = this.props;
    const opacity = isDragging ? 0.4 : 1;
    const stringStatus = String(status);
    const hasApplied = stringStatus === '3' || stringStatus === '7' || stringStatus === '10';

    return (
      <div
        className={classNames('card card-calendar', type.className, {
          'card-calendar-not-applied': helpers.isNotApplied(status),
        })}
        style={{ opacity }}
        // onClick={this.handleRequestDetails}
      >
        <div className={classNames('card-body card-appointment', type.className)}>
          <div className="icon">
            <i className={type.icon} style={hasApplied ? { color: 'green' } : {}} />
          </div>
          <div onClick={this.handleRequestDetails}>
            <CardAppointmentTitle
              className="card-appointment-title"
              title={title}
              type={type}
              style={hasApplied ? { color: 'green' } : {}}
            />
            <p className="card-appointment-discipline">{discipline}</p>
            <p className="card-appointment-content">{content}</p>
          </div>
          <CardMenu
            isOpen={this.state.isOpen}
            handleOpen={this.handleOpen}
            onMouseLeave={this.handleClose}
            lastCol={this.props.date.getDay() === 6}
          >
            <FormattedMessage {...messages.appointmentReassign}>
              {txt => (
                <CardMenuItem
                  icon={<i className="pe-7s-repeat" />}
                  label={txt}
                  onClick={this.handleRequestReassign}
                />
              )}
            </FormattedMessage>
            <FormattedMessage {...messages.appointmentDetails}>
              {txt => (
                <CardMenuItem
                  icon={<i className="pe-7s-look" />}
                  label={txt}
                  onClick={this.handleRequestDetails}
                />
              )}
            </FormattedMessage>
            <FormattedMessage {...messages.appointmentStatus}>
              {txt =>
                status === '1' ||
                status === '2' ||
                status === '3' ||
                status === '5' ||
                status === '6' ||
                status === '7' ? (
                  <CardMenuItem
                    icon={<i className="pe-7s-clock" />}
                    label={txt}
                    onClick={this.handleRequestStatus}
                  />
                ) : null
              }
            </FormattedMessage>
            <FormattedMessage {...messages.appointmentRemove}>
              {txt =>
                status === '4' || status === '5' || status === '6' || status === '7' || status === '8' ? (
                  <CardMenuItem
                    icon={<i className="pe-7s-trash" />}
                    label={txt}
                    onClick={this.handleRequestRemove}
                  />
                ) : null
              }
            </FormattedMessage>
          </CardMenu>
        </div>
      </div>
    );
  }
}

CardAppointment.defaultProps = {
  type: CalendarEventType.LESSON,
};

CardAppointment.propTypes = {
  type: PropTypes.oneOf(Object.keys(CalendarEventType).map(k => CalendarEventType[k])),
};

export default CardAppointment;
