import React from 'react';
import PropTypes from 'prop-types';

const LAYOUT_PROPS = ['sm', 'md', 'lg', 'xl'];

const Col = ({ children, className, xs, style, auto, ...rest }) => {
  const layoutClassNames = LAYOUT_PROPS.filter(prop => rest[prop]).map(prop => `col-${prop}-${rest[prop]}`).concat(xs ? [`col-${xs}`]: []).concat(auto ? ['col-auto'] : []);
  const colClassNames = layoutClassNames.length ? layoutClassNames : ['col'];
  return (
    <div className={colClassNames.concat(className ? [className] : []).join(' ')} style={style}>{children}</div>
  );
}

Col.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  auto: PropTypes.bool,
};

Col.defaultProps = {
  className: undefined,
  auto: false,
};

export default Col;
