import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'sm-ui';

import messages from '../plannerMessages';

const ModalMoveColumn = ({ isOpen, direction, onClose, onConfirm }) => {
  let resolvedTitle = '';
  let resolvedMessage = '';
  if (direction === 'l') {
    resolvedTitle = <FormattedMessage {...messages.lMoveColumnTitle} />;
    resolvedMessage = <FormattedMessage {...messages.lMoveColumnMessage} />;
  }
  if (direction === 'r') {
    resolvedTitle = <FormattedMessage {...messages.rMoveColumnTitle} />;
    resolvedMessage = <FormattedMessage {...messages.rMoveColumnMessage} />;
  }

  return (
    <Modal
      show={isOpen}
      icon={'pe-7s-repeat'}
      onClose={onClose}
      title={resolvedTitle}
      message={resolvedMessage}
      buttons={[
        {
          id: 1,
          label: <FormattedMessage {...messages.cancel} />,
          onClick: () => onClose(),
        },
        {
          id: 2,
          label: <FormattedMessage {...messages.confirm} />,
          onClick: id => onConfirm(id),
        },
      ]}
    />
  );
};

export default ModalMoveColumn;
