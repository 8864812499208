// @flow

import { action } from 'app/actions';

export const SAVE = 'configuration/SAVE';
export const UPLOAD = 'configuration/UPLOAD';
export const REMOVE_PHOTO = 'configuration/REMOVE_PHOTO';

export const save = payload => action(SAVE, { payload });
export const removePhoto = () => action(REMOVE_PHOTO);
export const upload = (payload: FormData) => action(UPLOAD, { payload });
