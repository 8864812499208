import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import Print from 'react-to-print';
import { filterScheduleEvents } from '../scheduleActions';
import * as helpers from './calendarHelpers';
import { Select, RoundButton, IconButton } from 'sm-ui';
import { Row, Col } from 'react-bootstrap';

import messages from './calendarMessages';
import './CalendarHeader.scss';
import { View } from './Calendar';

export const OptionsViewMap = {
  DAY: {
    id: 1,
    messageKey: 'viewOptionDay',
  },
  WEEK: {
    id: 2,
    messageKey: 'viewOptionWeek',
  },
  MONTH: {
    id: 3,
    messageKey: 'viewOptionMonth',
  },
  YEAR: {
    id: 4,
    messageKey: 'viewOptionYear',
  },
};

class CalendarHeader extends PureComponent {
  state = {
    optionsView: Object.keys(OptionsViewMap)
      .map(key => OptionsViewMap[key])
      .map(map => ({
        id: map.id,
        label: this.props.intl.formatMessage(messages[map.messageKey]),
        value: map.id,
      })),
    filter: queryString.parse(location.search),
    viewValue: this.props.viewValue || View.WEEK,
  };
  componentDidMount() {
    window.addEventListener('orientationchange', this.adjustOrientation);
  }
  componentWillUnmount() {
    window.removeEventListener('orientationchange', this.adjustOrientation);
  }

  getCalendarViewRef = () => {
    const { calendarRef } = this.props;
    if (!calendarRef.current) {
      return null;
    }
    return calendarRef.current.calendar.current.ref.current;
  };

  adjustOrientation = () => {
    // adjusting view according orientation (landscape)
    if (this.state.viewValue === View.DAY && window.orientation !== 0) {
      this.setState({
        viewValue: View.WEEK,
      });
    }
  };

  handleSelectOptionView = e => {
    if (this.props.onChangeView) {
      const { value } = e.target;
      this.setState({
        viewValue: value,
      });
      this.props.onChangeView(value, e);
    }
  };

  handleChangeFilter = event => {
    const { value, name } = event.target;
    this.setState(
      prevState => ({ filter: { ...prevState.filter, [name]: value } }),
      () => {
        this.props.onChangeFilter(this.state.filter);
      },
    );
  };

  render() {
    const { optionsView, viewValue, filter } = this.state;
    const { onRequestAdd, onRequestError, filterPlans, filterCourses, intl } = this.props;

    const filterCoursesGroupsOptions = helpers
      .toFilterCoursesGroupsOptions(filterCourses)
      .map(groupOption => ({
        ...groupOption,
        label: intl.formatMessage(
          messages[`groupLabelFilterCourse_${groupOption.groupLabelMessageKey}`],
        ),
      }));

    return (
      <Row className="p-2">
        <Col xs={12} md="auto">
          <FormattedMessage {...messages.headerTitle}>
            {txt => <h1 className="page-title">{txt}</h1>}
          </FormattedMessage>
        </Col>
        <Col auto>
          <Row>
            <Col xs={12} md={3}>
              <Select
                selectLabel={intl.formatMessage(messages.all)}
                value={filter.idCourse}
                name="idCourse"
                itemValue="idCourse"
                containerClassName="mt-3 mt-md-0"
                itemLabel="courseName"
                groupsOptions={filterCoursesGroupsOptions}
                onChange={this.handleChangeFilter}
              />
            </Col>
            <Col xs={12} md={3}>
              <Select
                selectLabel={intl.formatMessage(messages.all)}
                name="idPlan"
                containerClassName="mt-3 mt-md-0"
                itemValue="idPlan"
                itemLabel="planName"
                value={filter.idPlan}
                options={filterPlans}
                onChange={this.handleChangeFilter}
              />
            </Col>
            <Col xs={12} md={3}>
              <Select
                containerClassName="mt-3 mb-3 mt-md-0 mb-md-0"
                name="optionView"
                options={optionsView}
                value={viewValue}
                onChange={this.handleSelectOptionView}
              />
            </Col>
            <FormattedMessage {...messages.print}>
              {txt => (
                <Print
                  // eslint-disable-next-line react/jsx-no-bind
                  trigger={() => (
                    <div
                      className="col-auto btn-pointer d-none d-md-block"
                      style={{ marginTop: -15 }}
                    >
                      <IconButton label={txt} className="btn-pointer">
                        <i className="pe-7s-print btn-pointer" />
                      </IconButton>
                    </div>
                  )}
                  content={this.getCalendarViewRef}
                />
              )}
            </FormattedMessage>
            <Col>
              <RoundButton
                className="btn-pointer calendar--add-button"
                onClick={!filter.idPlan || !filter.idCourse ? onRequestError : onRequestAdd}
              >
                <i className="ti-plus" />
              </RoundButton>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

CalendarHeader.propTypes = {};

const mapStateToProps = ({ schedule }) => ({
  appointments: schedule.appointments,
  filterPlans: schedule.filterPlans,
  filterCourses: schedule.filterCourses,
});

export default compose(
  connect(
    mapStateToProps,
    { filterScheduleEvents },
  ),
  injectIntl,
)(CalendarHeader);
