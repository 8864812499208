import { take, fork, call } from 'redux-saga/effects';
import { apiTask } from '../app/sagas';
import { api } from '../app/api';
import last from 'lodash/last';
import * as actions from './plannerActions';
import download from '../common/download';

// Get planner table information
export const getPlanner = apiTask(
  actions.GET_PLANS_TABLE,
  api.post,
  '/planner-plans/getPlansTable.php',
);

export const revertPlanToOriginal = apiTask(
  actions.REVERT_PLAN_TO_ORIGINAL,
  api.post,
  '/planner-plans/revertPlanToOriginal.php',
);

// View Files
export const downloadFileTask = apiTask(
  actions.DOWNLOAD_FILE,
  api.post,
  '/planner-plans/viewFilePlanner.php',
);

// Save Planner
export const savePlan = apiTask(actions.SAVE_PLAN, api.post, '/planner-plans/savePlan.php');

// Update column label
export const updateMetadata = apiTask(
  actions.UPDATE_METADATA,
  api.post,
  '/planner-plans/editPlanTableMetadata.php',
);

// Update cell content
export const updateCell = apiTask(
  actions.UPDATE_CELL,
  api.post,
  '/planner-plans/editPlanTableLessonValue.php',
);

// Update cell content with file
export const updateCellWithFile = apiTask(
  actions.UPDATE_CELL_WITH_FILE,
  api.post,
  '/planner-plans/editPlanTableLessonValue.php',
);

// Update with new column
export const addColumn = apiTask(
  actions.ADD_COLUMN,
  api.post,
  '/planner-plans/addPlanTableMetadata.php',
);

// add selected row
export const addRow = apiTask(actions.ADD_ROW, api.post, '/planner-plans/addPlanTableLesson.php');

// Move selected column
export const moveColumn = apiTask(
  actions.MOVE_COLUMN,
  api.post,
  '/planner-plans/movePlanTableMetadata.php',
);

// Move selected row
export const moveRow = apiTask(
  actions.MOVE_ROW,
  api.post,
  '/planner-plans/movePlanTableLesson.php',
);

// Remove selected column
export const removeColumn = apiTask(
  actions.REMOVE_COLUMN,
  api.post,
  '/planner-plans/deletePlanTableMetadata.php',
);

// Remove selected cell
export const removeRow = apiTask(
  actions.REMOVE_ROW,
  api.post,
  '/planner-plans/deletePlanTableLesson.php',
);

export function* watchGetPlanner() {
  while (true) {
    const { payload } = yield take(actions.GET_PLANS_TABLE);
    yield fork(getPlanner, payload);
  }
}

export function* watchDownloadFile() {
  while (true) {
    const { payload } = yield take(actions.DOWNLOAD_FILE);
    const { fileName } = payload;
    const { data } = yield call(downloadFileTask, payload);
    if (data) {
      const format = last(fileName.split('.')) || 'pdf';
      const formatLowerCase = format.toLowerCase();
      const fileNameDownload = fileName || `download.${formatLowerCase}`;
      const response = `data:application/${formatLowerCase};base64,${data}`;
      download(response, fileNameDownload, `file/${formatLowerCase}`);
    }
  }
}

export function* watchSavePlan() {
  while (true) {
    const { payload } = yield take(actions.SAVE_PLAN);
    yield fork(savePlan, payload);
  }
}

export function* watchRevertPlanToOriginal() {
  while (true) {
    const { payload } = yield take(actions.REVERT_PLAN_TO_ORIGINAL);
    yield fork(revertPlanToOriginal, payload);
  }
}

export function* watchUpdatePlannerMetadata() {
  while (true) {
    const { payload } = yield take(actions.UPDATE_METADATA);
    yield fork(updateMetadata, payload);
  }
}

export function* watchUpdatePlannerCell() {
  while (true) {
    const { payload } = yield take(actions.UPDATE_CELL);
    yield fork(updateCell, payload);
  }
}

export function* watchUpdatePlannerCellWithFile() {
  while (true) {
    const { payload } = yield take(actions.UPDATE_CELL_WITH_FILE);
    yield fork(updateCellWithFile, payload);
  }
}

export function* watchAddColumn() {
  while (true) {
    const { payload } = yield take(actions.ADD_COLUMN);
    yield fork(addColumn, payload);
  }
}

export function* watchAddRow() {
  while (true) {
    const { payload } = yield take(actions.ADD_ROW);
    yield fork(addRow, payload);
  }
}

export function* watchMoveColumn() {
  while (true) {
    const { payload } = yield take(actions.MOVE_COLUMN);
    yield fork(moveColumn, payload);
  }
}

export function* watchMoveRow() {
  while (true) {
    const { payload } = yield take(actions.MOVE_ROW);
    yield fork(moveRow, payload);
  }
}

export function* watchRemoveColumn() {
  while (true) {
    const { payload } = yield take(actions.REMOVE_COLUMN);
    yield fork(removeColumn, payload);
  }
}

export function* watchRemoveRow() {
  while (true) {
    const { payload } = yield take(actions.REMOVE_ROW);
    yield fork(removeRow, payload);
  }
}
