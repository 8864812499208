import React from 'react';
import PropTypes from 'prop-types';

const NewCard = ({ icon, text, target }) => (
  <a className="card add-new" href={target}>
    <span>
      <div className="add-icon">
        <i className={icon} />
      </div>
      {text}
    </span>
  </a>
);

NewCard.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  target: PropTypes.string
};

NewCard.defaultProps = {
  icon: 'ti-plus',
  text: 'Adicionar escola',
  target: '/'
};

export default NewCard;
