import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.sidebar.plans',
    defaultMessage: 'Planner',
  },
  tablePreviousText: {
    id: 'planner.tablePreviousText',
    defaultMessage: 'Previous',
  },
  tableNextText: {
    id: 'planner.tableNextText',
    defaultMessage: 'Next',
  },
  tableLoadingText: {
    id: 'planner.tableLoadingText',
    defaultMessage: 'Loading',
  },
  tableNoDataText: {
    id: 'planner.tableNoDataText',
    defaultMessage: 'Nothing to show...',
  },
  tablePageText: {
    id: 'planner.tablePageText',
    defaultMessage: 'Page',
  },
  tableOfText: {
    id: 'planner.tableOfText',
    defaultMessage: 'of',
  },
  tableRowsText: {
    id: 'planner.tableRowsText',
    defaultMessage: '',
  },
  edit: {
    id: 'planner.edit',
    defaultMessage: 'Edit',
  },
  upload: {
    id: 'planner.upload',
    defaultMessage: 'Upload',
  },
  editElement: {
    id: 'planner.editElement',
    defaultMessage: 'Edit Element',
  },
  uploadElement: {
    id: 'planner.uploadElement',
    defaultMessage: 'Upload Element',
  },
  remove: {
    id: 'planner.remove',
    defaultMessage: 'Remove',
  },
  print: {
    id: 'planner.print',
    defaultMessage: 'Print',
  },
  download: {
    id: 'planner.download',
    defaultMessage: 'Download',
  },
  preview: {
    id: 'planner.preview',
    defaultMessage: 'View original version',
  },
  original: {
    id: 'planner.original',
    defaultMessage: 'Revert to original',
  },
  savePlan: {
    id: 'planner.savePlan',
    defaultMessage: 'Save plan',
  },
  save: {
    id: 'planner.save',
    defaultMessage: 'Save',
  },
  loading: {
    id: 'planner.loading',
    defaultMessage: 'Loading...',
  },
  filter: {
    id: 'planner.filter',
    defaultMessage: 'Filter...',
  },
  confirm: {
    id: 'planner.confirm',
    defaultMessage: 'Confirm',
  },
  continue: {
    id: 'planner.continue',
    defaultMessage: 'Continue',
  },
  cancel: {
    id: 'planner.cancel',
    defaultMessage: 'Cancel',
  },
  metadataMoveToRight: {
    id: 'planner.metadataMoveToRight',
    defaultMessage: 'Move to right',
  },
  metadataMoveToLeft: {
    id: 'planner.metadataMoveToLeft',
    defaultMessage: 'Move to left',
  },
  metadataAddRightColumn: {
    id: 'planner.metadataAddRightColumn',
    defaultMessage: 'Add right column',
  },
  metadataAddLeftColumn: {
    id: 'planner.metadataAddLeftColumn',
    defaultMessage: 'Add left column',
  },
  rowAddUp: {
    id: 'planner.rowAddUp',
    defaultMessage: 'Add up',
  },
  rowAddDown: {
    id: 'planner.rowAddDown',
    defaultMessage: 'Add Down',
  },
  rowMoveUp: {
    id: 'planner.rowMoveUp',
    defaultMessage: 'Move up',
  },
  rowMoveDown: {
    id: 'planner.rowMoveDown',
    defaultMessage: 'Move Down',
  },
  modalSaveTitle: {
    id: 'planner.modalSaveTitle',
    defaultMessage: 'Plan saved',
  },
  modalSaveMessage: {
    id: 'planner.modalSaveMessage',
    defaultMessage: 'Do you want to schedule your plan?',
  },
  modalCloseButton: {
    id: 'planner.modalCloseButton',
    defaultMessage: 'Close',
  },
  modalBackButton: {
    id: 'planner.modalBackButton',
    defaultMessage: 'Back',
  },
  modalContinueButton: {
    id: 'planner.modalContinueButton',
    defaultMessage: 'Continue',
  },
  modalConfirmTitle: {
    id: 'planner.modalConfirmTitle',
    defaultMessage: 'Reload',
  },
  modalConfirmMessage: {
    id: 'planner.modalConfirmMessage',
    defaultMessage: 'Are yout sure about this action?',
  },
  modalRemoveColumnTitle: {
    id: 'planner.modalRemoveColumnTitle',
    defaultMessage: 'Remove column',
  },
  modalRemoveColumnMessage: {
    id: 'planner.modalRemoveColumnMessage',
    defaultMessage: 'Do you really want to remove this column?',
  },
  modalRemoveRowTitle: {
    id: 'planner.modalRemoveRowTitle',
    defaultMessage: 'Remove cell',
  },
  modalRemoveRowMessage: {
    id: 'planner.modalRemoveRowMessage',
    defaultMessage: 'Do you really want to remove this cell?',
  },
  modalOriginalTitle: {
    id: 'planner.modalOriginalTitle',
    defaultMessage: 'Revert to Original',
  },
  modalOriginalMessage: {
    id: 'planner.modalOriginalMessage',
    defaultMessage:
      'Are you sure you want to revert to the original? All changes made will be lost',
  },
  modalScheduleCancelButton: {
    id: 'planner.modalScheduleCancelButton',
    defaultMessage: 'Cancel',
  },
  modalScheduleConfirmButton: {
    id: 'planner.modalScheduleConfirmButton',
    defaultMessage: 'Confirm',
  },
  lMoveColumnTitle: {
    id: 'planner.lMoveColumnTitle',
    defaultMessage: 'Move element to left',
  },
  lMoveColumnMessage: {
    id: 'planner.lMoveColumnMessage',
    defaultMessage: 'Are yout sure you want to move the element to left?',
  },
  rMoveColumnTitle: {
    id: 'planner.rMoveColumnTitle',
    defaultMessage: 'Move element to right',
  },
  rMoveColumnMessage: {
    id: 'planner.rMoveColumnMessage',
    defaultMessage: 'Are yout sure you want to move the element to right?',
  },
  aMoveRowTitle: {
    id: 'planner.aMoveRowTitle',
    defaultMessage: 'Move element to above',
  },
  aMoveRowMessage: {
    id: 'planner.aMoveRowMessage',
    defaultMessage: 'Are yout sure you want to move the element to above?',
  },
  bMoveRowTitle: {
    id: 'planner.bMoveRowTitle',
    defaultMessage: 'Move element to below',
  },
  bMoveRowMessage: {
    id: 'planner.bMoveRowMessage',
    defaultMessage: 'Are yout sure you want to move the element to below?',
  },
  lAddColumnTitle: {
    id: 'planner.lAddColumnTitle',
    defaultMessage: 'Add left column',
  },
  rAddColumnTitle: {
    id: 'planner.rAddColumnTitle',
    defaultMessage: 'Add right column',
  },
  aAddRowTitle: {
    id: 'planner.aAddRowTitle',
    defaultMessage: 'Add element to above',
  },
  aAddRowMessage: {
    id: 'planner.aAddRowMessage',
    defaultMessage: 'Are yout sure you want to add the element to above?',
  },
  bAddRowTitle: {
    id: 'planner.bAddRowTitle',
    defaultMessage: 'Add element to below',
  },
  bAddRowMessage: {
    id: 'planner.bAddRowMessage',
    defaultMessage: 'Are yout sure you want to add the element to below?',
  },
  bimonthly: {
    id: 'planner.bimonthly',
    defaultMessage: 'Bimestral',
  },
  quarterly: {
    id: 'planner.quarterly',
    defaultMessage: 'Trimestral',
  },
  classManagement: {
    id: 'planner.classManagement',
    defaultMessage: 'Class Management',
  },
  instruction: {
    id: 'plans.instruction',
    defaultMessage: 'Click on any item in the table bellow to see more options',
  },
});
