// @styleguide
// import React from 'react';
// import { FormattedMessage } from 'react-intl';
// import brandEducamos from 'common/logo-educamos.png';
// import brandSm from 'common/logo-sm.png';
// import messages from './appMessages';

// import './AppFooter.scss';

// const AppFooter = () => (
//   <div className="app-footer">
//     <div>
//       {/* <img src={brandEducamos} alt="Educamos" /> */}
//       <img src={brandSm} alt="SM" />
//       <FormattedMessage {...messages.customerService}>
//         {txt => <a href="mailto:atendimento@grupo-sm.com">{txt}</a>}
//       </FormattedMessage>
//     </div>
//   </div>
// );

// AppFooter.propTypes = {};

// export default AppFooter;

import React, {Fragment} from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from 'recompose';

import messages from "./appMessages";

import logoSM from "common/logo-sm-red.png"; 

import AppCookiesConfig from "./AppCookiesConfig";
import AppCookiesBanner from "./AppCookiesBanner";
import "./AppFooter.scss";

import { auth, manageCookies } from '../login/loginActions';

export class AppFooter extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function

  constructor(props) {
    super(props);
    this.state = {
      cookiesBannerShow: (typeof this.props.user.cookiesConfig === 'undefined' ? true : false),
      cookiesConfigShow: false,
      cookiesCustomization: (typeof this.props.user.cookiesConfig !== 'undefined' ? parseInt(this.props.user.cookiesConfig.personalization) : 0),
      cookiesAnalytical: (typeof this.props.user.cookiesConfig !== 'undefined' ? parseInt(this.props.user.cookiesConfig.analytical) : 0),
      cookiesPublicity: (typeof this.props.user.cookiesConfig !== 'undefined' ? parseInt(this.props.user.cookiesConfig.publicity) : 0),
    };
    this.showCookiesConfig = this.showCookiesConfig.bind(this);
    this.dismissCookiesConfig = this.dismissCookiesConfig.bind(this);
    this.saveCookiesConfig = this.saveCookiesConfig.bind(this);
    this.handleCookiesCustomizationChange = this.handleCookiesCustomizationChange.bind(this);
    this.handleCookiesAnalyticalChange = this.handleCookiesAnalyticalChange.bind(this);
    this.handleCookiesPublicityChange = this.handleCookiesPublicityChange.bind(this);
    this.saveCookiesSettings = this.saveCookiesSettings.bind(this);
  }

  showCookiesConfig = () => {
    this.setState({ 
      cookiesConfigShow: true
    });
  }

  dismissCookiesConfig = () => {
    this.setState({ 
      cookiesConfigShow: false,
      cookiesCustomization: (typeof this.props.user.cookiesConfig !== 'undefined' ? parseInt(this.props.user.cookiesConfig.personalization) : 0),
      cookiesAnalytical: (typeof this.props.user.cookiesConfig !== 'undefined' ? parseInt(this.props.user.cookiesConfig.analytical) : 0),
      cookiesPublicity: (typeof this.props.user.cookiesConfig !== 'undefined' ? parseInt(this.props.user.cookiesConfig.publicity) : 0),
     });
  }

  saveCookiesConfig = async (idAccess, personalization, analytical, publicity) => {

    const params = {
      idAccess, personalization, analytical, publicity
    }
    // this.props.manageCookies(params).then(() => {
    //   this.setState({
    //     cookiesBannerShow: false,
    //     cookiesConfigShow: false,
    //     cookiesCustomization: personalization,
    //     cookiesAnalytical: analytical,
    //     cookiesPublicity: publicity,
    //   });
    //   this.props.auth();
    // });
    await this.props.manageCookies(params);

    this.setState({
      cookiesBannerShow: false,
      cookiesConfigShow: false,
      cookiesCustomization: personalization,
      cookiesAnalytical: analytical,
      cookiesPublicity: publicity,
    });
    this.props.auth();
  }

  saveCookiesSettings = () => {
    // API params (idAccess, personalization, analytical, publicity) - Kindergaten idAccess is 3
    this.saveCookiesConfig(2, this.state.cookiesCustomization, this.state.cookiesAnalytical, this.state.cookiesPublicity);
  }

  handleCookiesCustomizationChange() {
    this.setState({ 
      cookiesCustomization: this.state.cookiesCustomization === 1 ? 0 : 1
    });
  }

  handleCookiesAnalyticalChange() {
    this.setState({ 
      cookiesAnalytical: this.state.cookiesAnalytical === 1 ? 0 : 1
    });
  }

  handleCookiesPublicityChange() {
    this.setState({ 
      cookiesPublicity: this.state.cookiesPublicity === 1 ? 0 : 1
    });
  }

  render() {

    return (
      <Fragment>

        {/* <div className={`app-footer ${!this.props.show ? "closed" : ""}`}> */}
        <div className="app-footer"> 

          <AppCookiesConfig 
            dismissCookiesConfig={this.dismissCookiesConfig} 
            cookiesConfigShow={this.state.cookiesConfigShow}
            cookiesCustomization={this.state.cookiesCustomization}
            cookiesAnalytical={this.state.cookiesAnalytical}
            cookiesPublicity={this.state.cookiesPublicity}
            handleCookiesCustomizationChange={this.handleCookiesCustomizationChange}
            handleCookiesAnalyticalChange={this.handleCookiesAnalyticalChange}
            handleCookiesPublicityChange={this.handleCookiesPublicityChange}
            saveCookiesSettings={this.saveCookiesSettings}
          />

          <div className="app-footer-content">

            <AppCookiesBanner 
              saveCookiesConfig={this.saveCookiesConfig}
              showCookiesConfig={this.showCookiesConfig} 
              cookiesBannerShow={this.state.cookiesBannerShow}
            />
            <div className="container">
              <div className="logo-sm">
                <Link className="navbar-brand" to="/">
                  <img src={logoSM} />
                </Link>
              </div>
              <div className="links">
                <h5>
                  <a href="https://www.smeducacao.com.br/contato" target="_blank">
                    <FormattedMessage {...messages.contact} />
                  </a>
                </h5>

                <a href="http://linkte.me/politicaprivacidadeapp" target="_blank">
                  <FormattedMessage {...messages.termsOfUse} />
                </a>

                <span className="sep">|</span>

                <a href="http://linkte.me/politicaprivacidadeapp" target="_blank">
                  <FormattedMessage {...messages.privacyPolicy} />
                </a>

                <span className="sep">|</span>

                <a href="http://linkte.me/politicacookiesapp" target="_blank">
                  <FormattedMessage {...messages.cookiesPolicy} />
                </a>

                <span className="sep">|</span>

                <a onClick={this.showCookiesConfig}>
                  <FormattedMessage {...messages.cookiesSettings} />
                </a>
              </div>
              <div className="social">
                <a href="https://www.facebook.com/SMEducacao" target="_blank">
                  <i className="fab fa-facebook-f" />
                </a>
                {/* <a href="#">
                  <i className="fab fa-twitter" />
                </a> */}
                <a href="https://www.youtube.com/user/edicoessm" target="_blank">
                  <i className="fab fa-youtube" />
                </a>
                <a href="https://www.instagram.com/smeducacao/" target="_blank">
                  <i className="fab fa-instagram" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

AppFooter.propTypes = {
  user: PropTypes.object,
  queryParams: PropTypes.object.isRequired,
  intl: intlShape,
};

const mapStateToProps = ({ app }) => ({
  user: app.user,
  queryParams: app.queryParams,
  token: app.token,
  cookies: app.cookies
});

export default compose(injectIntl, connect(
  mapStateToProps,
  { auth, manageCookies }
))(AppFooter);
