import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Modal, Input } from 'sm-ui';
import messages from './schoolAddModalMessages';
import SchoolAddModalFormContainer from './SchoolAddModalFormContainer';

const Step = {
  CONFIRM_ACCESS_CODE: 1,
  INSERT_ACCESS_CODE: 2,
  INSERT_SCHOOL_DATA: 3,
};

const initialState = {
  step: Step.CONFIRM_ACCESS_CODE,
  lastStep: Step.CONFIRM_ACCESS_CODE,
  codeActivated: null,
  accessCode: '',
  school: null,
};

class SchoolAddModal extends Component {
  static getDerivedStateFromProps(props, state) {
    const { codeActivated } = props;
    if (codeActivated !== state.prevCodeActivated) {
      return {
        prevCodeActivated: codeActivated,
        codeActivated,
      };
    }
    return null;
  }
  state = { ...initialState };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.codeActivated && !prevState.codeActivated) {
      console.log('codigo desativado, fechando');
      this.close();
    }
    if (!this.props.addingSchool && prevProps.addingSchool && !this.props.error.addSchool) {
      console.log('fechando de acordo com a observe');
      this.close();
    }
  }

  gotoStep = newStep => this.setState(({ step }) => ({ step: newStep, lastStep: step }));

  nextStep = () => {
    this.gotoStep(this.state.step + 1);
  };

  previousStep = () => {
    const { lastStep } = this.state;
    this.gotoStep(lastStep);
  };

  lastStep = () => {
    this.gotoStep(Step.INSERT_SCHOOL_DATA);
  };

  close = () => {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
      this.setState({ ...initialState });
    }
  };

  handleChangeAccessCode = e => {
    this.setState({
      accessCode: e.target.value,
    });
  };

  handleSelectSchool = school => {
    this.setState({
      school,
    });
  };

  activateAccessCode = () => {
    this.props.onActivateAccessCode(this.state.accessCode);
  };

  handleRequestAddSchool = () => {
    this.props.onRequestAddSchool(this.state.school);
  };

  renderContent() {
    const { step, accessCode } = this.state;
    const { codeActivated } = this.props;
    switch (step) {
      case Step.CONFIRM_ACCESS_CODE:
        return <FormattedMessage {...messages.message} />;
      case Step.INSERT_ACCESS_CODE:
        return (
          <div style={{ textAlign: 'left' }}>
            <FormattedMessage {...messages.invalidCode}>
              {invalidTxt => (
                <FormattedMessage {...messages.insertAccessCode}>
                  {txt => (
                    <Input
                      label={txt}
                      value={accessCode}
                      onChange={this.handleChangeAccessCode}
                      valid={codeActivated}
                      invalidFeedback={invalidTxt}
                    />
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
          </div>
        );
      default:
        return (
          <SchoolAddModalFormContainer
            onSelectSchool={this.handleSelectSchool}
            onAddSchool={this.close}
          />
        );
    }
  }
  renderButtons() {
    const { step, school } = this.state;
    switch (step) {
      case Step.CONFIRM_ACCESS_CODE:
        return [
          {
            id: 1,
            label: <FormattedMessage {...messages.noButton} />,
            onClick: this.lastStep,
          },
          {
            id: 2,
            label: <FormattedMessage {...messages.yesButton} />,
            onClick: this.nextStep,
            primary: true,
          },
        ];
      case Step.INSERT_ACCESS_CODE:
        return [
          {
            id: 1,
            label: <FormattedMessage {...messages.backButton} />,
            onClick: this.previousStep,
          },
          {
            id: 2,
            label: <FormattedMessage {...messages.confirmButton} />,
            onClick: this.activateAccessCode,
            primary: true,
          },
        ];
      default:
        return [
          {
            id: 1,
            label: <FormattedMessage {...messages.backButton} />,
            onClick: this.previousStep,
          },
          {
            id: 2,
            label: <FormattedMessage {...messages.confirmButton} />,
            onClick: this.handleRequestAddSchool,
            primary: true,
            disabled: !school,
          },
        ];
    }
  }
  render() {
    const buttons = this.renderButtons();
    const content = this.renderContent();
    const { ...rest } = this.props;
    return (
      <Modal
        {...rest}
        onClose={this.close}
        icon="icon pe-7s-home"
        title={<FormattedMessage {...messages.title} />}
        buttons={buttons}
      >
        <div>{content}</div>
      </Modal>
    );
  }
}

SchoolAddModal.propTypes = {};

export default SchoolAddModal;
