import React, { Component } from 'react';
import Select from 'react-select';
import classNames from 'classnames';
import _ from 'lodash';

import { CheckIcon } from '../resources/icons';

import './SchoolSelect.scss';

const SingleValue = ({ data }) => <span style={{ marginLeft: 15 }}>{data.name}</span>;

const OptionLabel = (school, selectedSchools) => {
  const alreadyHasTheSchool = _.find(selectedSchools, elem => elem.idSchool === school.id);
  return (
    <div className="school-select-option-label">
      <strong>
        {school.name} {!!alreadyHasTheSchool && <img src={CheckIcon} width="20px" />}
      </strong>
      <br />
      <span>{school.address}</span>
      <br />
      <span>{school.zipcode}</span>
      <br />
      <span>{school.city}</span> - <span>{school.state}</span>
    </div>
  );
};
class SchoolSelect extends Component {
  static getDerivedStateFromProps(props, state) {
    const { options, value } = props;
    let nextState = null;
    if (options !== state.prevOptions) {
      nextState = {
        prevOptions: options,
        options,
      };
    }
    if (value !== state.value) {
      nextState = {
        ...nextState,
        value,
      };
    }
    return nextState;
  }

  state = {
    options: [],
    value: null,
  };

  filter = () => this.state.options;

  handleChange = value => {
    this.setState({
      value,
    });
    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
  };

  handleInputChange = value => {
    const { options } = this.props;
    this.setState({
      options: options.filter(d => new RegExp(value, 'ig').test(JSON.stringify(d))),
    });
  };

  render() {
    const { label, selectedSchools, valid, invalidFeedback, ...props  } = this.props;
    const { value, options } = this.state;

    return (
      <div className="form-group form-group-mob">
        {label && 
          <label className={classNames('form-control-label', {
            'is-invalid': valid === false,
            'is-valid': valid === true,
          })} >
            {label}
          </label>
        }
        <Select
          {...props}
          value={value}
          onChange={this.handleChange}
          options={options}
          components={{ SingleValue }}
          classNamePrefix="school-select"
          filterOption={this.filter}
          formatOptionLabel={school => OptionLabel(school, selectedSchools)}
          onInputChange={this.handleInputChange}
          className={(valid === false ? "is-invalid" : "") || (valid === true ? "is-valid" : "")}
        />
        {valid === false && <div className={`invalid-feedback ${valid === false ? "show" : "hide"}`}>{invalidFeedback}</div>}
        {/* {valid === true && <div className="valid-feedback">{validFeedback}</div>} */}
      </div>
    );
  }
}

SchoolSelect.propTypes = {};

export default SchoolSelect;
