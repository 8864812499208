// @styleguide

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import moment from 'moment';
import { Provider } from './CalendarContext';

import WeekCalendar from './weekcalendar/WeekCalendar';
import DayCalendar from './daycalendar/DayCalendar';
import MonthCalendar from './monthcalendar/MonthCalendar';
import YearCalendar from './yearcalendar/YearCalendar';
import * as helpers from './calendarHelpers';
import './Calendar.scss';

export const View = {
  DAY: DayCalendar,
  WEEK: WeekCalendar,
  MONTH: MonthCalendar,
  YEAR: YearCalendar,
};

function getInitialDate() {
  const urlParams = queryString.parse(location.search);
  if (urlParams && urlParams.date) {
    return new Date(`${urlParams.date}T00:00:00`);
  }
  return new Date();
}

class Calendar extends Component {
  constructor(props) {
    super(props);
    this.calendar = React.createRef();
    const hours = helpers.createHours();
    this.state = {
      currentDate: getInitialDate(),
      hours,
      defaultContextValues: {
        onDropAppointment: this.handleDropAppointment,
        onRequestStatusAppointment: props.onRequestStatus,
        onRequestRemoveAppointment: props.onRequestRemove,
        onRequestReassignAppointment: props.onRequestReassign,
        onRequestDetailsAppointment: props.onRequestDetails,
        onChangeDate: this.handleChangeDate,
        moment,
        hours,
      },
    };
  }

  getCalendarView = () => this.calendar;

  handleChangeDate = date => {
    if (this.props.onChangeDate) this.props.onChangeDate(date);
  };

  handleDropAppointment = (appointment, cell) => {
    this.props.onRequestReassign(appointment, cell.date, cell.hour);
  };

  handleChangeDate = date => {
    this.setState({
      currentDate: date,
    });
    if (this.props.onChangeDate) {
      this.props.onChangeDate(date);
    }
  };
  render() {
    const { view, appointments, auxMaximusHour } = this.props;
    const { defaultContextValues, currentDate } = this.state;
    let { hours } = this.state;
    const CalendarViewComponent = view;

    const contextValues = {
      ...defaultContextValues,
      appointments,
    };

    hours = hours.filter(el => el <= auxMaximusHour);

    return (
      <div className="calendar-view-container">
        <Provider value={contextValues}>
          <CalendarViewComponent
            ref={this.calendar}
            hours={hours}
            currentDate={currentDate}
            onChangeDate={this.handleChangeDate}
          />
        </Provider>
      </div>
    );
  }
}

Calendar.propTypes = {
  view: PropTypes.oneOf(Object.keys(View).map(k => View[k])),
  onChangeDate: PropTypes.func,
  onRequestDetails: PropTypes.func.isRequired,
  onRequestReassign: PropTypes.func.isRequired,
  onRequestStatus: PropTypes.func.isRequired,
  onRequestRemove: PropTypes.func.isRequired,
};

Calendar.defaultProps = {
  view: View.WEEK,
  onChangeDate: undefined,
};

export default Calendar;
