import React from 'react';
import PropTypes from 'prop-types';

import './CalendarNav.scss';

const CalendarNav = ({ onNext, onPrevious, label }) => (
  <div className="calendar-nav">
    <span className="calendar-nav--label">{label}</span>
    <div className="calendar-nav-actions">
      <button onClick={onPrevious}>
        <i className="fa fa-angle-left" />
      </button>
      <button onClick={onNext}>
        <i className="fa fa-angle-right" />
      </button>
    </div>
  </div>
);

CalendarNav.propTypes = {
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  label: PropTypes.node.isRequired,
};

export default CalendarNav;
