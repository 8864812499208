// @flow

import { action } from 'app/actions';

export const GET_SCHOOLS = 'school/GET_SCHOOLS';
export const GET_SCHOOL_DETAILS = 'school/GET_SCHOOL_DETAILS';
export const ACTIVATE_ACCESS_CODE = 'school/ACTIVATE_ACCESS_CODE';
export const RESET_ADD_MODAL_FORM = 'school/RESET_ADD_MODAL_FORM';
export const GET_STATES = 'school/GET_STATES';
export const GET_CITIES = 'school/GET_CITIES';
export const SEARCH_SCHOOLS = 'school/SEARCH_SCHOOLS';
export const ADD_SCHOOL = 'school/ADD_SCHOOL';
export const SAVE_SCHOOL_DETAILS = 'school/SAVE_SCHOOL_DETAILS';
export const RESET_SCHOOL_FORM = 'school/RESET_SCHOOL_FORM';

export const saveSchoolDetails = payload => action(SAVE_SCHOOL_DETAILS, { payload });
export const addSchool = ({ idUser, idSchool }) =>
  action(ADD_SCHOOL, { payload: { idUser, idSchool } });
export const getSchools = () => action(GET_SCHOOLS);
export const getStates = (idCountry: number) => action(GET_STATES, { payload: { idCountry } });
export const getCities = (idCountry: number, state: string) =>
  action(GET_CITIES, { payload: { state, idCountry } });
export const searchSchools = (idCountry: number, state: string, city: string, keywords: string) =>
  action(SEARCH_SCHOOLS, { payload: { state, idCountry, keywords, city } });
export const resetAddModalForm = () => action(RESET_ADD_MODAL_FORM);
export const activateAccessCode = (accessCode: string) =>
  action(ACTIVATE_ACCESS_CODE, { payload: { accessCode } });
export const getSchoolDetails = (idUserSchool: number) =>
  action(GET_SCHOOL_DETAILS, { payload: { idUserSchool } });
export const resetSchoolForm = () => action(RESET_SCHOOL_FORM);
