import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Alert.scss';

export const types = [
  'primary',
  'secondary',
  'success',
  'warning',
  'danger',
  'info',
  'light',
  'dark',
];

class Alert extends Component {
  componentDidMount() {
    const { timeout, onExpire } = this.props;
    if (timeout) {
      setTimeout(() => {
        if (onExpire) {
          onExpire();
        }
      }, timeout);
    }
  }
  render() {
    const { type, children, title, onClose, className } = this.props;
    return (
      <div className={classNames('alert', `alert-${type}`, className)}>
        {title && <h4 className="alert-heading">{title}</h4>}
        {children}
        {onClose && (
          <button
            type="button"
            className="close alert-close-button"
            data-dismiss="alert"
            aria-label="Close"
            onClick={onClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        )}
      </div>
    );
  }
}
Alert.propTypes = {
  type: PropTypes.oneOf([undefined].concat(types)),
  title: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
  className: PropTypes.string,
  timeout: PropTypes.number,
  onExpire: PropTypes.func,
};

Alert.defaultProps = {
  type: 'primary',
  title: undefined,
  children: undefined,
  onClose: undefined,
  className: undefined,
  timeout: undefined,
  onExpire: undefined,
};
export default Alert;
