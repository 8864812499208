import React from 'react';
import { FormattedMessage } from 'react-intl';

import LoginContainer from 'login/LoginContainer';
import ExternalAuth from 'login/ExternalAuth';
import PlansContainer from 'plans/PlansContainer';
import PlannerContainer from 'planner/PlannerContainer';
import ReportsContainer from 'reports/ReportsContainer';
import ScheduleContainer from 'schedule/ScheduleContainer';
import CalendarContainer from 'schedule/calendar/CalendarContainer';
import SchoolContainer from 'school/SchoolContainer';
import RegisterContainer from 'register/RegisterContainer';
import ConfigurationContainer from 'configuration/ConfigurationContainer';
import NotificationContainer from 'notification/NotificationContainer';
import ProfileContainer from 'profile/ProfileContainer';
import SchoolViewContainer from 'school/SchoolViewContainer';
import AppContainer from './AppContainer';

import ChangePassword from 'changePassword/ChangePasswordContainer';

import messages from './appMessages';

const isProd = process.env.SM_ENV === 'production';

export const loginRoutes = [
  {
    path: '/login',
    exact: true,
    component: LoginContainer,
  },
  {
    path: '/auth',
    exact: true,
    component: ExternalAuth,
  },
  {
    path: '/register',
    exact: true,
    component: RegisterContainer,
  },
  {
    path: '/change-password',
    exact: true,
    component: ChangePassword,
  },
];

/** FOR TEMPORARILY USE, REMOVE WHEN IT IS READY */
const stageRoutes = [
  {
    path: '/reports',
    component: ReportsContainer,
    sidebar: {
      label: <FormattedMessage {...messages.sidebarReports} />,
      icon: <i className="ti-bar-chart" />,
    },
  },
];

export const appRoutes = [
  {
    path: '/',
    component: AppContainer,
    routes: [
      {
        path: '/plans',
        component: PlansContainer,
        exact: true,
        sidebar: {
          label: <FormattedMessage {...messages.sidebarPlans} />,
          icon: <i className="ti-write" />,
        },
        active: location => ['/plans', '/planner'].some(path => location.pathname.startsWith(path)),
        className: 'd-none d-lg-block',
      },
      {
        path: '/planner/:id',
        component: PlannerContainer,
        expanded: true,
      },
      {
        path: '/schedule',
        exact: true,
        component: ScheduleContainer,
        expanded: false,
        sidebar: {
          label: <FormattedMessage {...messages.sidebarSchedule} />,
          icon: <i className="ti-agenda" />,
        },
      },
      {
        path: '/calendar',
        component: CalendarContainer,
        expanded: true,
      },
      {
        path: '/school',
        exact: true,
        component: SchoolContainer,
        sidebar: {
          label: <FormattedMessage {...messages.sidebarSchool} />,
          icon: <i className="ti-home" />,
        },
      },
      {
        path: '/school/:id',
        component: SchoolViewContainer,
      },
      {
        path: '/configuration',
        component: ConfigurationContainer,
      },
      {
        path: '/notification',
        component: NotificationContainer,
      },
      {
        path: '/profile',
        component: ProfileContainer,
      },
    ].concat(isProd ? [] : stageRoutes),
  },
];
