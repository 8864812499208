import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'calendarErrorEventModal.title',
    defaultMessage: 'Error',
  },
  closeButton: {
    id: 'calendarErrorEventModal.button.close',
    defaultMessage: 'Close',
  },
  message: {
    id: 'calendarErrorEventModal.message',
    defaultMessage: 'You need to select a course and a plan before adding an event.',
  },
});
