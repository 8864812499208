/* eslint-disable */
import React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from '../plannerMessages';

import './MenuElement.scss';

const MenuRow = ({
  isOpen,
  index,
  arrayLength,
  onMouseLeave,
  onRemove,
  onAddUp,
  onAddDown,
  onMoveUp,
  onMoveDown,
}) => (
  <React.Fragment>
    {isOpen ? (
      <div
        className="element-modal"
        onMouseLeave={onMouseLeave}
        style={{ right: 'unset', left: '30px' }}
      >
        <div>
          {index !== 0 && (
            <div className="element-modal-btn" onClick={onMoveUp} style={{ padding: '2px' }}>
              <FormattedMessage {...messages.rowMoveUp} />
            </div>
          )}
          {index + 1 !== arrayLength && (
            <div className="element-modal-btn" onClick={onMoveDown} style={{ padding: '2px' }}>
              <FormattedMessage {...messages.rowMoveDown} />
            </div>
          )}
          <div className="element-modal-btn" onClick={onAddUp} style={{ padding: '2px' }}>
            <FormattedMessage {...messages.rowAddUp} />
          </div>
          <div className="element-modal-btn" onClick={onAddDown} style={{ padding: '2px' }}>
            <FormattedMessage {...messages.rowAddDown} />
          </div>
          <div className="element-modal-btn" onClick={onRemove} style={{ padding: '2px' }}>
            <FormattedMessage {...messages.remove} />
          </div>
        </div>
      </div>
    ) : null}
  </React.Fragment>
);

export default MenuRow;
