import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'login.title',
    defaultMessage: 'Class Planner',
  },
  nameField: {
    id: 'login.field.name',
    defaultMessage: 'Name / User',
  },
  enter: {
    id: 'login.enter',
    defaultMessage: 'Enter',
  },
  passwordField: {
    id: 'login.field.password',
    defaultMessage: 'Password',
  },
  signin: {
    id: 'login.signin',
    defaultMessage: 'Sign in',
  },
  signup: {
    id: 'login.signup',
    defaultMessage: 'Sign up',
  },
  errorRequired: {
    id: 'login.error.required',
    defaultMessage: 'Required field.',
  },
  errorInvalidEmail: {
    id: 'login.error.invalidEmail',
    defaultMessage: 'Invalid email.',
  },
  errorCode29: {
    id: 'login.error.code.29',
    defaultMessage: 'Permission denied.',
  },
  errorCode1: {
    id: 'login.error.code.1',
    defaultMessage: 'Invalid user and/or password.',
  },
  errorCode5: {
    id: 'login.error.code.5',
    defaultMessage: 'User not found',
  },
  errorCode500: {
    id: 'login.error.code.500',
    defaultMessage: 'Service unavailable. Try again later.',
  },
  errorCode24: {
    id: 'login.error.code.24',
    defaultMessage: 'Error to send your email, try again later.',
  },
  errorCode25: {
    id: 'login.error.code.25',
    defaultMessage: 'This e-mail could not be found.',
  },
  errorCode401: {
    id: "login.error.errorCode401",
    defaultMessage: "Invalid credentials"
  },
  error400: {
    id: "login.error.error400",
    defaultMessage: "Enter your login and/or password"
  },
  error401: {
    id: "login.error.error401",
    defaultMessage: "Invalid credentials"
  },
  error4011: {
    id: "login.error.error4011",
    defaultMessage: "Maximum number of attempts reached. <br/> Please click on I forgot my password and reset your password."
  },
  error40101: {
    id: "login.error.error40101",
    defaultMessage: "Invalid credentials, please try again. <br/> You have 4 more attempts."
  },
  error40102: {
    id: "login.error.error40102",
    defaultMessage: "Invalid credentials, please try again. <br/> You have 3 more attempts."
  },
  error40103: {
    id: "login.error.error40103",
    defaultMessage: "Invalid credentials, please try again. <br/> You have 2 more attempts."
  },
  error40104: {
    id: "login.error.error40104",
    defaultMessage: "Invalid credentials, please try again. <br/> You have 1 more attempt."
  },
  error503: {
    id: "login.error.error503",
    defaultMessage: "Service unavailable, try again later"
  },
  errorOnlyCi: {
    id: "login.error.errorOnlyCi",
    defaultMessage: "Hello, you are a user of SM Learning, access Evaluation System from the book."
  },
  expiredTitle: {
    id: "login.error.forgot.expiredTitle",
    defaultMessage: "Password Expired"
  },
  expiredText: {
    id: "login.error.forgot.expiredText",
    defaultMessage: "Your password has expired, we sent an email with a link to create a new password."
  },
  invalidCredentials: {
    id: "login.error.forgot.invalidCredentials",
    defaultMessage: "Invalid Credentials"
  },
  forgotPassword: {
    id: 'login.forgot.title',
    defaultMessage: 'Forgot password',
  },
  close: {
    id: 'login.modal.close',
    defaultMessage: 'Close',
  },
  send: {
    id: 'login.modal.send',
    defaultMessage: 'Send',
  },
  forgotMessage: {
    id: 'login.forgot.Message',
    defaultMessage:
      'If you have forgotten your password, please enter your e-mail address to receive your access data.',
  },
  forgotEmail: {
    id: 'login.forgot.field',
    defaultMessage: 'Email',
  },
  emailSent: {
    id: 'login.forgot.emailSent',
    defaultMessage: 'A message has been sent to {emailForgot}',
  },
  errorInvalidCredentialsCI: {
    id: "login.error.errorInvalidCredentialsCI",
    defaultMessage: "Invalid credentials, you must use the same credentials for accessing SM Learning."
  },
});
