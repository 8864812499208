import React from 'react';
import { compose } from 'recompose';
import { injectIntl } from 'react-intl';
import { withFormik } from 'formik';
import find from 'lodash/find';

import Role from 'app/role';

import { AvatarInput, Input, InputGroup, Button, Row, Col, RoundButton, Alert } from 'sm-ui';
import SchoolTeacherCard from './SchoolTeacherCard';

import placeholderSrc from 'common/placeholder-school.svg';

import { EditionStatus } from './schoolReducer';

import messages from './schoolFormMessages';

import './SchoolForm.scss';

const SchoolCoordinatorCard = props => <SchoolTeacherCard {...props} />;

const calendarIcon = <i className="ti-calendar" />;
const requiredFields = ['schoolYearStart', 'schoolYearEnd', 'vacationStart', 'vacationEnd'];
const optionalFields = ['vacation2Start', 'vacation2End'];
const allFields = requiredFields.concat(optionalFields);

const enhanceForm = withFormik({
  mapPropsToValues: ({ data, isClassManagementModal, schools, idSchool, ...props }) => {
    if (isClassManagementModal) {
      const selectedSchool = find(schools, s => s.idSchool === idSchool);

      if (!selectedSchool) return;

      return {
        ...data,
        name: selectedSchool.name,
        address: selectedSchool.address,
        city: selectedSchool.city,
        state: selectedSchool.state,
        accessCode: selectedSchool.accessCode,
        idSchool: selectedSchool.idSchool,
        idUser: selectedSchool.idUser,
        idUserSchool: selectedSchool.idUserSchool,
        schoolYearEnd: '',
        schoolYearStart: '',
        vacationEnd: '',
        vacationStart: '',
      };
    }

    return { ...data };
  },
  validate: values => {
    const errors = {};
    requiredFields.forEach(field => {
      if (!values[field]) {
        errors[field] = 'errorRequired';
      }
    });
    allFields.forEach((field, index) => {
      if (index % 2 !== 0) {
        if (values[allFields[index - 1]] > values[field]) {
          errors[allFields[index - 1]] = 'errorStartAfterEnd';
          errors[field] = 'errorStartAfterEnd';
        }
      }
    });
    if (values.vacation2Start && !values.vacation2End) {
      errors.vacation2End = 'errorMustInform';
    }
    if (!values.vacation2Start && values.vacation2End) {
      errors.vacation2Start = 'errorMustInform';
    }
    return errors;
  },
  handleSubmit: (values, { props }) => props.onSubmit(values),
  enableReinitialize: true,
});

function isCodeAllowed(role) {
  return [Role.COORDINATOR, Role.ADMINISTRADOR].includes(parseInt(role, 0));
}

class SchoolForm extends React.PureComponent {
  state = {
    hasAditionalVacationPeriod: false,
    alerts: [],
  };

  componentDidUpdate(prevProps) {
    const { intl } = this.props;
    if (
      prevProps.statusEdition !== this.props.statusEdition &&
      this.props.statusEdition === EditionStatus.SAVED
    ) {
      this.alert(intl.formatMessage(messages.successfullySaved), 'success');
    }
  }

  getMessageError = field => {
    const { intl, errors } = this.props;
    if (errors && errors[field]) {
      return intl.formatMessage(messages[errors[field]]);
    }
    return undefined;
  };

  addVacationPeriod = () => {
    this.setState({ hasAditionalVacationPeriod: true });
  };

  alert = (message, type) => {
    this.setState(({ alerts }) => ({ alerts: alerts.concat({ type, message, timeout: 3000 }) }));
  };

  removeAlert = alert => () => {
    this.setState(({ alerts }) => ({ alerts: alerts.filter(a => a !== alert) }));
  };

  isValid = field => {
    const { errors, submitCount } = this.props;
    if (errors && errors[field] && submitCount > 0) {
      return false;
    }
  };

  render() {
    const { hasAditionalVacationPeriod, alerts } = this.state;
    const {
      intl,
      role,
      values,
      handleChange,
      handleBlur,
      handleSubmit,
      isValid,
      errors,
      isClassManagementModal,
    } = this.props;
    const {
      accessCode,
      address,
      city,
      name,
      schoolDays,
      schoolYearEnd,
      schoolYearStart,
      state,
      teachers,
      coordinators,
      vacation2End,
      vacation2Start,
      vacationEnd,
      vacationStart,
    } = values || {};

    const isNotCoordinator = role !== 2;
    const hasTeacher = !!(teachers && teachers.length > 0);
    const hasCoordinator = !!coordinators;

    return (
      <Row>
        {!isClassManagementModal && (
          <Col sm={3} className="text-center">
            <AvatarInput
              placeholderSrc={placeholderSrc}
              label={intl.formatMessage(messages.updatePhoto)}
              readOnly
            />
            <br />
            {isCodeAllowed(role) && (
              <div className="cod text-center">
                {intl.formatMessage(messages.code)} {accessCode}
                <i className="pe-7s-check" />
              </div>
            )}
            <br />
            {schoolDays && (
              <div className="dias-letivos">
                <div className="text">{intl.formatMessage(messages.schoolDays)}</div>
                <div className="number">{schoolDays}</div>
              </div>
            )}
          </Col>
        )}
        <Col sm={isClassManagementModal ? 12 : 9}>
          {!isClassManagementModal && (
            <React.Fragment>
              <h2 className="section-title">
                <i className="pe-7s-tools" />
                {intl.formatMessage(messages.schoolData)}
              </h2>
              <form action="nova-escola.pug" method="post">
                <Input label={intl.formatMessage(messages.name)} value={name} readOnly />
                <Input label={intl.formatMessage(messages.address)} value={address} readOnly />
                <div className="row">
                  <div className="col-8">
                    <Input label={intl.formatMessage(messages.city)} value={city} readOnly />
                  </div>
                  <div className="col-4">
                    <Input label={intl.formatMessage(messages.state)} value={state} readOnly />
                  </div>
                </div>
              </form>
            </React.Fragment>
          )}
          {alerts.map(alert => (
            <Alert
              type={alert.type}
              onClose={this.removeAlert(alert)}
              onExpire={this.removeAlert(alert)}
              timeout={alert.timeout}
            >
              {alert.message}
            </Alert>
          ))}
          <div className="school-form-vacation">
            <h2
              className="section-title mt-5"
              style={isClassManagementModal ? { textAlign: 'left' } : {}}
            >
              <i className="pe-7s-tools" />
              {intl.formatMessage(messages.schoolYear)}
            </h2>
            <Button type="submit" variant="outline" onClick={handleSubmit} disabled={!isValid}>
              {intl.formatMessage(messages.save)}
            </Button>
            <Row>
              <Col>
                <InputGroup
                  icon={calendarIcon}
                  label={intl.formatMessage(messages.startSchoolYear)}
                  name="schoolYearStart"
                  valid={this.isValid('schoolYearStart')}
                  invalidFeedback={this.getMessageError('schoolYearStart')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="date"
                  value={schoolYearStart}
                  readOnly={isNotCoordinator && hasCoordinator}
                  disabled={isNotCoordinator && hasCoordinator}
                />
              </Col>
              <Col>
                <InputGroup
                  icon={calendarIcon}
                  label={intl.formatMessage(messages.endSchoolYear)}
                  name="schoolYearEnd"
                  valid={this.isValid('schoolYearEnd')}
                  invalidFeedback={this.getMessageError('schoolYearEnd')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="date"
                  value={schoolYearEnd}
                  readOnly={isNotCoordinator && hasCoordinator}
                  disabled={isNotCoordinator && hasCoordinator}
                />
              </Col>
            </Row>
            <Row className="school-form-row-vacation mt-2">
              <Col>
                <InputGroup
                  icon={calendarIcon}
                  label={intl.formatMessage(messages.startVacation)}
                  name="vacationStart"
                  valid={this.isValid('vacationStart')}
                  invalidFeedback={this.getMessageError('vacationStart')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="date"
                  value={vacationStart}
                  readOnly={isNotCoordinator && hasCoordinator}
                  disabled={isNotCoordinator && hasCoordinator}
                />
              </Col>
              <Col>
                <InputGroup
                  icon={calendarIcon}
                  label={intl.formatMessage(messages.endVacation)}
                  name="vacationEnd"
                  valid={this.isValid('vacationEnd')}
                  invalidFeedback={this.getMessageError('vacationEnd')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="date"
                  value={vacationEnd}
                  readOnly={isNotCoordinator && hasCoordinator}
                  disabled={isNotCoordinator && hasCoordinator}
                />
              </Col>
              {!hasAditionalVacationPeriod &&
                !hasCoordinator && (
                  <div className="school-form-add-vacation">
                    <RoundButton
                      title={intl.formatMessage(messages.addVacationPeriod)}
                      onClick={this.addVacationPeriod}
                    >
                      <i className="ti-plus" />
                    </RoundButton>
                  </div>
                )}
            </Row>
            {hasAditionalVacationPeriod && (
              <Row className="mt-2">
                <Col>
                  <InputGroup
                    icon={calendarIcon}
                    label={intl.formatMessage(messages.startVacation)}
                    name="vacation2Start"
                    valid={this.isValid('vacation2Start')}
                    invalidFeedback={this.getMessageError('vacation2Start')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="date"
                    value={vacation2Start}
                  />
                </Col>
                <Col>
                  <InputGroup
                    icon={calendarIcon}
                    label={intl.formatMessage(messages.endVacation)}
                    type="date"
                    name="vacation2End"
                    valid={this.isValid('vacation2End')}
                    invalidFeedback={this.getMessageError('vacation2End')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={vacation2End}
                  />
                </Col>
              </Row>
            )}
          </div>
          {hasTeacher && (
            <React.Fragment>
              <h2 className="section-title mt-5">
                <i className="pe-7s-tools" />
                {intl.formatMessage(messages.teachers)}
              </h2>
              {teachers.map(teacher => (
                <Row key={teacher.fullname || teacher.fullName}>
                  <Col sm={12}>
                    <SchoolTeacherCard {...teacher} />
                  </Col>
                </Row>
              ))}
            </React.Fragment>
          )}
          {hasCoordinator && (
            <React.Fragment>
              <h2 className="section-title mt-5">
                <i className="pe-7s-tools" />
                {intl.formatMessage(messages.coordinators)}
              </h2>
              <Row>
                <Col sm={12}>
                  <SchoolCoordinatorCard {...coordinators} />
                </Col>
              </Row>
            </React.Fragment>
          )}
        </Col>
      </Row>
    );
  }
}

export default compose(
  enhanceForm,
  injectIntl,
)(SchoolForm);
