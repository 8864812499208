import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { injectIntl, intlShape } from 'react-intl';
import { Loader } from 'sm-ui';
import first from 'lodash/first';
import groupBy from 'lodash/groupBy';
import RegisterForm from './RegisterForm';
import RegisterSchoolForm from './RegisterSchoolForm';
import {
  getStates,
  getCities,
  getSchools,
  getSchoolDetails,
  searchSchools,
  registerUser,
  resetForm,
  getCountries,
  registerSchool
} from './registerActions';
import { login } from '../login/loginActions';
import Login from '../login/Login';




class RegisterContainer extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    this.props.getCountries();
  }

  componentWillUnmount() {
    this.props.resetForm();
  }

  handleSubmit = async values => {
    await this.props.registerUser(values);

    // setTimeout(async () => {
    //   if(this.props.status === 3) {
    //     this.setState({ isLoading: true });
    //     const form = {
    //       email: values.email,
    //       password: values.password,
    //       access: 2
    //     };
  
    //     await this.props.login(form);
    //     this.setState({ isLoading: false });
    //   }
    // }, 800)

  };

  login = async values => {
    this.setState({ isLoading: true });
    let form = {
      email: values.email,
      password: values.password
    };

    await this.props.login(form);
    this.setState({ isLoading: false });
  };

  handleSubmitRegisterSchool = async values => {
    this.props.handleSubmitRegisterSchool(values);
  };

  render() {
    const { intl, countries, onlyRegisterSchool, ...props } = this.props;
    let footer = false;

    countries.forEach(el => {
      if (el.access === '2' && el.emailCustomerService !== null && intl.locale === el.language) {
        footer = true;
      }
    });

    countries.sort((a, b) => a.name - b.name);

    const countriesGroupByName = groupBy(countries, 'name');

    const selectCountries = Object.keys(countriesGroupByName).map(el => ({
      id: first(countriesGroupByName[el]).idCountry,
      value: first(countriesGroupByName[el]).idCountry,
      label: el,
    }));

    return (
      !onlyRegisterSchool ? 
        (
          <Login footer={footer}>
            <Loader show={this.state.isLoading} />
            <RegisterForm
              {...props}
              langCountries={countries}
              selectCountries={selectCountries}
              countries={countriesGroupByName}
              onSubmit={this.handleSubmit}
              login={this.login}
              statusRegister={this.props.status}
            />
          </Login>
        ) : (
          <RegisterSchoolForm
            {...props}
            langCountries={countries}
            selectCountries={selectCountries}
            countries={countriesGroupByName}
            onSubmit={this.handleSubmitRegisterSchool}
            onlyRegisterSchool={onlyRegisterSchool}
          />
      )
    );
  }
}

RegisterContainer.propTypes = {
  intl: intlShape.isRequired,
};

const mapStateToProps = ({ register }) => ({ ...register });

export default compose(
  connect(
    mapStateToProps,
    {
      getStates,
      getCities,
      getSchools,
      getSchoolDetails,
      searchSchools,
      registerUser,
      resetForm,
      getCountries,
      login,
      registerSchool
    },
  ),
  injectIntl,
)(RegisterContainer);
