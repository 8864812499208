// @styleguide
import React, { Fragment } from 'react';
import { Modal, Loader } from 'sm-ui';
import { injectIntl, FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import moment from 'moment';

import calendarMessages from './calendarMessages';
import messages from './calendarEventDetailsModalMessages';

const CalendarEventDetailsModal = ({ details, intl, onClose, appointment, ...props }) => {
  const titleMessageKey = get(appointment, 'type.titleMessageKey');
  return (
    <Modal
      title={titleMessageKey ? intl.formatMessage(calendarMessages[titleMessageKey]) : ''}
      subtitle={get(appointment, 'content')}
      icon={get(appointment, 'type.icon')}
      large
      buttons={[
        {
          label: intl.formatMessage(calendarMessages.reassign),
          onClick: () => props.onRequestReassign(appointment),
          className: 'd-sm-none',
        },
        {
          label: intl.formatMessage(calendarMessages.status),
          onClick: () => props.onRequestStatus(appointment),
          className: 'd-sm-none',
        },
        {
          label: intl.formatMessage(calendarMessages.remove),
          onClick: () => props.onRequestRemove(appointment),
          className: 'd-sm-none',
        },
        {
          label: intl.formatMessage(messages.close),
          onClick: onClose,
          className: 'd-none d-sm-flex',
          primary: true,
        },
      ]}
      onClose={onClose}
      {...props}
    >
      <div>
        {details && (
          <div>
            <div>
              <FormattedMessage {...messages.date}>
                {txt => <strong>{txt}</strong>}
              </FormattedMessage>
            </div>
            <div>
              {moment(details.date).format(intl.locale === 'en' ? 'YYYY/MM/DD' : 'DD/MM/YYYY')}
            </div>
            <hr />
            <div>
              <FormattedMessage {...messages.duration}>
                {txt => <strong>{txt}</strong>}
              </FormattedMessage>
            </div>
            <div>
              <FormattedMessage {...messages.classes}>
                {txt => (
                  <span>
                    {details.classes} {txt}
                  </span>
                )}
              </FormattedMessage>
            </div>
            <hr />
            {details.comments && (
              <Fragment>
                <div>
                  <FormattedMessage {...messages.about}>
                    {txt => <strong>{txt}</strong>}
                  </FormattedMessage>
                </div>
                <div>{details.comments}</div>
              </Fragment>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default injectIntl(CalendarEventDetailsModal);
