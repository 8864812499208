import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { capitalize } from 'utils/strings';
import moment from 'moment';
import CardMonth from './CardMonth';
import DayCalendarRow from './DayCalendarRow';
import { withDragDropContext } from '../CalendarContext';
import CalendarNav from '../CalendarNav';

import messages from './dayCalendarMessages';
import './DayCalendar.scss';

function toDayDate(date) {
  return moment(date)
    .startOf('day')
    .toDate();
}

class DayCalendar extends Component {
  static getDerivedStateFromProps(props, state) {
    const { currentDate } = props;
    if (currentDate !== state.prevCurrentDate) {
      return {
        prevCurrentDate: toDayDate(currentDate),
        currentDate,
      };
    }
    return null;
  }

  state = {
    currentDate: toDayDate(),
  };

  nextDate = () => {
    const nextCurrentDate = moment(this.state.currentDate)
      .add(1, 'day')
      .startOf('day')
      .toDate();
    this.selectDate(nextCurrentDate);
  };

  previousDate = () => {
    const nextCurrentDate = moment(this.state.currentDate)
      .subtract(1, 'day')
      .startOf('day')
      .toDate();
    this.selectDate(nextCurrentDate);
  };

  selectDate = date => {
    this.setState({
      currentDate: date,
    });
    if (this.props.onChangeDate) {
      this.props.onChangeDate(date);
    }
  };

  render() {
    const { hours } = this.props;
    const { currentDate } = this.state;
    const oneMonthFromNow = moment(currentDate)
      .add(1, 'month')
      .toDate();
    const twoMonthsFromNow = moment(currentDate)
      .add(2, 'month')
      .toDate();
    return (
      <div>
        <CalendarNav
          label={
            <FormattedMessage
              {...messages.period}
              values={{
                day: currentDate.getDate(),
                month: capitalize(moment(currentDate).format('MMMM')),
                weekDay: capitalize(moment(currentDate).format('dddd')),
                year: currentDate.getFullYear(),
              }}
            >
              {txt => <span>{unescape(txt)}</span>}
            </FormattedMessage>
          }
          onPrevious={this.previousDate}
          onNext={this.nextDate}
        />
        <div className="calendar day-calendar-wrapper">
          <div className="months-wrapper d-none d-md-block">
            <CardMonth
              month={currentDate.getMonth() + 1}
              year={currentDate.getFullYear()}
              onClickDate={this.selectDate}
            />
            <CardMonth
              month={oneMonthFromNow.getMonth() + 1}
              year={oneMonthFromNow.getFullYear()}
              onClickDate={this.selectDate}
            />
            <CardMonth
              month={twoMonthsFromNow.getMonth() + 1}
              year={twoMonthsFromNow.getFullYear()}
              onClickDate={this.selectDate}
            />
          </div>
          <div className="class-wrapper">
            <div className="day-header">
              <div className="week-name">{capitalize(moment(currentDate).format('dddd'))}</div>
              <div className="week-day">{currentDate.getDate()}</div>
            </div>
            {hours.map(hour => (
              <DayCalendarRow hour={hour} key={hour} date={currentDate} />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

DayCalendar.propTypes = {
  hours: PropTypes.arrayOf(PropTypes.oneOf([PropTypes.string, PropTypes.number])),
};

DayCalendar.defaultProps = {
  hours: [],
};

export default withDragDropContext(DayCalendar);
