import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { promisify } from 'app/actions';
import queryString from 'query-string';
import { withRouter, Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { animateScroll } from 'react-scroll';
import { Loader, Card, Navtabs, Select, SortBar } from 'sm-ui';
import { Row, Col } from 'react-bootstrap';
import { isNull } from 'util';
import Role from 'app/role';
import moment from 'moment';

import {
  getPlansScheduled,
  getStatusPlanScheduled,
  getAllScheduleAppointments,
} from './scheduleActions';
import './Schedule.scss';
import messages from './scheduleMessages';
import * as helpers from './scheduleHelpers';
import ScheduleStatusPlanTooltip from './ScheduleStatusPlanTooltip';

const CURRENT_YEAR = moment(Date.now()).format('YYYY');

const initialState = {
  activeTab: CURRENT_YEAR,
  activeTabTeacher: parseInt(CURRENT_YEAR),
  displayView: 'grid',
  filter: {},
  isOpen: false,
  position: null,
  isLoading: false,
  prevYearsTeacher: [],
};

function isFilterAdminAllowed(role) {
  return [Role.COORDINATOR, Role.ADMINISTRADOR].includes(parseInt(role, 0));
}

class ScheduleContainer extends PureComponent {
  static getDerivedStateFromProps(props, state) {
    const { years, yearsTeacher } = props;

    if (years !== state.prevYears) {
      return {
        prevYears: years,
        activeTab: years[0] || new Date().getFullYear(),
      };
    }

    if (yearsTeacher !== state.prevYearsTeacher) {
      return {
        prevYearsTeacher: yearsTeacher,
        activeTabTeacher: yearsTeacher[0] || new Date().getFullYear(),
      };
    }
    return null;
  }

  state = initialState;

  componentDidMount() {
    this.fetchPlans();
  }

  fetchPlans = () => {
    const { filter } = this.state;
    this.props.getPlansScheduled(filter);
  };

  handleFilterChange = event => {
    const { name } = event.target;
    const { value } = event.target;
    this.setState(
      ({ filter }) => ({ filter: { ...filter, [name]: value } }),
      () => {
        this.fetchPlans();
      },
    );
  };

  openSchedule = plansSchedule => () => {
    const { history } = this.props;
    const { idPlanScheduled, idPlan, idCourse } = plansSchedule;
    history.push(`/calendar?${queryString.stringify({ idPlanScheduled, idPlan, idCourse })}`);
  };

  openStatusPlan = ({ idCourse, idPlan, idPlanSchedule }, position) => {
    if (isNull(this.state.position) || this.state.position !== position) {
      this.props.getStatusPlanScheduled({ idsCourses: [idCourse], idPlan, idPlanSchedule });
      this.setState({ position });
    }
  };

  handleTabClick = ({ value }) => this.setState({ activeTab: value });

  handleTabClickTeacher = ({ value }) => this.setState({ activeTabTeacher: value });

  handleDisplayChange = displayNumber => {
    const displayView = displayNumber === 1 ? 'grid' : 'list';
    this.setState({ displayView });
  };

  handleGetAllAppointments = () => {
    const { plansScheduled } = this.props;
    const plansScheduledIds = plansScheduled.map(elem => ({
      idPlan: elem.idPlan,
      idCourse: elem.idCourse,
      idPlanScheduled: elem.idPlanScheduled,
    }));

    this.setState({ isLoading: true });

    Promise.all(
      plansScheduledIds.map(elem => {
        this.props.getAllScheduleAppointments({ ...elem });
      }),
    ).then(() => {
      setTimeout(() => {
        this.setState({ isLoading: false }, () => {
          this.props.history.push(`/calendar`);
        });
      }, 1000 * plansScheduledIds.length);
    });
  };

  renderCard({ plan, position }) {
    const { displayView } = this.state;
    const { statusContent } = this.props;
    const key = helpers.makeKey({ ...plan, idsCourses: [plan.idCourse] });
    return (
      <Col lg={displayView === 'grid' ? 4 : 12}>
        <Card
          type="diary"
          // displayView={displayView}
          {...plan}
          statusExtended
          statusComponent={() => (
            <ScheduleStatusPlanTooltip
              position={position}
              onShow={() => this.openStatusPlan(plan, position)}
              content={statusContent[key]}
            />
          )}
          mainColor="#88B2D6"
          fontColor="#ffffff"
          editable={false}
          onClick={this.openSchedule(plan)}
          key={`${position}-${key}`}
        />
      </Col>
    );
  }
  render() {
    const { activeTab, activeTabTeacher, displayView } = this.state;
    const {
      disciplinesFilter,
      levelsFilter,
      teachersFilter,
      plansScheduled,
      teachersDisciplinesFilter,
      teachersLevelsFilter,
      teachersPlansScheduled,
      role,
      years,
      yearsTeacher,
    } = this.props;

    const plansScheduledCards = plansScheduled
      .filter(p => new Date(p.scheduled).getFullYear() === activeTab)
      .map((plan, position) => this.renderCard({ plan, position }));
    const teachersPlansScheduledCards = teachersPlansScheduled
      .filter(p => new Date(p.scheduled).getFullYear() === activeTabTeacher)
      .map((plan, position) => this.renderCard({ plan, position }));

    return (
      <div>
        <Loader show={this.state.isLoading} />
        <Row className="align-items-center margin-bottom">
          <Col md="auto">
            <FormattedMessage {...messages.title}>
              {txt => <h1 className="page-title">{txt}</h1>}
            </FormattedMessage>
          </Col>
          <Col lg={8}>
            <Row>
              <Col md={7} xs={12} className="mt-3 mt-sm-3 mt-md-3 mt-lg-0">
                <FormattedMessage {...messages.filterByDiscipline}>
                  {txt => (
                    <Select
                      options={disciplinesFilter}
                      itemValue="id"
                      containerClassName="mt-0"
                      itemLabel="name"
                      name="idDisciplinesFilter"
                      selectLabel={txt}
                      onChange={this.handleFilterChange}
                    />
                  )}
                </FormattedMessage>
              </Col>
              <Col md={5} xs={12} className="mt-3 mt-sm-3 mt-md-3 mt-lg-0">
                <FormattedMessage {...messages.filterByLevel}>
                  {txt => (
                    <Select
                      options={levelsFilter}
                      itemValue="id"
                      containerClassName="mt-0 mt-xs-3"
                      itemLabel="name"
                      name="idLevelsFilter"
                      selectLabel={txt}
                      onChange={this.handleFilterChange}
                    />
                  )}
                </FormattedMessage>
              </Col>
            </Row>
          </Col>
          <Col md="auto" className="ml-auto">
            <SortBar onChange={this.handleDisplayChange} />
          </Col>
        </Row>
        <Navtabs
          onClick={this.handleTabClick}
          tabs={years.map((year, index) => ({
            key: index,
            label: year,
            value: year,
            active: activeTab === year,
          }))}
        />
        <Row>
          <Col
            lg={displayView === 'grid' ? 4 : 12}
            style={CURRENT_YEAR == activeTab ? { display: 'flex' } : { display: 'none' }}
          >
            <button
              style={{
                height: 238,
                width: '100%',
              }}
              className="card add-new scheduled-items-card"
              onClick={this.handleGetAllAppointments}
            >
              <span>
                <div className="scheduled-items-icon">
                  <i className="pe-7s-plus" />
                </div>
                Minha agenda
              </span>
            </button>
          </Col>
          {plansScheduledCards}
        </Row>
        {isFilterAdminAllowed(role) && (
          <div>
            {plansScheduled.length > 0 && <hr className="mt-5" />}
            <Row className="align-items-center margin-bottom mt-5">
              <Col>
                <Row>
                  <Col md={3} xs={12} className="mt-3 mt-sm-3 mt-md-3 mt-lg-0">
                    <FormattedMessage {...messages.teachersFilterLabel}>
                      {txt => (
                        <Select
                          options={teachersFilter}
                          containerClassName="mt-0"
                          itemValue="id"
                          itemLabel="name"
                          name="idTeachers"
                          selectLabel={txt}
                          onChange={this.handleFilterChange}
                        />
                      )}
                    </FormattedMessage>
                  </Col>
                  <Col md={3} xs={12} className="mt-3 mt-sm-3 mt-md-3 mt-lg-0">
                    <FormattedMessage {...messages.filterByDiscipline}>
                      {txt => (
                        <Select
                          options={teachersDisciplinesFilter}
                          containerClassName="mt-0"
                          itemValue="id"
                          itemLabel="name"
                          name="idTeachersDisciplines"
                          selectLabel={txt}
                          onChange={this.handleFilterChange}
                        />
                      )}
                    </FormattedMessage>
                  </Col>
                  <Col
                    md={3}
                    xs={12}
                    className="mt-3 mt-sm-3 mt-md-3 mt-lg-0 mb-3 mb-sm-3 mb-md-3 mb-lg-0"
                  >
                    <FormattedMessage {...messages.filterByLevel}>
                      {txt => (
                        <Select
                          options={teachersLevelsFilter}
                          containerClassName="mt-0"
                          itemValue="id"
                          itemLabel="name"
                          name="idTeachersLevels"
                          selectLabel={txt}
                          onChange={this.handleFilterChange}
                        />
                      )}
                    </FormattedMessage>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Navtabs
              onClick={this.handleTabClickTeacher}
              tabs={yearsTeacher.map((year, index) => ({
                key: index,
                label: year,
                value: year,
                active: activeTabTeacher === year,
              }))}
            />
          </div>
        )}
        <Row>{teachersPlansScheduledCards}</Row>
      </div>
    );
  }
}

const mapStateToProps = ({ schedule, app }) => ({ ...schedule, role: app.role });

export default compose(
  connect(
    mapStateToProps,
    promisify({ getPlansScheduled, getStatusPlanScheduled, getAllScheduleAppointments }),
  ),
  withRouter,
  injectIntl,
)(ScheduleContainer);
