import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import classNames from 'classnames';
import CardAppointmentTitle from '../CardAppointmentTitle';
import { withCalendar, withAppointmentDraggable } from '../CalendarContext';
import CalendarEventType from '../calendarEventType';
import * as helpers from '../calendarHelpers';

import './MonthAppointment.scss';

class MonthAppointment extends Component {
  handleRequestStatus = () => {
    if (this.props.onRequestStatusAppointment) {
      this.props.onRequestStatusAppointment(this.props);
    }
  };
  handleRequestDetails = () => {
    if (this.props.onRequestDetailsAppointment) {
      this.props.onRequestDetailsAppointment(this.props);
    }
  };
  handleRequestReassign = () => {
    if (this.props.onRequestReassignAppointment) {
      this.props.onRequestReassignAppointment(this.props);
    }
  };
  render() {
    const { type, title, discipline, content, status } = this.props;
    return (
      <div
        className={classNames('card card-calendar', type.className, {
          'card-calendar-not-applied': helpers.isNotApplied(status),
        })}
        onClick={this.handleRequestDetails}
      >
        <div className="card-body">
          <div className="row align-items-top month-appointment-container">
            <div className="col-auto pr-0">
              <i className={type.icon} />
            </div>
            <div className="col pl-2">
              <CardAppointmentTitle className="month-appointment-title" title={title} type={type} />
              <p className="month-appointment-discipline">{discipline}</p>
              <p className="month-appointment-content">{content}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MonthAppointment.propTypes = {
  type: PropTypes.oneOf(Object.keys(CalendarEventType).map(k => CalendarEventType[k])),
};

MonthAppointment.defaultProps = {
  type: CalendarEventType.LESSON,
};

export default compose(
  withAppointmentDraggable,
  withCalendar,
)(MonthAppointment);
