import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { injectIntl } from 'react-intl';

import { Modal } from 'sm-ui';

import { getStatusScheduled } from '../scheduleActions';

import messages from '../scheduleMessages';

import './CalendarStatusModal.scss';

class CalendarStatusModal extends React.PureComponent {
  componentDidUpdate(prevProps) {
    if (prevProps.idScheduledLesson !== this.props.idScheduledLesson) {
      this.handleContentData();
    }
    if (prevProps.statusChange === true && this.props.statusChange === false) {
      this.handleContentData();
    }
  }

  handleContentData = () => {
    const { idPlan, idCourse, idScheduledLesson } = this.props;
    this.props.getStatusScheduled({ idPlan, idCourse, idScheduledLesson });
  };

  render() {
    const { intl } = this.props;
    const buttons = [
      {
        label: intl.formatMessage(messages.statusModalCloseButton),
        onClick: this.props.onClose,
      },
      {
        label: intl.formatMessage(messages.statusModalEditButton),
        onClick: this.props.onEdit,
        primary: true,
      },
    ].slice(0, this.props.statusUpdated ? 1 : 2);
    return (
      <Modal
        title={intl.formatMessage(messages.statusModalTitle)}
        icon="pe-7s-search"
        onClose={this.props.onClose}
        buttons={buttons}
        modalBodyStyle={{
          maxWidth: '350px',
        }}
        {...this.props}
      >
        <div className="calendar-status-body" style={{ marginTop: 15 }}>
          <strong className="calendar-status-subtitle" style={{ color: 'red' }}>
            {this.props.scheduledLessonStatus.status &&
              intl.formatMessage(
                messages[`lessonStatus${this.props.scheduledLessonStatus.status}`] ||
                  messages.lessonStatusFallback,
              )}
          </strong>
          <h5 className="CalendarStatusModal__title">
            {this.props.scheduledLessonStatus.data.length > 0 &&
              intl.formatMessage(messages.statusModalHistory)}
          </h5>
          {this.props.isLoading ? (
            <div>Loading...</div>
          ) : (
            <div className="CalendarStatusModal__statusList">
              {this.props.scheduledLessonStatus.data.map((el, index) => (
                <div key={`${el.id}${index}`} className="CalendarStatusModal__statusWrapper">
                  <div>{el.added}</div>
                  <div>
                    {intl.formatMessage(messages.statusModalLessonUpdatedTo)}: "
                    {intl.formatMessage(
                      messages[`lessonStatus${el.status}`] || messages.lessonStatusFallback,
                    )}
                    "
                  </div>
                  <div className="CalendarStatusModal__statusComment">{el.comment}</div>
                </div>
              ))}
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ schedule }) => ({
  scheduledLessonStatus: schedule.scheduledLessonStatus,
  isLoading: schedule.isLoading,
});

export default compose(
  injectIntl,
  connect(
    mapStateToProps,
    { getStatusScheduled },
  ),
)(CalendarStatusModal);
