import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import CardMonthCell from './CardMonthCell';

const CardMonthOffset = ({ startDate }) => {
  const day = startDate.getDay();
  const offsetCells = [];
  let i = day;
  while (i > 0) {
    offsetCells.push(<CardMonthCell disabled={[0, 6].includes(i)} key={i} />);
    i--;
  }
  return <Fragment>{offsetCells}</Fragment>;
};

CardMonthOffset.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired
};

export default CardMonthOffset;
