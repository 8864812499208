import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { externalAuth } from 'login/loginActions';

class ExternalAuth extends Component {
  componentDidMount() {
    console.log('didMount');
    this.authorize();
  }

  authorize = () => {
    const {
      queryParams: { token, appToken },
    } = this.props;
    console.log('token', token, appToken);
    if (token) {
      this.props.externalAuth({ token });
    } else if (appToken) {
      this.props.externalAuth({ appToken });
    }
    this.props.history.push('/login');
  };

  render() {
    const {
      queryParams: { token, appToken },
    } = this.props;
    if (!appToken && !token) {
      return <Redirect to="/login" />;
    }
    return null;
  }
}

ExternalAuth.propTypes = {
  externalAuth: PropTypes.func.isRequired,
  queryParams: PropTypes.object.isRequired,
};

const mapStateToProps = ({ app }) => ({ queryParams: app.queryParams });

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    { externalAuth },
  ),
)(ExternalAuth);
