import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import WeekCalendarCell from './WeekCalendarCell';
import messages from '../calendarMessages';

const WeekCalendarRow = ({ dates, hour }) => (
  <Fragment>
    <FormattedMessage {...messages.hourLabel}>
      {txt => (
        <div className="row-label d-none d-md-flex">
          {txt}
          {hour}
        </div>
      )}
    </FormattedMessage>
    {dates.map(date => (
      <WeekCalendarCell date={date} hour={hour} key={date} />
    ))}
  </Fragment>
);

WeekCalendarRow.propTypes = {
  dates: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
  hour: PropTypes.string.isRequired,
};

export default WeekCalendarRow;
