import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { useDropzone } from 'react-dropzone';
import { injectIntl } from 'react-intl';
import last from 'lodash/last';

import { uploadFile } from '../plannerActions';

import messages from './DropzoneComponentMessages';

import './DropzoneComponent.scss';

function DropzoneComponent(props) {
  const onDrop = useCallback(acceptedFiles => {
    const files = last(acceptedFiles);
    props.uploadFile({ files });
  }, []);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ onDrop });

  const files = acceptedFiles.map(file => (
    <div key={file.path}>
      {file.path} - {file.size} bytes
    </div>
  ));

  return (
    <section className="container">
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <p>{props.intl.formatMessage(messages.instructions)}</p>
      </div>
      {files.length > 0 ? (
        <aside>
          <h4>{props.intl.formatMessage(messages.files)}</h4>
          <ul>{last(files)}</ul>
        </aside>
      ) : (
        <aside>
          <h4>{props.intl.formatMessage(messages.files)}</h4>
          <ul>{props.selectedElement.fileName}</ul>
        </aside>
      )}
    </section>
  );
}

const mapStateToProps = () => ({});

export default compose(
  injectIntl,
  connect(
    mapStateToProps,
    { uploadFile },
  ),
)(DropzoneComponent);
