import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Switch extends Component {
  static getDerivedStateFromProps(props, state) {
    if (props.checked !== state.prevChecked) {
      return {
        checked: props.checked,
        prevChecked: props.checked
      };
    }
    return null;
  }

  state = {};

  toggleCheck = e => {
    const checked = !this.state.checked;
    this.setState({
      checked
    });
    if (this.props.onChange) {
      e.target.checked = checked;
      this.props.onChange(e);
    }
  };

  render() {
    const { label, id, ...props } = this.props;
    const { checked } = this.state;
    return (
      <div className="form-group text-left">
        {label && <label htmlFor={id}>{label}</label>}
        <div className="float-right">
          <input className="switch" type="checkbox" {...props} checked={checked} />
          <label htmlFor={id} onClick={this.toggleCheck} role="button" tabIndex="-1" />
        </div>
      </div>
    );
  }
}

Switch.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func
};

Switch.defaultProps = {
  id: undefined,
  label: undefined,
  onChange: undefined
};

export default Switch;
