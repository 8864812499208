// @flow
import { UPLOAD_PATH } from 'config/constants';
import avatarPlaceholder from 'common/placeholder-avatar.svg';

export function getSidebarRoutes(routes: any[]) {
  let routesSidebar = [];
  routes.forEach(route => {
    if (route.sidebar) {
      routesSidebar.push(route);
    }
    // nested routes
    if (route.routes) {
      routesSidebar = routesSidebar.concat(getSidebarRoutes(route.routes));
    }
  });
  return routesSidebar;
}

export function getUserAvatarUrl(photo: string) {
  return photo ? `${UPLOAD_PATH}/${photo}` : avatarPlaceholder;
}

export function getError(action) {
  const { payload, error } = action;
  return {
    code: payload.errCode || 0,
    info: payload.errorInfo === undefined ? null : payload.errorInfo,
    status: payload.status || error.response ? error.response.status : '',
    loginErrorAttempts: payload.loginErrorAttempts || error.response ? error.response.loginErrorAttempts : '',
  };
}
