/* eslint-disable */
import React from 'react';
import MenuMetadata from './MenuMetadata';

const TableHeader = ({
  onClick,
  onMouseLeave,
  label,
  showHover,
  handleShowEditModal,
  handleShowAddColModal,
  handleShowMoveColumnModal,
  handleShowRemoveModal,
}) => (
  <React.Fragment>
    <div
      onContextMenu={e => {
        e.preventDefault();
        onClick();
      }}
    >
      {label}
      {showHover && (
        <MenuMetadata
          onMouseLeave={onMouseLeave}
          handleShowEditModal={handleShowEditModal}
          handleShowAddColModal={handleShowAddColModal}
          handleShowMoveColumnModal={handleShowMoveColumnModal}
          handleShowRemoveModal={handleShowRemoveModal}
        />
      )}
    </div>
  </React.Fragment>
);

export default TableHeader;
