import React, { Component } from 'react';
import classNames from 'classnames';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  withCalendar,
  withAppointmentDraggableTarget,
  getCellAppointments,
} from '../CalendarContext';
import Appointment from '../Appointment';

import './WeekCalendarCell.scss';

const today = new Date();

class WeekCalendarCell extends Component {
  renderCell() {
    const { isOver, canDrop, date, hour, appointments } = this.props;
    return (
      <div
        className={classNames('week-calendar-cell d-none d-md-block', {
          current: moment(date).isSame(today, 'day'),
          over: isOver && canDrop,
          'no-drop': !canDrop && isOver,
        })}
      >
        {getCellAppointments(date, appointments, hour).map(appointment => (
          <Appointment {...appointment} key={appointment.id} />
        ))}
      </div>
    );
  }
  render() {
    const { connectDragTarget } = this.props;
    return connectDragTarget && connectDragTarget(this.renderCell());
  }
}

WeekCalendarCell.propTypes = {
  isOver: PropTypes.bool.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  canDrop: PropTypes.bool.isRequired,
  connectDragTarget: PropTypes.func.isRequired,
  moment: PropTypes.func.isRequired,
};

export default compose(
  withCalendar,
  withAppointmentDraggableTarget,
)(WeekCalendarCell);
