import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Row, Col } from 'react-bootstrap';
import { withDragDropContext } from '../CalendarContext';
import CardMonth from '../daycalendar/CardMonth';
import CalendarNav from '../CalendarNav';

import './YearCalendar.scss';

const months = new Array(12).fill().map((_, i) => i + 1);

class YearCalendar extends Component {
  static getDerivedStateFromProps(props, state) {
    const { currentDate } = props;
    if (currentDate !== state.prevCurrentDate) {
      return {
        prevCurrentDate: currentDate,
        currentDate,
      };
    }
    return null;
  }

  state = {
    currentDate: new Date(),
  };

  nextYear = () => {
    const nextCurrentYear = this.state.currentDate.getFullYear() + 1;
    const nextCurrentDate = new Date(`${nextCurrentYear}-01-01T00:00:00`);
    this.selectDate(nextCurrentDate);
  };

  previousYear = () => {
    const nextCurrentYear = this.state.currentDate.getFullYear() - 1;
    const nextCurrentDate = new Date(`${nextCurrentYear}-01-01T00:00:00`);
    this.selectDate(nextCurrentDate);
  };

  selectDate = date => {
    this.setState({
      currentDate: date,
    });
    if (this.props.onChangeDate) {
      this.props.onChangeDate(date);
    }
  };

  render() {
    const { currentDate } = this.state;
    const year = currentDate.getFullYear();
    return (
      <div>
        <CalendarNav label={year} onPrevious={this.previousYear} onNext={this.nextYear} />
        <div className="calendar year-calendar-wrapper">
          <Row>
            {months.map(month => (
              <Col xs={12} md={4} className="year-calendar--month">
                <CardMonth month={month} year={year} />
              </Col>
            ))}
          </Row>
        </div>
      </div>
    );
  }
}

export default withDragDropContext(YearCalendar);
