import React from 'react';
import { Modal } from 'sm-ui';
import { injectIntl, FormattedMessage } from 'react-intl';
import messages from './calendarEventRemoveModalMessages';

const CalendarEventRemoveModal = ({ details, intl, onClose, onRemove, appointment, ...props }) => {
  return (
    <Modal
      title={<FormattedMessage {...messages.title} />}
      icon={'pe-7s-trash'}
      buttons={[
        {
          label: <FormattedMessage {...messages.close} />,
          onClick: onClose,
        },
        {
          label: <FormattedMessage {...messages.confirm} />,
          onClick: onRemove,
          primary: true,
        },
      ]}
      onClose={onClose}
      {...props}
    >
      <div>
        <FormattedMessage {...messages.confirmRemove} />
      </div>
    </Modal>
  );
};

export default injectIntl(CalendarEventRemoveModal);
