import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { promisify } from 'app/actions';
import { login, getCountries, verifyPermission } from './loginActions';
import LoginForm from './LoginForm';
import Login from './Login';

class LoginContainer extends Component {
  componentDidMount() {
    if (!this.props.countries.length) this.props.getCountries();
  }

  handleSubmit = async values => {
    await this.props.verifyPermission(values);
    setTimeout(() => {
      if (this.props.hasPermission) {
        this.props.login(values);
      }
    });
  };

  render() {
    const { error, countries, intl, verifyPermissionError } = this.props;
    return (
      <Login>
        <LoginForm
          onSubmit={this.handleSubmit}
          countries={countries}
          error={error}
          verifyPermissionError={verifyPermissionError}
        />
      </Login>
    );
  }
}

LoginContainer.propTypes = {
  login: PropTypes.func.isRequired,
  getCountries: PropTypes.func.isRequired,
  verifyPermission: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  hasPermission: PropTypes.bool.isRequired,
  error: PropTypes.arrayOf.isRequired,
  countries: PropTypes.arrayOf.isRequired,
  verifyPermissionError: PropTypes.arrayOf.isRequired,
};

const mapStateToProps = ({ login }) => ({ ...login });

export default compose(
  connect(mapStateToProps, promisify({ login, getCountries, verifyPermission })),
  injectIntl,
)(LoginContainer);
