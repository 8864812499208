import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col, Select, Alert } from 'sm-ui';
import _ from 'lodash';

import SchoolSelect from './SchoolSelect';
import { CheckIcon } from '../resources/icons';

import messages from './schoolAddModalFormMessages';

import './SchoolAddModalForm.scss';

class SchoolAddModalForm extends Component {
  state = {
    school: null,
  };

  handleSelectState = e => {
    const { onSelectState } = this.props;
    if (onSelectState) onSelectState(e.target.value);
  };

  handleSelectCity = e => {
    const { onSelectCity } = this.props;
    this.setState({
      school: null,
    });
    if (onSelectCity) onSelectCity(e.target.value);
  };

  handleSelectSchool = school => {
    this.setState({
      school,
    });
    this.props.onSelectSchool(school);
  };

  renderError() {
    const { error } = this.props;
    if (error.addSchool) {
      const messageError = messages[`errorCode${error.addSchool || 'Fallback'}`];
      return (
        <FormattedMessage {...messageError}>
          {txt => (
            <Alert type="danger" className="mt-2">
              {txt}
            </Alert>
          )}
        </FormattedMessage>
      );
    }
    return null;
  }

  render() {
    const { states, cities, schools, selectedSchools } = this.props;
    const { school } = this.state;
    const alreadyHasTheSchool =
      school && _.find(selectedSchools, elem => elem.idSchool === school.id);
    const error = this.renderError();
    return (
      <div className="school-add-modal-form-container">
        {error}
        <Row>
          <Col>
            <FormattedMessage {...messages.state}>
              {txt => (
                <FormattedMessage {...messages.selectStatePlaceholder}>
                  {txtPlaceholder => (
                    <Select
                      label={txt}
                      selectLabel={txtPlaceholder}
                      itemValue="state"
                      itemLabel="state"
                      options={states}
                      onChange={this.handleSelectState}
                    />
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
          </Col>
          <Col>
            <FormattedMessage {...messages.city}>
              {txt => (
                <FormattedMessage {...messages.selectCityPlaceholder}>
                  {txtPlaceholder => (
                    <Select
                      label={txt}
                      selectLabel={txtPlaceholder}
                      itemValue="city"
                      itemLabel="city"
                      options={cities}
                      onChange={this.handleSelectCity}
                    />
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormattedMessage {...messages.school}>
              {txt => (
                <FormattedMessage {...messages.searchSchoolPlaceholder}>
                  {txtPlaceholder => (
                    <FormattedMessage {...messages.noSchoolFounded}>
                      {txtNoSchoolFounded => (
                        <SchoolSelect
                          label={txt}
                          value={school}
                          disabled={cities.length === 0}
                          placeholder={txtPlaceholder}
                          noOptionsMessage={() => txtNoSchoolFounded}
                          itemValue="id"
                          itemLabel="name"
                          options={schools}
                          selectedSchools={selectedSchools}
                          onChange={this.handleSelectSchool}
                        />
                      )}
                    </FormattedMessage>
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="school-add-modal-form-details">
              {school && (
                <div>
                  {!!alreadyHasTheSchool && (
                    <div>
                      <img
                        src={CheckIcon}
                        width="60px"
                        className="school-add-modal-form-icon"
                      />
                    </div>
                  )}
                  <strong>{school.name}</strong>
                  <br />
                  <span>{school.address}</span>
                  <br />
                  <span>{school.zipcode}</span>
                  <br />
                  <span>{school.city}</span> - <span>{school.state}</span>
                </div>
              )}
              {!school && (
                <FormattedMessage {...messages.noSchoolSelected}>
                  {txt => <span>{txt}</span>}
                </FormattedMessage>
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

SchoolAddModalForm.propTypes = {};

export default SchoolAddModalForm;
