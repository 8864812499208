// @flow

import { apiTask } from 'app/sagas';
import { success } from 'app/actions';
import { authApi, api, apiCi } from 'app/api';
import { take, fork, all, takeLatest } from 'redux-saga/effects';
import * as actions from './loginActions';

export const authTask = apiTask(actions.AUTH, authApi.post, '/auth');
export const externalAuthTask = apiTask(actions.AUTH, authApi.post, '/auth');
export const loginTask = apiTask(actions.LOGIN, apiCi.post, '/login');
export const logoutTask = apiTask(actions.LOGOUT, authApi.get, '/logout');
export const getCountries = apiTask(actions.COUNTRIES, api.post, '/planner-plans/getCountries.php');
export const forgotPassword = apiTask(
  actions.FORGOT_PASSWORD,
  api.post,
  '/planner-plans/forgotPassword.php',
);
export const verifyPermission = apiTask(
  actions.VERIFY_PERMISSION,
  api.post,
  '/planner-plans/verifyPermission.php',
);

export const verifyExistencePlansScheduled = apiTask(
  actions.VERIFY_EXISTENCE_PLANS_SCHEDULED,
  api.post,
  '/planner-plans/getPlansScheduled.php',
);
export const changePasswordLink = apiTask(actions.CHANGE_PASSWORD_LINK, api.post, '/users/changePasswordLink.php');
export const manageUserTerms = apiTask(actions.MANAGE_USER_TERMS, api.post, '/users/manageUserTerms.php');




/**
 * Watch for every dispatch of LOGIN action and call the login task
 */
export function* watchLogin(): Iterable<T> {
  while (true) {
    const { payload } = yield take(actions.LOGIN);
    yield fork(loginTask, payload);
  }
}

export function* watchAuth(): Iterable<T> {
  while (true) {
    const { payload } = yield take(actions.AUTH);
    if (payload) {
      // yield fork(externalAuthTask, payload);
      yield fork(externalAuthTask, { ...payload, access: 2 });
    } else {
      // yield fork(authTask);
      yield fork(authTask, {access: 2});
    }
  }
}

export function* watchLogout(): Iterable<T> {
  while (yield take(actions.LOGOUT)) {
    yield fork(logoutTask);
  }
}

export function* watchLoginAuthSuccess() {
  yield takeLatest([success(actions.LOGIN), success(actions.AUTH)], verifyExistencePlansScheduled);
}

export function* watchGetCountries() {
  while (true) {
    const { payload } = yield take(actions.COUNTRIES);
    yield fork(getCountries, payload);
  }
}

export function* watchForgotPassword() {
  while (true) {
    const { payload } = yield take(actions.FORGOT_PASSWORD);
    yield fork(forgotPassword, payload);
  }
}

export function* watchVerifyPermission() {
  while (true) {
    const { payload } = yield take(actions.VERIFY_PERMISSION);
    yield fork(verifyPermission, payload);
  }
}

export function* watchChangePasswordLink() {
  while (true) {
    const { payload } = yield take(actions.CHANGE_PASSWORD_LINK);
    yield fork(changePasswordLink, payload);
  }
}

export const manageCookies = apiTask(actions.MANAGE_COOKIES, api.post, '/users/manageCookies.php');
export function* watchManageCookies() {
  while (true) {
    const { payload } = yield take(actions.MANAGE_COOKIES);
    yield fork(manageCookies, payload); 
  }
}

export function* watchManageUserTerms() {
  while (true) {
    const { payload } = yield take(actions.MANAGE_USER_TERMS);
    yield fork(manageUserTerms, payload);
  }
}

export function* sagas(): Iterable<T> {
  yield all([
    fork(authTask, { access: 2 } ),
    fork(watchLogin),
    fork(watchAuth),
    fork(watchLogout),
    fork(watchLoginAuthSuccess),
    fork(watchGetCountries),
    fork(watchForgotPassword),
    fork(watchVerifyPermission),
    fork(watchChangePasswordLink),
    fork(watchManageCookies),
    fork(watchManageUserTerms)
  ]);
}
