import { success, failure, request } from 'app/actions';
import * as actions from './configurationActions';

const initialState = {
  userInfo: "",
  userInfoStatus: null,
  isLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case request(actions.SAVE):
      return { 
        ...state, 
        userInfo: initialState.userInfo,
        userInfoStatus: initialState.userInfoStatus,
        isLoading: true
      };
    case failure(actions.SAVE):
      return { 
        ...state, 
        userInfo: action.payload.error,
        userInfoStatus: action.payload.errCode,
        isLoading: initialState.isLoading
      };
    case success(actions.SAVE):
      return { 
        ...state, 
        userInfo: action.payload.content,
        userInfoStatus: action.payload.status,
        isLoading: initialState.isLoading
      };
    default:
      return state;
  }
};

export default reducer;
