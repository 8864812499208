import React, { Component } from 'react';
import PropTypes from 'prop-types';

class NotificationContainer extends Component {
    render() {
        return (
            <div>
                <h1 className="page-title mb-5">Notificações</h1>
                <div className="year-tabs">
                    <ul className="nav nav-tabs mb-0">
                        <li className="nav-item"><a className="nav-link active" href="#">Novas<span className="badge badge-danger small ml-2">12</span></a></li>
                        <li className="nav-item"><a className="nav-link" href="#">Lidas<span className="badge small ml-2">234</span></a></li>
                    </ul>
                </div>
                <div className="notification">
                    <div className="row">
                        <div className="col-auto">
                            <div className="initials" style={{backgroundColor: '#88B2D6'}}>AJ</div>
                        </div>
                        <div className="col">
                            <h3 className="title">Aprender Juntos Matemática 3</h3>
                            <p className="m-0">A equipe A do grupo 3 realizou contribuições no fórum Nome do fórum</p><small>Há duas horas</small>
                        </div>
                    </div>
                </div>
                <div className="notification">
                    <div className="row">
                        <div className="col-auto">
                            <div className="initials" style={{backgroundColor: '#88B2D6'}}>AJ</div>
                        </div>
                        <div className="col">
                            <h3 className="title">Aprender Juntos Matemática 3</h3>
                            <p className="m-0">A equipe A do grupo 3 realizou contribuições no fórum Nome do fórum</p><small>Há duas horas</small>
                        </div>
                    </div>
                </div>
                <div className="notification">
                    <div className="row">
                        <div className="col-auto">
                            <div className="initials" style={{backgroundColor: '#88B2D6'}}>AJ</div>
                        </div>
                        <div className="col">
                            <h3 className="title">Aprender Juntos Matemática 3</h3>
                            <p className="m-0">A equipe A do grupo 3 realizou contribuições no fórum Nome do fórum</p><small>Há duas horas</small>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

NotificationContainer.propTypes = {

};

export default NotificationContainer;