import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Navbar } from 'sm-ui';
import brand from 'common/brand-sm.svg';
import brandApp from 'common/brand-organizador-curricular.svg';
import placeholderSrc from 'common/placeholder-avatar.svg';
import { getUserAvatarUrl } from './appHelpers';

import messages from './appMessages';

const AppNavbar = ({ intl, user, history, onRequestLogout }) => {

  if (!user) return null;
  return (
    <Navbar
      title={intl.formatMessage(messages.title)}
      userName={user.firstName}
      greeting={intl.formatMessage(messages.greeting)}
      country={user.abbreviation}
      brandSrc={brand}
      brandAppSrc={brandApp}
      avatarSrc={getUserAvatarUrl(user.photo)}
      avatarPlaceholderSrc={placeholderSrc}
      onRequestEdit={() => console.log('request edit')}
      // onRequestProfile={() => console.log('request profile')}
      onRequestConfiguration={() => {
        history.push('/configuration');
      }}
      onRequestLogout={onRequestLogout}
      editLabel={intl.formatMessage(messages.navbarEditLabel)}
      logoutLabel={intl.formatMessage(messages.navbarLogoutLabel)}
      // profileLabel={intl.formatMessage(messages.navbarProfileLabel)}
      configurationLabel={intl.formatMessage(messages.navbarConfigurationLabel)}
    />
  );
};

const mapStateToProps = ({ app }) => ({ user: app.user });

AppNavbar.propTypes = {
  intl: intlShape.isRequired,
  user: PropTypes.shape({
    abbreviation: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
  }),
  onRequestLogout: PropTypes.func.isRequired,
};

AppNavbar.defaultProps = {
  user: null,
};

export default compose(
  connect(mapStateToProps),
  withRouter,
  injectIntl,
)(AppNavbar);
