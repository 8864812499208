// @flow

import { success, failure, request } from 'app/actions';
import { getError } from 'app/appHelpers';
import * as actions from './changePasswordActions';

const initialState = {
  errorSent: {
    code: null,
    info: null,
    status: null,
  },
  error: {},
  isLoading: false,
  passwordLink: "",
  changePasswordStatus: null,
  changePasswordText: "",
};

const reducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case request(actions.CHANGE_PASSWORD):
      return {
        ...state,
        isLoading: true,
        changePasswordText: initialState.changePasswordText,
        changePasswordStatus: initialState.changePasswordStatus,
      };
    case success(actions.CHANGE_PASSWORD):
      return {
        ...state,
        isLoading: false,
        changePasswordText: action.payload.success,
        changePasswordStatus: action.payload.status,
      };
    case failure(actions.CHANGE_PASSWORD):
      return {
        ...state,
        isLoading: false,
        changePasswordText: action.payload.errCode,
        changePasswordStatus: action.payload.status,
      };
    default:
      return state;
  }
};

export default reducer;
