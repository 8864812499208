/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {withRouter} from 'react-router-dom';
import {compose} from 'recompose';

import XLSX from 'xlsx';
import saveAs from 'file-saver';

import { Row, SelectWithFilter, Loader } from 'sm-ui';

import HeaderIcons from './components/HeaderIcons';
import Table from './components/Table';
import TablePreview from './components/TablePreview';
import ModalOriginal from './components/ModalOriginal';
import ModalSchedule from './components/ModalSchedule';
import ModalSave from './components/ModalSave';

import { getPlanner, revertPlanToOriginal, savePlan } from './plannerActions';
import { getSchools } from '../school/schoolActions';
import { EditionState } from './plannerReducer';

import messages from './plannerMessages';
import helpers from './helpers';
import './PlannerContainer.scss';

const actions = {
  init() {
    return {
      idPlanner: '',
      localLoading: false,
      original: {
        isOpen: false,
      },
      preview: {
        isOpen: false,
      },
      save: {
        isOpen: false,
      },
      plan: {
        isOpen: false,
      },
      confirm: {
        isOpen: false,
      },
      schedule: {
        isOpen: false,
      },
      worksheet: null,
      selectData: [],
      selectedPeriod: 'bimonthly',
      fetchPlans: false,
    };
  },
  update({ key, value }) {
    return {
      [key]: value,
    };
  },
  updateIdPlanner(idPlanner) {
    return {
      idPlanner,
    };
  },
  updateLoading(localLoading) {
    return {
      localLoading,
    };
  },
  updateModal(key, isOpen) {
    return {
      [key]: {
        isOpen,
      },
    };
  },
  updateWorksheet(worksheet) {
    return {
      worksheet,
    };
  },
};

class PlannerContainer extends React.Component {
  state = actions.init();

  componentDidMount() {
    const {id} = this.props.match.params;
    this.setState({idPlanner: id});
    this.props.getPlanner({
      idPlan: id,
      period: this.state.selectedPeriod,
      first: true
    }); 
  }

  componentDidUpdate(prevProps, prevState) {
    const nextState = this.state;
    const nextProps = this.props;

    // if (prevProps.saved !== this.props.saved) {
    //   this.setState({ localLoading: true }, () => {
    //     setTimeout(() => {
    //       this.getPlanner();
    //       this.setState({ localLoading: false });
    //     }, 1000);
    //   });
    // }

    if (
      prevProps.editionState !== this.props.editionState &&
      this.props.editionState === EditionState.SAVED
    ) {
      setTimeout(() => {
        this.getPlanner();
        this.handleModal({ key: 'save', isOpen: true });
      });
    }

    // if (prevState.idPlanner !== nextState.idPlanner) {
    //   this.getPlanner();
    // }

    if (
      (!prevProps.planner &&
        nextProps.planner &&
        this.props.planner.table.period !== null &&
        this.props.planner.table.period !== false) ||
      (prevProps.planner &&
        prevState.selectData.length === 0 &&
        this.props.planner.table.period !== null &&
        this.props.planner.table.period !== false)
    ) {
      const selectData = this.props.planner.table.period
        // .filter(p => p !== 'quarterly')
        .map((el, index) => ({
          id: el,
          value: el,
          text: <FormattedMessage {...messages[el]} />,
          selected: index === 0,
        }));
      this.setState(actions.update({ key: 'selectData', value: selectData }));
    }
  }

  // Redux handlers
  getPlanner = () => {
    this.props.getPlanner({
      idPlan: this.state.idPlanner,
      period: this.state.selectedPeriod,
      second: true
    });
  };

  revertPlanToOriginal = () => this.props.revertPlanToOriginal({ idPlan: this.state.idPlanner });

  savePlan = () =>
    this.props.savePlan({ idPlan: this.state.idPlanner, period: this.state.selectedPeriod });

  // State handlers
  handleUpdateIdPlanner = id => {
    this.setState(actions.updateIdPlanner(id));
  };

  handleReloadData = key => {
    this.getPlanner();
    this.handleModal(key, false);
  };

  handleRevertPlanToOriginal = () => {
    this.revertPlanToOriginal();
    setTimeout(() => {
      this.setState({ fetchPlans: true });
      this.handleModal({ key: 'original', isOpen: false });
    }, 500);
  };

  handleModal = ({ key, isOpen }) => this.setState(actions.updateModal(key, isOpen));

  handleXlsxBuild = ({ plannerId, title, columns, rows }) => {
    const values = {
      cols: [],
      rows: [],
    };

    columns.map(col => {
      values.cols.push(col.label);
    });

    rows.map((row, index) => {
      values.rows.push([]);
      row.values.map(el => values.rows[index].push(el.value));
    });

    const wb = XLSX.utils.book_new();
    wb.Props = {
      Title: title,
    };
    wb.SheetNames.push('Sheet 1');

    const ws_data = [values.cols, ...values.rows];
    const ws = XLSX.utils.aoa_to_sheet(ws_data);
    wb.Sheets['Sheet 1'] = ws;

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

    saveAs(
      new Blob([helpers.s2ab(wbout)], { type: 'application/octet-stream' }),
      `${title}[${plannerId}].xlsx`,
    );
  };

  openShedule = () => {
    this.setState({ schedule: { isOpen: true }, save: { isOpen: false } });
  };

  render() {
    const { planner, isLoading, table } = this.props;
    const {
      original,
      preview,
      save,
      plan,
      localLoading,
      selectData,
      confirm,
      schedule,
      selectedPeriod,
    } = this.state;
    let componentRef = '';
    let originalData = [];
    let parsedData = [];

    if (planner) {
      originalData = planner.table.rows;

      originalData.length > 0 &&
        originalData.map(row => {
          const obj = {};
          row.values &&
            row.values.map(el => {
              const elString = el.value ? el.value : '';
              const parsedContent = elString.replace(/<(?:.|\n)*?>/gm, '');
              el.value = parsedContent;
              obj[el.idMetadata] = el.value;
            });
          parsedData.push(obj);
        });
    }

    return (
      <div>
        <Loader show={isLoading || localLoading} />
        <Row className="plannerContainer___actionMenuWrapper">
          <div style={{ display: 'flex', minWidth: '50%' }}>
            <div className="plannerContainer___helperFlexCenter" style={{ minWidth: '180px' }}>
              <FormattedMessage {...messages.title}>
                {title => (
                  <React.Fragment>
                    <h1 id="page-title" className="page-title">
                      {title}
                    </h1>
                  </React.Fragment>
                )}
              </FormattedMessage>
            </div>
            {planner &&
              planner.table.period &&
              planner.table.period.length > 1 && (
                <div
                  className="plannerContainer___helperFlexCenter"
                  style={{ minWidth: '300px', width: '100%' }}
                >
                  <div style={{ width: '100%' }}>
                    <SelectWithFilter
                      data={selectData}
                      onSelect={selected => {
                        this.setState(actions.updateLoading(true));
                        this.setState(
                          actions.update({ key: 'selectedPeriod', value: selected.id }),
                        );
                        setTimeout(() => {
                          this.setState(actions.updateLoading(false));
                          this.getPlanner();
                        }, 500);
                      }}
                      hideSearch
                      closeOnLeave
                    />
                  </div>
                </div>
              )}
          </div>
          <HeaderIcons
            data={[
              {
                id: 'print',
                label: <FormattedMessage {...messages.print} />,
                icon: 'pe-7s-print',
                onClick: () => helpers.printElem(),
              },
              {
                id: 'download',
                label: <FormattedMessage {...messages.download} />,
                icon: 'pe-7s-download',
                onClick: () => {
                  this.setState(actions.updateLoading(true));
                  setTimeout(() => {
                    const title = document.getElementById('page-title').innerHTML;
                    this.handleXlsxBuild({
                      plannerId: planner.id,
                      title,
                      columns: planner.table.columns,
                      rows: planner.table.rows,
                    });
                    this.setState(actions.updateLoading(false));
                  }, 1000);
                },
              },
              {
                id: 'preview',
                label: <FormattedMessage {...messages.preview} />,
                icon: 'pe-7s-search',
                onClick: () => this.handleModal({ key: 'preview', isOpen: true }),
              },
              {
                id: 'original',
                label: <FormattedMessage {...messages.original} />,
                icon: 'pe-7s-refresh',
                onClick: () => this.handleModal({ key: 'original', isOpen: true }),
              },
              {
                id: 'save',
                label: <FormattedMessage {...messages.savePlan} />,
                icon: 'ti-agenda',
                onClick: () => {
                  this.handleModal({ key: 'save', isOpen: true }), this.savePlan();
                },
              },
            ]}
          />
        </Row>

        <Row className="plannerContainer___actionMenuWrapper ">
          {planner && <div className="plannerContainer___plannerName">{planner.name}</div>}
        </Row>
        <Row className="plannerContainer___actionMenuWrapper2">
          <div
            className="plannerContainer"
            style={{ minWidth: '300px', width: '100%', paddingLeft: '15px' }}
          >
            <FormattedMessage {...messages.instruction} />
          </div>
        </Row>
        <Content className="table-page planner-container-table">
          {planner && (
            <Table
              id="table-print"
              columns={planner.table.columns}
              data={originalData}
              idPlan={this.props.match.params.id}
              selectedPeriod={selectedPeriod}
              shouldUpdate={!preview.isOpen}
              fetchPlans={this.state.fetchPlans}
            />
          )}
          {!planner && <FormattedMessage {...messages.loading} />}
        </Content>

        {planner && (
          <TablePreview
            isOpen={preview.isOpen}
            data={originalData}
            columns={planner.table.columns}
            handleLoading={isLoading => this.setState(actions.updateLoading(isLoading))}
            onClose={() => this.handleModal({ key: 'preview', isOpen: false })}
          />
        )}

        <ModalOriginal
          isOpen={original.isOpen}
          isLoading={isLoading => this.setState(actions.updateLoading(isLoading))}
          onClose={() => this.handleModal({ key: 'original', isOpen: false })}
          onConfirm={this.handleRevertPlanToOriginal}
        />

        <ModalSave
          isOpen={save.isOpen}
          isLoading={isLoading => this.setState(actions.updateLoading(isLoading))}
          onContinue={this.openShedule}
          onClose={() => this.handleModal({ key: 'save', isOpen: false })}
        />

        <ModalSchedule
          show={schedule.isOpen}
          period={selectedPeriod}
          onClose={() => this.handleModal({ key: 'schedule', isOpen: false })}
        />
      </div>
    );
  }
}

const Content = ({ children, ...props }) => <div {...props}>{children}</div>;

const mapStateToProps = ({ plans, planner, school }) => {
  return { ...plans, ...planner, saved: school.saved };
};

export default compose(withRouter, connect(
  mapStateToProps,
  { getPlanner, revertPlanToOriginal, savePlan, getSchools },
))(PlannerContainer);
