import React from 'react';

class SimpleCheckboxListComponent extends React.PureComponent {
  state = {
    values: [],
  };

  handleChange = e => {
    const inputValidation = this.state.values.includes(e.target.value);
    let values = [...this.state.values];
    if (inputValidation) {
      values = this.state.values.filter(el => el !== e.target.value);
    } else {
      values = [...this.state.values, e.target.value];
    }
    this.setState({ values });
    if (this.props.onChange) {
      this.props.onChange(values, e);
    }
  };

  render() {
    return (
      <React.Fragment>
        <label className="small">{this.props.label}</label>
        {this.props.data.map(el => (
          <div className="form-check" key={el.value}>
            <input
              type="checkbox"
              className="form-check-input"
              id={el.value}
              name={el.value}
              value={el.value}
              onChange={this.handleChange}
              checked={this.state.values.includes(el.value)}
            />
            <label className="form-check-label" htmlFor={el.value}>
              {el.label}
            </label>
          </div>
        ))}
      </React.Fragment>
    );
  }
}

export default SimpleCheckboxListComponent;
