import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import locale from '../config/locale';

const Notification = ({ title, description, time, lang, color }) => {
  if (lang === 'pt-br') moment.defineLocale('pt-br', locale.pt);
  if (lang === 'es') moment.defineLocale('es', locale.es);
  if (lang === 'en') moment.defineLocale('en', locale.en);

  const titleWords = title.split(' ');

  return (
    <div className="notification">
      <div className="row">
        <div className="col-auto">
          <div className="initials" style={{ backgroundColor: color, textTransform: 'uppercase' }}>
            {titleWords[0] && titleWords[0].substr(0, 1)}
            {titleWords[1] && titleWords[1].substr(0, 1)}
          </div>
        </div>
        <div className="col">
          <h3 className="title" style={{ color }}>
            {title}
          </h3>
          <p className="m-0">{description}</p>
          {time && (
            <small>
              {moment(time)
                .startOf('minutes')
                .fromNow()}
            </small>
          )}
        </div>
      </div>
    </div>
  );
};

Notification.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  time: PropTypes.string,
  lang: PropTypes.string,
  color: PropTypes.string
};

Notification.defaultProps = {
  title: '',
  description: '',
  time: '',
  lang: '',
  color: '#88B2D6'
};

export default Notification;
