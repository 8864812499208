// @styleguide
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { promisify } from 'app/actions';
import ClassManagementCoursesModal from './ClassManagementCoursesModal';
import ClassManagementRemoveCourseModal from './ClassManagementRemoveCourseModal';
import actions from './classManagementActions';

class ClassManagementContainer extends Component {
  state = {
    showRemoveModal: false,
    courseToRemove: null,
  };

  componentDidMount() {
    const { idPlan } = this.props;
    this.props.getCourses();
    this.props.getPlanCoursesHours({ idPlan });
  }

  handleRequestRemove = course => {
    this.setState({
      courseToRemove: course,
      showRemoveModal: true,
    });
  };

  removeCourse = async () => {
    const { 
      courseToRemove: { idCourse },
    } = this.state;
    const { deleteCourse, idPlan } = this.props;
    await deleteCourse({
      idCourse,
      idPlan,
    });
    this.setState({
      showRemoveModal: false,
    });
  };

  closeRemoveModal = () => {
    this.setState({
      showRemoveModal: false,
    });
  };

  render() {
    const { show, onClose, idPlan, period } = this.props;
    const { showRemoveModal } = this.state;

    return (
      <div>
        <ClassManagementCoursesModal
          show={show}
          onRequestRemove={this.handleRequestRemove}
          idPlan={idPlan}
          period={period}
          onClose={onClose}
        />
        <ClassManagementRemoveCourseModal
          show={showRemoveModal}
          onClose={this.closeRemoveModal}
          onConfirm={this.removeCourse}
        />
      </div>
    );
  }
}

ClassManagementContainer.propTypes = {
  show: PropTypes.bool,
  idPlan: PropTypes.number,
  onClose: PropTypes.func,
  period: PropTypes.string,
};

ClassManagementContainer.defaultProps = {
  show: false,
};

const mapStateToProps = ({ classManagement }, ownProps) => ({
  ...classManagement,
  idPlan: ownProps.match.params.id,
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    promisify(actions),
  ),
)(ClassManagementContainer);
