/* eslint-disable */
import { action } from 'app/actions';
/* eslint-enable */
export const PREFIX = 'schedule/classManagement/';
export const GET_COURSES = 'schedule/classManagement/GET_COURSES';
export const GET_PLAN_COURSES_HOUR = 'schedule/classManagement/GET_PLAN_COURSES_HOUR';
export const ADD_HOUR = 'schedule/classManagement/ADD_HOUR';
export const DELETE_HOUR = 'schedule/classManagement/DELETE_HOUR';
export const ADD_COURSE = 'schedule/classManagement/ADD_COURSE';
export const EDIT_COURSE = 'schedule/classManagement/EDIT_COURSE';
export const DELETE_COURSE = 'schedule/classManagement/DELETE_COURSE';
export const SCHEDULE_PLAN = 'schedule/classManagement/SCHEDULE_PLAN';
export const ADD_NEW_COURSE = 'schedule/classManagement/ADD_NEW_COURSE';
export const RESET_MODAL = 'schedule/classManagement/RESET_MODAL';

const actions = {
  getCourses: payload => action(GET_COURSES, { payload }),
  getPlanCoursesHours: payload => action(GET_PLAN_COURSES_HOUR, { payload }),
  addHour: (payload, meta) => action(ADD_HOUR, { payload, meta }),
  deleteHour: (payload, meta) => action(DELETE_HOUR, { payload, meta }),
  addCourse: (payload, meta) => action(ADD_COURSE, { payload, meta }),
  editCourse: (payload, meta) => action(EDIT_COURSE, { payload, meta }),
  deleteCourse: (payload, meta) => action(DELETE_COURSE, { payload, meta }),
  schedulePlan: payload => action(SCHEDULE_PLAN, { payload }),
  addNewCourse: () => action(ADD_NEW_COURSE),
  resetModal: () => action(RESET_MODAL),
};

export default actions;
