import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './Button.scss';

export const colors = [
  'primary',
  'secondary',
  'success',
  'warning',
  'danger',
  'info',
  'light',
  'dark',
];

const Button = ({
  // Booleans
  block,
  inline,
  active,
  disabled,
  outline,
  large,
  variant,
  // Others
  children,
  className,
  component,
  color,
  ...props
}) => {
  const Component = component;
  return (
    <Component
      className={cn(
        'btn',
        `btn-${color}`,
        variant ? `btn-${variant}-${color}` : '',
        { inline },
        { active },
        { disabled },
        { 'btn-lg': large },
        className,
      )}
      {...props}
    >
      {children}
    </Component>
  );
};

Button.propTypes = {
  inline: PropTypes.bool,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  outline: PropTypes.bool,
  children: PropTypes.node,
  large: PropTypes.bool,
  block: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.oneOf(colors),
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  variant: PropTypes.oneOf(['outline']),
};

Button.defaultProps = {
  color: 'primary',
  inline: false,
  active: true,
  disabled: false,
  outline: false,
  large: false,
  block: false,
  className: undefined,
  component: props => <button {...props} />,
  variant: undefined,
  children: '',
};

export default Button;
