import { defineMessages } from 'react-intl';

export default defineMessages({
  instructions: {
    id: 'dropzone.instructions',
    defaultMessage: "Drag 'n' drop some files here, or click to select files",
  },
  files: {
    id: 'dropzone.files',
    defaultMessage: 'File',
  },
});
