// @flow

import { success, failure, request } from 'app/actions';
import { getError } from 'app/appHelpers';
import * as actions from './loginActions';

const initialState = {
  authorizing: null,
  entering: null,
  leaving: null,
  checkingPlans: null,
  hasPlansScheduled: null,
  countries: [],
  errorSent: {
    code: null,
    info: null,
    status: null,
    errorSent: '',
    loginErrorAttempts: 0
  },
  isLoading: false,
  hasPermission: false,
  verifyPermissionError: {
    errCode: null,
    error: null,
    status: null,
  },
  forgotFeedback: "",
  forgotError: false,
  cookies: "",
  manageUserTermsPass: false
};

const reducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case request(actions.LOGOUT):
      return { ...state, leaving: true };
    case request(actions.FORGOT_PASSWORD):
      return { ...state, isLoading: true, errorSent: initialState.errorSent };
    case success(actions.FORGOT_PASSWORD):
      return {
        ...state,
        message: action.payload.success,
        errorSent: initialState.errorSent,
        isLoading: initialState.isLoading,
      };
    case failure(actions.FORGOT_PASSWORD):
      return { ...state, errorSent: getError(action), isLoading: initialState.isLoading };
    case request(actions.COUNTRIES):
      return { ...state };
    case success(actions.COUNTRIES):
      return { ...state, countries: action.payload.content };
    case request(actions.LOGIN):
      return { ...state, entering: true, checkingPlans: true };
    case request(actions.AUTH):
      return { ...state, entering: true, authorizing: true, checkingPlans: true };
    case success(actions.LOGIN):
    case success(actions.AUTH):
      return { ...state, entering: false, authorizing: false, manageUserTermsPass: initialState.manageUserTermsPass };
    case success(actions.LOGOUT):
      return { ...state, leaving: false };
    case failure(actions.AUTH):
      return { ...state, entering: false, authorizing: false, checkingPlans: false };
    case success(actions.VERIFY_EXISTENCE_PLANS_SCHEDULED):
      return {
        ...state,
        hasPlansScheduled: action.payload.plansScheduled && action.payload.plansScheduled.length > 0,
        checkingPlans: false,
      };
    case failure(actions.VERIFY_EXISTENCE_PLANS_SCHEDULED):
      return { ...state, checkingPlans: false };
    case failure(actions.LOGIN): {
      return {
        ...state,
        entering: false,
        checkingPlans: false,
        errorSent: action.payload.error,
        errorStatus: action.payload.status,
        error: {
          ...state.error,
          login: getError(action),
        },
      };
    }
    case request(actions.VERIFY_PERMISSION):
      return {
        ...state,
        hasPermission: initialState.hasPermission,
        verifyPermissionError: {
          errCode: initialState.errCode,
          error: initialState.error,
          status: initialState.status,
        },
      };
    case success(actions.VERIFY_PERMISSION):
      return {
        ...state,
        hasPermission: true,
        verifyPermissionError: {
          errCode: initialState.errCode,
          error: initialState.error,
          status: initialState.status,
        },
      };
    case failure(actions.VERIFY_PERMISSION):
        return {
          ...state,
          hasPermission: initialState.hasPermission,
          verifyPermissionError: {
            errCode: action.payload.errCode,
            error: action.payload.error,
            status: action.payload.status,
          },
        };
    case request(actions.CHANGE_PASSWORD_LINK):
      return {
        ...state,
        isLoading: true,
        errorSent: { 
          errorSent: initialState.errorSent.errorSent,
          code: initialState.errorSent.code
        },
        forgotError: false
      };
    case success(actions.CHANGE_PASSWORD_LINK):
      return {
        ...state,
        isLoading: false,
        errorSent: { 
          errorSent: initialState.errorSent.errorSent,
          code: initialState.errorSent.code
        },
        forgotError: false
      };
    case failure(actions.CHANGE_PASSWORD_LINK):
      return {
        ...state,
        isLoading: false,
        errorSent: { 
          errorSent: action.payload.error,
          code: action.payload.errCode
        },
        forgotError: true
      };
    case success(actions.MANAGE_COOKIES):
      return {
        ...state,
        // isLoading: false,
        cookies: action.payload.success
      };
    case request(actions.MANAGE_USER_TERMS): {
      return {
        ...state,
        manageUserTermsPass: initialState.manageUserTermsPass
      };
    }
    case success(actions.MANAGE_USER_TERMS): {
      return {
        ...state,
        manageUserTermsPass: true
      };
    }
    default:
      return state;
  }
};

export default reducer;
