export default {
  pt: {
    months: 'janeiro_fevereiro_março_abril_maio_junho_julho_agosto_setembro_outubro_novembro_dezembro'.split(
      '_'
    ),
    monthsShort: 'jan_fev_mar_abr_mai_jun_jul_ago_set_out_nov_dez'.split('_'),
    weekdays: 'domingo_segunda-feira_terça-feira_quarta-feira_quinta-feira_sexta-feira_sábado'.split(
      '_'
    ),
    weekdaysShort: 'dom_seg_ter_qua_qui_sex_sáb'.split('_'),
    weekdaysMin: 'dom_2ª_3ª_4ª_5ª_6ª_sáb'.split('_'),
    longDateFormat: {
      LT: 'HH:mm',
      L: 'DD/MM/YYYY',
      LL: 'D [de] MMMM [de] YYYY',
      LLL: 'D [de] MMMM [de] YYYY [às] LT',
      LLLL: 'dddd, D [de] MMMM [de] YYYY [às] LT'
    },
    calendar: {
      sameDay: '[Hoje às] LT',
      nextDay: '[Amanhã às] LT',
      nextWeek: 'dddd [às] LT',
      lastDay: '[Ontem às] LT',
      lastWeek: () =>
        this.day() === 0 || this.day() === 6
          ? '[Último] dddd [às] LT' // Saturday + Sunday
          : '[Última] dddd [às] LT', // Monday - Friday
      sameElse: 'L'
    },
    relativeTime: {
      future: 'em %s',
      past: '%s atrás',
      s: 'segundos',
      m: 'um minuto',
      mm: '%d minutos',
      h: 'uma hora',
      hh: '%d horas',
      d: 'um dia',
      dd: '%d dias',
      M: 'um mês',
      MM: '%d meses',
      y: 'um ano',
      yy: '%d anos'
    },
    ordinal: '%dº'
  },
  es: {
    months: 'enero_febrero_marzo_abril_mayo_junio_julio_agosto_septiembre_octubre_noviembre_diciembre'.split(
      '_'
    ),
    monthsShort: 'ene_feb_mar_abr_may_jun_jul_ago_sep_oct_nov_dez'.split('_'),
    weekdays: 'domingo_lunes_martes_miércoles_jueves_viernes_sábado'.split('_'),
    weekdaysShort: 'dom_lun_mar_mie_jue_vie_sáb'.split('_'),
    weekdaysMin: 'dom_2ª_3ª_4ª_5ª_6ª_sáb'.split('_'),
    longDateFormat: {
      LT: 'HH:mm',
      L: 'DD/MM/YYYY',
      LL: 'D [de] MMMM [de] YYYY',
      LLL: 'D [de] MMMM [de] YYYY [às] LT',
      LLLL: 'dddd, D [de] MMMM [de] YYYY [às] LT'
    },
    calendar: {
      sameDay: '[Hoy às] LT',
      nextDay: '[Mañana às] LT',
      nextWeek: 'dddd [às] LT',
      lastDay: '[Ayer às] LT',
      lastWeek: () =>
        this.day() === 0 || this.day() === 6
          ? '[Último] dddd [às] LT' // Saturday + Sunday
          : '[Última] dddd [às] LT', // Monday - Friday
      sameElse: 'L'
    },
    relativeTime: {
      future: 'em %s',
      past: '%s atrás',
      s: 'segundos',
      m: 'um minuto',
      mm: '%d minutos',
      h: 'uma hora',
      hh: '%d horas',
      d: 'um día',
      dd: '%d días',
      M: 'um mes',
      MM: '%d meses',
      y: 'um año',
      yy: '%d años'
    },
    ordinal: '%dº'
  },
  en: {
    months: 'january_february_march_april_may_june_july_august_september_october_november_dezember'.split(
      '_'
    ),
    monthsShort: 'jan_feb_mar_apr_may_jun_jul_aug_sep_oct_nov_dez'.split('_'),
    weekdays: 'sunday_monday_tuesday_wednesday_thursday_friday_saturday'.split('_'),
    weekdaysShort: 'sun_mon_tue_wed_thu_fri_sat'.split('_'),
    weekdaysMin: 'sun_2ª_3ª_4ª_5ª_6ª_sat'.split('_'),
    longDateFormat: {
      LT: 'HH:mm',
      L: 'DD/MM/YYYY',
      LL: 'MMMM D, YYYY',
      LLL: 'MMMM D, YYYY LT',
      LLLL: 'dddd, MMMM D, YYYY LT'
    },
    calendar: {
      sameDay: '[Today at] LT',
      nextDay: '[Tomorrow at] LT',
      nextWeek: 'dddd [at] LT',
      lastDay: '[Yesterday at] LT',
      lastWeek: () =>
        this.day() === 0 || this.day() === 6
          ? '[Last] dddd [at] LT' // Saturday + Sunday
          : '[Last] dddd [at] LT', // Monday - Friday
      sameElse: 'L'
    },
    relativeTime: {
      future: 'im %s',
      past: '%s ago',
      s: 'a seconds',
      m: 'a minute',
      mm: '%d minutes',
      h: 'an hour',
      hh: '%d hours',
      d: 'a day',
      dd: '%d days',
      M: 'a mounth',
      MM: '%d mounths',
      y: 'an year',
      yy: '%d years'
    },
    ordinal: '%dº'
  }
};
