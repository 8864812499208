import React from 'react';
import PropTypes from 'prop-types';
import { BaseCard as Card, CardBody, Row, Col, Initials, Avatar, CardIcon } from 'sm-ui';
import { getUserAvatarUrl } from 'app/appHelpers';

const SchoolTeacherCard = ({ fullname, photo, ...props }) => (
  <Card>
    <CardBody>
      <Row className="align-items-center">
        <Col auto>
          {photo && <Avatar src={getUserAvatarUrl(photo)} />}
          {!photo && <CardIcon icon="pe-7s-study" />}
        </Col>
        <Col class="text-left">
          <h5 className="card-title mb-2">{fullname}</h5>
          {/* <p className="mb-0">Ensino Fundamental 1</p> */}
        </Col>
      </Row>
    </CardBody>
  </Card>
);

SchoolTeacherCard.propTypes = {};

export default SchoolTeacherCard;
