import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { appLocales } from 'lang/messages';
import { compose } from 'recompose';
import queryString from 'query-string';
import { selectLanguage } from './langActions';

import './LanguageSelect.scss';

function getAllowedCountries(countries) {
  return countries.filter(c => c.access === '2');
}

function getDefaultCountry(countries, queryParams) {
  const defaultLanguage = navigator.language || navigator.languages[0];
  const [language, abbreviation] = defaultLanguage.split('-');
  return getAllowedCountries(countries).find(country => {
    if (queryParams.c) return country.id === queryParams.c;
    const matchLanguage = country.language.toLowerCase() === language.toLowerCase();
    if (abbreviation) {
      return country.abbreviation.toLowerCase() === abbreviation.toLowerCase() && matchLanguage;
    }
    return matchLanguage;
  });
}

class LanguageSelect extends PureComponent {
  static getDerivedStateFromProps(props, state) {
    if (props.countries !== state.prevCountries) {
      const queryParams = queryString.parse(props.location.search);
      const defaultCountry = getDefaultCountry(props.countries, queryParams);
      return {
        value: defaultCountry ? defaultCountry.id : state.value,
        prevCountries: props.countries,
      };
    }
    return null;
  }

  state = {
    value: '',
  };

  handleChange = e => {
    const { value } = e.target;
    const country = this.props.countries.find(c => c.id === value);
    this.setState({ value });
    this.props.history.push(`/login?c=${country.id}`);
    this.props.selectLanguage(country.language);
    // const
    // countries.forEach(el => {
    //   if (el.access === '2' && el.register === '0' && el.language === e.target.value) {
    //     history.push('/login');
    //   }
    // });
  };

  render() {
    return (
      <select value={this.state.value} onChange={this.handleChange} className="language-select">
        {getAllowedCountries(this.props.countries).map(c => (
          <option value={c.id} key={c.id}>
            {c.name}
          </option>
        ))}
      </select>
    );
  }
}

LanguageSelect.propTypes = {
  selectLanguage: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
};

const mapStateToProps = ({ lang }) => ({ locale: lang.locale });

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    { selectLanguage },
  ),
)(LanguageSelect);
