import React from 'react';

import './IconButton.scss';

const IconButton = ({ label, children, ...props }) => (
  <div className={`icon-button-container ${props.className}`}>
    <button {...props} className="icon-button">
      {children}
    </button>
    {label && <span>{label}</span>}
  </div>
);

export default IconButton;