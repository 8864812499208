import { take, fork } from 'redux-saga/effects';
import { apiTask } from '../app/sagas';
import { api } from '../app/api';
import * as actions from './plansActions';

export const getPlansTask = apiTask(actions.GET_PLANS, api.post, '/planner-plans/getPlans.php');

// Get planner table information
export const getPlanner = apiTask(
  actions.GET_PLANNER,
  api.post,
  '/planner-plans/getPlansTable.php',
);

export function* watchGetPlanner() {
  while (true) {
    const { payload } = yield take(actions.GET_PLANNER);
    yield fork(getPlanner, payload);
  }
}

export function* watchPlans() {
  while (true) {
    const { payload } = yield take(actions.GET_PLANS);
    yield fork(getPlansTask, payload);
  }
}
