import { defineMessages } from 'react-intl';

export default defineMessages({
  state: {
    id: 'schoolAddModalForm.state',
    defaultMessage: 'State',
  },
  city: {
    id: 'schoolAddModalForm.city',
    defaultMessage: 'City',
  },
  school: {
    id: 'schoolAddModalForm.school',
    defaultMessage: 'School',
  },
  autocompletePlaceholder: {
    id: 'schoolAddModalForm.autocompletePlaceholder',
    defaultMessage: 'Search for school...',
  },
  selectCityPlaceholder: {
    id: 'schoolAddModalForm.selectCityPlaceholder',
    defaultMessage: 'Select a city',
  },
  selectStatePlaceholder: {
    id: 'schoolAddModalForm.selectStatePlaceholder',
    defaultMessage: 'Select a state',
  },
  searchSchoolPlaceholder: {
    id: 'schoolAddModalForm.selectSchoolPlaceholder',
    defaultMessage: 'Search for school...',
  },
  noSchoolSelected: {
    id: 'schoolAddModal.noSchoolSelected',
    defaultMessage: 'No school selected yet.',
  },
  noSchoolFounded: {
    id: 'schoolAddModal.noSchoolFounded',
    defaultMessage: 'No school founded.',
  },
  errorCode6: {
    id: 'schoolAddModal.errorCode6',
    defaultMessage: 'This school has already been associated.',
  },
  errorCodeFallback: {
    id: 'schoolAddModal.errorCodeFallback',
    defaultMessage: 'Error',
  },
});
