import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape } from 'react-intl';
import CalendarEventType from './calendarEventType';
import * as helpers from './calendarHelpers';
import messages from './calendarMessages';
import './CardAppointmentTitle.scss';

const CardAppointmentTitle = ({ className, title, intl, type, style }) => {
  const parsedTitle = type.titleMessageKey
    ? intl.formatMessage(messages[type.titleMessageKey])
    : helpers.removeTagsHtml(title);
  return (
    <div className={classNames('card-title', className)} style={style}>
      <span dangerouslySetInnerHTML={{ __html: parsedTitle }} />
    </div>
  );
};

CardAppointmentTitle.propTypes = {
  className: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.keys(CalendarEventType).map(k => CalendarEventType[k])),
  intl: intlShape,
};

CardAppointmentTitle.defaultProps = {
  title: '',
  type: CalendarEventType.ASSESSMENT,
};

export default injectIntl(CardAppointmentTitle);
