import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'sm-ui';

import messages from '../plannerMessages';

const ModalMoveRow = ({ isOpen, direction, onClose, onConfirm }) => {
  let resolvedTitle = '';
  let resolvedMessage = '';
  if (direction === 'a') {
    resolvedTitle = <FormattedMessage {...messages.aMoveRowTitle} />;
    resolvedMessage = <FormattedMessage {...messages.aMoveRowMessage} />;
  }
  if (direction === 'b') {
    resolvedTitle = <FormattedMessage {...messages.bMoveRowTitle} />;
    resolvedMessage = <FormattedMessage {...messages.bMoveRowMessage} />;
  }
  return (
    <Modal
      show={isOpen}
      icon={'pe-7s-repeat'}
      onClose={onClose}
      title={resolvedTitle}
      message={resolvedMessage}
      buttons={[
        {
          id: 1,
          label: <FormattedMessage {...messages.cancel} />,
          onClick: () => onClose(),
        },
        {
          id: 2,
          label: <FormattedMessage {...messages.confirm} />,
          onClick: () => onConfirm(),
        },
      ]}
    />
  );
};

export default ModalMoveRow;
