import React, { Component } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { promisify } from 'app/actions';
import { connect } from 'react-redux';
import SchoolForm from './SchoolForm';

import { getSchools, getSchoolDetails, saveSchoolDetails } from './schoolActions';

class SchoolViewContainer extends Component {
  componentDidMount() {
    this.props.getSchools();

    let { id } = this.props.match.params;
    if (this.props.isClassManagementModal) {
      id = this.props.idUserSchool;
    }
    this.props.getSchoolDetails(id);
  }

  componentDidupdate(prevProps) {
    if (this.props.schools.length === 0) {
      this.props.getSchools();
    }
  }

  save = values => {
    let { id } = this.props.match.params;
    if (this.props.isClassManagementModal) {
      id = this.props.idUserSchool;
    }
    const {
      user: { idUser },
    } = this.props;

    this.props.saveSchoolDetails({ idUser, idUserSchool: id, ...values });
  };

  render() {
    const {
      schoolDetails,
      role,
      status,
      isClassManagementModal,
      idSchool,
      idUserSchool,
      schools,
    } = this.props;

    return (
      <div>
        <SchoolForm
          data={schoolDetails}
          onSubmit={this.save}
          role={role}
          statusEdition={status}
          isClassManagementModal={isClassManagementModal}
          idSchool={idSchool}
          schools={schools}
          idUserSchool={idUserSchool || null}
        />
      </div>
    );
  }
}

SchoolViewContainer.propTypes = {
  user: PropTypes.object.isRequired,
  getSchoolDetails: PropTypes.func.isRequired,
  role: PropTypes.number.isRequired,
  match: PropTypes.object.isRequired,
  saveSchoolDetails: PropTypes.func.isRequired,
  schoolDetails: PropTypes.object.isRequired,
};

const mapStateToProps = ({ school, app }) => ({
  ...school,
  user: app.user,
  role: app.role,
  status: school.schoolFormStatus,
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    { getSchools, getSchoolDetails, saveSchoolDetails },
  ),
)(SchoolViewContainer);
