import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import messages from './loginMessages';
import './LoginSignTabs.scss';

const LoginSignTabs = ({register}) => (
  <ul className="nav nav-tabs login-sign-tabs">
    {register &&
    <li className="nav-item">
      <FormattedMessage {...messages.signup}>
        {txt => (
          <NavLink className="nav-link" to="/register">
            {txt}
          </NavLink>
        )}
      </FormattedMessage>
    </li>
    }
    <li className="nav-item">
      <FormattedMessage {...messages.signin}>
        {txt => (
          <NavLink className="nav-link" to="/login">
            {txt}
          </NavLink>
        )}
      </FormattedMessage>
    </li>
  </ul>
);

LoginSignTabs.propTypes = {};

export default LoginSignTabs;
