const Role = {
  ADMINISTRADOR: 1,
  COORDINATOR: 2,
  TEACHER: 3,
  STUDENT: 4,
  AUTHOR: 5,
  EDITOR: 6,
};

export default Role;
