import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { capitalize } from 'utils/strings';
import CalendarNav from '../CalendarNav';
import CardMonth from '../daycalendar/CardMonth';
import { withDragDropContext } from '../CalendarContext';
import * as helpers from '../calendarHelpers';
import MonthCalendarCell from './MonthCalendarCell';
import MonthCalendarCellOffset from './MonthCalendarCellOffset';
import messages from './monthCalendarMessages';

import './MonthCalendar.scss';

function startOfMonth(date) {
  return moment(date)
    .startOf('month')
    .toDate();
}

class MonthCalendar extends Component {
  static getDerivedStateFromProps(props, state) {
    const { currentDate } = props;
    if (currentDate !== state.prevCurrentDate) {
      return {
        prevCurrentDate: currentDate,
        currentDate: startOfMonth(currentDate),
      };
    }
    return null;
  }

  state = {
    currentDate: startOfMonth(),
  };

  nextMonth = () => {
    const nextCurrentDate = moment(this.state.currentDate)
      .add(1, 'month')
      .toDate();
    this.selectDate(nextCurrentDate);
  };

  previousMonth = () => {
    const nextCurrentDate = moment(this.state.currentDate)
      .subtract(1, 'month')
      .toDate();
    this.selectDate(nextCurrentDate);
  };

  selectDate = date => {
    this.setState({
      currentDate: date,
    });
    if (this.props.onChangeDate) {
      this.props.onChangeDate(date);
    }
  };

  render() {
    const { currentDate } = this.state;
    const oneMonthFromNow = moment(currentDate)
      .add(1, 'month')
      .toDate();
    const twoMonthsFromNow = moment(currentDate)
      .add(2, 'month')
      .toDate();
    const dates = helpers.enumerateWorkingDatesByMonthAndYear(
      currentDate.getMonth() + 1,
      currentDate.getFullYear(),
    );
    return (
      <div>
        <CalendarNav
          label={
            <FormattedMessage
              {...messages.period}
              values={{
                month: capitalize(moment(currentDate).format('MMMM')),
                year: currentDate.getFullYear(),
              }}
            />
          }
          onPrevious={this.previousMonth}
          onNext={this.nextMonth}
        />
        <div className="calendar month-calendar-wrapper">
          <div className="months-wrapper d-none d-md-block">
            <CardMonth
              month={currentDate.getMonth() + 1}
              year={currentDate.getFullYear()}
              onClickDate={this.selectDate}
            />
            <CardMonth
              month={oneMonthFromNow.getMonth() + 1}
              year={oneMonthFromNow.getFullYear()}
              onClickDate={this.selectDate}
            />
            <CardMonth
              month={twoMonthsFromNow.getMonth() + 1}
              year={twoMonthsFromNow.getFullYear()}
              onClickDate={this.selectDate}
            />
          </div>
          <div className="days-wrapper month-calendar--days-wrapper">
            <MonthCalendarCellOffset startDate={currentDate} />
            {dates.map(date => (
              <MonthCalendarCell date={date} key={date} />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default withDragDropContext(MonthCalendar);
