/* eslint-disable */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'sm-ui';

import messages from '../plannerMessages';

const ModalSave = ({ isOpen, onClose, onContinue }) => (
  <Modal
    title={<FormattedMessage {...messages.modalSaveTitle} />}
    message={<FormattedMessage {...messages.modalSaveMessage} />}
    icon={'pe-7s-like2'}
    show={isOpen}
    onClose={onClose}
    buttons={[
      {
        id: 1,
        label: <FormattedMessage {...messages.modalBackButton} />,
        onClick: onClose,
      },
      {
        id: 2,
        label: <FormattedMessage {...messages.modalContinueButton} />,
        primary: true,
        onClick: onContinue,
      },
    ]}
  />
);

export default ModalSave;
