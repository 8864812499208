import { defineMessages } from 'react-intl';

export default defineMessages({
  schoolName: {
    id: 'schoolCard.schoolName',
    defaultMessage: 'School Name',
  },
  linkCoordinator: {
    id: 'schoolCard.linkCoordinator',
    defaultMessage: 'Link to coordinator',
  },
  address: {
    id: 'schoolCard.address',
    defaultMessage: 'Address',
  },
  code: {
    id: 'schoolCard.code',
    defaultMessage: 'CODE',
  },
  view: {
    id: 'schoolCard.view',
    defaultMessage: 'View',
  },
});
