// @styleguide
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Modal } from 'sm-ui';
import messages from './calendarErrorEventModalMessages';
import './CalendarErrorEventModal.scss';


class CalendarErrorEventModal extends Component {
  
  close = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  render() {
    const { intl, onClose, ...props } = this.props;
    
    return (
      <div className="modalError">
        <Modal
          title={<FormattedMessage {...messages.title} />}
          icon="pe pe-7s-close-circle"
          message={<FormattedMessage {...messages.message} />}
          onClose={this.close}
          buttons={[
            {
              id: 1,
              label: <FormattedMessage {...messages.closeButton} />,
              onClick: this.close,
            }
          ]}
          {...props}
        />
      </div>
    );
  }
}

CalendarErrorEventModal.propTypes = {};

export default injectIntl(CalendarErrorEventModal);
