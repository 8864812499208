import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import messages from './scheduleMessages';

class ScheduleStatusPlanTooltipDetail extends Component {
  state = {
    collapsed: false,
  };

  toggleCollapse = e => {
    e.stopPropagation();
    e.preventDefault();
    this.setState(({ collapsed }) => ({
      collapsed: !collapsed,
    }));
  };

  render() {
    const { collapsed } = this.state;
    const { applied, scheduled, period } = this.props;
    const appliedNumber = parseFloat(applied);
    const scheduledNumber = parseFloat(scheduled);
    const hasValues = !!appliedNumber || !!scheduledNumber;
    return (
      <Fragment>
        {!hasValues && (
          <p>
            <FormattedMessage {...messages.period}>
              {txt => (
                <strong>
                  {period}º {txt}:
                </strong>
              )}
            </FormattedMessage>{' '}
            <FormattedMessage {...messages.notStarted} />
          </p>
        )}
        {hasValues && (
          <Fragment>
            <a
              className={classNames('accordion-title text-success', { collapsed })}
              onClick={this.toggleCollapse}
              aria-expanded={!collapsed}
            >
              <FormattedMessage {...messages.period}>
                {txt => (
                  <strong>
                    {period}º {txt}
                  </strong>
                )}
              </FormattedMessage>
              <i className="fa fa-chevron-down" />
            </a>
            <div className={classNames('multi-collapse collapse', { show: collapsed })}>
              <div className="bar-chart horizontal-bars-chart my-2">
                <div className="bar-chart-wrapper" style={{ width: '85%' }}>
                  <FormattedMessage {...messages.scheduled}>
                    {txt => <span className="legend">{txt}</span>}
                  </FormattedMessage>
                  <div className="bc-row">
                    <div className="bc-bar bc--green" style={{ width: `${scheduledNumber}%` }}>
                      <div className="bc-bar-value">{scheduledNumber}%</div>
                    </div>
                  </div>
                  <FormattedMessage {...messages.applied}>
                    {txt => <span className="legend">{txt}</span>}
                  </FormattedMessage>
                  <div className="bc-row">
                    <div className="bc-bar bc--gray" style={{ width: `${applied}%` }}>
                      <div className="bc-bar-value">{appliedNumber}%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default ScheduleStatusPlanTooltipDetail;
