import React, { Component } from 'react';
import { animateScroll as scroll } from 'react-scroll';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export function withScrollTopOnMount(WrappedComponent) {
  class WithScrollOnStop extends Component {
    componentDidMount() {
      setImmediate(() => {
        scroll.scrollToTop();
      });
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }
  WithScrollOnStop.displayName = `WithScrollOnStop(${getDisplayName(WrappedComponent)})`;
  return WithScrollOnStop;
}
