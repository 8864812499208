import React, { Component } from 'react';
import classNames from 'classnames';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { withCalendar, getCellAppointments } from '../CalendarContext';
import './WeekCalendarRowMobile.scss';
import CalendarEventType from '../calendarEventType';
import messages from '../calendarMessages';

import WeekCardAppointmentMobile from './WeekCardAppointmentMobile';

class WeekCalendarRowMobile extends Component {
  render() {
    const { isOver, canDrop, date, hour, appointments } = this.props;
    return (
      <div className="item week-calendar-row-mobile d-flex d-md-none">
        <FormattedMessage {...messages.hourLabel}>
          {txt => (
            <div className="week-calendar-row-mobile--hour">
              {txt}
              {hour}
            </div>
          )}
        </FormattedMessage>
        <div className="week-calendar-row-mobile--cards">
          <div style={{ padding: 5 }}>
            {appointments &&
              getCellAppointments(date, appointments, hour).map(appointment => (
                <WeekCardAppointmentMobile {...appointment} key={appointment.id} hour={hour} />
              ))}
          </div>
        </div>
      </div>
    );
  }
}

WeekCalendarRowMobile.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  moment: PropTypes.func.isRequired,
};

export default compose(withCalendar)(WeekCalendarRowMobile);
