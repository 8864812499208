// @styleguide
import React, { Component, useState } from 'react';
import { renderToString } from 'react-dom/server';
import PropTypes from 'prop-types';
import uniq from 'lodash/uniq';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import './ClassManagementContainer.scss';
import { Select, Checkbox, Alert } from 'sm-ui';
import ClassManagementWeekDaysTab from './ClassManagementWeekDaysTab';
import ClassManagementHoursTab from './ClassManagementHoursTab';

import messages from './classManagementMessages';

let errorAux = false;

class ClassManagementCourse extends Component {
  static getDerivedStateFromProps(props, state) {
    const { courseName, idSchool } = props;
    let newState = {};
    if (courseName !== state.prevCourseName) {
      newState = {
        prevCourseName: courseName,
        courseName,
      };
    }
    if (idSchool !== state.prevIdSchool) {
      newState = {
        ...newState,
        prevIdSchool: idSchool,
        idSchoolSelected: idSchool,
      };
    }
    return newState;
  }

  state = {
    currentWeekDay: 2,
    courseName: this.props.courseName || '',
    idSchoolSelected: this.props.idSchool,
    selected: this.props.selected || false,
  };

  componentDidMount() {
    // focus when its new course
    if (!this.props.courseName) {
      this.input.current.focus();
    }
  }

  input = React.createRef();

  handleSelectWeekDay = weekDay => {
    this.setState({
      currentWeekDay: weekDay,
    });
  };

  handleChangeCourseName = event => {
    const { value } = event.target;
    errorAux = false;
    this.setState(
      {
        courseName: value,
      },
      () => {
        errorAux = true;
      },
    );
  };

  handleBlurCourseName = () => {
    this.updateOrSaveChanges();
  };

  updateOrSaveChanges = () => {
    const { courseName, idSchoolSelected } = this.state;
    if (this.props.onChangeCourse) {
      this.props.onChangeCourse({ fullname: courseName, idSchool: idSchoolSelected });
    }
  };

  handleSelectSchool = event => {
    const idSchoolSelected = event.target.value;
    this.setState({ idSchoolSelected }, () => {
      this.updateOrSaveChanges();
      this.props.handleSelectSchool(idSchoolSelected);
    });
  };

  handleSelectCourse = event => {
    const { checked } = event.target;
    this.setState(
      {
        selected: checked,
      },
      () => {
        if (this.props.onToggleCourse) {
          this.props.onToggleCourse();
        }
      },
    );
  };

  handleSchoolYearModal = () => {
    this.props.handleOpenSchoolYearModal();
  };

  renderLinkError = error => {
    if (error.code === 14) {
      return (
        <u style={{ color: '#1f71c6', cursor: 'pointer' }} onClick={this.handleSchoolYearModal}>
          <FormattedMessage {...messages.errorCode14clickHere} />
        </u>
      );
    }

    const isLinkError = parseInt(get(error, 'code'), 0) === 14;
    if (!isLinkError) return undefined;
    const { idSchoolSelected } = this.state;
    const { schools } = this.props;
    const schoolSelected = schools.find(s => s.idSchool === idSchoolSelected);
    const idUserSchoolSelected = schoolSelected ? schoolSelected.idUserSchool : null;
    const idLinkError = get(error, 'info[0].idRelUS');
    return (
      <Link to={`/school/${idUserSchoolSelected || idLinkError}`}>
        <FormattedMessage {...messages.errorCode14clickHere} />
      </Link>
    );
  };

  renderErrors = () => {
    const { error } = this.props;
    const { courseName, prevCourseName } = this.state;
    if (!error || Object.keys(error).length === 0) return null;
    const errors = Object.keys(error).map(key => error[key]);

    if (courseName !== prevCourseName) {
      if (errorAux) {
        this.setState({
          courseName: prevCourseName,
        });
        this.input.current.focus();
      }
    }

    return (
      <div>
        {errors.map(e => {
          const linkError = this.renderLinkError(e);
          const info = get(e, 'info[0]') || get(e, 'info');
          const values = {
            ...info,
            link: linkError,
          };
          return (
            <Alert type="danger" className="text-left" key={e.code || 500}>
              <FormattedMessage
                {...(messages[`errorCode${e.code}`] || messages.errorCode500)}
                values={values}
              />
            </Alert>
          );
        })}
      </div>
    );
  };

  render() {
    const {
      onRequestRemoveCourse,
      schools,
      removable,
      daysHours,
      onRemoveHour,
      onAddHour,
      idPlanScheduled,
      planScheduled,
      plannerDefaultClasses,
    } = this.props;
    const { currentWeekDay, courseName, selected, idSchoolSelected } = this.state;

    // get active weekdays by unique values from array dayHours ({weekDay: '1', hour: '2'})
    const activeWeekDays = uniq(daysHours.map(dayHour => parseInt(dayHour.weekDay, 0)));

    // get active hours by filtering hours from daysHours that matches currentWeekDay
    const activeHours = daysHours
      .filter(dayHour => parseInt(dayHour.weekDay, 0) === currentWeekDay)
      .map(dayHour => parseInt(dayHour.hour, 0));

    const maxVisibleHours = Math.max(6, Math.max(...activeHours));
    const errorsAlerts = this.renderErrors();
    return (
      <div className="row row-grid">
        <div className="col-4 text-left">
          {errorsAlerts}
          {planScheduled && planScheduled !== '0' && (
            <Alert type="success" className="text-left">
              <FormattedMessage {...messages.classScheduled} />
            </Alert>
          )}
          <div className="row align-items-center">
            <div className="col" style={{ maxWidth: 42 }}>
              <Checkbox checked={selected} onChange={this.handleSelectCourse} />
            </div>
            <div className="col">
              <FormattedMessage {...messages.insertClassName}>
                {insertName => (
                  <input
                    className="labelSelect "
                    type="text"
                    ref={this.input}
                    value={courseName || ''}
                    disabled={!removable}
                    onChange={this.handleChangeCourseName}
                    onBlur={this.handleBlurCourseName}
                    placeholder={insertName}
                  />
                )}
              </FormattedMessage>
            </div>
          </div>
          <div>
            <div className="col">
              {schools.length > 1 && (
                <div style={{ marginTop: -20, marginBottom: 15 }}>
                  <FormattedMessage {...messages.selectLabelSchool}>
                    {txt => (
                      <Select
                        id="school"
                        options={schools}
                        itemValue="idSchool"
                        selectLabel={txt}
                        value={idSchoolSelected}
                        itemLabel="name"
                        onChange={this.handleSelectSchool}
                      />
                    )}
                  </FormattedMessage>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-8 text-left">
          <div className="row">
            <div className="col-10">
              <div>
                <ClassManagementWeekDaysTab
                  weekDay={currentWeekDay}
                  activeWeekDays={activeWeekDays}
                  onSelectWeekDay={this.handleSelectWeekDay}
                />
                <ClassManagementHoursTab
                  weekDay={currentWeekDay}
                  activeHours={activeHours}
                  defaultMaxVisibleHours={maxVisibleHours}
                  plannerDefaultClasses={Number(plannerDefaultClasses)}
                  onAddHour={onAddHour}
                  onRemoveHour={onRemoveHour}
                  handleSelectCourse={selected => {
                    this.setState({ selected });
                    this.props.onToggleCourse();
                  }}
                />
              </div>
            </div>
            <div className="col-2 button-remove">
              {removable && (
                <button className="text-center removable" onClick={onRequestRemoveCourse}>
                  <i className="fa fa-trash-alt" /> <br />
                  <FormattedMessage {...messages.delete} />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ClassManagementCourse.propTypes = {};

ClassManagementCourse.defaultProps = {
  schools: [],
  removable: true,
  daysHours: [],
};

export default ClassManagementCourse;
