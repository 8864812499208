import { defineMessages } from 'react-intl';

export default defineMessages({
  date: {
    id: 'calendarEventDetailsModal.date',
    defaultMessage: 'Event Date',
  },
  duration: {
    id: 'calendarEventDetailsModal.duration',
    defaultMessage: 'Duration',
  },
  about: {
    id: 'calendarEventDetailsModal.about',
    defaultMessage: 'About the event',
  },
  close: {
    id: 'calendarEventDetailsModal.close',
    defaultMessage: 'Close',
  },
  classes: {
    id: 'calendarEventDetailsModal.classes',
    defaultMessage: 'classes',
  },
});
