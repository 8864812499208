import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';

const LanguageProvider = ({ locale, children, messages }) => (
  <IntlProvider locale={locale} key={locale} messages={messages[locale]}>
    {children}
  </IntlProvider>
);

LanguageProvider.propTypes = {
  locale: PropTypes.string,
  messages: PropTypes.object,
  children: PropTypes.element.isRequired
};

const mapStateToProps = ({ lang }) => ({ locale: lang.locale });

export default connect(mapStateToProps)(LanguageProvider);
