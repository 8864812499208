import { defineMessages } from 'react-intl';

export default defineMessages({
  sunday: {
    id: 'cardMonth.sunday',
    defaultMessage: 'S'
  },
  monday: {
    id: 'cardMonth.monday',
    defaultMessage: 'M'
  },
  tuesday: {
    id: 'cardMonth.tuesday',
    defaultMessage: 'T'
  },
  wednesday: {
    id: 'cardMonth.wednesday',
    defaultMessage: 'W'
  },
  thursday: {
    id: 'cardMonth.thursday',
    defaultMessage: 'T'
  },
  friday: {
    id: 'cardMonth.friday',
    defaultMessage: 'F'
  },
  saturday: {
    id: 'cardMonth.saturday',
    defaultMessage: 'S'
  }
});
