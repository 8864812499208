import { success, failure, request } from 'app/actions';
import * as actions from './reportsActions';
import * as helpers from './reportsHelpers';

const initialState = {
  statusPlanScheduled: {},
  plansScheduled: [],
  disciplinesFilter: [],
  disciplinesFilterLabel: '',
  levelsFilter: [],
  levelsFilterLabel: '',
  teachersPlansScheduled: [],
  teachersFilter: [],
  teachersDisciplinesFilter: [],
  teachersDisciplinesFilterLabel: '',
  teachersLevelsFilter: [],
  teachersLevelsFilterLabel: '',
  statusContent: {},
  loading: false,
  error: {
    hasError: false,
    code: null,
    message: null,
  },

  planner: null,
  scheduledPlans: null,
  scheduledPlansValues: null,
  addPlanScheduledLessonLog: null,
  scheduledLessonStatus: {
    status: '',
    data: [],
  },
  table: {
    columns: [],
    rows: [],
  },
  filterPlans: [],
  filterCourses: [],
  appointments: [],
  superlessons: [],
  scheduledLesson: null,
  errorReassignment: null,
  errorAddEvent: null,
  classDetails: null,
  eventDetails: null,
  idScheduledLesson: null,
  isLoading: false,
  auxMaximusHour: 0,
  years: [],
  yearsTeacher: [],
};

const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case request(actions.GET_STATUS_PLAN_SCHEDULED):
      return {
        ...state,
        statusPlanScheduled: {},
        plansScheduled: state.plansScheduled.map(plan => {
          if (plan.idPlan === action.idPlan && plan.idCourse === action.idCourse) {
            return {
              ...plan,
              statusLoading: true,
            };
          }
          return plan;
        }),
      };
    case success(actions.GET_STATUS_PLAN_SCHEDULED):
      return {
        ...state,
        statusPlanScheduled: action.payload.content,
        plansScheduled: state.plansScheduled.map(plan => {
          if (plan.idPlan === action.idPlan && plan.idCourse === action.idCourse) {
            return {
              ...plan,
              status: action.payload.content,
              statusLoading: false,
            };
          }
          return plan;
        }),
      };
    case failure(actions.GET_STATUS_PLAN_SCHEDULED):
      return {
        ...state,
        statusPlanScheduled: action.payload.content,
        plansScheduled: state.plansScheduled.map(plan => {
          if (plan.idPlan === action.idPlan && plan.idCourse === action.idCourse) {
            return {
              ...plan,
              statusLoading: false,
            };
          }
          return plan;
        }),
      };
    case success(actions.GET_PLANS_SCHEDULED): {
      const { payload } = action;
      const {
        plansScheduled,
        levelsFilter,
        disciplinesFilter,
        disciplinesFilterLabel,
        levelsFilterLabel,
        teachersPlans,
        teachersFilter,
        teachersDisciplinesFilter,
        teachersDisciplinesFilterLabel,
        teachersLevelsFilter,
        teachersLevelsFilterLabel,
      } = payload;
      const years = helpers.getYears(plansScheduled);
      const yearsTeacher = helpers.getYears(teachersPlans);
      return {
        ...state,
        plansScheduled: helpers.enhancePlansScheduled(plansScheduled || []),
        disciplinesFilter: disciplinesFilter || [],
        disciplinesFilterLabel: disciplinesFilterLabel || [],
        levelsFilter: levelsFilter || [],
        levelsFilterLabel: levelsFilterLabel || [],
        teachersPlansScheduled: helpers.enhancePlansScheduled(teachersPlans || []),
        teachersFilter: teachersFilter || [],
        teachersDisciplinesFilter: teachersDisciplinesFilter || [],
        teachersDisciplinesFilterLabel: teachersDisciplinesFilterLabel || [],
        teachersLevelsFilter: teachersLevelsFilter || [],
        teachersLevelsFilterLabel: teachersLevelsFilterLabel || [],
        years: years.length > 0 ? years : [new Date().getFullYear()],
        yearsTeacher: yearsTeacher.length > 0 ? yearsTeacher : [new Date().getFullYear()],
      };
    }
    default:
      return state;
  }
};

export default reportsReducer;
