import React from 'react';
import _ from 'lodash';

export default {
  getId: pathname => {
    const id = pathname.split('/');
    return id[2];
  },
  countColl: (id, handleEditOrigin, menuComponent) => ({
    Header: '',
    Cell: row =>
      id === 'last' ? (
        ''
      ) : (
        <div
          id={id}
          style={{
            textAlign: 'center',
            cursor: 'pointer',
            width: '100%',
            height: '100%',
          }}
          onContextMenu={e => {
            e.preventDefault();
            handleEditOrigin(`row-${row.viewIndex}`);
          }}
        >
          <small>
            <i>{row.viewIndex + 1}</i>
            {menuComponent && menuComponent(row.viewIndex)}
          </small>
        </div>
      ),
    maxWidth: id === 'last' ? 15 : 40,
    resizable: false,
    sortable: false,
  }),
  parseObjectKeys: el => Object.keys(el).map(key => key),
  formatText: (style, originalText) => {
    let parsedText = '';
    switch (style) {
      case 'BOLD':
        parsedText = `<b>${originalText}</b>`;
        break;
      case 'ITALIC':
        parsedText = `<i>${originalText}</i>`;
        break;
      case 'UNDERLINE':
        parsedText = `<u>${originalText}</u>`;
        break;
      case 'STRIKE':
        parsedText = `<del>${originalText}</del>`;
        break;
      default:
        parsedText = originalText;
        break;
    }
    return parsedText;
  },
  s2ab: s => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  },
  resolveColumns: columnsData => {
    const parsedColumnKeys = Object.keys(columnsData).map(key => key);
    const parsedColumns = parsedColumnKeys.map(el => columnsData[el]);
    parsedColumns.map((col, index) => {
      col.idIndex = (index + 1).toString();
    });
    return parsedColumns;
  },
  resolveRows: rowsData => {
    const parsedRows = [];
    rowsData.length > 0 &&
      rowsData.map(row => {
        const obj = row.values ? row.values : {};
        parsedRows.push(obj);
      });
    return parsedRows;
  },
  printElem: () => {
    const printDocument = window.open('', 'Print', 'height=600,width=800');
    const reactTable = document.getElementById('table-wrapper').firstChild.firstChild;
    const tableHeader = reactTable.firstChild.firstChild;
    const tableBody = reactTable.lastChild.childNodes;

    const clientWidth = _.first(reactTable.childNodes).clientWidth;

    printDocument.document.write('<div style="box-sizing: content-box;">');
    printDocument.document.write(
      `<table style="
          width: 100%;
          border-collapse: collapse;
          border: 1px solid black;
          font-size: 12px;
          font-family: Verdana, sans-serif;
          line-height: 1.5;
          zoom: ${clientWidth > 780 ? '0.6' : '1'}
        ">`,
    );
    printDocument.document.write('<tr>');
    tableHeader.childNodes.forEach((el, index) => {
      let width = '';
      el.firstChild.childNodes.forEach(el => (width = String(el.clientWidth)));

      if (index > 0 && index !== tableHeader.childNodes.length - 1) {
        printDocument.document.write(
          `<th style="border:1px solid black; min-width:${width}px!important; max-width:${width}px!important">`,
        );
        printDocument.document.write(el.textContent);
        printDocument.document.write('</th>');
      }
    });
    printDocument.document.write('</tr>');
    tableBody.forEach(el => {
      const length = el.firstChild.childNodes.length;
      printDocument.document.write('<tr>');
      el.firstChild.childNodes.forEach((el, index) => {
        if (index > 0 && index !== length - 1) {
          printDocument.document.write(
            '<td style="border:1px solid black; text-align: center; padding: 5px">',
          );
          printDocument.document.write(el.textContent);
          printDocument.document.write('</td>');
        }
      });
      printDocument.document.write('</tr>');
    });
    printDocument.document.write('</table>');
    printDocument.document.write('</div>');

    printDocument.document.close();
    printDocument.focus();
    printDocument.print();
    printDocument.close();

    return true;
  },
};
