import React from 'react';
import PropTypes from 'prop-types';
import Transition from 'react-transition-group/Transition';

// ease-in-out
const defaultStyle = duration => ({
  transition: `opacity ${duration}ms, max-height ${duration}ms`,
  // transition: `height ${duration}ms`,
  opacity: 0,
  maxHeight: 0
});

const transitionStyles = maxHeight => ({
  entering: { maxHeight, opacity: 1 },
  entered: { maxHeight, opacity: 1 }
});

const Collapse = ({ in: inProp, maxHeight, children, duration }) => (
  <Transition in={inProp} timeout={duration}>
    {state => (
      <div
        style={{
          ...defaultStyle(duration, maxHeight),
          ...transitionStyles(maxHeight)[state]
        }}
      >
        {children}
      </div>
    )}
  </Transition>
);

Collapse.propTypes = {
  duration: PropTypes.number,
  in: PropTypes.bool,
  maxHeight: PropTypes.number,
  children: PropTypes.node.isRequired
};

Collapse.defaultProps = {
  duration: 500,
  maxHeight: 1000
};

export default Collapse;
