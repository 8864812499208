// @flow
import { success, failure, request } from 'app/actions';
import moment from 'moment';
import _ from 'lodash';
import * as actions from './scheduleActions';
import * as helpers from './scheduleHelpers';

import download from 'common/download';

import { resolveColumns, resolveRows } from './calendar/calendarHelpers';
import { getError } from '../app/appHelpers';

moment.locale('pt-br');

const initialState = {
  plansScheduled: [],
  disciplinesFilter: [],
  disciplinesFilterLabel: '',
  levelsFilter: [],
  levelsFilterLabel: '',
  teachersPlansScheduled: [],
  teachersFilter: [],
  teachersDisciplinesFilter: [],
  teachersDisciplinesFilterLabel: '',
  teachersLevelsFilter: [],
  teachersLevelsFilterLabel: '',
  statusContent: {},
  loading: false,
  error: {
    hasError: false,
    code: null,
    message: null,
  },

  planner: null,
  scheduledPlans: null,
  scheduledPlansValues: null,
  addPlanScheduledLessonLog: null,
  scheduledLessonStatus: {
    status: '',
    data: [],
  },
  table: {
    columns: [],
    rows: [],
  },
  filterPlans: [],
  filterCourses: [],
  appointments: [],
  superlessons: [],
  scheduledLesson: null,
  errorReassignment: null,
  errorAddEvent: null,
  classDetails: null,
  eventDetails: null,
  idScheduledLesson: null,
  isLoading: false,
  auxMaximusHour: 0,
  years: [],
  yearsTeacher: [],
};

function filter(list: Array<any>, prop: string, value: any) {
  return list.filter(item => new RegExp(value, 'ig').test(item[prop].toString()));
}

const scheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case request(actions.REASSIGN_APPOINTMENT):
      return { ...state, errorReassignment: initialState.errorReassignment };
    case failure(actions.REASSIGN_APPOINTMENT):
      return { ...state, errorReassignment: getError(action) };
    case actions.RESET_REASSIGN_FORM:
      return { ...state, errorReassignment: initialState.errorReassignment };
    case request(actions.GET_PLANS_SCHEDULED_VALUES):
      return { ...state, isLoading: true };
    case failure(actions.GET_PLANS_SCHEDULED_VALUES):
      return { ...state, isLoading: false };
    case success(actions.GET_PLANS_SCHEDULED_VALUES):
      const scheduledLesson = {
        ...action.payload.content,
        date: moment(action.payload.content.date).format('L'),
      };
      return { ...state, isLoading: false, scheduledLesson };
    case request(actions.GET_STATUS_SCHEDULED):
      return { ...state, isLoading: true };
    case failure(actions.GET_STATUS_SCHEDULED):
      return { ...state, isLoading: false };
    case success(actions.GET_STATUS_SCHEDULED):
      const scheduledLessonStatus = {
        status: action.payload.lessonStatus,
        data: action.payload.content.reduce(
          (prevElem, elem) => [
            ...prevElem,
            {
              id: elem.id,
              status: elem.status,
              added: `${moment(elem.added).format('L')} - ${moment(elem.added).format('LT')}`,
              comment: elem.comments,
            },
          ],
          [],
        ),
      };
      return { ...state, scheduledLessonStatus, isLoading: false };
    case request(actions.ADD_PLAN_SCHEDULED_EVENT):
      return { ...state, errorAddEvent: initialState.errorAddEvent };
    case failure(actions.ADD_PLAN_SCHEDULED_EVENT):
      return { ...state, errorAddEvent: getError(action) };
    case success(actions.GET_PLANS_SCHEDULED): {
      const { payload } = action;
      const {
        plansScheduled,
        levelsFilter,
        disciplinesFilter,
        disciplinesFilterLabel,
        levelsFilterLabel,
        teachersPlans,
        teachersFilter,
        teachersDisciplinesFilter,
        teachersDisciplinesFilterLabel,
        teachersLevelsFilter,
        teachersLevelsFilterLabel,
      } = payload;
      const years = helpers.getYears(plansScheduled);
      const yearsTeacher = helpers.getYears(teachersPlans);
      return {
        ...state,
        plansScheduled: helpers.enhancePlansScheduled(plansScheduled || []),
        disciplinesFilter: disciplinesFilter || [],
        disciplinesFilterLabel: disciplinesFilterLabel || [],
        levelsFilter: levelsFilter || [],
        levelsFilterLabel: levelsFilterLabel || [],
        teachersPlansScheduled: helpers.enhancePlansScheduled(teachersPlans || []),
        teachersFilter: teachersFilter || [],
        teachersDisciplinesFilter: teachersDisciplinesFilter || [],
        teachersDisciplinesFilterLabel: teachersDisciplinesFilterLabel || [],
        teachersLevelsFilter: teachersLevelsFilter || [],
        teachersLevelsFilterLabel: teachersLevelsFilterLabel || [],
        years: years.length > 0 ? years : [new Date().getFullYear()],
        yearsTeacher: yearsTeacher.length > 0 ? yearsTeacher : [new Date().getFullYear()],
      };
    }
    case failure(actions.GET_PLANS_SCHEDULED):
      return {
        ...state,
        error: {
          hasError: true,
          code: action.payload.status,
          message: action.payload.error,
        },
      };
    case request(actions.GET_STATUS_PLAN_SCHEDULED):
      return { ...state, statusContent: { ...state.statusContent, [action.key]: null } };
    case success(actions.GET_STATUS_PLAN_SCHEDULED):
      const {
        key,
        payload: { content },
      } = action;
      return {
        ...state,
        statusContent: {
          ...state.statusContent,
          [key]: { scheduled: content[0][0].PlanScheduled, applied: content[0][0].planApplied },
        },
      };
    case success(actions.GET_PLANNER_DETAIL):
      const parsedColumns = resolveColumns(action.payload.content.table.columns);
      const parsedRows = resolveRows(action.payload.content.table.rows);
      return {
        ...state,
        planner: action.payload.content,
        table: {
          columns: parsedColumns,
          rows: parsedRows,
        },
      };
    case failure(actions.GET_PLANNER_DETAIL):
      return {
        ...state,
        planner: null,
        table: {
          columns: [],
          rows: [],
        },
      };
    case success(actions.GET_SCHEDULE_APPOINTMENTS): {
      return {
        ...state,
        auxMaximusHour: action.payload.auxMaximusHour,
        appointments: helpers.toAppointments(action.payload.lessonsCards),
      };
    }
    case success(actions.GET_ALL_SCHEDULE_APPOINTMENTS): {
      return {
        ...state,
        auxMaximusHour: action.payload.auxMaximusHour,
        appointments: [
          ...state.appointments,
          ...helpers.toAppointments(action.payload.lessonsCards),
        ],
      };
    }
    case request(actions.GET_CLASS_APPOINTMENT_DETAILS):
      return { ...state, classDetails: initialState.classDetails };
    case success(actions.GET_CLASS_APPOINTMENT_DETAILS):
      return {
        ...state,
        classDetails: action.payload.content,
      };
    case request(actions.GET_EVENT_APPOINTMENT_DETAILS):
      return { ...state, eventDetails: initialState.eventDetails };
    case success(actions.GET_EVENT_APPOINTMENT_DETAILS):
      return {
        ...state,
        eventDetails: action.payload.content,
      };
    case request(actions.ADD_PLANS_SCHEDULED_LESSON_LOG):
      return { ...state };
    case success(actions.ADD_PLANS_SCHEDULED_LESSON_LOG):
      return {
        ...state,
        addPlanScheduledLessonLog: action.payload.content,
      };
    case failure(actions.ADD_PLANS_SCHEDULED_LESSON_LOG):
      return {
        ...state,
        addPlanScheduledLessonLog: null,
      };
    case success(actions.GET_FILTERS_COURSES_PLAN):
      return {
        ...state,
        filterCourses: action.payload.filterCourses,
        filterPlans: action.payload.filterPlans,
      };
    case request(actions.REMOVE_EVENT):
      return { ...state };
    case success(actions.REMOVE_EVENT):
      return { ...state };
    case failure(actions.REMOVE_EVENT):
      return { ...state };
    case request(actions.GET_SUPER_LESSONS):
      return { ...state };
    case success(actions.GET_SUPER_LESSONS):
      return { ...state, superlessons: action.payload.superlessons };
    case failure(actions.GET_SUPER_LESSONS):
      return { ...state, superlessons: [] };
    case request(actions.VIEW_FILE_SUPERLESSONS):
      return { ...state, isLoading: true };
    case success(actions.VIEW_FILE_SUPERLESSONS):
      const dataApplication = _.first(action.payload.split(','));
      const base64 = _.last(action.payload.split(','));
      let fileType = '';
      if (_.includes(dataApplication, 'word')) {
        fileType = 'docx';
      }
      if (_.includes(dataApplication, 'pdf')) {
        fileType = 'pdf';
      }
      if (_.includes(dataApplication, 'odt')) {
        fileType = 'odt';
      }
      const file = `data:application/${fileType};base64,${base64}`;
      download(file, `file.${fileType}`, `file/${fileType}`);

      return {
        ...state,
        isLoading: false,
        hasError: false,
      };
    case failure(actions.VIEW_FILE_SUPERLESSONS):
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    default:
      return state;
  }
};

const getMimetype = signature => {
  switch (signature) {
    case '89504E47':
      return 'image/png';
    case '47494638':
      return 'image/gif';
    case '25504446':
      return 'application/pdf';
    case 'FFD8FFDB':
    case 'FFD8FFE0':
    case 'FFD8FFE1':
      return 'image/jpeg';
    case '504B0304':
      return 'application/zip';
    default:
      return 'Unknown filetype';
  }
};

export default scheduleReducer;
