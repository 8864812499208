import React, { PureComponent, Fragment } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { injectIntl, FormattedMessage, FormattedHTMLMessage, intlShape } from 'react-intl';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import find from 'lodash/find';
import unionBy from 'lodash/unionBy';
import { Input, Select, Row, Col, Alert, Button, Loader } from 'sm-ui';

import LanguageSelect from 'lang/LanguageSelect';
import SchoolSelect from '../school/SchoolSelect';
import LoginSignTabs from '../login/LoginSignTabs';
import { EditionStatus } from './registerReducer';

import messages from './registerMessages';

import './RegisterForm.scss';

const ID_COUNTRY_BRAZIL = 14;
const ID_COUNTRY_COLOMBIA = 15;
const ID_USER_TYPE_TEACHER = '3';
const ID_USER_TYPE_COORDINATOR = '2';
const ID_USER_TYPE_PUBLIC = '2';
const ID_USER_TYPE_PRIVATE = '1';
const idsCountries = [ID_COUNTRY_BRAZIL];

const RegisterSchema = Yup.object().shape({
  firstname: Yup.string().required('errorRequired'),
  lastname: Yup.string().required('errorRequired'),
  idSchool: Yup.number().required('errorRequired'),
  idCountry: Yup.number().required('errorRequired'),
  email: Yup.string().email('errorInvalidEmail').required('errorRequired'),
  emailConfirm: Yup.string().email('errorInvalidEmail').oneOf([Yup.ref('email'), null], 'errorDontMatch').required('errorRequired'),
  password: Yup.string().matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    "errorStrong").required('errorRequired'),
  passwordConfirm: Yup.string().oneOf([Yup.ref('password'), null], 'errorDontMatch').required('errorRequired'),
  city: Yup.string().required('errorRequired'),
  teachingType: Yup.string().required('errorRequired'),
  idType: Yup.string().required('errorRequired'),
  state: Yup.string().required('errorRequired'),
  terms: Yup.boolean()
    .oneOf([true], 'mustAcceptTerms')
    .required("errorRequired"),
  receiveCommercial: Yup.boolean()
});

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  emailConfirm: '',
  password: '',
  passwordConfirm: '',
  city: '',
  state: '',
  idSchool: '',
  profile: '',
  courses: [],
  selectedGroups: [],
  idType: '',
  teachingType: '',
  terms: false,
  receiveCommercial: false
};

const enhanceForm = withFormik({
  mapPropsToValues: () => ({}),
  initialValues,
  validationSchema: RegisterSchema,
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },
});

class RegisterForm extends PureComponent {
  state = {
    school: null,
    groups: [],
    selectedGroups: [],
    isLoading: true,
  };

  componentDidMount() {
    setTimeout(() => {
      this.handleSelectCountry();
      this.setState({ isLoading: false });
    }, 1000);
  }

  componentDidUpdate(prevProps) {
    const { intl, values } = this.props;

    if (prevProps.values.idCountry !== values.idCountry) {
      this.props.getStates(values.idCountry);
    }

    if (prevProps.values.state !== values.state) {
      this.props.getCities(values.idCountry, values.state);
    }

    if (prevProps.values.city !== values.city) {
      this.props.searchSchools(values.idCountry, values.state, values.city);
    }
  
    if (prevProps.statusRegister !== this.props.statusRegister && this.props.statusRegister === 3) {
      this.props.login(values);  
    }
  }

  getMessageError = field => {
    const { intl, errors } = this.props;
    if (errors && errors[field]) {
      return intl.formatMessage(messages[errors[field]]);
    }
    return undefined;
  };

  isValid = field => {
    const { errors, error, touched } = this.props;
    if (errors && errors[field] && touched[field]) {
      return false;
    }
    if (Object.keys(error).length > 0 && error.code !== 1) return false;
    if (Object.keys(error).length > 0 && error.code === 1 && field === "email") return false;
    if (Object.keys(error).length > 0 && error.code === 1 && field === "emailConfirm") return false;
    return touched[field] ? true : undefined;
  };

  handleSelectSchool = school => {
    this.setState({ school });
    this.props.setFieldValue('idSchool', school.id);
  };

  handleSelectCountry = event => {
    let value = '14';
    // let value = this.props.values.idCountry;
    if (event) {
      // eslint-disable-next-line prefer-destructuring
      value = event.target.value;
    }

    let groups = [];

    const selectedCountry = find(this.props.selectCountries, el => el.id === value);
    const selectedCountryDetails = this.props.countries[selectedCountry.label];
    const selectedCountryGroups = unionBy(selectedCountryDetails, 'groups').find(el => el.groups);
    if (selectedCountryGroups && selectedCountryGroups.groups) {
      groups = selectedCountryGroups.groups.map(el => ({
        ...el,
        value: el.id,
        label: el.name,
      }));
    }

    this.setState({ groups });

    this.props.setFieldValue('idCountry', value);
    this.props.setFieldValue('state', initialValues.state);
    this.props.setFieldValue('city', initialValues.city);
    this.props.setFieldValue('idSchool', initialValues.idSchool);
    // this.props.setFieldValue('groups', initialValues.selectedGroups);
    this.props.setFieldValue('firstname', initialValues.firstname);
    this.props.setFieldValue('lastname', initialValues.lastname);
    this.props.setFieldValue('email', initialValues.email);
    this.props.setFieldValue('emailConfirm', initialValues.emailConfirm);
    this.props.setFieldValue('password', initialValues.password);
    this.props.setFieldValue('passwordConfirm', initialValues.passwordConfirm);
    this.props.setFieldValue('idType', initialValues.idType);
    this.props.setFieldValue('teachingType', initialValues.teachingType);
    this.props.setFieldValue('idSchool', initialValues.idSchool);
    this.props.setFieldValue('terms', initialValues.terms);
    this.props.setFieldValue('receiveCommercial', initialValues.receiveCommercial);
    this.setState({ school: null });
  };

  handleSelectState = event => {
    const { value } = event.target;
    this.props.setFieldValue('state', value);
    this.props.setFieldValue('city', initialValues.city);
    this.props.setFieldValue('idSchool', initialValues.idSchool);
    this.setState({ school: null });
  };

  handleSelectCity = event => {
    const { value } = event.target;
    this.props.setFieldValue('city', value);
    this.props.setFieldValue('idSchool', initialValues.idSchool);
    this.setState({ school: null });
  };

  redirectLogin = () => {
    this.props.history.push('/login');
  };

  handleChangeGroup = selectedGroup => {
    const alreadyAdded = !!find(this.state.selectedGroups, id => id === selectedGroup.id);
    let selectedGroups = [];

    if (alreadyAdded) {
      selectedGroups = this.state.selectedGroups.filter(el => el !== selectedGroup.id);
    } else {
      selectedGroups = [...this.state.selectedGroups, selectedGroup.id];
    }

    this.setState({ selectedGroups });
    this.props.setFieldValue('groups', selectedGroups);
  };

  renderErrors() {
    const { error } = this.props;
    if (!error || !error.code) return null;
    return (
      <Alert type="danger">
        <FormattedMessage {...messages[`errorCode${error.code}`] || messages.errorCode500} />
      </Alert>
    );
  }

  renderAlerts() {
    const { status } = this.props;
    if (status !== EditionStatus.SAVED) return null;
    return (
      <Fragment>
        <Alert type="success" className="text-center">
          <FormattedMessage {...messages.saved}>{txt => <div>{txt}</div>}</FormattedMessage>
        </Alert>
        <FormattedMessage {...messages.login}>
          {txt => (
            <Button color="success" className="btn-block mt-2" onClick={this.redirectLogin}>
              {txt}
            </Button>
          )}
        </FormattedMessage>
      </Fragment>
    );
  }

  render() {
    const {
      handleSubmit,
      handleChange,
      handleBlur,
      values,
      isValid,
      schoolsSearch,
      cities,
      states,
      intl,
      selectCountries,
      langCountries,
    } = this.props;
    const { school, groups } = this.state;
    const errors = this.renderErrors();
    // const alerts = this.renderAlerts();

    let register = false;

    langCountries.forEach(el => {
      if (el.register === '1' && el.access === '2' && intl.locale === el.language) {
        register = true;
      }
    });

    return (
      <React.Fragment>
        <Loader show={this.state.isLoading} />
        <form className="col ml-auto mr-auto register-form" onSubmit={handleSubmit}>
          <LanguageSelect countries={langCountries} history={this.props.history} />
          <LoginSignTabs register={register} />
          <div className="form-group form-group-mob">
            <Input
              label={intl.formatMessage(messages.nameField)}
              autoComplete="firstname"
              name="firstname"
              valid={this.isValid('firstname')}
              invalidFeedback={this.getMessageError('firstname')}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="form-group form-group-mob">
            <Input
              label={intl.formatMessage(messages.surnameField)}
              autoComplete="lastname"
              name="lastname"
              valid={this.isValid('lastname')}
              invalidFeedback={this.getMessageError('lastname')}
              value={values.surname}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="form-group form-group-mob">
            <Input
              label={intl.formatMessage(messages.emailField)}
              autoComplete="email"
              name="email"
              valid={this.isValid('email')}
              invalidFeedback={this.getMessageError('email')}
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="form-group form-group-mob">
            <Input
              label={intl.formatMessage(messages.emailConfirmField)}
              autoComplete="email"
              name="emailConfirm"
              valid={this.isValid('emailConfirm')}
              invalidFeedback={this.getMessageError('emailConfirm')}
              value={values.emailConfirm}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="form-group form-group-mob input-password">
            <Input
              label={intl.formatMessage(messages.passwordField)}
              type="password"
              autoComplete="password"
              valid={this.isValid('password')}
              invalidFeedback={this.getMessageError('password')}
              name="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div class="card2 card-password">
            <FormattedMessage {...messages.passCriterio} />
            <ul>
              <li><FormattedMessage {...messages.caracterNumber} /></li>
              <li><FormattedMessage {...messages.letterUppercase} /></li>
              <li><FormattedMessage {...messages.letterLowercase} /></li>
              <li><FormattedMessage {...messages.number} /></li>
              <li><FormattedMessage {...messages.specialCaracter} /> {` ' ~ ! @ # $ % ^ & * () _ + - = {} | \\ : " ; ' < > ? , . / )`}</li>
            </ul>
          </div>
          <div className="form-group form-group-mob">
            <Input
              label={intl.formatMessage(messages.passwordConfirmField)}
              type="password"
              autoComplete="password"
              valid={this.isValid('passwordConfirm')}
              invalidFeedback={this.getMessageError('passwordConfirm')}
              name="passwordConfirm"
              value={values.passwordConfirm}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <h5 className="label">{intl.formatMessage(messages.profileField)}</h5>
          <div className="form-check form-check-inline">
            <Input
              className="form-check-input"
              type="radio"
              label={intl.formatMessage(messages.teacher)}
              name="idType"
              value={ID_USER_TYPE_TEACHER}
              onChange={handleChange}
              valid={this.isValid('idType')}
              invalidFeedback={this.getMessageError('ID_USER_TYPE_TEACHER')}
              style={{ width:"inherit" }}
            />
            {/* <label className="form-check-label" htmlFor="professor">
              {intl.formatMessage(messages.teacher)}
            </label> */}
          </div>
          <div className="form-check form-check-inline">
            <Input
              className="form-check-input"
              type="radio"
              label={intl.formatMessage(messages.manager)}
              name="idType"
              value={ID_USER_TYPE_COORDINATOR}
              onChange={handleChange}
              valid={this.isValid('idType')}
              invalidFeedback={this.getMessageError('ID_USER_TYPE_COORDINATOR')}
              style={{ width:"inherit" }}
            />
            {/* <label className="form-check-label" htmlFor="coordenador">
              {intl.formatMessage(messages.manager)}
            </label> */}
          </div>
          
          <h5 className="label">{intl.formatMessage(messages.teachingTypes)}</h5>
          <div className="form-check form-check-inline">
            <Input
              className="form-check-input"
              type="radio"
              label={intl.formatMessage(messages.public)}
              name="teachingType"
              value={ID_USER_TYPE_PUBLIC}
              onChange={handleChange}
              valid={this.isValid('teachingType')}
              invalidFeedback={this.getMessageError('ID_USER_TYPE_PUBLIC')}
              style={{ width:"inherit" }}
            />
            {/* <label className="form-check-label" htmlFor="Público">
              {intl.formatMessage(messages.public)}
            </label> */}
          </div>
          <div className="form-check form-check-inline">
            <Input
              className="form-check-input"
              type="radio"
              label={intl.formatMessage(messages.private)}
              name="teachingType"
              value={ID_USER_TYPE_PRIVATE}
              onChange={handleChange}
              valid={this.isValid('teachingType')}
              invalidFeedback={this.getMessageError('ID_USER_TYPE_PRIVATE')}
              style={{ width:"inherit" }}
            />
            {/* <label className="form-check-label" htmlFor="Privado">
              {intl.formatMessage(messages.private)}
            </label> */}
          </div>
          <Row>
            <Col>
              <FormattedMessage {...messages.country}>
                {txt => (
                  <FormattedMessage {...messages.selectCountryPlaceholder}>
                    {txtPlaceholder => (
                      <Select
                        label={txt}
                        name="idCountry"
                        valid={this.isValid('idCountry')}
                        invalidFeedback={this.getMessageError('idCountry')}
                        selectLabel={txtPlaceholder}
                        value={values.idCountry}
                        options={selectCountries}
                        onChange={this.handleSelectCountry}
                        disabled
                      />
                    )}
                  </FormattedMessage>
                )}
              </FormattedMessage>
            </Col>
            <Col>
              <FormattedMessage {...messages.state}>
                {txt => (
                  <FormattedMessage {...messages.selectStatePlaceholder}>
                    {txtPlaceholder => (
                      <Select
                        label={txt}
                        selectLabel={txtPlaceholder}
                        name="state"
                        disabled={!values.idCountry}
                        value={values.state}
                        valid={this.isValid('state')}
                        invalidFeedback={this.getMessageError('state')}
                        itemValue="state"
                        itemLabel="state"
                        options={states}
                        onChange={this.handleSelectState}
                      />
                    )}
                  </FormattedMessage>
                )}
              </FormattedMessage>
            </Col>
            <Col>
              <FormattedMessage {...messages.city}>
                {txt => (
                  <FormattedMessage {...messages.selectCityPlaceholder}>
                    {txtPlaceholder => (
                      <Select
                        label={txt}
                        selectLabel={txtPlaceholder}
                        itemValue="city"
                        valid={this.isValid('city')}
                        invalidFeedback={this.getMessageError('city')}
                        disabled={!values.state}
                        value={values.city}
                        itemLabel="city"
                        name="city"
                        options={cities}
                        onChange={this.handleSelectCity}
                      />
                    )}
                  </FormattedMessage>
                )}
              </FormattedMessage>
            </Col>
          </Row>
          {values.city && (
            <Row>
              <Col>
                <FormattedMessage {...messages.school}>
                  {txt => (
                    <FormattedMessage {...messages.searchSchoolPlaceholder}>
                      {txtPlaceholder => (
                        <FormattedMessage {...messages.noSchoolFounded}>
                          {txtNoSchoolFounded => (
                            <SchoolSelect
                              label={txt}
                              value={school}
                              valid={this.isValid('idSchool')}
                              invalidFeedback={this.getMessageError('idSchool')}
                              disabled={cities.length === 0}
                              placeholder={txtPlaceholder}
                              // eslint-disable-next-line react/jsx-no-bind
                              noOptionsMessage={() => txtNoSchoolFounded}
                              itemValue="id"
                              itemLabel="name"
                              options={schoolsSearch}
                              onChange={this.handleSelectSchool}
                            />
                          )}
                        </FormattedMessage>
                      )}
                    </FormattedMessage>
                  )}
                </FormattedMessage>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <div className="school-add-modal-form-details">
                {school && (
                  <div>
                    <strong>{school.name}</strong>
                    <br />
                    <span>{school.address}</span>
                    <br />
                    <span>{school.zipcode}</span>
                    <br />
                    <span>{school.city}</span> - <span>{school.state}</span>
                  </div>
                )}
                {!school && (
                  <FormattedMessage {...messages.noSchoolSelected}>
                    {txt => <span>{txt}</span>}
                  </FormattedMessage>
                )}
              </div>
            </Col>
          </Row>
          <div className="form-group form-group-mob">
            <input
              name="terms"
              id="terms"
              className={classNames('form-control switch', {
                'is-invalid': this.isValid('terms') === false,
                'is-valid': this.isValid('terms') === true,
              })}
              type="checkbox"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.terms}
            />
            <label
              className={classNames('form-control-label', {
                  'is-invalid': this.isValid('terms') === false,
                  'is-valid': this.isValid('terms') === true,
                })}
              htmlFor="terms"
            >
            <FormattedHTMLMessage {...messages.readTerms} />
            </label>
            {this.isValid('terms') === false && <div className="invalid-feedback">{this.getMessageError('terms')}</div>}
          </div>
          <div className="form-group form-group-mob">
            <input
              name="receiveCommercial"
              id="receiveCommercial"
              className={classNames('form-control switch')}
              type="checkbox"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.receiveCommercial}
            />
            <label
              className={classNames('form-control-label')}
              htmlFor="receiveCommercial"
            >
            <FormattedHTMLMessage {...messages.receiveCommercial} />
            </label>
          </div>
          
          <Button
            color="primary"
            large
            className="btn-block"
            type="submit"
            value={intl.formatMessage(messages.submit)}
            disabled={!isValid}
          >
            {intl.formatMessage(messages.submit)}
          </Button>
          {errors}
          {/* {alerts} */}
        </form>
      </React.Fragment>
    );
  }
}


RegisterForm.propTypes = {
  intl: intlShape.isRequired,
};

export default compose(
  enhanceForm,
  injectIntl,
  withRouter,
)(RegisterForm);
