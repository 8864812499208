const CalendarEventType = {
  ASSESSMENT: {
    id: 'ASSESSMENT',
    statusIds: [5, 6, 7],
    titleMessageKey: 'eventTypeAssessment',
    icon: 'icon ti-write',
    className: 'card-prova',
  },
  EVENT: {
    id: 'EVENT',
    statusIds: [4],
    titleMessageKey: 'eventTypeEvent',
    icon: 'icon pe-7s-pin',
    className: 'card-evento',
  },
  SUPER_CLASS: {
    id: 'SUPER_CLASS',
    statusIds: [8, 9, 10],
    titleMessageKey: 'eventTypeSuperClass',
    icon: 'icon pe-7s-rocket',
    className: 'card-super-aula',
  },
  CLASS: {
    id: 'CLASS',
    statusIds: [1, 2, 3],
    icon: 'icon pe-7s-clock',
    className: 'card-super-aula',
  },
};

export default CalendarEventType;

export function getEventType(statusId) {
  return Object.keys(CalendarEventType)
    .map(k => CalendarEventType[k])
    .find(eventType => eventType.statusIds.includes(parseInt(statusId, 0)));
}
