import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { injectIntl } from 'react-intl';
import moment from 'moment';

import first from 'lodash/first';
import last from 'lodash/last';

import { Modal, Loader } from 'sm-ui';

import { getEventType } from './calendarEventType';

import './CalendarClassDetailsModal.scss';

import messages from './calendarMessages';

function ValueCell({ type, id, value, onClickFile, fileName }) {
  switch (type) {
    case 'file':
      return (
        <button onClick={onClickFile({ type, id, fileName })} className="class-details-file">
          {fileName}
        </button>
      );
    default:
      return (
        <span>
          <div dangerouslySetInnerHTML={{ __html: value }} />
        </span>
      );
  }
}

class CalendarClassDetailsModal extends React.PureComponent {
  componentDidMount() {
    this.props.handleGetPlansScheduledValues();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.idScheduledLesson !== this.props.idScheduledLesson) {
      this.props.handleGetPlansScheduledValues();
    }

    if (prevProps.show !== this.props.show) {
      this.props.handleGetPlansScheduledValues();
    }
  }

  render() {
    const { id, appointment, scheduledLesson, classDetails, ...props } = this.props;
    let { details } = this.props;

    let title = appointment ? <div dangerouslySetInnerHTML={{ __html: appointment.title }} /> : '';
    let subtitle = appointment ? (
      <div dangerouslySetInnerHTML={{ __html: appointment.catalogingPath }} />
    ) : (
      ''
    );
    let icon = 'pe-7s-clock';
    let superclasse = false;

    if (details && (details.status === '8' || details.status === '9' || details.status === '10')) {
      title = <div dangerouslySetInnerHTML={{ __html: appointment.eventName }} />;
      subtitle = <div dangerouslySetInnerHTML={{ __html: appointment.courseName }} />;
      icon = 'pe-7s-rocket';
      superclasse = true;
      details = false;
    }

    const buttons = [
      {
        label: props.intl.formatMessage(messages.reassign),
        onClick: () => props.onRequestReassign(appointment),
        className: 'd-sm-none',
      },
      {
        label: props.intl.formatMessage(messages.status),
        onClick: () => props.onRequestStatus(appointment),
        className: 'd-sm-none',
      },
    ];

    const eventType = getEventType(appointment.status);
    if (eventType && eventType.id !== 'CLASS') {
      buttons.push({
        label: props.intl.formatMessage(messages.remove),
        onClick: () => props.onRequestRemove(appointment),
        className: 'd-sm-none',
      });
    }

    if (superclasse)
      buttons.push([
        {
          id: 1,
          label: props.intl.formatMessage(messages.classDetailModalSuperclassClose),
          onClick: props.onClose,
          className: 'd-none d-sm-flex',
        },
      ]);

    return (
      <Modal
        id={id}
        title={title}
        subtitle={subtitle}
        icon={icon}
        large={!superclasse}
        buttons={buttons}
        {...props}
      >
        <div className="calendar-details-body">
          <div className="calendar-table-wrapper" style={{ textAlign: 'left' }}>
            {!details || (superclasse && <Loader show simple />)}
            {!superclasse &&
              details &&
              details.map(val => (
                <div className="metadataRow">
                  <div
                    className="metadataLabel"
                    dangerouslySetInnerHTML={{ __html: val.metadataLabel }}
                  />
                  <div className="metadataContent">
                    <ValueCell {...props} {...val} />
                  </div>
                </div>
              ))}
            {superclasse && (
              <div>
                <div className="row">
                  <div className="col-12 superclassLabel">
                    {props.intl.formatMessage(messages.classDetailModalSuperclassDate)}
                  </div>
                  <div className="col-12 superclassContent">
                    {classDetails && moment(classDetails.date).format('L')}
                  </div>
                  <div className="col-12 superclassLabel">
                    {props.intl.formatMessage(messages.classDetailModalSuperclassDuration)}
                  </div>
                  <div className="col-12 superclassContent">
                    {classDetails && (
                      <span>
                        {`${classDetails.classes} `}
                        {String(classDetails.classes) === '1'
                          ? props.intl.formatMessage(messages.classDetailModalSuperclassClass)
                          : props.intl.formatMessage(messages.classDetailModalSuperclassClasses)}
                      </span>
                    )}
                  </div>
                  {classDetails && (
                    <React.Fragment>
                      <div className="col-12 superclassLabel">
                        {props.intl.formatMessage(messages.classDetailModalSuperclassActivities)}
                      </div>
                      <div className="col-12 superclassContent">
                        {classDetails.filePath ? (
                          <a
                            href="javascript:void(0)"
                            // href={scheduledLesson.filePath}
                            onClick={() => {
                              const { filePath } = classDetails;
                              const filePatchFirstParse = first(filePath.split('&'));
                              const filePatchLastParse = last(filePath.split('&'));
                              const id = last(filePatchFirstParse.split('='));
                              const type = last(filePatchLastParse.split('='));
                              this.props.handleViewFileSuperlessons({ id, type });
                            }}
                          >
                            {props.intl.formatMessage(messages.classDetailModalSuperclassDownload)}
                          </a>
                        ) : (
                          <div>
                            {props.intl.formatMessage(
                              messages.classDetailModalSuperclassNotFounded,
                            )}
                          </div>
                        )}
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ schedule }) => ({ classDetails: schedule.classDetails });

export default compose(
  injectIntl,
  connect(mapStateToProps),
)(CalendarClassDetailsModal);
