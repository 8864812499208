import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import messages from '../calendar/calendarMessages';

class ClassManagementHoursTab extends Component {
  static getDerivedStateFromProps(props, state) {
    const { plannerDefaultClasses } = props;
    if (plannerDefaultClasses !== state.prevDefaultMaxVisibleHours) {
      return {
        prevDefaultMaxVisibleHours: plannerDefaultClasses,
        maxVisibleHours: plannerDefaultClasses,
      };
    }
  }

  state = {
    maxVisibleHours: this.props.plannerDefaultClasses,
  };

  componentDidMount() {
    this.props.activeHours.map(el => {
      if (el > this.props.plannerDefaultClasses) {
        this.setState({ maxVisibleHours: el });
      }
    });
  }

  showMoreHours = () => {
    const { limitHours } = this.props;
    this.setState(({ maxVisibleHours }) => ({
      maxVisibleHours: maxVisibleHours < limitHours ? maxVisibleHours + 1 : maxVisibleHours,
    }));
  };

  handleToggleHour = hour => () => {
    const { activeHours, weekDay } = this.props;
    const included = activeHours.includes(hour);
    if (included) {
      if (this.props.onRemoveHour) {
        this.props.onRemoveHour(hour, weekDay);
        if (activeHours.length === 1) this.props.handleSelectCourse(false);
      }
    } else if (this.props.onAddHour) {
      this.props.handleSelectCourse(true);
      this.props.onAddHour(hour, weekDay);
    }
  };

  render() {
    const { maxVisibleHours } = this.state;
    const { limitHours, activeHours, hours } = this.props;

    return (
      <div className="tab">
        <div>
          {hours.slice(0, maxVisibleHours).map(hour => (
            <button
              key={hour}
              id={`hour${hour}`}
              onClick={this.handleToggleHour(hour)}
              className={classNames('buttonHour', { active: activeHours.includes(hour) })}
            >
              <FormattedMessage {...messages.hourLabel}>
                {txt => (
                  <span>
                    {txt}
                    {hour}
                  </span>
                )}
              </FormattedMessage>
            </button>
          ))}
          {maxVisibleHours < limitHours && (
            <button id="showMore" className="buttonShowMore" onClick={this.showMoreHours}>
              +
            </button>
          )}
        </div>
      </div>
    );
  }
}

ClassManagementHoursTab.propTypes = {
  weekDay: PropTypes.number.isRequired,
  defaultMaxVisibleHours: PropTypes.number,
  plannerDefaultClasses: PropTypes.number,
  limitHours: PropTypes.number,
  activeHours: PropTypes.arrayOf(PropTypes.number),
  hours: PropTypes.arrayOf(PropTypes.number),
};

ClassManagementHoursTab.defaultProps = {
  defaultMaxVisibleHours: 6,
  plannerDefaultClasses: 6,
  limitHours: 10,
  activeHours: [],
  hours: Array(10)
    .fill()
    .map((_, index) => index + 1),
};

export default ClassManagementHoursTab;
