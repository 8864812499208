import React from 'react';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ script: 'sub' }, { script: 'super' }],
    ['clean'],
  ],
};

const TextEditor = ({ name, value, onChange }) => {
  return (
    <React.Fragment>
      <ReactQuill
        className="form-control-rich"
        theme="snow"
        name={name}
        cols="30"
        rows="5"
        modules={modules}
        onChange={onChange}
        value={value}
      />
    </React.Fragment>
  );
};

export default TextEditor;
