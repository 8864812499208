import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'calendarEventRemoveModal.title',
    defaultMessage: 'Remove',
  },
  close: {
    id: 'calendarEventRemoveModal.close',
    defaultMessage: 'Close',
  },
  confirm: {
    id: 'calendarEventRemoveModal.confirm',
    defaultMessage: 'Confirm',
  },
  confirmRemove: {
    id: 'calendarEventRemoveModal.confirmRemove',
    defaultMessage: 'Are you sure you want to remove? This operation is irreversible.',
  },
});
