/* eslint-disable */
import React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from '../plannerMessages';

import './MenuMetadata.scss';

const MenuMetadata = ({
  onMouseLeave,
  handleShowEditModal,
  handleShowAddColModal,
  handleShowMoveColumnModal,
  handleShowRemoveModal,
}) => (
  <React.Fragment>
    <div className="action-modal" onMouseLeave={onMouseLeave}>
      <div>
        <div className="action-modal-btn" onClick={handleShowEditModal} style={{ padding: '2px' }}>
          <FormattedMessage {...messages.edit} />
        </div>
        <div
          className="action-modal-btn"
          onClick={() =>
            handleShowMoveColumnModal({
              direction: 'l',
              show: true,
            })
          }
          style={{ padding: '2px' }}
        >
          <FormattedMessage {...messages.metadataMoveToLeft} />
        </div>
        <div
          className="action-modal-btn"
          onClick={() =>
            handleShowMoveColumnModal({
              direction: 'r',
              show: true,
            })
          }
          style={{ padding: '2px' }}
        >
          <FormattedMessage {...messages.metadataMoveToRight} />
        </div>
        <div
          className="action-modal-btn"
          onClick={() =>
            handleShowAddColModal({
              direction: 'l',
              show: true,
            })
          }
          style={{ padding: '2px' }}
        >
          <FormattedMessage {...messages.metadataAddLeftColumn} />
        </div>
        <div
          className="action-modal-btn"
          onClick={() =>
            handleShowAddColModal({
              direction: 'r',
              show: true,
            })
          }
          style={{ padding: '2px' }}
        >
          <FormattedMessage {...messages.metadataAddRightColumn} />
        </div>
        <div
          className="action-modal-btn"
          onClick={handleShowRemoveModal}
          style={{ padding: '2px' }}
        >
          <FormattedMessage {...messages.remove} />
        </div>
      </div>
    </div>
  </React.Fragment>
);

export default MenuMetadata;
