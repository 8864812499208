import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Loader.scss';

const Loader = ({ show, simple, ...props }) => (
  <div {...props} className={classNames('loading', { show, simple })} />
);

Loader.propTypes = {
  show: PropTypes.bool,
  simple: PropTypes.bool,
};

Loader.defaultProps = {
  show: false,
  simple: false,
};

export default Loader;
