import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames'

const Row = ({ className, ...props }) => <div className={classNames('row', className)} {...props}/>

Row.propTypes = {
  className: PropTypes.string
};

Row.defaultProps = {
  className: undefined
};

export default Row;
