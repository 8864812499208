import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './Input.scss';

const Wrapper = ({ type, children }) => {
  if (type === 'password') {
    return <div className="toggle-password-wrapper">{children}</div>;
  }
  return children;
};

class Input extends React.Component {
  // support controlled input
  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.prevValue) {
      return {
        value: props.value,
        prevValue: props.value,
      };
    }
    return null;
  }
  state = {};

  handleChange = e => {
    const { readOnly } = this.props;
    if (readOnly) return;
    const { value } = e.target;
    this.setState({ value });
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };

  toggleShowPassword = () => this.setState(({ showPassword }) => ({ showPassword: !showPassword }));

  render() {
    const {
      id,
      label,
      className,
      containerClassName,
      type,
      validFeedback,
      invalidFeedback,
      valid,
      ...props
    } = this.props;
    const { value, showPassword } = this.state;
    return (
      <div className={classNames('form-group form-group-mob', containerClassName)}>
        {label && (
        <label
          className={classNames('form-control-label', {
              'is-invalid': valid === false,
              'is-valid': valid === true,
            })}
          htmlFor={id}
        >
          {label}
        </label>
        )}
        <Wrapper type={type}>
          <input
            {...props}
            id={id}
            className={classNames('form-control', {
              'is-invalid': valid === false,
              'is-valid': valid === true,
            })}
            /* eslint-disable */
            type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
            /* eslint-enable */
            onChange={this.handleChange}
            value={value}
          />
          {type === 'password' && (
            <button className="toggle-password" type="button" onClick={this.toggleShowPassword}>
              <i className="fas fa-eye" />
            </button>
          )}
          {valid === false && <div className="invalid-feedback">{invalidFeedback}</div>}
          {valid === true && <div className="valid-feedback">{validFeedback}</div>}
        </Wrapper>
      </div>
    );
  }
}

Input.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  valid: PropTypes.oneOf([null, undefined, false, true]),
  containerClassName: PropTypes.string,
  validFeedback: PropTypes.string,
  invalidFeedback: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool.isRequired,
};

Input.defaultProps = {
  id: undefined,
  name: undefined,
  label: '',
  type: 'text',
  valid: undefined,
  className: '',
  containerClassName: undefined,
  validFeedback: undefined,
  invalidFeedback: undefined,
  onChange: undefined,
  readOnly: false,
};

export default Input;
