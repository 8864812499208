import { action } from 'app/actions';

export const GET_PLANS_SCHEDULED = 'schedule/GET_PLANS_SCHEDULED';
export const GET_SCHEDULE_APPOINTMENTS = 'schedule/GET_SCHEDULE_APPOINTMENTS';
export const GET_ALL_SCHEDULE_APPOINTMENTS = 'schedule/GET_ALL_SCHEDULE_APPOINTMENTS';
export const GET_PLANNER_DETAIL = 'schedule/GET_PLANNER_DETAIL';
export const GET_PLANS_SCHEDULED_VALUES = 'schedule/GET_PLANS_SCHEDULED_VALUES';
export const ADD_PLANS_SCHEDULED_LESSON_LOG = 'schedule/ADD_PLANS_SCHEDULED_LESSON_LOG';
export const FILTER_SCHEDULE_EVENTS = 'schedule/FILTER_SCHEDULE_EVENTS';
export const GET_STATUS_PLAN_SCHEDULED = 'schedule/GET_STATUS_PLAN_SCHEDULED';
export const REASSIGN_APPOINTMENT = 'schedule/REASSIGN_APPOINTMENT';
export const ADD_PLAN_SCHEDULED_EVENT = 'schedule/ADD_PLAN_SCHEDULED_EVENT';
export const GET_CLASS_APPOINTMENT_DETAILS = 'schedule/GET_CLASS_APPOINTMENT_DETAILS';
export const GET_EVENT_APPOINTMENT_DETAILS = 'schedule/GET_EVENT_APPOINTMENT_DETAILS';
export const GET_FILTERS_COURSES_PLAN = 'schedule/GET_FILTERS_COURSES_PLAN';
export const GET_STATUS_SCHEDULED = 'schedule/GET_STATUS_SCHEDULED';
export const DOWNLOAD_FILE = 'schedule/DOWNLOAD_FILE';
export const REMOVE_EVENT = 'schedule/REMOVE_EVENT';
export const GET_SUPER_LESSONS = 'schedule/GET_SUPER_LESSONS';
export const VIEW_FILE_SUPERLESSONS = 'schedule/VIEW_FILE_SUPERLESSONS';
export const RESET_REASSIGN_FORM = 'schedule/RESET_REASSIGN_FORM';

export const getPlannerDetail = payload => action(GET_PLANNER_DETAIL, { payload });
export const getPlansScheduled = payload => action(GET_PLANS_SCHEDULED, { payload });
export const getPlansScheduledValues = payload => action(GET_PLANS_SCHEDULED_VALUES, { payload });
export const addPlanScheduledLessonLog = payload =>
  action(ADD_PLANS_SCHEDULED_LESSON_LOG, { payload });
export const getScheduleAppointments = payload => action(GET_SCHEDULE_APPOINTMENTS, { payload });
export const getAllScheduleAppointments = payload =>
  action(GET_ALL_SCHEDULE_APPOINTMENTS, { payload });
export const filterScheduleEvents = payload => action(FILTER_SCHEDULE_EVENTS, { payload });
export const getStatusPlanScheduled = payload => action(GET_STATUS_PLAN_SCHEDULED, { payload });
export const reassignAppointment = payload => action(REASSIGN_APPOINTMENT, { payload });
export const addPlanScheduledEvent = payload => action(ADD_PLAN_SCHEDULED_EVENT, { payload });
export const getClassAppointmentDetails = payload =>
  action(GET_CLASS_APPOINTMENT_DETAILS, { payload });
export const getEventAppointmentDetails = payload =>
  action(GET_EVENT_APPOINTMENT_DETAILS, { payload });
export const getFiltersCoursesPlans = payload => action(GET_FILTERS_COURSES_PLAN, { payload });
export const getStatusScheduled = payload => action(GET_STATUS_SCHEDULED, { payload });
export const downloadFile = payload => action(DOWNLOAD_FILE, { payload });
export const removePlanScheduledEvent = payload => action(REMOVE_EVENT, { payload });
export const getSuperlessons = payload => action(GET_SUPER_LESSONS, { payload });
export const viewFileSuperlessons = payload => action(VIEW_FILE_SUPERLESSONS, { payload });
export const resetReassignForm = () => action(RESET_REASSIGN_FORM);
