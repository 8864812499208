/* eslint-disable */
import React from 'react';
import { IconButton } from 'sm-ui';

import './HeaderIcons.scss';

const HeaderIcons = ({ data }) => (
  <React.Fragment>
    <div className="headerIcon___wrapper">
      {data.map(el => (
        <div onClick={el.onClick}>
          <IconButton
            key={el.id}
            label={el.label}
            className="headerIcon___icon-button"
            style={{ cursor: 'pointer' }}
          >
            <i className={el.icon} />
          </IconButton>
        </div>
      ))}
    </div>
  </React.Fragment>
);

export default HeaderIcons;
