import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ModalButton from './ModalButton';

import './Modal.scss';

class Modal extends Component {
  componentDidUpdate(prevProps) {
    if (!prevProps.show && this.props.show) {
      this.onShow();
    } else if (prevProps.show && !this.props.show) {
      this.onHide();
    }
  }

  onShow = () => {
    const { fullscreen } = this.props;
    if (fullscreen) document.body.style.overflow = 'hidden';
  };

  onHide = () => {
    const { fullscreen } = this.props;
    if (fullscreen) document.body.style.overflow = 'initial';
  };

  render() {
    const {
      id,
      icon,
      title,
      subtitle,
      className,
      modalClassName,
      message,
      messageClass,
      buttons,
      large,
      children,
      onClose,
      fullscreen,
      show,
      modalBodyStyle,
    } = this.props;
    return (
      <div
        className={classNames('modal fade', { show, fullscreen }, className)}
        tabIndex="-1"
        role="dialog"
        id={id}
      >
        <div
          className={classNames(modalClassName, 'modal-dialog modal-dialog-centered', {
            'modal-large': large,
          })}
          role="document"
          style={modalBodyStyle}
        >
          {show && (
            <div className="modal-content">
              <div className="modal-header">
                <button
                  className="close"
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={onClose}
                >
                  <i className="icon pe-7s-close-circle" />
                </button>
              </div>
              <div className={classNames('modal-body', { fullscreen })}>
                {icon && <i className={`icon ${icon}`} />}
                {title && <h5 className="modal-title">{title}</h5>}
                {subtitle && <p className="modal-subtitle">{subtitle}</p>}
                {message && <p className={classNames('modal-message', messageClass)}>{message}</p>}
                {children}
              </div>
              <div className="modal-footer">
                {buttons &&
                  buttons.map((button, index) => (
                    <ModalButton {...button} key={button.id || index} />
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  id: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.any,
  message: PropTypes.any,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.any,
      onClick: PropTypes.func,
    }),
  ),
  children: PropTypes.node,
  className: PropTypes.string,
  modalClassName: PropTypes.string,
  large: PropTypes.bool,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  modalBodyStyle: PropTypes.any,
  fullscreen: PropTypes.bool,
};

Modal.defaultProps = {
  id: undefined,
  icon: null,
  title: null,
  message: null,
  buttons: null,
  children: null,
  large: false,
  show: false,
  onClose: undefined,
  modalBodyStyle: undefined,
  className: undefined,
  modalClassName: undefined,
  fullscreen: false,
};

export default Modal;
