import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { promisify } from 'app/actions';
import { withRouter, Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Navtabs, Select } from 'sm-ui';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';

import messages from './reportsMessages';
import * as actions from './reportsActions';
import ReportCard from './ReportCard';

const CURRENT_YEAR = moment(Date.now()).format('YYYY');

const initialState = {
  activeTab: CURRENT_YEAR,
  filter: {},
  position: null,
};

class ScheduleContainer extends PureComponent {
  static getDerivedStateFromProps(props, state) {
    if (props.years !== state.prevYears) {
      return {
        prevYears: props.years,
        activeTab: props.years[0] || new Date().getFullYear(),
      };
    }
    return null;
  }

  state = initialState;

  componentDidMount() {
    this.props.getPlansScheduled(this.state.filter);
  }

  handleFilterChange = event => {
    const { name } = event.target;
    const { value } = event.target;
    this.setState(({ filter }) => ({ filter: { ...filter, [name]: value } }));
  };

  handleRequestInfo = plan => () => {
    this.props.getStatusPlanScheduled({ idsCourses: [plan.idCourse], idPlan: plan.idPlan }, plan);
  };

  handleTabClick = ({ value }) => this.setState({ activeTab: value });

  render() {
    const plansScheduledPaginated = this.props.plansScheduled.filter(
      p => new Date(p.scheduled).getFullYear() === this.state.activeTab,
    );
    return (
      <div>
        <Row className="align-items-center margin-bottom">
          <Col md="auto">
            <FormattedMessage {...messages.title}>
              {txt => <h1 className="page-title">{txt}</h1>}
            </FormattedMessage>
          </Col>
          <Col lg={8}>
            <Row>
              <Col md={7} xs={12} className="mt-3 mt-sm-3 mt-md-3 mt-lg-0">
                <FormattedMessage {...messages.filterByDiscipline}>
                  {txt => (
                    <Select
                      options={this.props.disciplinesFilter}
                      itemValue="id"
                      containerClassName="mt-0"
                      itemLabel="name"
                      name="idDisciplinesFilter"
                      selectLabel={txt}
                      onChange={this.handleFilterChange}
                    />
                  )}
                </FormattedMessage>
              </Col>
              <Col md={5} xs={12} className="mt-3 mt-sm-3 mt-md-3 mt-lg-0">
                <FormattedMessage {...messages.filterByLevel}>
                  {txt => (
                    <Select
                      options={this.props.levelsFilter}
                      itemValue="id"
                      containerClassName="mt-0 mt-xs-3"
                      itemLabel="name"
                      name="idLevelsFilter"
                      selectLabel={txt}
                      onChange={this.handleFilterChange}
                    />
                  )}
                </FormattedMessage>
              </Col>
            </Row>
          </Col>
        </Row>
        <Navtabs
          onClick={this.handleTabClick}
          tabs={this.props.years.map((year, index) => ({
            key: index,
            label: year,
            value: year,
            active: this.state.activeTab === year,
          }))}
        />
        {plansScheduledPaginated.map(plan => (
          <Row className="mt-2">
            <ReportCard
              {...plan}
              key={`${plan.idPlan}/${plan.idCourse}`}
              onLoad={this.handleRequestInfo(plan)}
            />
          </Row>
        ))}
        <Row className="mt-4">
          <Col md={7} xs={12} className="mt-3 mt-sm-3 mt-md-3 mt-lg-0">
            <FormattedMessage {...messages.filterByDiscipline}>
              {txt => (
                <Select
                  options={this.props.disciplinesFilter}
                  itemValue="id"
                  containerClassName="mt-0"
                  itemLabel="name"
                  name="idDisciplinesFilter"
                  selectLabel={txt}
                  onChange={this.handleFilterChange}
                />
              )}
            </FormattedMessage>
          </Col>
          <Col md={5} xs={12} className="mt-3 mt-sm-3 mt-md-3 mt-lg-0">
            <FormattedMessage {...messages.filterByLevel}>
              {txt => (
                <Select
                  options={this.props.levelsFilter}
                  itemValue="id"
                  containerClassName="mt-0 mt-xs-3"
                  itemLabel="name"
                  name="idLevelsFilter"
                  selectLabel={txt}
                  onChange={this.handleFilterChange}
                />
              )}
            </FormattedMessage>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ reports, app }) => ({ ...reports, role: app.role });

export default compose(
  connect(
    mapStateToProps,
    actions,
  ),
  withRouter,
  injectIntl,
)(ScheduleContainer);
