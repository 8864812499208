import uniq from 'lodash/uniq';
import get from 'lodash/get';

export function getDetailsFromPlans(plans) {
  if (plans.length === 0) return {};
  return {
    discipline: plans[0].discipline.name,
    abbreviation: plans[0].discipline.abbreviation,
    icon: plans[0].discipline.icon,
    level: plans[0].catalogingPath,
  };
}

export const getYears = plans => {
  const allYears = plans.map(plan => new Date(plan.added).getFullYear());
  return uniq(allYears).sort();
};

export const enhancePlans = (plans = []) =>
  plans.map(plan => ({
    ...plan,
    title: get(plan, 'collection.name') || get(plan, 'discipline.name'),
    abbreviation: get(plan, 'collection.abbreviation') || get(plan, 'discipline.abbreviation'),
    icon: get(plan, 'discipline.icon'),
    description: plan.catalogingPath,
    actionLabel: '',
    actionClass: 'success',
    year: new Date(plan.added).getFullYear(),
  }));
