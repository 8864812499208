// @styleguide

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import PropTypes from 'prop-types';
import WeekCalendarRow from './WeekCalendarRow';
import WeekCalendarHeader from './WeekCalendarHeader';
import WeekCalendarRowMobile from './WeekCalendarRowMobile';
import { withDragDropContext } from '../CalendarContext';
import CalendarNav from '../CalendarNav';
import * as helpers from '../calendarHelpers';
import messages from './weekCalendarMessages';

import './WeekCalendar.scss';

function endOfWeek(date = new Date()) {
  // console.log('date', date, date.getDay());
  if (date.getDay() === 0) return date;
  return moment(date)
    .endOf('week')
    .toDate();
}

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
class WeekCalendar extends Component {
  static getDerivedStateFromProps(props, state) {
    const { currentDate } = props;
    if (currentDate !== state.prevCurrentDate) {
      return {
        prevCurrentDate: currentDate,
        currentDate,
        selectedDate: currentDate,
        dates: helpers.createWeekDatesFromEndDate(endOfWeek(currentDate, 6)),
      };
    }
    return null;
  }

  state = {
    currentDate: new Date(),
    selectedDate: new Date(),
    dates: helpers.createWeekDatesFromEndDate(endOfWeek(), 6),
  };

  nextWeek = () => {
    const prevCurrentDate = this.state.currentDate;
    const nextCurrentDate = moment(prevCurrentDate)
      .add('7', 'days')
      .toDate();
    this.selectDate(nextCurrentDate);
  };

  previousWeek = () => {
    const prevCurrentDate = this.state.currentDate;
    const nextCurrentDate = moment(prevCurrentDate)
      .subtract('7', 'days')
      .toDate();
    this.selectDate(nextCurrentDate);
  };

  selectDate = date => {
    this.setState({
      currentDate: date,
      dates: helpers.createWeekDatesFromEndDate(date, 6),
    });
    if (this.props.onChangeDate) {
      this.props.onChangeDate(date);
    }
  };

  // do not trigger movement between weeks
  handleChangeDate = date => {
    this.setState({ selectedDate: date });
  };

  render() {
    const { dates, currentDate } = this.state;
    const { hours } = this.props;

    return (
      <div id="wcc" className="week-calendar-container">
        <CalendarNav
          label={
            <FormattedMessage
              {...messages.title}
              values={{
                currentWeek: helpers.weekOfMonth(currentDate),
                weeks: helpers.weeksInMonth(currentDate),
                month: capitalize(moment(currentDate).format('MMMM')),
                year: currentDate.getFullYear(),
              }}
            />
          }
          onPrevious={this.previousWeek}
          onNext={this.nextWeek}
        />
        <div className="calendar-wrapper">
          <div className="calendar week-calendar-wrapper">
            <WeekCalendarHeader
              dates={dates}
              currentDate={this.state.selectedDate}
              onChangeDate={this.handleChangeDate}
            />
            {hours.map(hour => (
              <WeekCalendarRow hour={hour} key={hour} dates={dates} />
            ))}
          </div>
        </div>
        {hours.map(hour => (
          <WeekCalendarRowMobile date={this.state.selectedDate} hour={hour} key={hour} />
        ))}
      </div>
    );
  }
}

WeekCalendar.propTypes = {
  hours: PropTypes.arrayOf(PropTypes.string),
};

WeekCalendar.defaultProps = {
  hours: [],
};

export default withDragDropContext(WeekCalendar);
