import React from 'react';
import PropTypes from 'prop-types';
import Transition from 'react-transition-group/Transition';

// ease-in-out
const defaultStyle = duration => ({
  transition: `opacity ${duration}ms ${duration}ms`,
  opacity: 0,
  maxHeight: 0,
});

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
};

const FadeIn = ({ in: inProp, children, duration }) => (
  <Transition in={inProp} timeout={duration}>
    {state => (
      <div
        style={{
          ...defaultStyle(duration),
          ...transitionStyles[state],
        }}
      >
        {children}
      </div>
    )}
  </Transition>
);

FadeIn.propTypes = {
  duration: PropTypes.number,
  in: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

FadeIn.defaultProps = {
  duration: 500,
};

export default FadeIn;
