import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './CardIcon.scss';

const CardIcon = ({ icon, ...props }) => (
  <i className={classNames(icon, 'card-icon-sm')} {...props} />
);

CardIcon.propTypes = {
  icon: PropTypes.string.isRequired,
};

export default CardIcon;
