import React, { Component } from 'react';
import classNames from 'classnames';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {
  withCalendar,
  withAppointmentDraggableTarget,
  getCellAppointments,
} from '../CalendarContext';
import DayAppointment from './DayAppointment';
import messages from '../calendarMessages';
import './DayCalendarRow.scss';

class DayCalendarRow extends Component {
  renderCell() {
    const { isOver, canDrop, date, hour, appointments } = this.props;
    return (
      <div
        className={classNames('item day-calendar-row', {
          over: isOver && canDrop,
          'no-drop': !canDrop && isOver,
        })}
      >
        <FormattedMessage {...messages.hourLabel}>
          {txt => (
            <div className="day-name">
              {txt}
              {hour}
            </div>
          )}
        </FormattedMessage>
        {appointments &&
          getCellAppointments(date, appointments, hour).map(appointment => (
            <DayAppointment {...appointment} key={appointment.id} />
          ))}
      </div>
    );
  }
  render() {
    const { connectDragTarget } = this.props;
    return connectDragTarget && connectDragTarget(this.renderCell());
  }
}

DayCalendarRow.propTypes = {
  isOver: PropTypes.bool.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  canDrop: PropTypes.bool.isRequired,
  connectDragTarget: PropTypes.func.isRequired,
  moment: PropTypes.func.isRequired,
};

export default compose(
  withCalendar,
  withAppointmentDraggableTarget,
)(DayCalendarRow);
