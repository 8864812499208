import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Sidebar } from 'sm-ui';
import { getSidebarRoutes } from './appHelpers';
import NavLink from './NavLink';

import './AppSidebar.scss';

const AppSidebar = ({ routes, collapsed }) => (
  <Sidebar collapsed={collapsed}>
    {getSidebarRoutes(routes).map(({ sidebar, path, active, className }) => (
      <NavLink to={path} key={path} active={active} className={className}>
        {sidebar.icon}
        <div className={classNames('sidebar-label', { collapsed })}>{sidebar.label}</div>
      </NavLink>
    ))}
  </Sidebar>
);

AppSidebar.propTypes = {
  collapsed: PropTypes.bool,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      sidebar: PropTypes.shape({ icon: PropTypes.node, label: PropTypes.node }),
    }),
  ),
};

AppSidebar.defaultProps = {
  collapsed: false,
  routes: [],
};

export default AppSidebar;
