/* eslint-disable */
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Table from './Table';
import { Modal } from 'sm-ui';

import helpers from '../helpers';
import messages from '../plannerMessages';
import './Table.scss';
import './TablePreview.scss';

const TablePreview = ({ onClose, handleLoading, isOpen, data, columns, ...props }) => (
  <Modal
    show={isOpen}
    onClose={onClose}
    fullscreen
    className="planner-container-table-preview"
    buttons={[
      {
        id: 1,
        label: <FormattedMessage {...messages.cancel} />,
        onClick: () => onClose(false),
      },
      {
        id: 2,
        label: <FormattedMessage {...messages.print} />,
        primary: true,
        onClick: () => {
          helpers.printElem();
          setTimeout(() => {
            onClose(false);
          }, 1000);
        },
      },
    ]}
    modalBodyStyle={{
      minWidth: '90%',
    }}
    large
    {...props}
  >
    <Table preview original={true} />
  </Modal>
);

export default TablePreview;
