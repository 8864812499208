// @flow
import React, { Component } from 'react';
import moment from 'moment';
import { DragSource, DropTarget, DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import ItemTypes from './calendarItemTypes';

const CalendarContext = React.createContext();
export const { Consumer, Provider } = CalendarContext;

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export function withCalendar(WrappedComponent) {
  const WithCalendar = props => (
    <Consumer>{contextProps => <WrappedComponent {...contextProps} {...props} />}</Consumer>
  );
  WithCalendar.displayName = `WithCalendar(${getDisplayName(WrappedComponent)})`;
  return WithCalendar;
}

const appointmentSource = {
  beginDrag(props) {
    // console.log('begin drag', props);
    return props;
  },
};

function collectSource(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

const appointmentTarget = {
  drop(props, monitor) {
    // console.log('drop', monitor.getItem(), props);
    props.onDropAppointment(monitor.getItem(), props);
  },
  canDrop(props) {
    return true;
  },
};

function collectTarget(connect, monitor) {
  return {
    connectDragTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  };
}

export const withAppointmentDraggableSource = DragSource(
  ItemTypes.APPOINTMENT,
  appointmentSource,
  collectSource,
);
export const withAppointmentDraggableTarget = DropTarget(
  ItemTypes.APPOINTMENT,
  appointmentTarget,
  collectTarget,
);

export const withDragDropContext = DragDropContext(HTML5Backend);

export const withAppointmentDraggable = WrappedComponent => {
  class AppointmentDraggable extends Component {
    renderAppointment() {
      return (
        <div>
          <WrappedComponent {...this.props} />
        </div>
      );
    }

    render() {
      const { connectDragSource } = this.props;
      return connectDragSource && connectDragSource(this.renderAppointment());
    }
  }
  return withAppointmentDraggableSource(AppointmentDraggable);
};

export function getCellAppointments(date: Date, appointments: any[], hour?: string): any[] {
  if (appointments) {
    const dateStringCell = date.toISOString().slice(0, 10);
    return appointments.filter(a => {
      if (!isNaN(a.date.getDay())) {
        const dateStringAppointment = a.date.toISOString().slice(0, 10);
        return (
          dateStringCell === dateStringAppointment &&
          (!hour || parseInt(hour, 0) === parseInt(a.hour, 0))
        );
      }
    });
  }
  return [];
}
