import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'reports.title',
    defaultMessage: 'Reports',
  },
  filterByDiscipline: {
    id: 'reports.selectDiscipline',
    defaultMessage: 'Discipline...',
  },
  filterByLevel: {
    id: 'reports.selectLevel',
    defaultMessage: 'Level...',
  },
  firstTwoMonths: {
    id: 'reports.firstTwoMonths',
    defaultMessage: 'First Two Months',
  },
  secondTwoMonths: {
    id: 'reports.secondTwoMonths',
    defaultMessage: 'Second Two Months',
  },
  thirdTwoMonths: {
    id: 'reports.thirdTwoMonths',
    defaultMessage: 'Third Two Months',
  },
  fourthTwoMonths: {
    id: 'reports.fourthTwoMonths',
    defaultMessage: 'Fourth Two Months',
  },
  loadInfo: {
    id: 'reports.loadInfo',
    defaultMessage: 'Click here to load more info',
  },
});
