import { take, fork, all, call } from 'redux-saga/effects';
import { apiTask } from '../app/sagas';
import { api } from '../app/api';
import last from 'lodash/last';
import * as actions from './scheduleActions';
import * as helpers from './scheduleHelpers';
import download from '../common/download';

export const getFiltersCoursesPlansTask = apiTask(
  actions.GET_FILTERS_COURSES_PLAN,
  api.get,
  '/planner-plans/getFiltersCoursesPlans.php',
);

export const getPlannerDetailTask = apiTask(
  actions.GET_PLANNER_DETAIL,
  api.post,
  '/planner-plans/getPlansTable.php',
);

export const getClassAppointmentDetailsTask = apiTask(
  actions.GET_CLASS_APPOINTMENT_DETAILS,
  api.post,
  '/planner-plans/getPlansScheduledValues.php',
);

export const getPlansScheduledValuesTask = apiTask(
  actions.GET_PLANS_SCHEDULED_VALUES,
  api.post,
  '/planner-plans/getPlansScheduledValues.php',
);

export const getEventAppointmentDetailsTask = apiTask(
  actions.GET_EVENT_APPOINTMENT_DETAILS,
  api.post,
  '/planner-plans/getPlansScheduledValues.php',
);

export const addPlanScheduledLessonLogTask = apiTask(
  actions.ADD_PLANS_SCHEDULED_LESSON_LOG,
  api.post,
  '/planner-plans/addPlanScheduledLessonLog.php',
);

export const getScheduleAppointmentsTask = apiTask(
  actions.GET_SCHEDULE_APPOINTMENTS,
  api.post,
  '/planner-plans/getPlansScheduledList.php',
);

export const getAllScheduleAppointmentsTask = apiTask(
  actions.GET_ALL_SCHEDULE_APPOINTMENTS,
  api.post,
  '/planner-plans/getPlansScheduledList.php',
);

export const getPlansScheduledTask = apiTask(
  actions.GET_PLANS_SCHEDULED,
  api.post,
  '/planner-plans/getPlansScheduled.php',
);

export const getStatusPlanScheduledTask = apiTask(
  actions.GET_STATUS_PLAN_SCHEDULED,
  api.post,
  '/planner-plans/getStatusPlanScheduled.php',
);

export const reassignAppointmentTask = apiTask(
  actions.REASSIGN_APPOINTMENT,
  api.post,
  '/planner-plans/reasignPlanScheduledLesson.php',
);

export const addPlanScheduledEventTask = apiTask(
  actions.ADD_PLAN_SCHEDULED_EVENT,
  api.post,
  '/planner-plans/addPlanScheduledEvent.php',
);

export const getStatusScheduled = apiTask(
  actions.GET_STATUS_SCHEDULED,
  api.post,
  '/planner-plans/getStatusScheduled.php',
);

export const downloadFileTask = apiTask(
  actions.DOWNLOAD_FILE,
  api.post,
  '/planner-plans/viewFilePlanner.php',
);

export const removeEvent = apiTask(
  actions.REMOVE_EVENT,
  api.post,
  '/planner-plans/deletePlanScheduledEvent.php',
);

export const getSuperlessons = apiTask(
  actions.GET_SUPER_LESSONS,
  api.post,
  '/planner-plans/getSuperlessons.php',
);

export const viewFileSuperlessons = apiTask(
  actions.VIEW_FILE_SUPERLESSONS,
  api.post,
  '/planner-plans/viewFileSuperlessons.php',
);

export function* watchGetPlannerDetail() {
  while (true) {
    const { payload } = yield take(actions.GET_PLANNER_DETAIL);
    yield fork(getPlannerDetailTask, payload);
  }
}

export function* watchGetFiltersCoursesPlans() {
  while (true) {
    const { payload } = yield take(actions.GET_FILTERS_COURSES_PLAN);
    yield fork(getFiltersCoursesPlansTask, payload);
  }
}

export function* watchGetPlansScheduledValues() {
  while (true) {
    const { payload } = yield take(actions.GET_PLANS_SCHEDULED_VALUES);
    yield fork(getPlansScheduledValuesTask, payload);
  }
}

export function* watchAddPlanScheduledEvent() {
  while (true) {
    const { payload } = yield take(actions.ADD_PLAN_SCHEDULED_EVENT);
    yield call(addPlanScheduledEventTask, payload);
    yield fork(getScheduleAppointmentsTask, payload);
  }
}
export function* watchGetPlansScheduled() {
  while (true) {
    const { payload } = yield take(actions.GET_PLANS_SCHEDULED);
    yield fork(getPlansScheduledTask, payload);
  }
}

export function* watchAddPlanScheduledLessonLog() {
  while (true) {
    const { payload } = yield take(actions.ADD_PLANS_SCHEDULED_LESSON_LOG);
    yield call(addPlanScheduledLessonLogTask, payload);
    yield fork(getScheduleAppointmentsTask, payload.loadParams);
  }
}

export function* watchGetScheduleAppointments() {
  while (true) {
    const { payload } = yield take(actions.GET_SCHEDULE_APPOINTMENTS);
    yield fork(getScheduleAppointmentsTask, payload);
  }
}

export function* watchGetAllScheduleAppointments() {
  while (true) {
    const { payload } = yield take(actions.GET_ALL_SCHEDULE_APPOINTMENTS);
    yield fork(getAllScheduleAppointmentsTask, payload);
  }
}

export function* watchReassignAppointment() {
  while (true) {
    const { payload } = yield take(actions.REASSIGN_APPOINTMENT);
    yield fork(reassignAppointmentTask, payload);
  }
}

export function* watchGetStatusPlanScheduled() {
  while (true) {
    const { payload } = yield take(actions.GET_STATUS_PLAN_SCHEDULED);
    yield fork(getStatusPlanScheduledTask, payload, { key: helpers.makeKey(payload) });
  }
}

export function* watchGetClassAppointmentDetails() {
  while (true) {
    const { payload } = yield take(actions.GET_CLASS_APPOINTMENT_DETAILS);
    yield fork(getClassAppointmentDetailsTask, payload);
  }
}

export function* watchGetEventAppointmentDetails() {
  while (true) {
    const { payload } = yield take(actions.GET_EVENT_APPOINTMENT_DETAILS);
    yield fork(getEventAppointmentDetailsTask, payload);
  }
}

export function* watchGetStatusScheduled() {
  while (true) {
    const { payload } = yield take(actions.GET_STATUS_SCHEDULED);
    yield fork(getStatusScheduled, payload);
  }
}

export function* watchRemoveEvent() {
  while (true) {
    const { payload } = yield take(actions.REMOVE_EVENT);
    yield fork(removeEvent, payload);
  }
}

export function* watchGetSuperlessons() {
  while (true) {
    const { payload } = yield take(actions.GET_SUPER_LESSONS);
    yield fork(getSuperlessons, payload);
  }
}

export function* watchViewFileSuperlessons() {
  while (true) {
    const { payload } = yield take(actions.VIEW_FILE_SUPERLESSONS);
    yield fork(viewFileSuperlessons, payload);
  }
}

export function* watchDownloadFile() {
  while (true) {
    const { payload } = yield take(actions.DOWNLOAD_FILE);
    const { fileName } = payload;
    const { data } = yield call(downloadFileTask, payload);
    if (data) {
      const format = last(fileName.split('.')) || 'pdf';
      const formatLowerCase = format.toLowerCase();
      const fileNameDownload = fileName || `download.${formatLowerCase}`;
      const response = `data:application/${formatLowerCase};base64,${data}`;
      download(response, fileNameDownload, `file/${formatLowerCase}`);
    }
  }
}

export function* sagas() {
  yield all([
    fork(watchGetPlannerDetail),
    fork(watchGetPlansScheduled),
    fork(watchGetClassAppointmentDetails),
    fork(watchGetEventAppointmentDetails),
    fork(watchGetPlansScheduledValues),
    fork(watchGetScheduleAppointments),
    fork(watchGetAllScheduleAppointments),
    fork(watchGetStatusPlanScheduled),
    fork(watchReassignAppointment),
    fork(watchAddPlanScheduledEvent),
    fork(watchAddPlanScheduledLessonLog),
    fork(watchGetFiltersCoursesPlans),
    fork(watchGetStatusScheduled),
    fork(watchDownloadFile),
    fork(watchGetSuperlessons),
    fork(watchViewFileSuperlessons),
    fork(watchRemoveEvent),
  ]);
}
