import React from 'react';
import PropTypes from 'prop-types';

import './Navtabs.scss';

const Navtabs = ({ tabs, onClick }) => (
  <ul className="nav nav-tabs">
    {tabs.map((tab, index) => (
      <li key={`tab-${index}-${tab.value}`} className="nav-item">
        <a
          href={tab.path}
          className={`nav-link nav-btn ${tab.active ? 'active' : ''}`}
          /* eslint-disable */
          onClick={() => onClick(tab)}
          /* eslint-enable */
        >
          {tab.label}
        </a>
      </li>
    ))}
  </ul>
);

Navtabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func.isRequired
};

Navtabs.defaultProps = {
  tabs: []
};

export default Navtabs;
