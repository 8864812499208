/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { promisify } from 'app/actions';
import { EditorState } from 'draft-js';
import { FormattedMessage } from 'react-intl';
import Highlighter from 'react-highlight-words';
import ReactTable from 'react-table';
import { Loader, Download } from 'sm-ui';

import TableHeader from './TableHeader';
import MenuElement from './MenuElement';
import MenuUpload from './MenuUpload';
import MenuRow from './MenuRow';
import ModalEdit from './ModalEdit';
import ModalRemove from './ModalRemove';
import ModalAddColumn from './ModalAddColumn';
import ModalAddRow from './ModalAddRow';
import ModalMoveColumn from './ModalMoveColumn';
import ModalMoveRow from './ModalMoveRow';

import {
  getPlanner,
  updateMetadata,
  updateCell,
  updateCellWithFile,
  addColumn,
  addRow,
  moveColumn,
  moveRow,
  removeColumn,
  removeRow,
  viewFile,
  uploadFile,
} from '../plannerActions';
import messages from '../plannerMessages';
import helpers from '../helpers';

import menuIcon from './assets/menu.png';
import uploadIcon from './assets/upload-outline.png';

import 'react-table/react-table.css';
import './Table.scss';

const initialState = {
  editorState: EditorState.createEmpty(),
  showEditModal: false,
  showAddColModal: false,
  showAddRowModal: false,
  showMoveColumnModal: false,
  showMoveRowModal: false,
  showRemoveModal: false,
  showRemoveRowlModal: false,
  selectedElement: '',
  selectedColumn: '',
  label: '',
  editOrigin: '',
  editionType: '',
  direction: '',
  filter: [],
  filteredData: [],
  parsedColumns: [],
  parsedData: [],
  formatedData: [],
  columnsBuilder: [],
  isLoading: false,
  updateLoading: false,
  shouldUpdate: false,
  selectedPeriod: '',
  contextMenuStyle: {},
  pathname: '',
};

class Table extends React.PureComponent {
  state = initialState;

  componentDidMount() {
    if (this.props.original) {
      this.getPlannerOriginal();
    } else {
      this.getPlanner();
    }

    this.setState({ pathname: location.pathname });
  }

  componentDidUpdate(prevProps, prevState) {
    const nextState = this.state;

    if (prevProps.fetchPlans !== this.props.fetchPlans && this.props.fetchPlans) {
      if (this.props.original) {
        this.getPlannerOriginal();
      } else {
        this.getPlanner();
      }
    }

    if (prevState.pathname !== location.pathname) {
      this.setState({ pathname: location.pathname });
      if (this.props.original) {
        this.getPlannerOriginal();
      } else {
        this.getPlanner();
      }
    }

    if (prevProps.original !== this.props.original && this.props.original) {
      this.getPlannerOriginal();
    }

    if (prevProps.shouldUpdate !== this.props.shouldUpdate && this.props.shouldUpdate) {
      this.getPlanner();
    }

    const columnBuilder = prevState.parsedColumns !== nextState.parsedColumns;
    const rowsBuilder = prevState.parsedData !== nextState.parsedData;
    const rowsFilter = prevState.filteredData !== nextState.filteredData;

    if (prevProps.selectedPeriod !== this.props.selectedPeriod && !this.props.original) {
      this.setState({ selectedPeriod: this.props.selectedPeriod });
      this.getPlanner();
    }

    if (columnBuilder) {
      this.handleColumnBuilder();
    }

    if (rowsBuilder) {
      this.handleRowsDataFilter();
    }

    if (rowsFilter) {
      this.handleRowsDataView();
    }

    if (prevState.editOrigin !== nextState.editOrigin) {
      this.handleRowsData();
      this.handleColumnBuilder();
    }

    if (prevState.filter !== nextState.filter) {
      this.handleRowsDataFilter();
    }

    if (!this.props.original) {
      if (this.state.shouldUpdate) {
        this.getPlanner();
        this.setState({ shouldUpdate: false });
      }
    }
  }

  // ----- Start handle the tooltip from the user interactions
  onMetadataClick = element => {
    this.setState({ editionType: 'simple' });
    this.handleEditElement({ key: 'selectedElement', value: element });
    this.handleEditOrigin(`col-${element.id}`);
  };

  onElementClick = (element, column) => {
    this.setState({ editionType: 'advanced' });
    this.handleEditElement({ key: 'selectedElement', value: element });
    this.handleEditElement({ key: 'selectedColumn', value: column });
    this.handleShowEditModal({ isOpen: true, label: element.value });
  };

  onElementFileClick = (element, column) => {
    this.setState({ editionType: 'file' });
    this.handleEditElement({ key: 'selectedElement', value: element });
    this.handleEditElement({ key: 'selectedColumn', value: column });
    this.handleShowEditModal({ isOpen: true, label: element.value });
  };

  onMouseLeave = () => this.handleEditOrigin('');
  // ----- End handle the tooltip from the user interactions

  // ----- Start event to capture the Enter key
  onKeyDown = (event, callback) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      callback();
    }
  };
  // ----- End event to capture the Enter key

  onFilter = e => {
    const filterContent = e.target.value.toLowerCase();
    const normalizedContent = filterContent.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    this.setState({ filter: [normalizedContent] });
  };

  // ----- Start the fetch data from the API
  getPlannerOriginal = () => {
    // Get the original data
    this.setState({ isLoading: true });
    this.props.getPlanner({ idPlan: this.props.planner.id, original: true }).then(({ data }) => {
      if (data.status === 200) {
        return this.handleColumnsMetadata();
      }
      return this.getPlanner();
    });
  };

  getPlanner = () => {
    // Get the current data
    this.setState({ isLoading: true }, () => {
      this.props
      .getPlanner({ idPlan: this.props.idPlan || this.props.planner.id, period: this.props.selectedPeriod, table: true })
      .then(({ data }) => {
        if (data.status === 200) {
          return this.handleColumnsMetadata();
        }
        return this.getPlanner();
      });
    });

    // setTimeout(() => {
    //   this.props
    //     .getPlanner({ idPlan: this.props.auxPlan? this.props.auxPlan.id : this.props.planner.id, period: this.props.selectedPeriod })
    //     .then(({ data }) => {
    //       if (data.status === 200) {
    //         return this.handleColumnsMetadata();
    //       }
    //       return this.getPlanner();
    //     });
    // }, 2000);
  };
  // ----- End the fetch data from the API

  // ----- Start the user interactions that have calls to API's
  updateMetadata = async (idMetadata, label) => {
    this.props
      .updateMetadata({ idMetadata, label })
      .then(() => this.handleLabelUpdate(''))
      .then(() => this.setState({ shouldUpdate: true }));
  };

  updateCell = ({ idPlan, idLesson, idMetadata, idFiles, value }) => {
    this.props
      .updateCell({ idPlan, idLesson, idMetadata, idFiles, value })
      .then(() => this.handleShowEditModal({ isOpen: false, label: '' }))
      .then(() => this.setState({ shouldUpdate: true }));
  };

  updateCellWithFile = params => {
    this.setState({ updateLoading: true });
    this.props.updateCellWithFile(params);
    setTimeout(() => {
      this.setState({ shouldUpdate: true }, () => {
        this.handleShowEditModal({ isOpen: false, label: '' });
        this.props.uploadFile({ files: null });
      });
    });
    setTimeout(() => {
      this.setState({ updateLoading: false });
      this.getPlanner();
    }, 2000);
  };

  addColumn = idMetadata => {
    const { label, direction } = this.state;
    const { planner } = this.props;
    const idPlan = planner ? planner.id : '';

    this.props
      .addColumn({ idPlan, idMetadata, label, direction })
      .then(() =>
        this.handleShowAddColModal({
          direction: '',
          show: false,
        }),
      )
      .then(() => this.handleLabelUpdate(''))
      .then(() => this.setState({ shouldUpdate: true }));
  };

  addRow = () => {
    const { direction, selectedElement } = this.state;
    const idLesson = this.props.planner.table.rows[selectedElement].id;
    this.props
      .addRow({ idLesson, direction })
      .then(() =>
        this.handleShowAddRowModal({
          element: '',
          direction: '',
          show: false,
        }),
      )
      .then(() => this.setState({ shouldUpdate: true }));
  };

  moveColumn = idMetadata => {
    const { direction } = this.state;
    this.props
      .moveColumn({ idMetadata, direction })
      .then(() =>
        this.handleShowMoveColumnModal({
          direction: '',
          show: false,
        }),
      )
      .then(() => this.setState({ shouldUpdate: true }));
  };

  moveRow = () => {
    const { direction, selectedElement } = this.state;
    const idLesson = this.props.planner.table.rows[selectedElement].id;
    this.props
      .moveRow({ idLesson, direction })
      .then(() =>
        this.handleShowMoveRowModal({
          element: '',
          direction: '',
          show: false,
        }),
      )
      .then(() => this.setState({ shouldUpdate: true }));
  };

  removeColumn = () => {
    const idMetadata = this.state.selectedElement.id;
    this.handleShowRemoveModal(false);
    this.props.removeColumn({ idMetadata }).then(() => this.setState({ shouldUpdate: true }));
  };

  removeRow = () => {
    const { selectedElement } = this.state;
    const idLesson = this.props.planner.table.rows[selectedElement].id;
    this.handleShowRemoveRowlModal(false);
    this.props.removeRow({ idLesson }).then(() => this.setState({ shouldUpdate: true }));
  };
  // ----- End the user interactions that have calls to API's

  // ----- Start the handlers to resolve the data or the flow in the page
  handleEditElement = ({ key, value }) => {
    this.setState({ [key]: value });
  };

  handleTextEditor = value => {
    this.setState({ editorState: value });
  };

  handleEditOrigin = (value, contextMenuStyle) => {
    this.setState({ editOrigin: value, contextMenuStyle });
  };

  handleLabelUpdate = value => {
    this.setState({ label: value });
  };

  handleShowRemoveModal = value => {
    this.setState({ showRemoveModal: value });
  };

  handleShowRemoveRowlModal = value => {
    this.setState({ showRemoveRowlModal: value });
  };

  handleUpdateMetadata = ({ id, label }) => {
    this.handleShowEditModal({ isOpen: false, label: '' });
    this.updateMetadata(id, label);
  };

  handleShowEditModal = ({ isOpen, label }) => {
    !isOpen && this.handleEditOrigin('');
    this.handleLabelUpdate(label);
    this.setState({ showEditModal: isOpen });
  };

  handleShowAddColModal = ({ direction, show }) => {
    this.setState({ direction: direction, showAddColModal: show });
  };

  handleShowMoveColumnModal = ({ direction, show }) => {
    this.setState({ direction: direction, showMoveColumnModal: show });
  };

  handleShowAddRowModal = ({ direction, show, element }) => {
    element && this.handleEditElement({ key: 'selectedElement', value: element });
    this.setState({ direction: direction, showAddRowModal: show });
  };

  handleShowMoveRowModal = ({ direction, show, element }) => {
    element && this.handleEditElement({ key: 'selectedElement', value: element });
    this.setState({ direction: direction, showMoveRowModal: show });
  };

  handleColumnsMetadata = () => {
    const { table, columns } = this.props;
    let parsedColumns = [];

    if (columns) {
      parsedColumns = helpers.resolveColumns(columns);
    } else {
      parsedColumns = table.columns;
    }

    this.setState({ parsedColumns }, () => this.handleRowsData());
  };

  handleRowsData = () => {
    const { table, data } = this.props;
    let parsedData = [];

    if (data) {
      parsedData = helpers.resolveRows(data);
    } else {
      parsedData = table.rows;

      parsedData.map((data, index) => 
        data.map((d,i) => {
            if(d.value === null || d.value === "") {
              parsedData[index].splice(i, 1);
            }
        }),
      )
    }

    this.setState({ parsedData, isLoading: false });
  };

  handleRowsDataFilter = () => {
    const { filter, parsedData } = this.state;
    const filteredData =
      parsedData.length > 0
        ? parsedData.filter(row => {
            const keys = helpers.parseObjectKeys(row);
            const filtered = keys.filter(el => {
              if (row[el].value && filter[0]) {
                const filterContent = row[el].value.toLowerCase();
                const normalizedContent = filterContent
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '');

                return normalizedContent.indexOf(filter[0]) !== -1;
              }
            });
            if (filter.length === 0 || filter[0] === '') return true;
            if (filtered.length > 0) return true;
          })
        : [];

    this.setState({ filteredData });
  };

  handleRowsDataView = () => {
    const { filter, filteredData, editOrigin, parsedColumns } = this.state;
    const formatedData =
      filteredData.length > 0
        ? filteredData.map((row, position) => {
            const keys = Object.keys(row).map(key => key);
            const formated = {};
            keys.map((key, index) => {
              if(parsedColumns[key]) {
                const currentCell = row[key];
                const currentColumn = parsedColumns[key];
                const { type, editable } = currentColumn;
                const { fileName, value, id } = currentCell;
                const isEditable = editable === '1';
                const isFile = type === 'file';
                const showMenuEditElement = isEditable && !isFile;
                const showMenuAddFileElement = isEditable && isFile;
                const contentString = fileName || value;
                const parsedContent =
                  contentString && contentString.replace(/<(?:.|\n)*?>/gm, '').toLowerCase();
                const normalizedContent = contentString
                  ? parsedContent.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                  : ' ';
                const filterTest =
                  filter.length === 0 || (filter.length === 1 && filter[0] === '')
                    ? false
                    : normalizedContent.includes(filter);
                const content = <div dangerouslySetInnerHTML={{ __html: contentString }} />;
                formated[index + 1] = (
                  <div
                    className="element-wrapper"
                    onClick={e => {
                      this.handleEditOrigin(`${position}/${key}`, { top: e.pageY, left: e.pageX });
                    }}
                    onContextMenu={e => {
                      e.preventDefault();
                      this.handleEditOrigin(`${position}/${key}`, { top: e.pageY, left: e.pageX });
                    }}
                    style={{
                      minHeight: '45px',
                      justifyContent: fileName ? 'space-between' : 'center',
                    }}
                  >
                    {filterTest && (
                      <div style={{ paddingTop: '10px' }}>
                        <Highlighter
                          searchWords={filter}
                          autoEscape={true}
                          textToHighlight={normalizedContent}
                        />
                      </div>
                    )}
                    {!filterTest &&
                      !isFile && (
                        <div style={{ paddingTop: '10px' }}>
                          {/* if the element has the code &lt;br/&gt; from the br element, then it will resolve the data*/}
                          {(() => {
                            if (
                              String(content.props.dangerouslySetInnerHTML.__html).includes(
                                '&lt;br/&gt;',
                              )
                            ) {
                              const topics = String(
                                content.props.dangerouslySetInnerHTML.__html,
                              ).split('&lt;br/&gt;');
                              return (
                                <div>
                                  {topics.map(el => (
                                    <div>{el}</div>
                                  ))}
                                </div>
                              );
                            } else {
                              return content;
                            }
                          })()}
                        </div>
                      )}
                    {!filterTest &&
                      isFile && (
                        <React.Fragment>
                          <button
                            className="planner-table-link"
                            onClick={() => this.props.viewFile({ fileName, type, id })}
                          >
                            {content}
                          </button>
                          <div
                            className="icon-wrapper"
                            onClick={() => this.onElementFileClick(row[key], parsedColumns[key])}
                            style={fileName ? { width: '45px' } : {}}
                          >
                            <img
                              src={uploadIcon}
                              style={{
                                width: '15px',
                                margin: '5px',
                              }}
                            />
                          </div>
                        </React.Fragment>
                      )}
                    {/* Rule to show the edit tolltip */}
                    {showMenuEditElement && (
                      <MenuElement
                        isOpen={`${position}/${key}` === editOrigin}
                        onMouseLeave={this.onMouseLeave}
                        onClick={() => this.onElementClick(row[key], parsedColumns[key])}
                      />
                    )}
                    {/* Rule to show the file upload tolltip */}
                    {/* {showMenuAddFileElement && (
                      <MenuUpload
                        isOpen={`${position}/${key}` === editOrigin}
                        onMouseLeave={this.onMouseLeave}
                        onClick={() => this.onElementFileClick(row[key], parsedColumns[key])}
                      />
                    )} */}
                  </div>
                );
              }
            });
            return formated;
          })
        : [];

    this.setState({ formatedData });
  };

  menuRowComponent = index => {
    const { formatedData, editOrigin } = this.state;
    const keys = helpers.parseObjectKeys(formatedData);

    return (
      <MenuRow
        index={index}
        arrayLength={keys.length}
        isOpen={`${index}` === editOrigin}
        onMouseLeave={this.onMouseLeave}
        onRemove={() => {
          this.handleShowRemoveRowlModal(true);
          this.handleEditElement({ key: 'selectedElement', value: editOrigin });
        }}
        onAddUp={() =>
          this.handleShowAddRowModal({
            element: editOrigin,
            direction: 'a',
            show: true,
          })
        }
        onAddDown={() =>
          this.handleShowAddRowModal({
            element: editOrigin,
            direction: 'b',
            show: true,
          })
        }
        onMoveUp={() =>
          this.handleShowMoveRowModal({
            element: editOrigin,
            direction: 'a',
            show: true,
          })
        }
        onMoveDown={() =>
          this.handleShowMoveRowModal({
            element: editOrigin,
            direction: 'b',
            show: true,
          })
        }
      />
    );
  };

  handleColumnBuilder = () => {
    const { parsedColumns, editOrigin, selectedElement } = this.state;
    const { preview } = this.props;

    const columnsBuilder = [];
    columnsBuilder.push({
      Header: '',
      Cell: row => (
        <div
          id="first"
          style={{
            textAlign: 'center',
            cursor: 'pointer',
            width: '100%',
            height: '100%',
            paddingTop: '10px',
          }}
          onContextMenu={e => {
            e.preventDefault();
            this.handleEditOrigin(`${row.viewIndex}`);
          }}
        >
          <small>
            <i>{row.viewIndex + 1}</i>
            {this.menuRowComponent(row.viewIndex)}
          </small>
        </div>
      ),
      maxWidth: 40,
      resizable: false,
      sortable: false,
    });
    parsedColumns.map(col => {
      columnsBuilder.push({
        id: parseInt(col.idIndex),
        Header: () => (
          <TableHeader
            onClick={e => this.onMetadataClick(col)}
            label={col.label}
            showHover={!preview && editOrigin === `col-${col.id}`}
            onMouseLeave={this.onMouseLeave}
            handleShowEditModal={() =>
              this.handleShowEditModal({ isOpen: true, label: selectedElement.label })
            }
            handleShowAddColModal={this.handleShowAddColModal}
            handleShowMoveColumnModal={this.handleShowMoveColumnModal}
            handleShowRemoveModal={() => this.handleShowRemoveModal(true)}
          />
        ),
        minWidth: 150,
        accessor: col.idIndex,
        Cell: row => <div className="table-row">{row.fileName ? row.fileName : row.value}</div>,
      });
    });
    columnsBuilder.push({
      Header: '',
      Cell: () => '',
      maxWidth: 15,
      resizable: false,
      sortable: false,
    });

    this.setState({ columnsBuilder });
  };

  render() {
    const { preview } = this.props;
    const {
      label,
      direction,
      editionType,
      editorState,
      showEditModal,
      selectedElement,
      selectedColumn,
      showAddColModal,
      showRemoveModal,
      showAddRowModal,
      showMoveRowModal,
      showRemoveRowlModal,
      showMoveColumnModal,
      parsedColumns,
      formatedData,
      columnsBuilder,
    } = this.state;

    return (
      <div
        id="table"
        className="table-with-actions"
        style={
          preview
            ? {
                position: 'inherit',
                display: 'content',
                height: '100%',
                width: '100%',
              }
            : {}
        }
      >
        {(this.state.isLoading || this.state.updateLoading) && <Loader show />}
        <React.Fragment>
          {/* Table Header with Search Input */}
          {!preview && (
            <div className="table-header">
              <div>
                <input className="search" type="search" onChange={this.onFilter} />
              </div>
            </div>
          )}
          <div id="table-wrapper" onMouseLeave={this.onMouseLeave}>
            {parsedColumns && (
              <ReactTable
                id="inner-table"
                data={formatedData}
                columns={columnsBuilder}
                defaultPageSize={10}
                className="-striped -highlight"
                previousText={<FormattedMessage {...messages.tablePreviousText} />}
                nextText={<FormattedMessage {...messages.tableNextText} />}
                loadingText={<FormattedMessage {...messages.tableLoadingText} />}
                noDataText={
                  this.state.isLoading ? '' : <FormattedMessage {...messages.tableNoDataText} />
                }
                pageText={<FormattedMessage {...messages.tablePageText} />}
                ofText={<FormattedMessage {...messages.tableOfText} />}
                rowsText={''}
                sortable={false}
              />
            )}

            {!preview && (
              <React.Fragment>
                <ModalEdit
                  isOpen={showEditModal}
                  label={label}
                  editionType={editionType}
                  editorState={editorState}
                  selectedColumn={selectedColumn}
                  selectedElement={{ idPlan: this.props.planner.id, ...selectedElement }}
                  onLabelChange={this.handleLabelUpdate}
                  onTextFieldChange={this.handleTextEditor}
                  onMetadataUpdate={() =>
                    this.handleUpdateMetadata({
                      id: selectedElement.id,
                      label,
                    })
                  }
                  onCellUpdate={(idPlan, idLesson, idMetadata, idFiles, value) =>
                    this.updateCell({
                      idPlan,
                      idLesson,
                      idMetadata,
                      idFiles,
                      value,
                    })
                  }
                  onCellUpdateWithFile={params => this.updateCellWithFile(params)}
                  onClose={() => this.handleShowEditModal({ isOpen: false, label: '' })}
                />
                <ModalRemove
                  isOpen={showRemoveModal || showRemoveRowlModal}
                  type={showRemoveModal ? 'column' : 'row'}
                  onClose={() => {
                    showRemoveModal
                      ? this.handleShowRemoveModal(false)
                      : this.handleShowRemoveRowlModal(false);
                  }}
                  onConfirm={() => {
                    showRemoveModal ? this.removeColumn() : this.removeRow();
                  }}
                />
                <ModalAddColumn
                  isOpen={!!showAddColModal}
                  direction={direction}
                  label={label}
                  onKeyDown={e => this.onKeyDown(e, () => this.addColumn(selectedElement.id))}
                  onClose={() =>
                    this.handleShowAddColModal({
                      direction: '',
                      show: false,
                    })
                  }
                  onChange={this.handleLabelUpdate}
                  onConfirm={() =>
                    selectedElement && selectedElement.id && this.addColumn(selectedElement.id)
                  }
                />
                <ModalAddRow
                  isOpen={showAddRowModal}
                  direction={direction}
                  onClose={() =>
                    this.handleShowAddRowModal({
                      direction: '',
                      show: false,
                    })
                  }
                  onConfirm={() => {
                    const { idLesson } = selectedElement;
                    this.addRow(idLesson);
                  }}
                />
                <ModalMoveColumn
                  isOpen={showMoveColumnModal}
                  direction={direction}
                  onClose={() =>
                    this.handleShowMoveColumnModal({
                      direction: '',
                      show: false,
                    })
                  }
                  onConfirm={() =>
                    selectedElement && selectedElement.id && this.moveColumn(selectedElement.id)
                  }
                />
                <ModalMoveRow
                  isOpen={showMoveRowModal}
                  direction={direction}
                  onClose={() =>
                    this.handleShowMoveRowModal({
                      direction: '',
                      show: false,
                    })
                  }
                  onConfirm={() => this.moveRow()}
                />
              </React.Fragment>
            )}
          </div>
        </React.Fragment>
      </div>
    );
  }
}

Table.propTypes = {
  preview: PropTypes.bool,
  original: PropTypes.bool,
};

Table.defaultProps = {
  preview: false,
  original: false,
};

const mapStateToProps = ({ planner, table, fileName }) => ({ ...planner, ...table, ...fileName });

export default connect(
  mapStateToProps,
  promisify({
    getPlanner,
    updateMetadata,
    updateCell,
    updateCellWithFile,
    addColumn,
    addRow,
    moveColumn,
    moveRow,
    removeColumn,
    removeRow,
    viewFile,
    uploadFile,
  }),
)(Table);
