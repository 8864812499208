import { api } from 'app/api';
import qs from 'query-string';
import { success } from 'app/actions';
import * as loginActions from 'login/loginActions';
import * as configurationActions from 'configuration/configurationActions';

// const user = process.env.NODE_ENV === 'development' ? { name: 'teste' } : null;
// const token =
//   process.env.NODE_ENV === 'development'
//     ? 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.W3siaWRVc2VyIjo2OTg2MSwiaWRUeXBlIjozLCJmaXJzdE5hbWUiOiJPc2NhciBJdmFuIiwibGFzdE5hbWUiOiJHYW1leiBIZXJuYW5kZXoiLCJlbWFpbCI6Im9zY2FyLmdhbWV6QGdydXBvLXNtLmNvbSIsInBhc3N3b3JkIjpudWxsLCJzaGFyZU15UXVlc3Rpb25zIjowLCJwaG90byI6bnVsbCwidGltZXpvbmUiOiJBbWVyaWNhL0JvZ290YSIsImlkQ291bnRyeSI6MTUsImFiYnJldmlhdGlvbiI6IkNPIiwibGFuZ3VhZ2UiOiJlcyIsImNvdW50cnlOYW1lIjoiQ29sb21iaWEiLCJpZFNjaG9vbCI6MjI3NjUsInNjaG9vbE5hbWUiOiJDSVVEQUQgRVNDT0xBUiBERSBDT01GRU5BTENPIn1d.W22-DaX_N1F47eeTC-gkVEGhqhRG3H2zBGvVyPccxZo'
//     : null;

const initialState = {
  role: null,
  user: null,
  token: null,
  error: {},
  queryParams: qs.parse(location.search.replace('?', '')),
  userCI: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case success(loginActions.LOGIN):
    case success(loginActions.AUTH): {
      // move this later to saga
      const { token, user, cookieToken, userCi } = action.payload;
      api.defaults.headers.common['X-Auth-Token'] = token;
      localStorage.setItem('X-Auth-Token', token);
      localStorage.setItem('X-Token', cookieToken);
      const role = user && parseInt(user.idType, 0);
      const userCI = !user && userCi !== null ? true : false;

      return {
        ...state,
        user,
        userCI,
        token,
        role,
        authorizing: false,
        error: { ...state.error, login: undefined },
      };
    }
    case success(configurationActions.UPLOAD):
      return {
        ...state,
        user: { ...state.user, photo: action.payload.filename },
      };
    default:
      return state;
  }
};

export default reducer;
