import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { promisify } from 'app/actions';
import { withScrollTopOnMount } from 'app/hocs';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import pickBy from 'lodash/pickBy';
import { compose } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { Loader } from 'sm-ui';

import CalendarHeader, { OptionsViewMap } from './CalendarHeader';
import CalendarReassignModal from './CalendarReassignModal';
import CalendarStatusModal from './CalendarStatusModal';
import CalendarStatusChangeModal from './CalendarStatusChangeModal';
import CalendarClassDetailsModal from './CalendarClassDetailsModal';
import CalendarEventDetailsModal from './CalendarEventDetailsModal';
import CalendarEventRemoveModal from './CalendarEventRemoveModal';
import Calendar, { View } from './Calendar';
import CalendarEventType from './calendarEventType';
import CalendarAddEventModal from './CalendarAddEventModal';
import CalendarErrorEventModal from './CalendarErrorEventModal';
import './CalendarContainer.scss';

import messages from './calendarMessages';

import {
  getScheduleAppointments,
  getAllScheduleAppointments,
  reassignAppointment,
  addPlanScheduledEvent,
  getClassAppointmentDetails,
  getPlansScheduledValues,
  getEventAppointmentDetails,
  getFiltersCoursesPlans,
  removePlanScheduledEvent,
  resetReassignForm,
  viewFileSuperlessons,
  downloadFile,
} from '../scheduleActions';

function getView(viewOption) {
  switch (parseInt(viewOption, 0)) {
    case OptionsViewMap.DAY.id:
      return View.DAY;
    case OptionsViewMap.WEEK.id:
      return View.WEEK;
    case OptionsViewMap.MONTH.id:
      return View.MONTH;
    default:
      return View.YEAR;
  }
}

function getDefaultViewValue() {
  const { idView } = queryString.parse(location.search);
  return idView || OptionsViewMap.WEEK.id;
}

function dateToString(date) {
  return typeof date === 'string' ? date : date.toISOString().slice(0, 10);
}

function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

class CalendarContainer extends Component {
  state = {
    showModal: {},
    viewValue: getDefaultViewValue(),
    params: queryString.parse(location.search),
    defaultReassignDate: null,
    defaultReassignHour: null,
    appointment: {},
    // params: {},
    statusUpdated: false,
    isLoading: false,
    waitingList: false,
  };

  componentDidMount() {
    this.fetchAppointments();
    this.fetchFilters();
  }

  componentDidUpdate(prevProps, prevState) {
    if (isEmpty(prevState.params) && !isEmpty(this.state.params)) {
      this.fetchAppointments();
    }
    if (!isEmpty(prevState.params) && isEmpty(this.state.params)) {
      this.handleGetAllAppointments();
    }
  }

  fetchFilters = () => {
    this.props.getFiltersCoursesPlans();
  };

  fetchAppointments = async () => {
    const { params } = this.state;
    this.setState({
      params,
      waitingList: true,
      isLoading: true,
    });
    const paramsWithValues = pickBy(params, value => !!value);

    await this.props.getScheduleAppointments(paramsWithValues).then(() => {
      this.setState(
        {
          params: {
            idCourse: params.idCourse,
            idPlan: params.idPlan,
            idPlanScheduled:
              this.props.appointments.length > 0
                ? this.props.appointments[0].idPlanScheduled
                : params.idPlanScheduled,
          },
        },
        () => {
          this.updateUrl();
        },
      );
      this.setState({
        isLoading: false,
        waitingList: false,
      });
    });
  };

  calendar = React.createRef();

  showModal = modalName => () => {
    this.setState(({ showModal }) => ({ showModal: { ...showModal, [modalName]: true } }));
  };

  closeModal = modalName => () => {
    this.setState(({ showModal }) => ({ showModal: { ...showModal, [modalName]: false } }));
  };

  handleGetPlansScheduledValues = () => {
    const { idPlanScheduled } = this.state.params;
    let idCourse = this.state.params.idCourse;
    let idScheduledLesson = this.state.appointment.idScheduledLesson;
    let idPlan = this.state.params.idPlan;

    const index = _.find(this.props.appointments, elem => elem.idPlan === idPlan);

    if (index) {
      idCourse = index.idCourse;
      idScheduledLesson = index.idScheduledLesson;
      idPlan = index.idPlan;
    }

    const params = {
      idPlan: idPlan || this.state.appointment.idPlan,
      idCourse,
      idPlanScheduled,
      idScheduledLesson,
    };

    this.props.getPlansScheduledValues(params);
  };

  handleContinue = async data => {
    // const { location } = this.props;
    // this.setState({
    //   params:queryString.parse(location.search)
    // });

    const params = queryString.parse(location.search);

    setTimeout(async () => {
      // const { idPlan, idCourse } = this.state.params;
      if (!params.idPlan || !params.idCourse) {
        throw new Error('You need to select a course and a plan before adding an event');
      }

      const idPlan = params.idPlan;
      const idCourse = params.idCourse;

      await this.props.addPlanScheduledEvent({ ...data, idPlan, idCourse });
      this.closeModal('add')();
    }, 1000);
  };

  handleChangeView = viewOption => {
    this.setState(
      ({ params }) => ({
        viewValue: viewOption,
        params: { ...params, idView: viewOption },
      }),
      () => {
        this.updateUrl();
      },
    );
  };

  updateUrl = () => {
    const { params } = this.state;
    const { date, ...rest } = params;
    const paramsUrl = { ...rest, date: date ? dateToString(date) : undefined };
    const paramsWithValues = pickBy(paramsUrl, value => !!value);
    history.replaceState(
      paramsUrl,
      JSON.stringify(paramsWithValues),
      `?${queryString.stringify(paramsWithValues)}`,
    );
  };

  handleChangeDate = date => {
    this.setState(({ params }) => ({ params: { ...params, date } }), this.updateUrl);
  };

  handleCloseReassignModal = () => {
    this.props.resetReassignForm();
    this.closeModal('reassign')();
  };

  handleRequestReassignAppointment = (appointment, date, hour) => {
    this.setState(({ showModal }) => ({
      showModal: { ...showModal, reassign: true },
      defaultReassignDate: date,
      defaultReassignHour: hour,
      appointment,
    }));
  };

  handleRequestStatusAppointment = appointment => {
    this.setState(({ showModal }) => ({
      showModal: { ...showModal, status: true },
      appointment,
    }));
  };

  handleRequestRemoveAppointment = appointment => {
    this.setState(({ showModal }) => ({
      showModal: { ...showModal, remove: true },
      appointment,
    }));
  };

  handleDownloadFile = ({ type, id, fileName }) => () => {
    this.props.downloadFile({ type, id, fileName });
  };

  handleViewFileSuperlessons = ({ type, id }) => {
    this.props.viewFileSuperlessons({ type, id });
  };

  handleRequestDetailsAppointment = async appointment => {
    const isEvent = [CalendarEventType.EVENT, CalendarEventType.ASSESSMENT].includes(
      appointment.type,
    );
    const action = isEvent
      ? this.props.getEventAppointmentDetails
      : this.props.getClassAppointmentDetails;
    await action(appointment);
    const modal = isEvent ? 'eventDetails' : 'classDetails';
    this.setState(({ showModal }) => ({
      showModal: { ...showModal, [modal]: true },
      appointment,
    }));
  };

  handleChangeFilter = params => {
    this.setState(
      {
        params,
      },
      () => {
        this.fetchAppointments();
        this.updateUrl();
      },
    );
  };

  handleReassign = async ({ date, automaticReassign, hour }) => {
    const { idPlanScheduled, idScheduledLesson } = this.state.appointment;
    const data = {
      idPlanScheduled,
      idLessonScheduled: idScheduledLesson,
      date,
      automaticReasign: automaticReassign ? 1 : 0,
      hour,
    };
    await this.props.reassignAppointment(data);
    this.closeModal('reassign')();
    this.fetchAppointments();
  };

  handleRemove = async () => {
    const { idScheduledLesson } = this.state.appointment;
    const data = { idScheduledLesson };

    await this.props.removePlanScheduledEvent(data);
    this.closeModal('remove')();
    this.fetchAppointments();
  };

  handleUpdateStatus = async () => {
    this.closeModal('statusChange')();
    this.setState({ statusUpdated: true });
  };

  handleCloseStatus = async () => {
    this.closeModal('status')();
    this.setState({ statusUpdated: false });
  };

  handleGetAllAppointments = () => {
    const { plansScheduled } = this.props;
    const plansScheduledIds = plansScheduled.map(elem => ({
      idPlan: elem.idPlan,
      idCourse: elem.idCourse,
      idPlanScheduled: elem.idPlanScheduled,
    }));

    this.setState({ isLoading: true });

    Promise.all(
      plansScheduledIds.map(elem => {
        this.props.getAllScheduleAppointments({ ...elem });
      }),
    ).then(() => {
      setTimeout(() => {
        this.setState({ isLoading: false }, () => {
          this.props.history.push(`/calendar`);
        });
      }, 1000 * plansScheduledIds.length);
    });
  };

  render() {
    const {
      auxMaximusHour,
      appointments,
      errorReassignment,
      scheduledLesson,
      eventDetails,
      classDetails,
      token,
    } = this.props;
    const {
      showModal,
      appointment,
      viewValue,
      defaultReassignDate,
      defaultReassignHour,
      waitingList,
    } = this.state;
    let { idPlan, idCourse } = this.state.params;
    const view = getView(viewValue);

    const index = _.find(this.props.appointments, elem => elem.idPlan === idPlan);

    if (index) {
      idCourse = index.idCourse;
    }

    return (
      <div id="calendar-container" className="calendar--container">
        {waitingList && (
          <div className="calendar-waiting">
            <FormattedMessage {...messages.waitingCalendar}>
              {txt => <h4 style={{ color: '#fff' }}>{txt}</h4>}
            </FormattedMessage>
          </div>
        )}
        <Loader show={this.state.isLoading} />
        <CalendarHeader
          onRequestAdd={this.showModal('add')}
          onRequestError={this.showModal('error')}
          onChangeView={this.handleChangeView}
          calendarRef={this.calendar}
          viewValue={viewValue}
          onChangeFilter={this.handleChangeFilter}
        />
        <Calendar
          view={view}
          ref={this.calendar}
          appointments={appointments}
          auxMaximusHour={auxMaximusHour}
          onChangeDate={this.handleChangeDate}
          onRequestReassign={this.handleRequestReassignAppointment}
          onRequestDetails={this.handleRequestDetailsAppointment}
          onRequestStatus={this.handleRequestStatusAppointment}
          onRequestRemove={this.handleRequestRemoveAppointment}
        />
        <CalendarErrorEventModal
          id="error-event-modal"
          show={showModal.error}
          onClose={this.closeModal('error')}
          onContinue={this.handleContinue}
        />
        <CalendarAddEventModal
          id="add-event-modal"
          show={showModal.add}
          onClose={this.closeModal('add')}
          onContinue={this.handleContinue}
        />
        <CalendarReassignModal
          id="reassign-modal"
          show={showModal.reassign}
          error={errorReassignment}
          onSubmit={this.handleReassign}
          defaultDate={defaultReassignDate}
          defaultHour={defaultReassignHour}
          onClose={this.handleCloseReassignModal}
        />
        <CalendarStatusModal
          id="status-modal"
          show={showModal.status}
          statusChange={showModal.statusChange}
          onEdit={this.showModal('statusChange')}
          onClose={this.handleCloseStatus}
          idPlan={idPlan || this.state.appointment.idPlan}
          idCourse={idCourse}
          idScheduledLesson={this.state.appointment.idScheduledLesson}
          statusUpdated={this.state.statusUpdated}
        />
        <CalendarStatusChangeModal
          id="status-change-modal"
          show={showModal.statusChange}
          idPlan={idPlan || this.state.appointment.idPlan}
          idCourse={idCourse}
          idScheduledLesson={this.state.appointment.idScheduledLesson}
          appointmentCategory={this.state.appointment.category}
          onClose={this.closeModal('statusChange')}
          onUpdate={this.handleUpdateStatus}
        />
        <CalendarClassDetailsModal
          id="class-detail-modal"
          handleGetPlansScheduledValues={this.handleGetPlansScheduledValues}
          show={showModal.classDetails}
          appointment={appointment}
          scheduledLesson={scheduledLesson}
          idScheduledLesson={this.state.appointment.idScheduledLesson}
          handleViewFileSuperlessons={this.handleViewFileSuperlessons}
          details={classDetails}
          token={token}
          onRequestReassign={this.handleRequestReassignAppointment}
          onRequestStatus={this.handleRequestStatusAppointment}
          onRequestRemove={this.handleRequestRemoveAppointment}
          onClose={this.closeModal('classDetails')}
          onClickFile={this.handleDownloadFile}
        />
        <CalendarEventDetailsModal
          id="event-detail-modal"
          show={showModal.eventDetails}
          appointment={appointment}
          details={eventDetails}
          onRequestReassign={this.handleRequestReassignAppointment}
          onRequestStatus={this.handleRequestStatusAppointment}
          onRequestRemove={this.handleRequestRemoveAppointment}
          onClose={this.closeModal('eventDetails')}
        />
        <CalendarEventRemoveModal
          id="event-remove-modal"
          show={showModal.remove}
          onRemove={this.handleRemove}
          onClose={this.closeModal('remove')}
        />
      </div>
    );
  }
}

CalendarContainer.propTypes = {
  getScheduleAppointments: PropTypes.func.isRequired,
  addPlanScheduledEvent: PropTypes.func.isRequired,
  getClassAppointmentDetails: PropTypes.func.isRequired,
  getEventAppointmentDetails: PropTypes.func.isRequired,
  getFiltersCoursesPlans: PropTypes.func.isRequired,
  appointments: PropTypes.arrayOf.isRequired,
  removePlanScheduledEvent: PropTypes.func.isRequired,
  getPlansScheduledValues: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  schedule: {
    auxMaximusHour,
    appointments,
    errorReassignment,
    classDetails,
    eventDetails,
    scheduledLessonStatus,
    scheduledLesson,
    plansScheduled,
  },
  app: { token },
}) => ({
  auxMaximusHour,
  appointments,
  scheduledLessonStatus,
  scheduledLesson,
  token,
  errorReassignment,
  eventDetails,
  classDetails,
  plansScheduled,
  reassignAppointment,
});

export default compose(
  withScrollTopOnMount,
  withRouter,
  connect(
    mapStateToProps,
    promisify({
      getScheduleAppointments,
      getAllScheduleAppointments,
      reassignAppointment,
      addPlanScheduledEvent,
      getClassAppointmentDetails,
      getEventAppointmentDetails,
      getFiltersCoursesPlans,
      getPlansScheduledValues,
      viewFileSuperlessons,
      downloadFile,
      removePlanScheduledEvent,
      resetReassignForm,
    }),
  ),
)(CalendarContainer);
