import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import CardMonthCell from './CardMonthCell';

import messages from './cardMonthMessages';

const CardMonthHeader = () => (
  <Fragment>
    <FormattedMessage {...messages.sunday}>
      {txt => (
        <CardMonthCell className="week" disabled>
          {txt}
        </CardMonthCell>
      )}
    </FormattedMessage>
    <FormattedMessage {...messages.monday}>
      {txt => <CardMonthCell className="week">{txt}</CardMonthCell>}
    </FormattedMessage>
    <FormattedMessage {...messages.tuesday}>
      {txt => <CardMonthCell className="week">{txt}</CardMonthCell>}
    </FormattedMessage>
    <FormattedMessage {...messages.wednesday}>
      {txt => <CardMonthCell className="week">{txt}</CardMonthCell>}
    </FormattedMessage>
    <FormattedMessage {...messages.thursday}>
      {txt => <CardMonthCell className="week">{txt}</CardMonthCell>}
    </FormattedMessage>
    <FormattedMessage {...messages.friday}>
      {txt => <CardMonthCell className="week">{txt}</CardMonthCell>}
    </FormattedMessage>
    <FormattedMessage {...messages.saturday}>
      {txt => (
        <CardMonthCell className="week" disabled>
          {txt}
        </CardMonthCell>
      )}
    </FormattedMessage>
  </Fragment>
);

CardMonthHeader.shouldComponentUpdate = false;

export default CardMonthHeader;
