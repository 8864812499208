// @flow
export const REQUEST = 'REQUEST';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';

export const success = (prefix: string) => `${prefix}_${SUCCESS}`;
export const failure = (prefix: string) => `${prefix}_${FAILURE}`;
export const request = (prefix: string) => `${prefix}_${REQUEST}`;

export function action(type: string, payload: any = {}, args?: any = {}) {
  return { type, ...payload, ...args };
}

export function extractPrefix(type: string) {
  const typeSplited = type.split('/');
  return typeSplited[typeSplited.length - 1].replace(
    new RegExp(`_(${REQUEST}|${SUCCESS}|${FAILURE})$`, 'g'),
    '',
  );
}

export const promisify = actions => dispatch =>
  Object.keys(actions)
    .map(k => ({
      [k]: payload => {
        if (typeof actions[k] !== 'function') {
          throw new Error(`${k} is not a function to promisify`);
        }
        return new Promise((resolve, reject) =>
          dispatch(actions[k]({ ...payload, resolve, reject })),
        );
      },
    }))
    .reduce((a1, a2) => ({ ...a1, ...a2 }), {});
