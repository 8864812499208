import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ProfileContainer extends Component {
    render() {
        return (
            <div>
                <h1 class="page-title mb-5">Meu Perfil</h1>
            </div>
        );
    }
}

ProfileContainer.propTypes = {

};

export default ProfileContainer;