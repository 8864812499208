import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { promisify } from 'app/actions';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import Role from 'app/role';

import { Row, Col, Card, Navtabs, Select, SortBar, Modal } from 'sm-ui';
import Table from '../planner/components/Table';

import { getPlans, getPlanner } from './plansActions';
import { registerSchool } from '../register/registerActions';
import messages from './plansMessages';

import printHelper from '../planner/helpers';

import RegisterContainer from '../register/RegisterContainer';

import './Plans.scss';

const CURRENT_YEAR = moment(Date.now()).format('YYYY');

const initialState = {
  activeTab: CURRENT_YEAR,
  displayView: 'grid',
  filter: {},
  showModal: {},
  onlyRegisterSchool: true,
};

function isFilterAdminAllowed(role) {
  return [Role.COORDINATOR, Role.ADMINISTRADOR].includes(parseInt(role, 0));
}
class PlansContainer extends React.Component {
  static getDerivedStateFromProps(props, state) {
    const { years } = props;
    if (years !== state.prevYears) {
      return {
        prevYears: years,
        activeTab: years[0] || new Date().getFullYear(),
      };
    }
    return null;
  }

  state = initialState;

  componentDidMount() {
    this.fetchPlans();
  }

  getTable = () => this.table.current;

  table = React.createRef();

  fetchPlans = () => {
    const { filter } = this.state;
    this.props.getPlans(filter);

    if (!this.props.idSchool) {
      this.showModal('registerSchool')();
    }
  };

  handleChangeFilter = name => event => {
    const { value } = event.target;
    const parsedValue = value ? value.split(',') : undefined; // transforming value in array because of how they come from api, like '5061,5103', as an array stringified
    this.setState(
      ({ filter }) => ({ filter: { ...filter, [name]: parsedValue } }),
      () => {
        this.fetchPlans();
      },
    );
  };

  handleTabClick = tab => this.setState({ activeTab: tab.label });

  handleDisplayChange = displayNumber => {
    const displayView = displayNumber === 1 ? 'grid' : 'list';
    this.setState({ displayView });
  };

  openPlanner = id => () => {
    this.props.history.push(`planner/${id}`);
  };

  openPlannerDialog = ({ idPlan, idTeacher }) => async () => {
    await this.props.getPlanner({
      idPlan,
      idTeacher,
      period: 'bimonthly',
    });
    this.showModal('planner')();
  };

  showModal = modalName => () => {
    this.setState(({ showModal }) => ({ showModal: { ...showModal, [modalName]: true } }));
  };

  closeModal = modalName => () => {
    this.setState(({ showModal }) => ({ showModal: { ...showModal, [modalName]: false } }));
  };

  handleSubmitRegisterSchool = async values => {
    await this.props.registerSchool(values);
    this.closeModal('registerSchool')();
    this.forceUpdate();
  };

  render() {
    const { activeTab, displayView, showModal } = this.state;

    const {
      planner,
      intl,
      plans,
      years,
      teachersPlans,
      disciplinesFilter,
      disciplinesFilterLabel,
      levelsFilter,
      levelsFilterLabel,
      teachersFilter,
      teachersDisciplinesFilter,
      teachersDisciplinesFilterLabel,
      teachersLevelsFilter,
      teachersLevelsFilterLabel,
      role,
      countries,
      idSchool,
    } = this.props;

    const plansFiltered = plans.filter(p => p.year === activeTab);
    const teacherPlansFiltered = teachersPlans.filter(p => p.year === activeTab);

    const modalButtons = [
      {
        label: <FormattedMessage {...messages.close} />,
        onClick: this.closeModal('planner'),
      },
      {
        id: 2,
        label: <FormattedMessage {...messages.print} />,
        onClick: printHelper.printElem,
        primary: true,
      },
    ];

    // const modalClose = [
    //   {
    //     label: <FormattedMessage {...messages.close} />,
    //     onClick: this.closeModal('planner'),
    //   }
    // ];

    return (
      <div>
        <Row className="align-items-center margin-bottom">
          <Col className="col-auto">
            <FormattedMessage {...messages.title}>
              {txt => <h1 className="page-title">{txt}</h1>}
            </FormattedMessage>
          </Col>
          <Col className="col-8">
            <Row>
              <Col className="col-7">
                <Select
                  options={disciplinesFilter}
                  itemValue="id"
                  containerClassName="mt-0"
                  itemLabel="name"
                  selectLabel={disciplinesFilterLabel}
                  onChange={this.handleChangeFilter('idDisciplines')}
                />
              </Col>
              <Col className="col-5">
                <Select
                  options={levelsFilter}
                  containerClassName="mt-0"
                  itemValue="id"
                  itemLabel="name"
                  selectLabel={levelsFilterLabel}
                  onChange={this.handleChangeFilter('idLevels')}
                />
              </Col>
            </Row>
          </Col>
          <Col className="col-auto ml-auto">
            <SortBar onChange={this.handleDisplayChange} />
          </Col>
        </Row>
        {/* <Navtabs
          onClick={this.handleTabClick}
          tabs={years.map(year => ({
            key: year,
            label: year,
            active: activeTab === year,
          }))}
        /> */}
        <div className={displayView === 'grid' ? 'grid-3' : 'plans__list'}>
          {plans.map(plan => (
            <Card
              key={plan.id}
              type="diary"
              displayView={displayView}
              title={plan.title}
              abbreviation={plan.abbreviation}
              icon={plan.icon}
              description={plan.description}
              actionLabel={plan.saved ? intl.formatMessage(messages.saved) : plan.actionLabel}
              actionClass={plan.actionClass}
              status={plan.status}
              mainColor="#88B2D6"
              fontColor="#ffffff"
              editable
              onClick={this.openPlanner(plan.id)}
              onEdit={this.openPlanner(plan.id)}
            />
          ))}
        </div>
        {isFilterAdminAllowed(role) && (
          <div>
            <hr className="mt-5" />
            <Row className="align-items-center margin-bottom mt-5">
              <Col>
                <Row>
                  <Col className="col-3">
                    <Select
                      options={teachersFilter}
                      itemValue="id"
                      containerClassName="mt-0"
                      itemLabel="name"
                      selectLabel={intl.formatMessage(messages.teachersFilterLabel)}
                      onChange={this.handleChangeFilter('idTeachers')}
                    />
                  </Col>
                  <Col className="col-3">
                    <Select
                      options={teachersDisciplinesFilter}
                      itemValue="id"
                      containerClassName="mt-0"
                      itemLabel="name"
                      selectLabel={teachersDisciplinesFilterLabel}
                      onChange={this.handleChangeFilter('idTeachersDisciplines')}
                    />
                  </Col>
                  <Col className="col-3">
                    <Select
                      options={teachersLevelsFilter}
                      containerClassName="mt-0"
                      itemValue="id"
                      itemLabel="name"
                      selectLabel={teachersLevelsFilterLabel}
                      onChange={this.handleChangeFilter('idTeachersLevels')}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        )}
        <div className={displayView === 'grid' ? 'grid-3' : 'plans__list'}>
          {teachersPlans.map(plan => (
            <Card
              key={plan.id}
              type="diary"
              displayView={displayView}
              title={plan.title}
              abbreviation={plan.abbreviation}
              icon={plan.icon}
              teacherName={plan.teacherName}
              description={plan.description}
              actionClass={plan.actionClass}
              status={plan.status}
              mainColor="#88B2D6"
              fontColor="#ffffff"
              editable={false}
              onClick={this.openPlannerDialog(plan)}
            />
          ))}
        </div>
        {planner && (
          <Modal
            show={showModal.planner}
            onClose={this.closeModal('planner')}
            fullscreen
            large
            buttons={modalButtons}
          >
            <Table
              preview
              ref={this.table}
              columns={planner.table.columns}
              idPlan={this.props.planner ? this.props.planner.id : undefined}
            />
          </Modal>
        )}
        {!idSchool && (
          <Modal
            show={showModal.registerSchool}
            onClose={this.closeModal('registerSchool')}
            large
            title={intl.formatMessage(messages.needRegisterSchoolTitle)}
            // buttons={modalClose}
          >
            <h6>{intl.formatMessage(messages.needRegisterSchool)}</h6>
            <br />
            <RegisterContainer
              onlyRegisterSchool={this.state.onlyRegisterSchool}
              handleSubmitRegisterSchool={this.handleSubmitRegisterSchool}
            />
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ plans, app }) => ({
  ...plans,
  role: app.role,
  idSchool: app.user.idSchool,
});

export default compose(
  withRouter,
  connect(mapStateToProps, promisify({ getPlans, getPlanner, registerSchool })),
  injectIntl,
)(PlansContainer);
