// @flow

import { action } from 'app/actions';

export const LOGIN = 'login/LOGIN';
export const LOGOUT = 'login/LOGOUT';
export const AUTH = 'login/AUTH';
export const VERIFY_EXISTENCE_PLANS_SCHEDULED = 'login/VERIFY_EXISTENCE_PLANS_SCHEDULED';
export const COUNTRIES = 'login/COUNTRIES';
export const FORGOT_PASSWORD = 'login/FORGOT_PASSWORD';
export const VERIFY_PERMISSION = 'login/VERIFY_PERMISSION';
export const MANAGE_COOKIES = 'footer/MANAGE_COOKIES';
export const CHANGE_PASSWORD_LINK = 'login/CHANGE_PASSWORD_LINK';
export const MANAGE_USER_TERMS = 'user/MANAGE_USER_TERMS';

export const auth = () => action(AUTH); // use cookie
export const externalAuth = payload => action(AUTH, { payload });
export const login = (payload: Object) => action(LOGIN, { payload: { ...payload, idAccess: 2 } });
export const test = payload => action('TEST', { payload });
export const logout = () => action(LOGOUT);
export const getCountries = payload => action(COUNTRIES, { payload });
export const forgotPassword = payload => action(FORGOT_PASSWORD, { payload });
export const verifyPermission = payload => action(VERIFY_PERMISSION, { payload });
export const manageCookies = payload => action(MANAGE_COOKIES, { payload });
export const changePasswordLink = payload => action(CHANGE_PASSWORD_LINK, {payload});
export const manageUserTerms = payload => action(MANAGE_USER_TERMS, {payload});