import React from 'react';
import PropTypes from 'prop-types';
// import { Popover, OverlayTrigger } from 'react-bootstrap';

// DiaryCard

import StatusExtended from './StatusExtended';

import './Card.scss';

const Card = ({
  type,
  displayView,
  children,
  title,
  abbreviation,
  icon,
  teacherName,
  description,
  status,
  statusExtended,
  statusComponent,
  label,
  labelIcon,
  actionLabel,
  actionClass,
  relatedClass,
  handleAction,
  address,
  editLabel,
  onEdit,
  mainColor,
  fontColor,
  collapsed,
  collapsedShow,
  expanded,
  groupName,
  // Booleans
  editable,
  onClick,
}) => {
  const splitTitle = title.split(' ');
  let fontIcon = '';
  abbreviation
    ? (fontIcon = abbreviation)
    : splitTitle.length > 0 &&
      splitTitle[0] &&
      splitTitle[1] &&
      (fontIcon = splitTitle[0].substr(0, 1) + splitTitle[1].substr(0, 1));
  return (
    <div className="card card-school">
      {!type && <div className="card-body">{children}</div>}
      {type === 'school' && (
        <School
          title={title}
          displayView={displayView}
          teacherName={teacherName}
          description={description}
          label={label}
          labelIcon={labelIcon}
          actionLabel={actionLabel}
          actionClass={actionClass}
          relatedClass={relatedClass}
          handleAction={handleAction}
          address={address}
          editLabel={editLabel}
          onEdit={onEdit}
          editable={editable}
          onClick={onClick}
        />
      )}
      {type === 'diary' && (
        <Diary
          title={title}
          displayView={displayView}
          teacherName={teacherName}
          groupName={groupName}
          description={description}
          icon={icon}
          status={status}
          statusExtended={statusExtended}
          statusComponent={statusComponent}
          actionLabel={actionLabel}
          actionClass={actionClass}
          handleAction={handleAction}
          relatedClass={relatedClass}
          editLabel={editLabel}
          onEdit={onEdit}
          editable={editable}
          mainColor={mainColor}
          fontColor={fontColor}
          fontIcon={fontIcon}
          onClick={onClick}
          collapsed={collapsed}
          collapsedShow={collapsedShow}
          expanded={expanded}
        />
      )}
    </div>
  );
};

const School = ({
  title,
  // displayView,
  teacherName,
  description,
  label,
  labelIcon,
  actionLabel,
  handleAction,
  address,
  editLabel,
  onEdit,
  editable,
  onClick,
  relatedClass,
}) => (
  <div className="card-body link" onClick={onClick}>
    <i className="card-icon pe-7s-home" />
    <h2 className="card-title" style={{ cursor: 'pointer' }}>
      {title}
    </h2>
    <p className="card-description">{description}</p>
    <p className="card-description">{teacherName}</p>
    {label && (
      <div className="cod">
        {label}
        {labelIcon && <i className={labelIcon} />}
      </div>
    )}
    {actionLabel && (
      <button className="btn btn-sm btn-outline-success" onClick={handleAction}>
        {actionLabel}
      </button>
    )}
    {relatedClass && relatedClass}
    {address && (
      <p className="card-bottom-text">
        <strong>Endereço:</strong>
        <br />
        {address}
        <br />
        <br />
      </p>
    )}
    {editable && (
     <button className="edit-card float-right" onClick={onEdit}>
        <i className="ti-pencil-alt" />
        {editLabel}
      </button>
    )}
  </div>
);

const Diary = ({
  title,
  displayView,
  teacherName,
  description,
  icon,
  status,
  statusExtended,
  statusComponent,
  groupName,
  actionLabel,
  actionClass,
  handleAction,
  fontIcon,
  editLabel,
  onEdit,
  editable,
  mainColor,
  fontColor,
  onClick,
  relatedClass,
}) => (
  <div className={`card-body link ${displayView === 'grid' ? '' : 'card-list'}`}>
    {status && (
      <div className="fixed-actions">
        <a data-tip data-for="status">
          <i className="ti-info-alt" />
          <br />
          Status
        </a>
        <ReactTooltip id="status">
          <span>{status}</span>
        </ReactTooltip>
      </div>
    )}
    {statusExtended && statusComponent()}
    {editable && (
      <button className="edit-card float-right" onClick={onEdit}>
        <i className="ti-pencil-alt" />
        {editLabel}
      </button>
    )}
    <div onClick={onClick}>
      <div className="initials" style={{ backgroundColor: mainColor, color: fontColor }}>
        {icon ? <i className={icon} /> : fontIcon}
      </div>
      <div className={displayView === 'grid' ? '' : 'display-list'}>
        <h5 className="card-title my-3" style={{ color: mainColor, cursor: 'pointer' }}>
          {title}
        </h5>
        <p>{description}</p>
        <p>{groupName}</p>
        <p>{teacherName}</p>
        <p style={{ color: mainColor }}>{relatedClass}</p>
        <div className={displayView === 'grid' ? '' : 'action-list'} onClick={handleAction}>
          {actionLabel && (
            <span
              className={`btn btn-sm btn-outline-${actionClass}`}
              style={handleAction === undefined ? { cursor: 'unset' } : {}}
            >
              {actionLabel}
            </span>
          )}
        </div>
      </div>
    </div>
  </div>
);

Card.propTypes = {
  type: PropTypes.string,
  displayView: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string,
  abbreviation: PropTypes.string,
  icon: PropTypes.string,
  teacherName: PropTypes.string,
  description: PropTypes.string,
  status: PropTypes.string,
  statusExtended: PropTypes.string,
  statusComponent: PropTypes.func,
  label: PropTypes.string,
  labelIcon: PropTypes.string,
  actionLabel: PropTypes.string,
  actionClass: PropTypes.string,
  relatedClass: PropTypes.string,
  handleAction: PropTypes.func,
  address: PropTypes.string,
  editLabel: PropTypes.string,
  onEdit: PropTypes.func,
  mainColor: PropTypes.string,
  fontColor: PropTypes.string,
  onClick: PropTypes.func,
  collapsed: PropTypes.string,
  collapsedShow: PropTypes.string,
  expanded: PropTypes.bool,
  // Booleans
  editable: PropTypes.bool,
  canEdit: PropTypes.bool,
};

Card.defaultProps = {
  type: '',
  displayView: 'grid',
  children: '',
  title: '',
  abbreviation: undefined,
  icon: undefined,
  teacherName: '',
  description: '',
  status: '',
  statusExtended: undefined,
  statusComponent: () => <StatusExtended />,
  label: '',
  labelIcon: '',
  actionLabel: '',
  relatedClass: '',
  actionClass: 'success',
  handleAction: undefined,
  address: '',
  editLabel: 'Editar',
  onEdit: undefined,
  mainColor: '',
  fontColor: '',
  onClick: () => {},
  collapsed: '',
  collapsedShow: '',
  expanded: false,
  // Booleans
  editable: false,
  canEdit: false,
};

School.propTypes = {
  title: PropTypes.string.isRequired,
  teacherName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  labelIcon: PropTypes.string.isRequired,
  actionLabel: PropTypes.string.isRequired,
  relatedClass: PropTypes.string.isRequired,
  handleAction: PropTypes.func,
  address: PropTypes.string.isRequired,
  editLabel: PropTypes.string.isRequired,
  onEdit: PropTypes.func,
  onClick: PropTypes.func,
  // Booleans
  editable: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
};

School.defaultProps = {
  handleAction: null,
  onEdit: null,
  onClick: () => {},
};

Diary.propTypes = {
  title: PropTypes.string.isRequired,
  displayView: PropTypes.string.isRequired,
  teacherName: PropTypes.string,
  description: PropTypes.string.isRequired,
  icon: PropTypes.string,
  status: PropTypes.string.isRequired,
  statusExtended: PropTypes.bool.isRequired,
  actionLabel: PropTypes.string.isRequired,
  actionClass: PropTypes.string.isRequired,
  relatedClass: PropTypes.string.isRequired,
  handleAction: PropTypes.func,
  editLabel: PropTypes.string.isRequired,
  onEdit: PropTypes.func,
  mainColor: PropTypes.string.isRequired,
  fontColor: PropTypes.string.isRequired,
  fontIcon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  groupName: PropTypes.string,
  // Booleans
  editable: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
};

Diary.defaultProps = {
  groupName: undefined,
  teacherName: undefined,
  handleAction: null,
  onEdit: null,
  icon: undefined,
  onClick: () => {},
};

export default Card;
