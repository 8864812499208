import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'calendarAddEventModal.title',
    defaultMessage: 'Add',
  },
  addAssessment: {
    id: 'calendarAddEventModal.addAssessment',
    defaultMessage: 'Add Assessment',
  },
  addEvent: {
    id: 'calendarAddEventModal.addEvent',
    defaultMessage: 'Add Event',
  },
  cancelButton: {
    id: 'calendarAddEventModal.button.cancel',
    defaultMessage: 'Cancel',
  },
  continueButton: {
    id: 'calendarAddEventModal.button.continue',
    defaultMessage: 'Continue',
  },
  message: {
    id: 'calendarAddEventModal.message',
    defaultMessage: 'What do you like to add?',
  },
  optionAssessment: {
    id: 'calendarAddEventModal.option.assessment',
    defaultMessage: 'Assessment',
  },
  optionEvent: {
    id: 'calendarAddEventModal.option.event',
    defaultMessage: 'Event',
  },
  optionSuperlesson: {
    id: 'calendarAddEventModal.option.superlesson',
    defaultMessage: 'Super lesson',
  },
  optionScheduleAndBind: {
    id: 'calendarAddEventModal.option.scheduleAndBind',
    defaultMessage: 'Schedule and bind assessment since Simplifica',
  },
  optionScheduleAndCreate: {
    id: 'calendarAddEventModal.option.scheduleAndCreate',
    defaultMessage: 'Schedule and create assessment in Simplifica',
  },
  selectLabel: {
    id: 'calendarAddEventModal.selectLabel',
    defaultMessage: 'Select...',
  },
  eventName: {
    id: 'calendarAddEventModal.eventName',
    defaultMessage: 'Event name...',
  },
  assessmentName: {
    id: 'calendarAddEventModal.assessmentName',
    defaultMessage: 'Assessment name...',
  },
  superlessonDate: {
    id: 'calendarAddEventModal.superlessonDate',
    defaultMessage: 'Super lesson date',
  },
  superlessonTitle: {
    id: 'calendarAddEventModal.superlessonTitle',
    defaultMessage: 'Add Super lesson',
  },
  superlessonName: {
    id: 'calendarAddEventModal.superlessonName',
    defaultMessage: 'Super lesson name...',
  },
  eventDate: {
    id: 'calendarAddEventModal.eventDate',
    defaultMessage: 'Event date',
  },
  assessmentDate: {
    id: 'calendarAddEventModal.assessmentDate',
    defaultMessage: 'Assessment date',
  },
  fullTime: {
    id: 'calendarAddEventModal.fullTime',
    defaultMessage: 'Full time',
  },
  describeEvent: {
    id: 'calendarAddEventModal.describeEvent',
    defaultMessage: 'Describe the event...',
  },
  selectClassesLabel: {
    id: 'calendarAddEventModal.selectClassesLabel',
    defaultMessage: 'Which classes will be ocupied?',
  },
  reassignModalAutomaticReassignment: {
    id: 'calendarAddEventModal.automaticReassignment',
    defaultMessage: 'Automatic reassignment',
  },
});
