// @flow

import axios from 'axios';

const defaultOptions = { withCredentials: true };

function createOptions(headers) {
  if (process.env.NODE_ENV !== 'development' || !headers) {
    return {};
  }
  const token = localStorage.getItem('X-Auth-Token');
  const cookieToken = localStorage.getItem('X-Token');
  if (token && cookieToken) {
    return {
      headers: {
        'X-Auth-Token': token,
        'X-Token': cookieToken,
      },
    };
  }
  return {};
}

const devOptions = process.env.NODE_ENV === 'development' ? {} : {};

// process.env.NODE_TLS_REJECT_UNAUTHORIZED = 0;


export const createApi = (url: string, options: any) =>
  axios.create({
    baseURL: url,
    ...defaultOptions,
    ...options,
    ...devOptions,
  });

// console.log('SM_API_NODE', process.env.SM_API_NODE_URL);
// $FlowFixMe
export const nodeApi = createApi(process.env.SM_API_NODE_URL, createOptions(true));
// $FlowFixMe
export const authApi = createApi(process.env.SM_API_NODE_URL, createOptions(true));
// $FlowFixMe
export const api = createApi(process.env.SM_API_URL, { withCredentials: false });
// $FlowFixMe
export const apiCi = createApi(process.env.SM_API_CI_URL);

// if (process.env.NODE_ENV === 'development') {
//   api.defaults.headers.common['X-Auth-Token'] =
//     'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.W3siaWRVc2VyIjo2OTg2MSwiaWRUeXBlIjozLCJmaXJzdE5hbWUiOiJPc2NhciBJdmFuIiwibGFzdE5hbWUiOiJHYW1leiBIZXJuYW5kZXoiLCJlbWFpbCI6Im9zY2FyLmdhbWV6QGdydXBvLXNtLmNvbSIsInBhc3N3b3JkIjpudWxsLCJzaGFyZU15UXVlc3Rpb25zIjowLCJwaG90byI6bnVsbCwidGltZXpvbmUiOiJBbWVyaWNhL0JvZ290YSIsImlkQ291bnRyeSI6MTUsImFiYnJldmlhdGlvbiI6IkNPIiwibGFuZ3VhZ2UiOiJlcyIsImNvdW50cnlOYW1lIjoiQ29sb21iaWEiLCJpZFNjaG9vbCI6MjI3NjUsInNjaG9vbE5hbWUiOiJDSVVEQUQgRVNDT0xBUiBERSBDT01GRU5BTENPIn1d.W22-DaX_N1F47eeTC-gkVEGhqhRG3H2zBGvVyPccxZo';
// }
