/* eslint-disable */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'sm-ui';

import messages from '../plannerMessages';

const ModalOriginal = ({ isOpen, isLoading, onClose, onConfirm }) => (
  <Modal
    title={<FormattedMessage {...messages.modalOriginalTitle} />}
    message={<FormattedMessage {...messages.modalOriginalMessage} />}
    icon={'pe-7s-refresh'}
    show={isOpen}
    onClose={onClose}
    buttons={[
      {
        id: 1,
        label: <FormattedMessage {...messages.cancel} />,
        onClick: () => onClose(false),
      },
      {
        id: 2,
        label: <FormattedMessage {...messages.confirm} />,
        primary: true,
        onClick: () => {
          isLoading(true);
          onConfirm();
          setTimeout(() => {
            isLoading(false);
            onClose(false);
          }, 1000);
        },
      },
    ]}
  />
);

export default ModalOriginal;
