import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'plans.title',
    defaultMessage: 'Plans',
  },
  teachersFilterLabel: {
    id: 'plans.teachersFilterLabel',
    defaultMessage: 'Teachers',
  },
  saved: {
    id: 'plans.saved',
    defaultMessage: 'Saved',
  },
  close: {
    id: 'plans.close',
    defaultMessage: 'Close',
  },
  print: {
    id: 'plans.print',
    defaultMessage: 'Print',
  },
  needRegisterSchool: {
    id: 'plans.needRegisterSchool',
    defaultMessage: 'First you need to register a school',
  },
  needRegisterSchoolTitle: {
    id: 'plans.needRegisterSchoolTitle',
    defaultMessage: 'Register School',
  },
});
