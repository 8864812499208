import React, { Component, useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';
import { getUserAvatarUrl } from 'app/appHelpers';
import placeholderSrc from 'common/placeholder-avatar.svg';

import { Input, AvatarInput, Loader } from 'sm-ui';
import messages from './configurationMessages';
import './ConfigurationForm.scss'

const shapeConfigurationSchema = {
  firstname: Yup.string(),
  lastname: Yup.string(),
  email: Yup.string(),
  oldPassword: Yup.string(),
  newPassword: Yup.string().matches(
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    "errorStrong"),
  confirmNewPassword: Yup.string().oneOf([Yup.ref("newPassword"), null], "errorDontMatch"),
  school: Yup.string(),
};

const ConfigurationSchema = Yup.object().shape( shapeConfigurationSchema );

const initialValues = {
  firstname: "",
  lastname: "",
  email: "",
  oldPassword: "",
  newPassword: "",
  confirmNewPassword: "",
  schoolName: "",
};

const enhanceForm = withFormik({
  mapPropsToValues: ({ values }) => ({ ...values }),
  initialValues,
  validationSchema: ConfigurationSchema,
  handleSubmit: (values, { props }) => {
    const payload = { ...values, idAccess: 7, idUser: props.user.idUser };
    props.onSubmit(payload);
  },
  enableReinitialize: true,
});

function fileToPayload(file, idUser) {
  const formData = new FormData();
  formData.append('idUser', idUser);
  formData.append('file', file);
  return formData;
}

const ConfigurationForm = ({
  values,
  errors, 
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  handleSubmit,
  onUpload,
  onRemove,
  user,
  isLoading,
  userInfo,
  userInfoStatus
}) => {
  const [editForm, setEditForm] = useState(false);
  const [userCI, setUserCI] = useState(user.mdlUserGuid ? true : false);

  function handleEditForm() {
    setEditForm(true);
  };

  function handleCancelForm() {
    setEditForm(false);
    handleInputValues();
  };
  
  function handleInputValues() {
    setFieldValue("firstname", user.firstName);
    setFieldValue("lastname", user.lastName);
    setFieldValue("email", user.email);
    setFieldValue("schoolName", user.schoolName);
  };

  useEffect(() => { handleInputValues() }, []);

  useEffect(() => {
    if(userInfoStatus === 200) {
      setTimeout(() => {
        window.location.reload(false);
      }, 1000);
    }
  }, [userInfoStatus]);
  
  return (
  <div>
    <Loader show={isLoading} />
    <div className="row">
      <div className="col-sm-3">
        {values.photo && <button class="photo-btn-close pe-7s-close" onClick={onRemove}></button>}
        <FormattedMessage {...messages.updatePhoto}>
          {txt => (
            <AvatarInput
              src={getUserAvatarUrl(values.photo)}
              placeholderSrc={placeholderSrc}
              label={txt}
              id="avatar"
              name="avatar"
              alt={values.name}
              onChange={file => onUpload(fileToPayload(file, values.idUser))}
            />
          )}
        </FormattedMessage>
      </div>
      <div className="col-sm-9">
        <FormattedMessage {...messages.myData}>
          {txt => (
            <h2 className="section-title">
              <i className="pe-7s-tools" />
              {txt}
            </h2>
          )}
        </FormattedMessage>
        <form onSubmit={handleSubmit}> 
          {userInfoStatus === 200 && <div className="boxMsg alert alert-success"><FormattedMessage {...messages.confirmedEditConfig} /></div>}
            {userInfoStatus && userInfoStatus !== 200 && 
              <div className="boxMsg alert alert-danger">
                { userInfoStatus
                  ? <FormattedMessage {...messages[`error${userInfoStatus}`]} />
                  : <FormattedMessage {...messages.error503 } />
                }
              </div>
            }
          <FormattedMessage {...messages.firstName}>
            {txt => (
              <Fragment>
                <Input
                  id="firstname"
                  name="firstname"
                  label={txt}
                  disabled={!editForm || userCI}
                  defaultValue={user.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Fragment>
              
            )}
          </FormattedMessage>
          <FormattedMessage {...messages.lastName}>
            {txt => (
              <Fragment>
                <Input
                  id="lastname"
                  name="lastname"
                  label={txt}
                  disabled={!editForm || userCI}
                  defaultValue={user.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Fragment>
              
            )}
          </FormattedMessage>
          <FormattedMessage {...messages.email}>
            {txt => (
              <Input
                id="email"
                name="email"
                defaultValue={user.email}
                type="email"
                disabled
                label={txt}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            )}
          </FormattedMessage>
          <FormattedMessage {...messages.olderPassword}>
            {txt => (
              <Fragment>
                <Input
                  id="oldPassword"
                  name="oldPassword"
                  type={"password"}
                  label={txt}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={!editForm || userCI}
                />
              </Fragment>
              
            )}
          </FormattedMessage>
          <div className="input-password">
            <FormattedMessage {...messages.newPassword}>
              {txt => (
                <Fragment>
                  <Input
                    id="newPassword"
                    name="newPassword"
                    type={"password"}
                    label={txt}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={!editForm || userCI}
                  />
                  {editForm && errors.newPassword === 'errorNotEqual' && touched.newPassword && <PassNotEqual />}
                  {editForm && errors.newPassword === 'errorStrong' && touched.newPassword && <PassStrongField />}
                </Fragment>
                
              )}
            </FormattedMessage>
          </div>
          <div class="card2 card-password">
            <FormattedMessage {...messages.passCriterio} />
            <ul>
              <li><FormattedMessage {...messages.caracterNumber} /></li>
              <li><FormattedMessage {...messages.letterUppercase} /></li>
              <li><FormattedMessage {...messages.letterLowercase} /></li>
              <li><FormattedMessage {...messages.number} /></li>
              <li><FormattedMessage {...messages.specialCaracter} /> {` ' ~ ! @ # $ % ^ & * () _ + - = {} | \\ : " ; ' < > ? , . / )`}</li>
            </ul>
          </div>

          <FormattedMessage {...messages.confirmNewPassword}>
            {txt => (
              <Fragment>
                <Input
                  id="confirmNewPassword"
                  name="confirmNewPassword"
                  type={"password"} 
                  label={txt}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={!editForm || userCI}
                />
                {editForm && errors.confirmNewPassword === 'errorDontMatch' && touched.newPassword && <ConfirmPassDontMatch />}
              </Fragment>
            )}
          </FormattedMessage>
          <div style={{ display: !editForm ? 'none' : 'block' }}>
            <FormattedMessage {...messages.save}>
              {txt => {
                return (
                  <button
                    className="btn btn-primary btn-lg btn-block"
                    type="submit"
                    style={{ marginTop: "20px" }}
                    setFieldValue={("firstname", "lastname", "email", "schoolName")}
                    value="submit"
                  >
                    {txt}
                  </button>
                );
              }}
            </FormattedMessage>
            <button
              className="btn btn-outline-primary btn-lg btn-block"
              type="button"
              onClick={handleCancelForm}
            >
              <FormattedMessage {...messages.cancel} />
            </button>
          </div>
        </form>
        <button
          className="btn btn-primary btn-lg btn-block"
          type="button"
          onClick={handleEditForm}
          style={{ display: editForm || userCI ? 'none' : 'block', marginTop: "20px"  }}
        >
          <FormattedMessage {...messages.edit} />
        </button>
      </div>
    </div>
  </div>
)};

function checkError({ errors, touched, field, type, editForm }) {
  if (errors[field] && touched[field] && editForm) {
    if (type === "border") {
      return { border: "solid 1px red" };
    }
    if (type === "label") {
      return { color: "red" };
    }
  }
  return {};
}

const ConfirmPassDontMatch = () => <div style={{ color: "red", fontSize: "10px" }}><FormattedMessage {...messages.confirmPassDontMatch} /></div>;

const PassStrongField = () => <div style={{ color: "red", fontSize: "10px" }}><FormattedMessage {...messages.passwordNotStrong} /></div>;

const PassNotEqual = () => <div style={{ color: "red", fontSize: "10px" }}><FormattedMessage {...messages.passwordNotEqual} /></div>;

ConfigurationForm.propTypes = {};

export default enhanceForm(ConfigurationForm);
