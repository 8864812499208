import React, { Fragment } from 'react';

function bodyClick() {
  function noop() {}
  const body = document.getElementsByTagName('body')[0];
  body.addEventListener('click', noop);
  body.click();
  body.removeEventListener('click', noop);
}

const CardMenuItem = ({ onClick, icon, label }) => (
  <a
    className="icon-link"
    role="button"
    tabIndex={0}
    onClick={e => {
      e.stopPropagation();
      e.preventDefault();
      bodyClick();
      if (onClick) {
        onClick(e);
      }
    }}
  >
    <Fragment>
      {icon}
      {label}
    </Fragment>
  </a>
);

export default CardMenuItem;
