import React from 'react';
import PropTypes from 'prop-types';

const Grid = ({ children, number }) => <div className={`grid-${number}`}>{children}</div>;

Grid.propTypes = {
  children: PropTypes.node.isRequired,
  number: PropTypes.string
};

Grid.defaultProps = {
  number: '3'
};

export default Grid;
