import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SM_GA_ID } from 'config/constants';

function gtag() {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(arguments);
}

function initAnalytics(user) {
  const { idUser, idType, idCountry, idSchool } = user;
  gtag('js', new Date());
  gtag('config', SM_GA_ID);
  gtag('set', {
    user: idUser,
    type: idType,
    country: idCountry,
    school: idSchool,
  });
}

function insertScript() {
  const gtagScript = document.createElement('script');
  gtagScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${SM_GA_ID}`);
  gtagScript.setAttribute('async', true);
  document.head.insertBefore(gtagScript, document.head.firstChild);
}

class Analytics extends Component {
  componentDidMount() {
    if (this.props.user) {
      initAnalytics(this.props.user);
      insertScript();
      gtag('event', 'sign_up');
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.user && prevProps.user !== this.props.user) {
      initAnalytics(this.props.user);
      gtag('event', 'sign_up');
    }
  }

  render() {
    return null;
  }
}

Analytics.propTypes = {
  user: PropTypes.shape({
    idUser: PropTypes.number,
    idType: PropTypes.number,
    idCountry: PropTypes.number,
    idSchool: PropTypes.number,
  }),
};

Analytics.defaultProps = {
  user: null,
};

const mapStateToProps = ({ app }) => ({ user: app.user });

export default connect(mapStateToProps)(Analytics);
