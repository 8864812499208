import React from 'react';
import PropTypes from 'prop-types';
import { TextFilter } from 'react-text-filter';

const actions = {
  init() {
    return {
      isOpen: false,
      selected: {},
      filter: '',
    };
  },
  showContent(isOpen) {
    return {
      isOpen,
    };
  },
  updateSelectedValue(selected) {
    return {
      selected,
    };
  },
  searchTerms(filter) {
    return {
      filter,
    };
  },
};

const helpers = {
  filter: filter => el => el.text.toLowerCase().indexOf(filter.toLowerCase()) !== -1,
};

class SelectWithFilter extends React.Component {
  state = actions.init();

  componentDidUpdate(prevProps) {
    const nextProps = this.props;

    if (prevProps.data.length === 0 && nextProps.data.length > 0) {
      this.props.data.map(el => el.selected && this.handleSelectValue(el));
    }
  }

  onSearch = ({ target: { value: filter } }) => this.setState(actions.searchTerms(filter));

  onSelectValue = selected => {
    this.handleSelectValue(selected);
    this.handleHideContent();
    this.props.onSelect(selected);
  };

  handleSelectValue = selected => this.setState(actions.updateSelectedValue(selected));

  handleHideContent = () => this.setState(actions.showContent(false));

  handleShowContent = () => {
    const { isOpen } = this.state;
    this.setState(actions.showContent(!isOpen));
  };

  render() {
    const {
      label,
      selectLabel,
      data,
      searchPlaceholder,
      noDataText,
      closeOnLeave,
      textStyle,
      style,
      hideSearch
    } = this.props;
    const { isOpen, selected, filter } = this.state;

    const filteredData = filter ? data.filter(helpers.filter(filter)) : data.slice(0);

    return (
      <div onMouseLeave={closeOnLeave ? this.handleHideContent : undefined}>
        <div className="col" style={{ ...style }}>
          <span
            className={`select2 select2-container select2-container--default select2-container--below ${
              isOpen ? 'select2-container--open' : ''
            }`}
            onClick={this.handleShowContent}
          >
            <span className="selection">
              <span className="select2-selection select2-selection--single">
                <span className="select2-selection__rendered">{selected.text || label}</span>
                <span className="select2-selection__arrow" role="presentation">
                  <b role="presentation" />
                </span>
              </span>
            </span>
            <span className="dropdown-wrapper" aria-hidden="true" />
          </span>
          {isOpen && (
            <span
              className="select2-container select2-container--default select2-container--open"
              style={{ display: 'block' }}
            >
              <span className="select2-dropdown select2-dropdown--below">
                {hideSearch === false && (
                  <span className="select2-search select2-search--dropdown">
                    <TextFilter
                      className="select2-search__field"
                      placeholder={searchPlaceholder}
                      type="search"
                      onFilter={this.onSearch}
                    />
                  </span>
                )}
                <span className="select2-results">
                  <ul className="select2-results__options">
                    {selectLabel && (
                      <li
                        value=""
                        role="treeitem"
                        className="select2-results__option"
                        onClick={() => this.onSelectValue('')}
                        style={textStyle}
                      >
                        {selectLabel}
                      </li>
                    )}
                    {filteredData.map(el => (
                      <li
                        key={el.id}
                        value={el.value}
                        role="treeitem"
                        aria-selected={selected.value === el.value}
                        className={`select2-results__option ${
                          selected.value === el.value ? 'select2-results__option--highlighted' : ''
                        }`}
                        onClick={() => this.onSelectValue(el)}
                        style={textStyle}
                      >
                        {el.text}
                      </li>
                    ))}
                    {data.length === 0 && <span style={{ padding: '5px' }}>{noDataText}</span>}
                  </ul>
                </span>
              </span>
            </span>
          )}
        </div>
      </div>
    );
  }
}

SelectWithFilter.propTypes = {
  label: PropTypes.string,
  selectLabel: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  noDataText: PropTypes.string,
  onSelect: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.object),
  closeOnLeave: PropTypes.bool,
};

SelectWithFilter.defaultProps = {
  label: undefined,
  selectLabel: undefined,
  searchPlaceholder: '',
  noDataText: 'No content to show',
  onSelect: () => {},
  data: [],
  closeOnLeave: false,
};

export default SelectWithFilter;
