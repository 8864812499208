import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Checkbox extends Component {
  static getDerivedStateFromProps(props, state) {
    if (props.checked !== state.prevChecked) {
      return {
        checked: props.checked,
        prevChecked: props.checked,
      };
    }
    return null;
  }

  state = {};

  toggleCheck = e => {
    const { disabled } = this.props;
    if (disabled) return;
    const checked = !this.state.checked;
    this.setState({
      checked,
    });
    if (this.props.onChange) {
      e.target.checked = checked;
      this.props.onChange(e);
    }
  };

  render() {
    const { label, id, disabled, style } = this.props;
    const { checked } = this.state;
    return (
      <div className="text-left" style={style}>
        <input
          className="custom"
          id={id}
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={this.toggleCheck}
        />
        <label htmlFor={id} onClick={this.toggleCheck}>
          {label}
        </label>
      </div>
    );
  }
}

Checkbox.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.object,
};

Checkbox.defaultProps = {
  id: undefined,
  label: undefined,
  onChange: undefined,
  disabled: false,
  style: undefined,
};

export default Checkbox;
