import { defineMessages } from 'react-intl';

export default defineMessages({
  name: {
    id: 'classManagement.name',
    defaultMessage: 'Name',
  },
  schoolDays: {
    id: 'classManagement.schoolDays',
    defaultMessage: 'School days',
  },
  addGroup: {
    id: 'classManagement.addGroup',
    defaultMessage: 'Add Group',
  },
  newGroup: {
    id: 'classManagement.newGroup',
    defaultMessage: 'New Group',
  },
  delete: {
    id: 'classManagement.delete',
    defaultMessage: 'Delete',
  },
  cancel: {
    id: 'classManagement.cancel',
    defaultMessage: 'Cancel',
  },
  back: {
    id: 'classManagement.back',
    defaultMessage: 'back',
  },
  continue: {
    id: 'classManagement.continue',
    defaultMessage: 'Continue',
  },
  hasErrorCourse: {
    id: 'classManagement.hasErrorCourse',
    defaultMessage: 'Please select at least one group.',
  },
  hasErrorHour: {
    id: 'classManagement.hasErrorHour',
    defaultMessage: 'Please select at least one hour.',
  },
  deleteGroup: {
    id: 'classManagement.deleteGroup',
    defaultMessage: 'Delete Group',
  },
  deleteGroupMessage: {
    id: 'classManagement.deleteGroupMessage',
    defaultMessage: 'Are you sure you want to delete this group?',
  },
  sucessAddGroup: {
    id: 'classManagement.sucessAddGroup',
    defaultMessage: 'Group added with sucess',
  },
  sucessEditGroup: {
    id: 'classManagement.sucessEditGroup',
    defaultMessage: 'Group updated with sucess',
  },
  sucessDeleteGroup: {
    id: 'classManagement.sucessDeleteGroup',
    defaultMessage: 'Group deleted with sucess',
  },
  day01: {
    id: 'classManagement.day00',
    defaultMessage: 'Sun',
  },
  day02: {
    id: 'classManagement.day01',
    defaultMessage: 'Mon',
  },
  day03: {
    id: 'classManagement.day02',
    defaultMessage: 'Tue',
  },
  day04: {
    id: 'classManagement.day03',
    defaultMessage: 'Wed',
  },
  day05: {
    id: 'classManagement.day04',
    defaultMessage: 'Thu',
  },
  day06: {
    id: 'classManagement.day05',
    defaultMessage: 'Fri',
  },
  day07: {
    id: 'classManagement.day06',
    defaultMessage: 'Sat',
  },
  errorCode500: {
    id: 'classManagement.errorCode.500',
    defaultMessage: 'An error occurred. Please try again later.',
  },
  errorCode12: {
    id: 'classManagement.errorCode.12',
    defaultMessage:
      'This plan requires more classes than is available. This plan has {planLessonsCount} classes and the group has {teacherLessonsCount} classes available.',
  },
  errorCode14: {
    id: 'classManagement.errorCode.14',
    defaultMessage:
      'It is necessary to configure the school year information parameters. To do this, {link}',
  },
  errorCode15: {
    id: 'classManagement.errorCode.15',
    defaultMessage: 'A course with this name already exists',
  },
  errorCode19: {
    id: 'classManagement.errorCode.19',
    defaultMessage: 'You need to select at least one class to this group',
  },
  groupExists: {
    id: 'classManagement.groupExists',
    defaultMessage: 'A group with this name already exists.',
  },
  selectLabelSchool: {
    id: 'classManagement.selectLabelSchool',
    defaultMessage: 'Select a School',
  },
  errorCode14clickHere: {
    id: 'classManagement.errorCode.14.clickHere',
    defaultMessage: 'click Here',
  },
  hasErrorAddSchool: {
    id: 'classManagement.hasErrorAddSchool',
    defaultMessage: 'Please, add a school before this step!',
  },
  hasErrorSelectSchool: {
    id: 'classManagement.hasErrorSelectSchool',
    defaultMessage: 'Please, select a school!',
  },
  insertClassName: {
    id: 'classManagement.insertClassName',
    defaultMessage: 'Insert class name',
  },
  classScheduled: {
    id: 'classManagement.classScheduled',
    defaultMessage: 'This plan was already scheduled for this group',
  },
});
