import React from 'react';
import PropTypes from 'prop-types';

import './ReportBar.scss';

const ReportBar = props => (
  <div className="report-bar">
    <div className="header">
      <strong>{props.title}</strong>
    </div>
    <div className="bar-container">
      <div className="bar" style={{ width: `${props.percentage}%` }} />
      <span>{props.percentage}%</span>
    </div>
    <div className="zero">0</div>
  </div>
);

ReportBar.propTypes = {};

ReportBar.defaultProps = {
  percentage: 0,
  color: 0,
  index: 0,
};

export default ReportBar;
