import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Radio = ({ id, label, inline, ...props }) => (
  <div className={classNames('form-check', { 'form-check-inline': inline })}>
    <input className="form-check-input" type="radio" id={id} {...props} />
    {label && (
      <label className="form-check-label" htmlFor={id}>
        {label}
      </label>
    )}
  </div>
);

Radio.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  inline: PropTypes.bool,
};

Radio.defaultProps = {
  id: undefined,
  label: undefined,
  inline: false,
};

export default Radio;
