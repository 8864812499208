import "@babel/polyfill";
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import configureStore, { history } from 'config/configureStore';
import App from 'app/App';
import rootSaga from 'app/sagas';
import LanguageProvider from 'lang/LanguageProvider';
import { translationMessages } from 'lang/messages';
import registerServiceWorker from './registerServiceWorker';

// eslint-disable-next-line
import 'sm-ui/scss/main.scss';
import './index.scss';

const store = configureStore(window.__INITIAL_STATE__);
store.runSaga(rootSaga);

const MOUNT_NODE = document.getElementById('root');

ReactDOM.render(
  <Provider store={store}>
    <LanguageProvider messages={translationMessages}>
      <Router history={history}>
        <App />
      </Router>
    </LanguageProvider>
  </Provider>,
  MOUNT_NODE,
);

if (location.protocol === 'https:') {
  registerServiceWorker();
}
