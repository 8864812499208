// @flow
import { put, call, fork, all, take } from 'redux-saga/effects';
import { success, failure, request, action } from 'app/actions';
import * as loginSagas from 'login/loginSagas';
import * as schoolSagas from 'school/schoolSagas';
import * as registerSagas from 'register/registerSagas';
import * as plansSagas from 'plans/plansSagas';
import * as scheduleSagas from 'schedule/scheduleSagas';
import * as classManagementSagas from 'schedule/classManagement/classManagementSagas';
import * as plannerSagas from 'planner/plannerSagas';
import * as configurationSagas from 'configuration/configurationSagas';
import * as reportsSagas from 'reports/reportsSagas';
import * as changePasswordSagas from 'changePassword/changePasswordSagas';
import omit from 'lodash/omit';

/** Reusable generator for fetch entities using REQUEST, SUCCESS AND FAILURE
 * suffixes to identify transitions
 * */
export function* genericApiTask(
  prefix: string,
  apiFn: () => Promise<any>,
  apiUrl: string,
  apiArgs: any,
  actionArgs: any,
): Iterable<T> {
  try {
    const pureApiArgs = apiArgs instanceof FormData ? apiArgs : omit(apiArgs, ['resolve, reject']);
    const { resolve } = apiArgs || {};
    yield put(action(request(prefix), actionArgs));
    const { data } = yield call(apiFn, apiUrl, pureApiArgs);
    yield put(action(success(prefix), { ...actionArgs, payload: data }));
    if (resolve) {
      resolve({ data });
    }
    return { data };
  } catch (error) {
    yield put(
      action(failure(prefix), {
        ...actionArgs,
        error,
        payload: error && error.response ? error.response.data : {},
      }),
    );
    const { reject } = apiArgs || {};
    if (reject) {
      reject(error);
    }
    return { error };
  }
}

export function* genericWatchApiTask(actionType: string, task: Generator) {
  while (true) {
    const { payload } = yield take(actionType);
    yield fork(task, payload);
  }
}

// bind the generic generator
export function apiTask(actionType: string, apiFn: () => Promise<any>, apiUrl: string) {
  return genericApiTask.bind(null, actionType, apiFn, apiUrl);
}

export function watchApiTask(actionType: string, apiFn: () => Promise<any>, ...args: any) {
  const task = genericApiTask.bind(null, actionType, apiFn, ...args);
  return genericWatchApiTask.bind(null, actionType, task);
}

// eslint-disable-next-line
export default function* rootSaga(): Iterable<T> {
  yield all([
    fork(reportsSagas.sagas),
    fork(loginSagas.sagas),
    fork(schoolSagas.sagas),
    fork(configurationSagas.sagas),
    fork(classManagementSagas.sagas),
    fork(scheduleSagas.sagas),
    fork(registerSagas.sagas),
    fork(plansSagas.watchPlans),
    fork(plansSagas.watchGetPlanner),
    fork(plannerSagas.watchGetPlanner),
    fork(plannerSagas.watchDownloadFile),
    fork(plannerSagas.watchSavePlan),
    fork(plannerSagas.watchRevertPlanToOriginal),
    fork(plannerSagas.watchUpdatePlannerMetadata),
    fork(plannerSagas.watchUpdatePlannerCell),
    fork(plannerSagas.watchUpdatePlannerCellWithFile),
    fork(plannerSagas.watchAddColumn),
    fork(plannerSagas.watchAddRow),
    fork(plannerSagas.watchMoveColumn),
    fork(plannerSagas.watchMoveRow),
    fork(plannerSagas.watchRemoveColumn),
    fork(plannerSagas.watchRemoveRow),
    fork(changePasswordSagas.sagas),
  ]);
}
