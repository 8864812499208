import { success, FAILURE, REQUEST, extractPrefix, failure } from 'app/actions';
import { getError } from 'app/appHelpers';
import * as actions from './classManagementActions';
import omit from 'lodash/omit';
import get from 'lodash/get';

const initialState = {
  courses: [],
  coursesHours: [],
  error: {
    code: null,
    message: null,
  },
  schools: [],
  errorCourse: {},
  planDetails: {},
  plannerDefaultClasses: 6,
};

export const NEW_COURSE = {
  idCourse: 'NEW_COURSE',
};

function extractIdCourse(action) {
  return (
    get(action, 'meta.course.idCourse') ||
    get(action, 'payload.errorInfo.idCourse') ||
    get(action, 'payload.errorInfo[0].idCourse') ||
    get(action, 'payload.successInfo[0].idCourse') ||
    get(action, 'payload.successInfo.idCourse')
  );
}

const reducer = (state = initialState, action) => {
  switch (true) {
    case action.type.startsWith(actions.PREFIX) && action.type.endsWith(FAILURE):
      let idCourse = extractIdCourse(action);
      if (idCourse) {
        const actionTypePrefix = extractPrefix(action.type);
        return {
          ...state,
          errorCourse: {
            ...state.errorCourse,
            [idCourse]: {
              [actionTypePrefix]: getError(action),
            },
          },
        };
      }
      return { ...state, error: getError(action) };
    case action.type.startsWith(actions.PREFIX) && action.type.endsWith(REQUEST): {
      idCourse = extractIdCourse(action);
      if (idCourse) {
        const actionTypePrefix = extractPrefix(action.type);
        return {
          ...state,
          errorCourse: {
            ...state.errorCourse,
            [idCourse]: omit(state.errorCourse[idCourse], [actionTypePrefix]),
          },
        };
      }
      return { ...state, error: initialState.error };
    }
    default: {
      switch (action.type) {
        case actions.RESET_MODAL:
          return { ...state, error: initialState.error, errorCourse: initialState.errorCourse };
        case success(actions.GET_COURSES):
          return {
            ...state,
            schools: action.payload.schools,
          };
        case actions.ADD_NEW_COURSE:
          return { ...state, courses: state.courses.concat([{ ...NEW_COURSE }]) };
        case success(actions.GET_PLAN_COURSES_HOUR):
          return {
            ...state,
            courses: action.payload.courses,
            planDetails: { ...action.payload.planInfo, ...action.payload.planInfo.discipline },
            plannerDefaultClasses: action.payload.plannerDefaultClasses,
          };
        case success(actions.EDIT_COURSE):
          idCourse = extractIdCourse(action);
          if (idCourse) {
            const actionTypePrefix = extractPrefix(action.type);
            return {
              ...state,
              errorCourse: {
                ...state.errorCourse,
                [idCourse]: omit(state.errorCourse[idCourse], [actionTypePrefix]),
              },
            };
          }
          return { ...state, error: initialState.error };
        default:
          return state;
      }
    }
  }
};

export default reducer;
