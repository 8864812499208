import React, { Component } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import messages from './schoolMessages';
import SchoolCard from './SchoolCard';
import SchoolAddCard from './SchoolAddCard';
import SchoolAddModal from './SchoolAddModal';

import { Row, Col } from 'react-bootstrap';
import { getSchools, activateAccessCode, resetAddModalForm, addSchool } from './schoolActions';

class SchoolContainer extends Component {
  state = {
    showAddModal: false,
  };

  componentDidMount() {
    const {
      user: { idCountry },
    } = this.props;
    this.props.getSchools();
  }

  showAddModal = () => {
    this.setState({ showAddModal: true });
  };

  closeAddModal = () => {
    this.props.resetAddModalForm();
    this.setState({ showAddModal: false });
  };

  openSchool = ({ idUserSchool }) => {
    this.props.history.push(`/school/${idUserSchool}`);
  };

  addSchool = school => {
    const {
      user: { idUser },
    } = this.props;
    this.props.addSchool({ idUser, idSchool: school.id });
  };

  render() {
    const { showAddModal } = this.state;
    const { schools, role, codeActivated } = this.props;

    return (
      <div>
        <FormattedMessage {...messages.title}>
          {txt => <h1 className="page-title mb-5">{txt}</h1>}
        </FormattedMessage>
        <Row>
          <Col xs={12} md={4}>
            <SchoolAddCard onClick={this.showAddModal} />
          </Col>
          {schools.filter(s => s).map(school => (
            <Col xs={12} md={4}>
              <SchoolCard
                key={school.idUserSchool}
                {...school}
                onClick={this.openSchool}
                role={role}
              />
            </Col>
          ))}
        </Row>
        <SchoolAddModal
          show={showAddModal}
          onClose={this.closeAddModal}
          onActivateAccessCode={this.props.activateAccessCode}
          codeActivated={codeActivated}
          onRequestAddSchool={this.addSchool}
        />
      </div>
    );
  }
}

SchoolContainer.propTypes = {};

const mapStateToProps = ({ school, app }) => ({ ...school, role: app.role, user: app.user });

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    { getSchools, activateAccessCode, resetAddModalForm, addSchool },
  ),
)(SchoolContainer);
