/**
 * Watcher (see login/loginSagas for samples)
 */

import { fork, all, call, take } from 'redux-saga/effects';
import { apiTask, watchApiTask } from '../app/sagas';
import { api } from '../app/api';
import * as actions from './reportsActions';

export const getStatusPlanScheduled = apiTask(
  actions.GET_STATUS_PLAN_SCHEDULED,
  api.post,
  '/planner-plans/getStatusPlanScheduled.php',
);

export function* watchGetStatusPlansScheduled() {
  while (true) {
    const { payload, idPlan, idCourse } = yield take(actions.GET_STATUS_PLAN_SCHEDULED);
    yield fork(getStatusPlanScheduled, payload, { idPlan, idCourse });
  }
}

export const getPlansScheduled = apiTask(
  actions.GET_PLANS_SCHEDULED,
  api.post,
  '/planner-plans/getPlansScheduled.php',
);

export function* watchGetPlansScheduled() {
  while (true) {
    const { payload } = yield take(actions.GET_PLANS_SCHEDULED);
    yield fork(getPlansScheduled, payload);
  }
}

export function* sagas() {
  yield all([fork(watchGetStatusPlansScheduled), fork(watchGetPlansScheduled)]);
}
