// @styleguide

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from 'sm-ui';
import messages from './classManagementMessages';

const ClassManagementCoursesHeader = ({
  icon,
  abbreviation,
  name,
  catalogingPath,
  onRequestAddCourse,
}) => (
  <div className="row mb-3">
    <div className="col-6 text-left">
      <div className="initials-modal" style={{ backgroundColor: '#88B2D6' }}>
        {icon ? <i className={icon} /> : abbreviation}
      </div>
      <h5 className="card-title-modal my-3" style={{ color: '#88B2D6' }}>
        {name}
      </h5>
      <p className="card-text-modal">{catalogingPath}</p>
    </div>
    <div className="col-6 text-right">
      <Button color="primary" variant="outline" onClick={onRequestAddCourse}>
        <FormattedMessage {...messages.addGroup} />
      </Button>
    </div>
  </div>
);

ClassManagementCoursesHeader.propTypes = {};

export default ClassManagementCoursesHeader;
