import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './Select.scss';

function renderOptions({ options, itemValue, itemLabel }) {
  return options.map(o => (
    <option key={o[itemValue]} value={o[itemValue]}>
      {o[itemLabel]}
    </option>
  ));
}

function renderGroupOptions({ groupsOptions, groupLabel, groupValue, ...props }) {
  return groupsOptions.map(go => {
    const options = renderOptions({ ...props, options: go[groupValue] });
    return (
      <optgroup key={go[groupLabel]} label={go[groupLabel]}>
        {options}
      </optgroup>
    );
  });
}

const Select = props => {
  const {
    id,
    name,
    label,
    options,
    groupsOptions,
    containerClassName,
    selectLabel,
    labelClass,
    groupLabel,
    groupValue,
    selectClassName,
    optionClassName,
    itemValue,
    itemLabel,
    valid,
    validFeedback,
    invalidFeedback,
    ...rest
  } = props;
  return (
    <div className={classNames('form-group form-group-mob', containerClassName)}>
      {label && (
        <label
          className={classNames('form-control-label', labelClass, {
            'is-invalid': valid === false,
            'is-valid': valid === true,
          })}
          htmlFor={id}
        >
          {label}
        </label>
      )}
      <select
        className={classNames('form-control', selectClassName, {
          'is-invalid': valid === false,
          'is-valid': valid === true,
        })}
        id={id}
        name={name}
        {...rest}
      >
        {selectLabel && (
          <option className={optionClassName} value="">
            {selectLabel}
          </option>
        )}
        {renderOptions(props)}
        {renderGroupOptions(props)}
      </select>
      {valid === false && <div className="invalid-feedback">{invalidFeedback}</div>}
      {valid === true && <div className="valid-feedback">{validFeedback}</div>}
    </div>
  );
};

Select.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  selectLabel: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  groupsOptions: PropTypes.arrayOf(PropTypes.object),
  containerClass: PropTypes.string,
  labelClass: PropTypes.string,
  itemValue: PropTypes.string,
  itemLabel: PropTypes.string,
  groupValue: PropTypes.string,
  groupLabel: PropTypes.string,
  selectClass: PropTypes.string,
  optionClass: PropTypes.string,
  valid: PropTypes.oneOf([null, undefined, false, true]),
  validFeedback: PropTypes.string,
  invalidFeedback: PropTypes.string,
};

Select.defaultProps = {
  id: undefined,
  name: undefined,
  label: '',
  options: [],
  groupsOptions: [],
  containerClass: '',
  itemValue: 'value',
  itemLabel: 'label',
  groupLabel: 'label',
  groupValue: 'value',
  selectLabel: undefined,
  labelClass: '',
  selectClass: '',
  optionClass: '',
  valid: undefined,
  validFeedback: undefined,
  invalidFeedback: undefined,
};

export default Select;
