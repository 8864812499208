// @flow

import { apiTask } from 'app/sagas';
import { success } from 'app/actions';
import { authApi, api, apiCi } from 'app/api';
import { take, fork, all, takeEvery } from 'redux-saga/effects';
import * as actions from './changePasswordActions';


export const changePassword = apiTask(actions.CHANGE_PASSWORD, api.post, '/users/changePassword.php');


/**
 * Watch for every dispatch of LOGIN action and call the login task
 */

export function* watchChangePassword() {
  while (true) {
    const { payload } = yield take(actions.CHANGE_PASSWORD);
    yield fork(changePassword, payload);
  }
}

export function* sagas(): Iterable<T> {
  yield all([
    fork(watchChangePassword)
  ]);
}
