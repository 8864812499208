import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'configuration.title',
    defaultMessage: 'Configurations',
  },
  updatePhoto: {
    id: 'configuration.updatePhoto',
    defaultMessage: 'Update Photo',
  },
  firstName: {
    id: 'configuration.firstName',
    defaultMessage: 'First Name',
  },
  lastName: {
    id: 'configuration.lastName',
    defaultMessage: 'Last Name',
  },
  email: {
    id: 'configuration.email',
    defaultMessage: 'Email',
  },
  password: {
    id: 'configuration.password',
    defaultMessage: 'Password',
  },
  resetPassword: {
    id: 'configuration.resetPassword',
    defaultMessage: 'Reset password',
  },
  myData: {
    id: 'configuration.myData',
    defaultMessage: 'My data',
  },
  school: {
    id: 'configuration.school',
    defaultMessage: 'School',
  },
  errorRequired: {
    id: 'login.error.required',
    defaultMessage: 'Required field.',
  },
  errorInvalidEmail: {
    id: 'login.error.invalidEmail',
    defaultMessage: 'Invalid email.',
  },
  olderPassword: {
    id: "configuration.olderPassword",
    defaultMessage: "Older Password"
  },
  newPassword: {
    id: "configuration.newPassword",
    defaultMessage: "New Password"
  },
  confirmNewPassword: {
    id: "configuration.confirmNewPassword",
    defaultMessage: "Confirm New Password"
  },
  save: {
    id: "configuration.save",
    defaultMessage: "Save"
  },
  cancel: {
    id: "configuration.cancel",
    defaultMessage: "Cancel"
  },
  edit: {
    id: "configuration.edit",
    defaultMessage: "Edit"
  },
  passwordNotEqual: {
    id: "configuration.passwordNotEqual",
    defaultMessage: "Password cannot be the same as the previous one"
  },
  confirmedEditConfig: {
    id: "configuration.confirmedEditConfig",
    defaultMessage: "User info updated with success"
  },
  error44: {
    id: "configuration.error44",
    defaultMessage: "This user comes from CI"
  },
  error5: {
    id: "configuration.error5",
    defaultMessage: "User not found"
  },
  error48: {
    id: "configuration.error48",
    defaultMessage: "Older password dont match"
  },
  error503: {
    id: "configuration.error503",
    defaultMessage: "Service unavailable, try again later",
  },
  passCriterio: {
    id: "configuration.passCriterio",
    defaultMessage: "The minimum criteria for setting the password are:",
  },
  caracterNumber: {
    id: "configuration.caracterNumber",
    defaultMessage: "Minimum number of 8 characters",
  },
  letterUppercase: {
    id: "configuration.letterUppercase",
    defaultMessage: "Capital letters, from A to Z",
  },
  letterLowercase: {
    id: "configuration.letterLowercase",
    defaultMessage: "Lower case letters, from a to z",
  },
  number: {
    id: "configuration.number",
    defaultMessage: "Use of numeric characters from 0 to 9",
  },
  specialCaracter: {
    id: "configuration.specialCaracter",
    defaultMessage: "Use of special characters (for example, ",
  },
  confirmPassDontMatch: {
    id: "configuration.confirmPassDontMatch",
    defaultMessage: "Password not match",
  },
  passwordNotStrong: {
    id: "configuration.passwordDontMatch",
    defaultMessage: "The password does not meet the minimum requirements",
  },
});
