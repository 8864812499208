// @flow
/* eslint-disable */
import { combineReducers } from 'redux';
import { success } from 'app/actions';

import app from 'app/appReducer';
import lang from 'lang/langReducer';
import login from 'login/loginReducer';
import plans from 'plans/plansReducer';
import schedule from 'schedule/scheduleReducer';
import classManagement from 'schedule/classManagement/classManagementReducer';
import planner from 'planner/plannerReducer';
import school from 'school/schoolReducer';
import reports from 'reports/reportsReducer';
import register from 'register/registerReducer';
import { LOGOUT } from 'login/loginActions';
import configuration from 'configuration/configurationReducer';

import changePassword from 'changePassword/changePasswordReducer';

const appReducer = combineReducers({
  app,
  lang,
  login,
  school,
  reports,
  plans,
  schedule,
  classManagement,
  planner,
  register,
  changePassword,
  configuration
});

const rootReducer = (state: any, action: any) => {
  // console.log('action', action);
  if (action.type === success(LOGOUT)) {
    return appReducer(undefined, action); // reset the state when user logouts
  }
  return appReducer(state, action);
};

export default rootReducer;
