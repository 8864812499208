import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import find from 'lodash/find';

import { Modal } from 'sm-ui';
import SchoolViewContainer from '../../school/SchoolViewContainer';

import messages from './classManagementMessages';

function classManagementSchoolYearModal({
  isOpen,
  idSchoolSelected,
  schools,
  handleCloseSchoolYearModal,
  ...props
}) {
  let idUserSchool = '';
  if (idSchoolSelected) {
    const school = find(schools, s => s.idSchool === idSchoolSelected);
    if (school) {
      idUserSchool = school.idUserSchool;
    }
  }

  return (
    <Modal
      id="classManagementSchoolYearModal"
      show={isOpen}
      onClose={handleCloseSchoolYearModal}
      buttons={[
        {
          label: <FormattedMessage {...messages.back} />,
          onClick: handleCloseSchoolYearModal,
        },
      ]}
    >
      <SchoolViewContainer
        idUserSchool={idUserSchool}
        idSchool={idSchoolSelected}
        isClassManagementModal
      />
    </Modal>
  );
}

const mapStateToProps = ({ school }) => ({ ...school });

export default connect(mapStateToProps)(classManagementSchoolYearModal);
