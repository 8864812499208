import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './ModalButton.scss';

const ModalButton = ({ primary, label, onClick, disabled, className, ...props }) => (
  <a
    className={classNames('modal-button', className, { primary, disabled })}
    onClick={disabled ? undefined : onClick}
    {...props}
  >
    {primary ? <strong>{label}</strong> : label}
  </a>
);

ModalButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  primary: PropTypes.bool,
  label: PropTypes.any.isRequired,
  disabled: PropTypes.bool.isRequired,
};

ModalButton.defaultProps = {
  primary: false,
};
export default ModalButton;
