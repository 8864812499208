import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import messages from './schoolMessages';

const SchoolAddCard = ({ onClick }) => (
  <a className="card add-new" onClick={onClick}>
    <FormattedMessage {...messages.addSchool}>
      {txt => (
        <span>
          <div className="add-icon">
            <i className="ti-plus" />
          </div>
          {txt}
        </span>
      )}
    </FormattedMessage>
  </a>
);

SchoolAddCard.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default SchoolAddCard;
