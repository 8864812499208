import React from 'react';
import PropTypes from 'prop-types';

const Initials = ({ color, children }) => (
  <div className="initials" style={{ backgroundColor: color }}>
    {children}
  </div>
);

Initials.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node
};

Initials.defaultProps = {
  color: '#B5B5B5',
  children: undefined
};

export default Initials;
