/* eslint-disable */
import { success, failure, request } from 'app/actions';
import * as actions from './plansActions';
import * as helpers from './plansHelpers';

const initialState = {
  plans: [],
  disciplinesFilter: [],
  levelsFilter: [],
  teachersPlans: [],
  teachersFilter: [],
  teachersDisciplinesFilter: [],
  teachersLevelsFilter: [],
  isLoading: false,
  error: {
    hasError: false,
    code: null,
    message: null
  },
  planner: undefined,
  years: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case request(actions.GET_PLANS):
      return { ...state, isLoading: true };
    case success(actions.GET_PLANS): {
      const {payload} = action;
      const {plans, levelsFilter, disciplinesFilter, disciplinesFilterLabel, levelsFilterLabel, teachersPlans, teachersFilter, teachersDisciplinesFilter, teachersDisciplinesFilterLabel, teachersLevelsFilter, teachersLevelsFilterLabel} = payload;
      const years = helpers.getYears(plans);
      return {
        ...state,
        plans: helpers.enhancePlans(plans),
        disciplinesFilter: disciplinesFilter || [],
        disciplinesFilterLabel: disciplinesFilterLabel || [],
        levelsFilter: levelsFilter || [],
        levelsFilterLabel: levelsFilterLabel || [],
        teachersPlans: helpers.enhancePlans(teachersPlans),
        teachersFilter: teachersFilter || [],
        teachersDisciplinesFilter: teachersDisciplinesFilter || [],
        teachersDisciplinesFilterLabel: teachersDisciplinesFilterLabel || [],
        teachersLevelsFilter: teachersLevelsFilter || [],
        teachersLevelsFilterLabel: teachersLevelsFilterLabel || [],
        years: years.length > 0 ? years : [new Date().getFullYear()]
      };
    }
      case request(actions.GET_PLANNER):
        return { ...state, isLoading: true };
      case success(actions.GET_PLANNER):
        return {
          ...state,
          planner: action.payload.content,
          success: true,
          isLoading: false,
          hasError: false
        };
      case failure(actions.GET_PLANNER):
        return {
          ...state,
          isLoading: false,
          success: false,
          hasError: true
        };
    default:
      return state;
  }
};

export default reducer;
