import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import CardMonthCell from './CardMonthCell';
import { withCalendar } from '../CalendarContext';

const today = new Date();

class CardMonthDayCell extends Component {
  handleClickDate = e => {
    const { date, onClick } = this.props;
    if (onClick) {
      onClick(date, e);
    }
  };

  render() {
    const { date, appointments } = this.props;
    const dateMoment = moment(date);
    const current = dateMoment.isSame(today, 'day');
    const featured = appointments.some(a => moment(a.date).isSame(date, 'day'));
    const disabled = [0, 6].includes(dateMoment.day());
    const muted = false; // afterwards, check whats the meaning of muted
    return (
      <CardMonthCell
        current={current}
        disabled={disabled}
        muted={muted}
        featured={featured}
        onClick={this.handleClickDate}
      >
        {date.getDate()}
      </CardMonthCell>
    );
  }
}

CardMonthDayCell.defaultProps = {
  appointments: [],
  date: PropTypes.instanceOf(Date).isRequired,
  onClick: PropTypes.func
};

export default withCalendar(CardMonthDayCell);
