import React from 'react';
import { FormattedMessage } from 'react-intl';
import Role from 'app/role';

import messages from './schoolCardMessages';

import { CheckIcon } from '../resources/icons';

import './SchoolCard.scss';

function isCodeAllowed(role) {
  return [Role.COORDINATOR, Role.ADMINISTRADOR].includes(parseInt(role, 0));
}
class SchoolCard extends React.PureComponent {
  state = { isOpen: false };

  handleClick = () => {
    const { onClick, ...rest } = this.props;
    if (onClick) {
      onClick(rest);
    }
  };

  render() {
    const { Name, Address, City, State, accessCode, role, CoordName } = this.props;

    return (
      <div className="card card-school" onClick={this.handleClick}>
        <div className="card-body">
          {CoordName && (
            <div>
              <img
                src={CheckIcon}
                width="30px"
                className="school-card-icon"
                onMouseEnter={() => this.setState({ isOpen: true })}
                onMouseLeave={() => this.setState({ isOpen: false })}
              />
              {this.state.isOpen && (
                <div className="school-card-menu">
                  {CoordName} <div className="arrow" />
                </div>
              )}
            </div>
          )}
          <i className="card-icon pe-7s-home" />
          <FormattedMessage {...messages.schoolName}>
            {txt => <h2 className="card-title">{txt}</h2>}
          </FormattedMessage>
          <p className="card-description">{Name}</p>
          {isCodeAllowed(role) && (
            <FormattedMessage {...messages.code}>
              {txt => (
                <div className="cod">
                  {txt} {accessCode}
                  <i className="pe-7s-check" />
                </div>
              )}
            </FormattedMessage>
          )}
          <FormattedMessage {...messages.address}>
            {txt => (
              <p className="card-bottom-text">
                <strong>{txt}</strong>
                <br />
                <span>{Address}</span>
                <br />
                {City &&
                  State && (
                    <span>
                      {City}, {State}
                    </span>
                  )}
              </p>
            )}
          </FormattedMessage>
          <FormattedMessage {...messages.view}>
            {txt => (
              <button className="edit-card float-right">
                <i className="ti-pencil-alt" />
                {txt}
              </button>
            )}
          </FormattedMessage>
        </div>
      </div>
    );
  }
}

export default SchoolCard;
