import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { promisify } from 'app/actions';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';
import LanguageSelect from 'lang/LanguageSelect';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import { Input, Modal, Loader, Alert } from 'sm-ui';
import { changePasswordLink } from './loginActions';

import messages from './loginMessages';
import LoginSignTabs from './LoginSignTabs';

const SigninSchema = Yup.object().shape({
  email: Yup.string()
    .email('errorInvalidEmail')
    .required('errorRequired'),
  password: Yup.string().required('errorRequired'),
  emailForgot: Yup.string().email('errorInvalidEmail'),
});

const enhanceForm = withFormik({
  initialValues: { email: '', password: '', emailForgot: '' },
  validationSchema: SigninSchema,
  handleSubmit: (values, { props }) => {
    let param = {
      email: values.email,
      password: values.password
    }
    props.onSubmit(param);
  },
});

const initialState = {
  showModal: {},
  emailSentAlert: null,
  errorsAlerts: null,
  emailForgot: '',
  showExpiredModal: false,
  experied: false
};

class LoginForm extends Component {
  state = initialState;

  componentDidMount() {
    document.addEventListener('keydown', this.pegaTecla);
  }

  componentWillUnount() {
    document.removeEventListener('keydown');
  }

  pegaTecla = () => {
    const tecla = event.keyCode;
    if ((tecla === 13 || tecla === 27) && this.state.showModal.forgotPassword) {
      this.sendPassword(this.state.emailForgot)();
    }
  }; 

  
  getMessageError = field => {
    const { intl, errors, error } = this.props;
    if (errors && errors[field]) {
      return intl.formatMessage(messages[errors[field]]);
    }
    return undefined;
  };

  isValid = field => {
    const { errors, error, touched } = this.props;

    if (errors && errors[field] && touched[field]) {
      return false;
    }
    if (error) return false;
    return touched[field] ? true : undefined;
  };

  showModal = modalName => () => {
    this.setState(({ showModal }) => ({ showModal: { ...showModal, [modalName]: true } }));
  };

  closeModal = modalName => () => {
    this.setState(({ showModal }) => ({ showModal: { ...showModal, [modalName]: false } }));
    this.setState({ emailSentAlert: initialState.emailSentAlert });
    this.setState({ errorsAlerts: initialState.errorsAlerts });
  };

  sendPassword = (email, idCountry) => async () => {
    const selectedCountry = this.props.countries.find(c => c.language === this.props.locale);
    const idCountry = selectedCountry ? selectedCountry.idCountry : undefined;
    let err = null;
    try {
      await this.props.changePasswordLink({ email, idCountry, idAccess: '2' });
    } catch (e) {
      this.setState({ errorsAlerts: this.renderErrors() });
      this.setState({ emailSentAlert: initialState.emailSentAlert });
      err = e;
    } finally {
      if (!err) {
        this.setState({ errorsAlerts: initialState.errorsAlerts });
        this.setState({ emailSentAlert: this.renderEmailSent() });
      }
    }
  };

  handleChangeEmailForgot = e => {
    this.setState({ emailForgot: e.target.value });
  };

  handleModalExpired = () => {
    const { values } = this.props;
    const selectedCountry = this.props.countries.find(c => c.language === this.props.locale);
    const idCountry = selectedCountry ? selectedCountry.idCountry : undefined;
    this.handleShowExpiredModal({status: true});
    this.props.changePasswordLink({ email: values.email, idCountry, idAccess: "2" });
  }

  handleShowExpiredModal = ({ status }) => {
    this.setState({
      showExpiredModal: status,
      experied: status
    })
  }

  renderErrors = () => {
    const { errorSent, errorStatus } = this.props;

    const loginErrorAttempts = errorSent ? errorSent.loginErrorAttempts : false;
    const isUserCi = errorSent ? errorSent.isUserCi : false;
    const passwordExpired = errorSent ? errorSent.passwordExpired : false;

    if (errorStatus === 400) {
      /* Login or Password Empty */
      return <FormattedHTMLMessage {...messages[`error${errorStatus}`]} />;
    } else {

      if ((errorSent && errorSent.message === "Only CI user") || this.props.userCI || isUserCi) {
        return <FormattedHTMLMessage {...messages[`errorOnlyCi`]} />;
      } else if (errorSent && errorSent.message === "Invalid Credentials" && (this.props.userCI || isUserCi)) {
        return <FormattedHTMLMessage {...messages[`errorInvalidCredentialsCI`]} />;
      } else {
        if(passwordExpired && !this.state.experied) {
          this.handleModalExpired();
        } 
        if(loginErrorAttempts) {
          if (loginErrorAttempts === 5) {
            /* Attempts reached */
            return <FormattedHTMLMessage {...messages[`error${errorStatus}1`]} />;
          } else if (loginErrorAttempts <= 4 && loginErrorAttempts > 0) {
            /* Show how many attempts remain */
            return <FormattedHTMLMessage {...messages[`error${errorStatus}0${loginErrorAttempts}`]} />;
          }
        } else if (errorStatus) {
          /* Error 503 */
          return <FormattedHTMLMessage {...messages[`error${errorStatus}`]} />;
        }
      }
    }
  };

  renderEmailSent() {
    const { emailForgot } = this.state;
    return (
      <div>
        <Alert type="success" className="text-left">
          <FormattedMessage {...messages.emailSent} values={{ email: emailForgot }} />
        </Alert>
      </div>
    );
  }

  render() {
    const {
      handleSubmit,
      handleChange,
      handleBlur,
      values,
      isValid,
      countries,
      intl,
      isLoading,
      verifyPermissionError,
    } = this.props;
    const { showModal, emailSentAlert, errorsAlerts, emailForgot } = this.state;
    let register = false;
    let idCountry = '';

    countries.forEach(el => {
      if (el.register === '1' && el.access === '2' && intl.locale === el.language) {
        register = true;
      }
      if (el.access === '2' && intl.locale === el.language) {
        idCountry = el.idCountry;
      }
    });

    const modalButtons = [
      {
        label: <FormattedMessage {...messages.close} />,
        onClick: this.closeModal('forgotPassword'),
      },
      {
        id: 2,
        label: <FormattedMessage {...messages.send} />,
        onClick: this.sendPassword(emailForgot, idCountry),
        primary: true,
      },
    ];
    const errors = this.renderErrors();

    return (
      <form className="col ml-auto mr-auto" onSubmit={handleSubmit}>
        <Loader show={isLoading} />
        <LanguageSelect countries={countries} />
        <LoginSignTabs register={register} />
        {verifyPermissionError.errCode && (
          <Alert type="danger">
            <FormattedMessage
              {...messages[`errorCode${verifyPermissionError.errCode}`] || messages.errorCode500}
            />
          </Alert>
        )}
        {!this.state.showModal.forgotPassword && errors &&
          <Alert type="danger" className="text-left" style={{marginTop: '22px'}}>
            {errors}
          </Alert>
        }
        <div className="form-group form-group-mob">
          <FormattedMessage {...messages.nameField}>
            {txt => (
              <Input
                label={txt}
                autoComplete="username"
                name="email"
                valid={this.isValid('email')}
                invalidFeedback={this.getMessageError('email')}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            )}
          </FormattedMessage>
        </div>
        <div className="form-group form-group-mob">
          <FormattedMessage {...messages.passwordField}>
            {txt => (
              <Input
                label={txt}
                type="password"
                autoComplete="password"
                valid={this.isValid('password')}
                invalidFeedback={this.getMessageError('password')}
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            )}
          </FormattedMessage>
        </div>
        <div className="forgotPassword">
          <FormattedMessage {...messages.forgotPassword}>
            {txt => (
              <a href="javascript:void(0)" onClick={this.showModal('forgotPassword')}>
                {txt}
              </a>
            )}
          </FormattedMessage>
        </div>
        <FormattedMessage {...messages.enter}>
          {txt => (
            <input
              className="btn btn-primary btn-lg btn-block"
              type="submit"
              value={txt}
              disabled={!isValid}
              style={{ marginTop: '20px' }}
            />
          )}
        </FormattedMessage>
        <Modal
          icon="pe-7s-lock"
          title={<FormattedMessage {...messages.forgotPassword} />}
          message={<FormattedMessage {...messages.forgotMessage} />}
          show={showModal.forgotPassword}
          onClose={this.closeModal('forgotPassword')}
          buttons={modalButtons}
        >
          {errorsAlerts}
          {emailSentAlert}
          <div className="form-group form-group-mob" style={{ textAlign: 'left' }}>
            <FormattedMessage {...messages.forgotEmail}>
              {txt => (
                <Input
                  label={txt}
                  autoComplete="emailForgot"
                  name="emailForgot"
                  valid={this.isValid('emailForgot')}
                  invalidFeedback={this.getMessageError('emailForgot')}
                  value={this.state.emailForgot}
                  onChange={this.handleChangeEmailForgot}
                  onBlur={handleBlur}
                />
              )}
            </FormattedMessage>
          </div>
        </Modal>
        <Modal
          icon="pe-7s-lock"
          title={<FormattedMessage {...messages.expiredTitle} />}
          subtitle={<FormattedMessage {...messages.expiredText} />}
          show={this.state.showExpiredModal}
          onClose={() => this.handleShowExpiredModal({status: false})}
        ></Modal>
      </form>
    );
  }
}

LoginForm.propTypes = {
  intl: intlShape.isRequired,
};

const mapStateToProps = ({ app, login, lang }) => ({ ...login, locale: lang.locale, userCI: app.userCI });

export default compose(
  connect(
    mapStateToProps,
    promisify({ changePasswordLink }),
  ),
  enhanceForm,
  injectIntl,
)(LoginForm);
