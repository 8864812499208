import { success, failure, request } from 'app/actions';
import * as actions from './schoolActions';

export const EditionStatus = {
  PENDING: 1,
  SAVING: 2,
  SAVED: 3,
  FAILED: 4,
};

const initialState = {
  schools: [],
  schoolDetails: null,
  schoolsSearch: [],
  codeActivated: null,
  states: [],
  cities: [],
  error: {},
  addingSchool: false,
  schoolFormStatus: EditionStatus.PENDING,
  saved: false,
};

const schoolReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.RESET_ADD_MODAL_FORM:
      return { ...initialState, schools: state.schools };
    case request(actions.GET_SCHOOL_DETAILS):
      return { ...state, schoolDetails: initialState.schoolDetails };
    case request(actions.GET_CITIES):
      return { ...state, schoolsSearch: initialState.schoolsSearch };
    case request(actions.ACTIVATE_ACCESS_CODE):
      return { ...state, codeActivated: initialState.codeActivated };
    case request(actions.ADD_SCHOOL):
      return { ...state, addingSchool: true };
    case success(actions.GET_SCHOOLS):
      return { ...state, schools: action.payload.content };
    case success(actions.SEARCH_SCHOOLS):
      return { ...state, schoolsSearch: action.payload.content };
    case success(actions.GET_SCHOOL_DETAILS):
      return { ...state, schoolDetails: action.payload.content, error: {} };
    case success(actions.ACTIVATE_ACCESS_CODE):
      return { ...state, codeActivated: true };
    case success(actions.GET_STATES):
      return { ...state, states: action.payload.content };
    case success(actions.ADD_SCHOOL):
      return { ...state, error: initialState.error, addingSchool: initialState.addingSchool };
    case success(actions.GET_CITIES):
      return { ...state, cities: action.payload.content };
    case failure(actions.ACTIVATE_ACCESS_CODE):
      return { ...state, codeActivated: false };
    case failure(actions.ADD_SCHOOL):
      return {
        ...state,
        error: { addSchool: action.payload.errCode },
        addingSchool: initialState.addingSchool,
      };
    case actions.RESET_SCHOOL_FORM:
      return { ...state, schoolFormStatus: initialState.schoolFormStatus };
    case request(actions.SAVE_SCHOOL_DETAILS):
      return { ...state, schoolFormStatus: EditionStatus.SAVING, saved: false };
    case success(actions.SAVE_SCHOOL_DETAILS):
      return { ...state, schoolFormStatus: EditionStatus.SAVED, saved: true };
    case failure(actions.SAVE_SCHOOL_DETAILS):
      return { ...state, schoolFormStatus: EditionStatus.FAILED, saved: false };
    default:
      return state;
  }
};

export default schoolReducer;
