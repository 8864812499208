import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { promisify } from 'app/actions';
import queryString from 'query-string';
import { injectIntl } from 'react-intl';
import { Modal, Select } from 'sm-ui';

import { addPlanScheduledLessonLog } from '../scheduleActions';

import messages from '../scheduleMessages';

class CalendarStatusChangeModal extends React.PureComponent {
  state = {
    selectedStatus: '',
    comment: '',
    options: [],
  };

  componentDidMount() {
    this.hanldeMenuBuilder();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.scheduledLessonStatus.status !== this.props.scheduledLessonStatus.status) {
      this.hanldeMenuBuilder();
    }
  }

  hanldeMenuBuilder = () => {
    const selectedStatus = this.props.scheduledLessonStatus.status;
    if (selectedStatus === '1' || selectedStatus === '2' || selectedStatus === '3') {
      const statusValues = ['1', '2', '3'];
      const options = statusValues.map(el => ({
        value: el,
        label: this.props.intl.formatMessage(messages[`lessonStatus${el}`]),
      }));
      this.setState({ selectedStatus, options });
    }
    if (selectedStatus === '5' || selectedStatus === '6' || selectedStatus === '7') {
      const statusValues = ['5', '6', '7'];
      const options = statusValues.map(el => ({
        value: el,
        label: this.props.intl.formatMessage(messages[`lessonStatus${el}`]),
      }));
      this.setState({ selectedStatus, options });
    }
  };

  handleChangeSelect = e => {
    this.setState({ selectedStatus: e.target.value });
  };

  handleChangeText = e => {
    this.setState({ comment: e.target.value });
  };

  handleSubmit = () => {
    const { idPlan, idCourse, idScheduledLesson } = this.props;
    const params = {
      idPlan,
      idCourse,
      idScheduledLesson,
      value: this.state.selectedStatus,
      comments: this.state.comment,
      action: '1',
      loadParams: queryString.parse(location.search),
    };

    this.props.addPlanScheduledLessonLog(params).then(() => {
      this.setState({ selectedStatus: '', comment: '' });
      this.props.onUpdate();
    });
  };

  render() {
    return (
      <Modal
        title={this.props.intl.formatMessage(messages.statusChangeModalTitle)}
        icon="pe-7s-info"
        message={this.props.intl.formatMessage(messages.statusChangeModalMessage, {
          appointment: this.props.appointmentCategory,
        })}
        onClose={this.props.onClose}
        buttons={[
          {
            label: this.props.intl.formatMessage(messages.statusChangeModalCancelButton),
            onClick: this.props.onClose,
          },
          {
            label: this.props.intl.formatMessage(messages.statusChangeModalUpdateButton),
            onClick: this.handleSubmit,
            primary: true,
          },
        ]}
        {...this.props}
        modalBodyStyle={{ maxWidth: '400px' }}
      >
        <div>
          <Select
            name="selectStatusChange"
            selectLabel={this.props.intl.formatMessage(messages.statusChangeModalSelectLabel)}
            options={this.state.options}
            value={this.props.selectedStatus}
            onChange={this.handleChangeSelect}
          />
          <textarea
            type="text"
            value={this.state.comment}
            onChange={this.handleChangeText}
            style={{
              width: '100%',
              padding: '5px',
              marginTop: '20px',
              fontSize: '14px',
              border: '1px solid #8D8D8D',
              borderRadius: '5px',
            }}
          />
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ schedule }) => ({
  scheduledLessonStatus: schedule.scheduledLessonStatus,
});

export default compose(
  injectIntl,
  connect(
    mapStateToProps,
    promisify({ addPlanScheduledLessonLog }),
  ),
)(CalendarStatusChangeModal);
