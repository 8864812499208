import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'schedule.title',
    defaultMessage: 'Schedule',
  },
  contentModalTitle: {
    id: 'schedule.contentModal.title',
    defaultMessage: 'Content',
  },
  detailsModalTitle: {
    id: 'schedule.detailsModal.title',
    defaultMessage: 'Appointment',
  },
  statusModalTitle: {
    id: 'schedule.statusModal.title',
    defaultMessage: 'Status',
  },
  statusModalEditButton: {
    id: 'schedule.statusModal.button.edit',
    defaultMessage: 'Edit',
  },
  statusModalCloseButton: {
    id: 'schedule.statusModal.button.close',
    defaultMessage: 'Close',
  },
  statusModalContinueButton: {
    id: 'schedule.statusModal.button.continue',
    defaultMessage: 'Continue',
  },
  statusChangeModalTitle: {
    id: 'schedule.statusChangeModal.title',
    defaultMessage: 'Appointment Status',
  },
  statusChangeModalMessage: {
    id: 'schedule.statusChangeModal.message',
    defaultMessage: 'Insert the appointment {appointment} status:',
  },
  statusChangeModalCancelButton: {
    id: 'schedule.statusChangeModal.button.cancel',
    defaultMessage: 'Cancel',
  },
  statusChangeModalUpdateButton: {
    id: 'schedule.statusChangeModal.button.update',
    defaultMessage: 'Update',
  },
  statusChangeModalStatusLate: {
    id: 'schedule.statusChangeModal.status.late',
    defaultMessage: 'Late',
  },
  statusChangeModalStatusApplied: {
    id: 'schedule.statusChangeModal.status.applied',
    defaultMessage: 'Applied',
  },
  statusChangeModalSelectLabel: {
    id: 'schedule.statusChangeModal.select.label',
    defaultMessage: 'Select status...',
  },
  appointmentReassign: {
    id: 'schedule.appointment.reassign',
    defaultMessage: 'Reassign',
  },
  appointmentDetails: {
    id: 'schedule.appointment.details',
    defaultMessage: 'Details',
  },
  appointmentStatus: {
    id: 'schedule.appointment.status',
    defaultMessage: 'Status',
  },
  appointmentRemove: {
    id: 'schedule.appointment.remove',
    defaultMessage: 'Remove',
  },
  selectLabel: {
    id: 'schedule.appointment.selectLabel',
    defaultMessage: 'Select',
  },
  relocationLabel: {
    id: 'schedule.appointment.relocationLabel',
    defaultMessage: 'Automatic rescheduling',
  },
  valueLate: {
    id: 'schedule.appointment.calendarRessignModalValueLate',
    defaultMessage: 'Late',
  },
  valueApplied: {
    id: 'schedule.appointment.calendarRessignModalValueApplied',
    defaultMessage: 'Applied',
  },
  nothingToShow: {
    id: 'schedule.calendarTable.nothingToShow.',
    defaultMessage: "There's no data to show",
  },
  collectionDropdown: {
    id: 'schedule.collectionDropdown',
    defaultMessage: 'Collection',
  },
  period: {
    id: 'schedule.period',
    defaultMessage: 'period',
  },
  gradeDropdown: {
    id: 'schedule.gradeDropdown',
    defaultMessage: 'Grade',
  },
  status: {
    id: 'schedule.status',
    defaultMessage: 'Status',
  },
  scheduled: {
    id: 'schedule.scheduled',
    defaultMessage: 'Scheduled',
  },
  applied: {
    id: 'schedule.applied',
    defaultMessage: 'Applied',
  },
  firstPeriod: {
    id: 'schedule.status.firstPeriod',
    defaultMessage: 'First Period',
  },
  secondPeriod: {
    id: 'schedule.status.secondPeriod',
    defaultMessage: 'Second Period',
  },
  thirdPeriod: {
    id: 'schedule.status.thirdPeriod',
    defaultMessage: 'Third Period',
  },
  fourthPeriod: {
    id: 'schedule.status.fourthPeriod',
    defaultMessage: 'Fourth Period',
  },
  notStarted: {
    id: 'schedule.notStarted',
    defaultMessage: 'Not started',
  },
  filterByDiscipline: {
    id: 'schedule.selectDiscipline',
    defaultMessage: 'Discipline...',
  },
  filterByLevel: {
    id: 'schedule.selectLevel',
    defaultMessage: 'Level...',
  },
  lessonStatus1: {
    id: 'schedule.lessonStatus1',
    defaultMessage: 'Scheduled',
  },
  lessonStatus2: {
    id: 'schedule.lessonStatus2',
    defaultMessage: 'Not applied',
  },
  lessonStatus3: {
    id: 'schedule.lessonStatus3',
    defaultMessage: 'Applied',
  },
  lessonStatus4: {
    id: 'schedule.lessonStatus4',
    defaultMessage: 'Event',
  },
  lessonStatus5: {
    id: 'schedule.lessonStatus5',
    defaultMessage: 'Evaluation scheduled',
  },
  lessonStatus6: {
    id: 'schedule.lessonStatus6',
    defaultMessage: 'Evaluation not applied',
  },
  lessonStatus7: {
    id: 'schedule.lessonStatus7',
    defaultMessage: 'Evaluation applied',
  },
  lessonStatusFallback: {
    id: 'schedule.lessonStatusFallBack',
    defaultMessage: 'Status',
  },
  statusModalHistory: {
    id: 'schedule.statusModalHistory',
    defaultMessage: 'History',
  },
  statusModalLessonUpdatedTo: {
    id: 'schedule.statusModalLessonUpdatedTo',
    defaultMessage: 'Status updated to',
  },
  teachersFilterLabel: {
    id: 'schedule.teachersFilterLabel',
    defaultMessage: 'Teacher...',
  },
});
