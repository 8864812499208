import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { withFormik } from "formik";
import * as Yup from "yup";
import CryptoJS from "crypto-js";

import { Loader, Alert } from "sm-ui";

import Login from '../login/Login';
import LoginSignTabs from '../login/LoginSignTabs';

import { changePassword, changePasswordLink } from "./changePasswordActions";
import * as login from "../login/loginActions";

import messages from "./changePasswordMessages";
import "./ChangePassword.scss";


const shapeChangePasswordShapeSchema = {
  hash: Yup.string(),
  email: Yup.string()
    .email("errorInvalidEmail")
    .required("errorRequired"),
  password: Yup.string().required("errorRequired").matches(
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    "errorStrong"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "errorDontMatch")
    .required("errorRequired"),
};

const ChangePasswordSchema = Yup.object().shape( shapeChangePasswordShapeSchema );

const initialValues = {
  hash: "",
  email: "",
  password: "",
  confirmPassword: "",
};

const enhanceForm = withFormik({
  mapPropsToValues: () => ({}),
  initialValues,
  validationSchema: ChangePasswordSchema,
  handleSubmit: async (values, { props }) => {
    console.log("values", values);
    const payload = { ...values, "idAccess": 2 };
    await props.changePassword(payload);
  },
});

class ChangePasswordContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      viewPassword: false,
      viewPasswordConfirm: false,
      email: '',
      hash: '',
      errorHash: false,
    };
    this.handleViewPassword = this.handleViewPassword.bind();
    this.handleViewPasswordConfirm = this.handleViewPasswordConfirm.bind();
  }

  componentDidMount() {
    const hash = this.props.location.search.replace('?hash=','');

    if(hash) {
      const bytes = CryptoJS.AES.decrypt(hash, "rej723");
      const email = bytes.toString(CryptoJS.enc.Utf8);

      if(email) {
        this.props.setFieldValue("email", email);
        this.props.setFieldValue("hash", hash);
    
        this.setState({
          email,
          hash
        });
      } else {
        this.setState({
          errorHash: true,
        });
      }
    } 
    else {
      this.redirectLogin();
    }
  }

  componentDidUpdate() {
    if(this.props.changePasswordStatus === 200) {
      setTimeout(() => {
        this.redirectLogin();
      }, 3000)
    }
  }

  redirectLogin = () => {
    this.props.history.push("/login");
  };

  handleViewPassword = () => {
    this.setState({
      viewPassword: this.state.viewPassword ? false : true
    })
  }

  handleViewPasswordConfirm = () => {
    this.setState({
      viewPasswordConfirm: this.state.viewPasswordConfirm ? false : true
    })
  }

  render() {
    const { error, errors, touched, handleSubmit, handleChange, handleBlur, isLoading, changePasswordStatus, changePasswordText } = this.props;
    const { email, hash, errorHash } = this.state;

    console.log("this.props", this.props);

    return (
      <Login>
        <form className="col ml-auto mr-auto" onSubmit={handleSubmit}>
          <Loader show={isLoading} />
          <LoginSignTabs register={false} />
          { changePasswordStatus === 200 && <div className="boxMsg alert alert-success"><FormattedMessage {...messages.confirmedChangePassword} /></div> }
          { (errorHash || changePasswordText === "ERROR_45") && <div className="boxMsg alert alert-danger"><FormattedMessage {...messages.errorHash} /></div> }
          { changePasswordText === "ERROR_5" && <div className="boxMsg alert alert-danger"><FormattedMessage {...messages.errorUser} /></div> }
          <h4 style={{color: "#1C77C4", paddingTop: '20px'}}><FormattedMessage {...messages.title} name="title" /></h4>
          <div className="form-group form-group-mob">
              <input
                className="form-control"
                name="hash"
                type="hidden"
                value={hash}
                disabled
              />
          </div>
          <div className="form-group form-group-mob">
            <label
              htmlFor="form-email"
              style={checkError({ errors, touched, field: "email", type: "label" })}
            >
              <FormattedMessage {...messages.email} name="email" />
            </label>
            <input
              className="form-control"
              name="email"
              type="email"
              value={email}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled
            />
          </div>
          <div className="form-group form-group-mob" style={{position: 'relative'}}>
            <label
              htmlFor="form-password"
              style={checkError({ errors, touched, field: "password", type: "label" })}
            >
              <FormattedMessage {...messages.password} name="password" />
            </label>
            <input
              className="form-control input-password"
              onChange={handleChange}
              name="password"
              onBlur={handleBlur}
              type={this.state.viewPassword ? "text" : "password"}
              style={checkError({ errors, touched, field: "password", type: "border" })}
            />
            <a className="pass-icon" onClick={this.handleViewPassword} ><i className="far fa-eye"></i></a>
            {errors.password === 'errorStrong' && touched.password && <PassStrongField />}
            {errors.password === 'errorRequired' && touched.password && <RequiredField />}
            <div className="card2 card-password">
              <FormattedMessage {...messages.passCriterio} />
              <ul>
                <li><FormattedMessage {...messages.caracterNumber} /></li>
                <li><FormattedMessage {...messages.letterUppercase} /></li>
                <li><FormattedMessage {...messages.letterLowercase} /></li>
                <li><FormattedMessage {...messages.number} /></li>
                <li><FormattedMessage {...messages.specialCaracter} /> {` ' ~ ! @ # $ % ^ & * () _ + - = {} | \\ : " ; ' < > ? , . / )`}</li>
              </ul>
            </div>
          </div>
          <div className="form-group form-group-mob" style={{position: 'relative'}}>
            <label
              htmlFor="form-password"
              style={checkError({
                errors,
                touched,
                field: "confirmPassword",
                type: "label",
              })}
            >
              <FormattedMessage {...messages.confirmPassword} />
            </label>
            <input
              className="form-control input-password"
              name="confirmPassword"
              type={this.state.viewPasswordConfirm ? "text" : "password"}
              onChange={handleChange}
              onBlur={handleBlur}
              style={checkError({ errors, touched, field: "confirmPassword", type: "border" })}
            />
            <a className="pass-icon" onClick={this.handleViewPasswordConfirm} ><i className="far fa-eye"></i></a>
            {errors.confirmPassword === 'errorDontMatch' && touched.password && <ConfirmPassDontMatch />}
            {errors.confirmPassword === 'errorRequired' && touched.confirmPassword && <RequiredField />}
          </div>
          <FormattedMessage {...messages.button}>
            {msg => {
              const isDisabled = !this.props.isValid;
              return (
                <button
                  className="btn btn-primary btn-lg btn-block"
                  type="submit"
                  disabled={isDisabled}
                  style={{ cursor: isDisabled ? "not-allowed" : "pointer" }}
                  setFieldValue={("email", "hash")}
                >
                  {msg}
                </button>
              );
            }}
          </FormattedMessage>
        </form>
        
      </Login>
    );
  }
}

function checkError({ errors, touched, field, type }) {
  if (errors[field] && touched[field]) {
    if (type === "border") {
      return { border: "solid 1px red" };
    }
    if (type === "label") {
      return { color: "red" };
    }
  }
  return {};
}

const RequiredField = () => <div style={{ color: "red", fontSize: "10px" }}><FormattedMessage {...messages.requiredField} /></div>;

const ConfirmPassDontMatch = () => <div style={{ color: "red", fontSize: "10px" }}><FormattedMessage {...messages.confirmPassDontMatch} /></div>;

const PassStrongField = () => <div style={{ color: "red", fontSize: "10px" }}><FormattedMessage {...messages.passwordNotStrong} /></div>;

const mapStateToProps = ({ changePassword }) => ({
  isLoading: changePassword.isLoading,
  changePasswordText: changePassword.changePasswordText,
  changePasswordStatus: changePassword.changePasswordStatus
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    { changePassword },
  ),
  enhanceForm,
)(ChangePasswordContainer);
