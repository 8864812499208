import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import { Modal, Select, Input, Switch, CheckboxList, Checkbox } from 'sm-ui';

import messages from './calendarAddEventModalMessages';
import calendarMessages from './calendarMessages';

import * as helpers from './calendarHelpers';

import { getSuperlessons } from '../scheduleActions';

const OPTION_ASSESSMENT = 'optionAssessment';
const OPTION_EVENT = 'optionEvent';
const OPTION_SUPERLESSON = 'optionSuperlesson';

const Options = {
  [OPTION_ASSESSMENT]: {
    titleMessage: messages.addAssessment,
    nameMessage: messages.assessmentName,
    dateMessage: messages.assessmentDate,
  },
  [OPTION_EVENT]: {
    titleMessage: messages.addEvent,
    nameMessage: messages.eventName,
    dateMessage: messages.eventDate,
  },
};

const OptionSuperlesson = {
  [OPTION_SUPERLESSON]: {
    titleMessage: messages.superlessonTitle,
    nameMessage: messages.superlessonName,
    dateMessage: messages.superlessonDate,
  },
};

const initialState = {
  option: null,
  optionSchedule: null,
  fullPeriod: false,
  data: {
    hours: [],
    automaticReasign: 0,
    type: '',
  },
};

class CalendarAddEventModal extends React.PureComponent {
  state = {
    ...initialState,
    hours: helpers.createHours(),
  };

  componentDidMount() {
    this.handleGetSuperlessons();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show && !this.props.show) {
      this.clear();
    }
    if (prevProps.idCountry !== this.props.idCountry) {
      this.handleGetSuperlessons();
    }
  }

  handleGetSuperlessons = () => {
    this.props.getSuperlessons({ idCountry: this.props.idCountry });
  };

  handleSelect = e => {
    const type = e.target.value;
    this.setState(({ data }) => ({
      option: type,
      data: { ...data, type },
    }));
  };

  handleSelectSuperclass = e => {
    const idSuperlesson = e.target.value;
    this.setState(({ data }) => ({
      data: { ...data, idSuperlesson },
    }));
  };

  handleChange = param => e => {
    const { value, checked } = e.target;
    const valueToUpdate = value || checked;
    this.updateData(param, valueToUpdate);
  };

  clear = () => {
    this.setState(initialState);
  };

  updateData = (param, value) => {
    this.setState(({ data }) => ({ data: { ...data, [param]: value } }));
  };

  handleChangeFullPeriod = e => {
    const { checked } = e.target;
    const { hours } = this.state;
    this.setState(({ data }) => ({
      fullPeriod: checked,
      data: { ...data, hours },
    }));
  };

  handleChangeAutomaticReassign = e => {
    const { checked } = e.target;
    const parsedValue = checked ? 1 : 0;
    this.updateData('automaticReasign', parsedValue);
  };

  handleSelectHours = values => {
    this.updateData('hours', values);
  };

  close = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  continue = () => {
    this.props.onContinue(this.state.data);
  };

  valid = () => {
    const { data } = this.state;
    if (['name', 'date'].some(f => !data[f])) return false;
    if (data.hours.length === 0) return false;
    return true;
  };

  render() {
    const { intl, onClose, onSelect, onContinue, user, idCountry, ...props } = this.props;
    const { option, fullPeriod, data, hours } = this.state;

    let selectOptions = { ...Options };

    if (user.idType !== 3) {
      selectOptions = {
        ...Options,
        ...OptionSuperlesson,
      };
    }

    const options = Object.keys(selectOptions).map(key => ({
      id: key,
      value: key,
      label: intl.formatMessage(messages[key]),
    }));

    const hoursOptions = hours.map(hour => ({
      value: hour,
      label: intl.formatMessage(calendarMessages.hourLabel) + hour,
    }));

    const superlessonOptions =
      this.props.superlessons.length > 0 &&
      this.props.superlessons.reduce((prevElem, elem) => {
        const optionData = {
          groupLabelMessageKey: elem.idCataloging,
          label: elem.catalogingPath,
          value: elem.content.map(key => ({
            id: key.id,
            value: key.id,
            label: key.name,
          })),
        };

        optionData.value.length > 1 &&
          optionData.value.sort(function(a, b) {
            if (a.label > b.label) return 1;
            if (a.label < b.label) return -1;
            return 0;
          });

        return [...prevElem, optionData];
      }, []);

    const optionData = selectOptions[option] || selectOptions[OPTION_ASSESSMENT];
    const titleMessage = option ? optionData.titleMessage : messages.title;
    const isValid = this.valid();

    return (
      <Modal
        title={<FormattedMessage {...titleMessage} />}
        icon="pe pe-7s-plus"
        message={<FormattedMessage {...messages.message} />}
        onClose={this.close}
        buttons={[
          {
            id: 1,
            label: <FormattedMessage {...messages.cancelButton} />,
            onClick: this.close,
            disabled: false,
          },
          {
            id: 2,
            label: <FormattedMessage {...messages.continueButton} />,
            onClick: this.continue,
            disabled: !isValid,
            primary: true,
            disabled: false,
          },
        ]}
        {...props}
      >
        <div>
          {!option && (
            <Select
              name="optionAdd"
              selectLabel={intl.formatMessage(messages.selectLabel)}
              options={options || []}
              onChange={this.handleSelect}
            />
          )}
          {option && (
            <div>
              <div className="form-group mb-4 mt-0">
                {option &&
                  option !== OPTION_SUPERLESSON && (
                    <FormattedMessage {...messages[option]}>
                      {txt => (
                        <span className="form-control text-center">
                          {txt}
                          <i className="far fa-check-circle" />
                        </span>
                      )}
                    </FormattedMessage>
                  )}
                {option &&
                  option === OPTION_SUPERLESSON && (
                    <Select
                      name="superclassSelect"
                      selectLabel={intl.formatMessage(messages.selectLabel)}
                      groupsOptions={superlessonOptions || []}
                      onChange={this.handleSelectSuperclass}
                    />
                  )}
              </div>
              <div className="form-group">
                <FormattedMessage {...optionData.nameMessage}>
                  {txt => <Input placeholder={txt} onChange={this.handleChange('name')} />}
                </FormattedMessage>
              </div>
              <div className="form-group text-center">
                <FormattedMessage {...optionData.dateMessage}>
                  {txt => (
                    <Input
                      placeholder={txt}
                      label={txt}
                      type="date"
                      onChange={this.handleChange('date')}
                    />
                  )}
                </FormattedMessage>
              </div>
              {option === OPTION_EVENT && (
                <FormattedMessage {...messages.fullTime}>
                  {txt => (
                    <Switch id="full-period" label={txt} onChange={this.handleChangeFullPeriod} />
                  )}
                </FormattedMessage>
              )}
              {(option === OPTION_ASSESSMENT || !fullPeriod) && (
                <FormattedMessage {...messages.selectClassesLabel}>
                  {txt => (
                    <CheckboxList
                      label={txt}
                      items={hoursOptions || []}
                      disabled={fullPeriod}
                      onChange={this.handleSelectHours}
                      values={data.hours}
                    />
                  )}
                </FormattedMessage>
              )}
              <div className="form-group">
                <Checkbox
                  label={intl.formatMessage(messages.reassignModalAutomaticReassignment)}
                  onChange={this.handleChangeAutomaticReassign}
                />
              </div>
              {option === OPTION_EVENT && (
                <div className="form-group">
                  <FormattedMessage {...messages.describeEvent}>
                    {txt => (
                      <textarea
                        className="form-control"
                        placeholder={txt}
                        onChange={this.handleChange('comments')}
                      />
                    )}
                  </FormattedMessage>
                </div>
              )}
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ app, schedule }) => ({
  role: app.role,
  idCountry: get(app.user, 'idCountry'),
  superlessons: schedule.superlessons || [],
  user: app.user,
});

export default compose(
  connect(
    mapStateToProps,
    { getSuperlessons },
  ),
  injectIntl,
)(CalendarAddEventModal);
