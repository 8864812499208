import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import './MonthCalendarCellOffset.scss';

const MonthCalendarCellOffset = ({ startDate }) => {
  const day = startDate.getDay();
  if ([0, 6].includes(day)) return null;
  const offsetCells = [];
  let i = day;
  while (i > 1) {
    offsetCells.push(<div key={i} className="month-calendar-cell-offset" />);
    i--;
  }
  return <Fragment>{offsetCells}</Fragment>;
};

MonthCalendarCellOffset.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired
};

export default MonthCalendarCellOffset;
