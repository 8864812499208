// @styleguide
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { promisify } from 'app/actions';
import actions from './classManagementActions';
import ClassManagementCourse from './ClassManagementCourse';
import { NEW_COURSE } from './classManagementReducer';

function isNewCourse(course) {
  return !course.idCourse || course.idCourse === NEW_COURSE.idCourse;
}
class ClassManagementCoursesContainer extends Component {
  state = {
    coursesSelected: [],
    selectAux: false,
  };

  handleChangeCourse = course => async ({ fullname, idSchool }) => {
    const { idCourse } = course;
    const { idPlan } = this.props;
    if (isNewCourse(course)) {
      if (fullname) {
        await this.props.addCourse({ fullname, idPlan }, { course });
      }
    } else if (fullname && idSchool) {
      await this.props.editCourse({ idCourse, fullname, idSchool, idPlan }, { course });
    }
    this.handleInsert();
  };

  handleToggleCourse = course => () => {
    const { coursesSelected } = this.state;
    const alreadySelected = coursesSelected.includes(course);
    this.setState(
      {
        coursesSelected: alreadySelected
          ? coursesSelected.filter(c => c !== course)
          : coursesSelected.concat([course]),
      },
      () => {
        if (this.props.onSelectCourses) {
          this.props.onSelectCourses(this.state.coursesSelected);
        }
      },
    );
    this.props.handleSelectSchool(course.idSchool);
  };

  handleRequestRemoveCourse = course => () => {
    if (isNewCourse(course)) return;
    if (this.props.onRequestRemove) {
      this.props.onRequestRemove(course);
    }
  };

  handleInsert() {
    this.setState({
      selectAux: true,
    });
    this.props.insertSelected();
  }

  addHour = course => (hour, weekDay) => {
    if (isNewCourse(course)) return;
    const { idCourse } = course;
    const { idPlan } = this.props;
    this.props.addHour({ idCourse, weekDay, hour, idPlan }, { course });
  };

  removeHour = course => (hour, weekDay) => {
    if (isNewCourse(course)) return;
    const { idCourse } = course;
    const { idPlan } = this.props;
    this.props.deleteHour({ idCourse, weekDay, hour, idPlan }, { course });
  };

  render() {
    const {
      courses,
      schools,
      errorCourse,
      handleOpenSchoolYearModal,
      handleSelectSchool,
      plannerDefaultClasses,
    } = this.props;

    return (
      <div>
        {courses.map((course, index) => {
          const {
            idCourse,
            courseName,
            courseDaysHours,
            eva,
            idPlanScheduled,
            idSchool,
            planScheduled,
          } = course;
          const newCourse = isNewCourse(course);
          const removable = (eva === null && parseInt(planScheduled) === 0) || newCourse;

          return (
            <ClassManagementCourse
              courseName={courseName}
              key={idCourse || index}
              error={errorCourse[course.idCourse]}
              schools={schools}
              idSchool={idSchool}
              removable={removable}
              daysHours={courseDaysHours}
              onChangeCourse={this.handleChangeCourse(course)}
              onToggleCourse={this.handleToggleCourse(course)}
              onRequestRemoveCourse={this.handleRequestRemoveCourse(course)}
              onAddHour={this.addHour(course)}
              onRemoveHour={this.removeHour(course)}
              idPlanScheduled={idPlanScheduled}
              planScheduled={planScheduled}
              handleOpenSchoolYearModal={handleOpenSchoolYearModal}
              handleSelectSchool={handleSelectSchool}
              plannerDefaultClasses={plannerDefaultClasses}
              selected={this.state.selectAux}
            />
          );
        })}
      </div>
    );
  }
}

ClassManagementCoursesContainer.propTypes = {
  courses: PropTypes.array,
};

ClassManagementCoursesContainer.defaultProps = {
  courses: [],
};

const mapStateToProps = ({ classManagement, selectAux }) => ({ ...classManagement, selectAux });

export default connect(mapStateToProps, promisify({ ...actions }))(ClassManagementCoursesContainer);
