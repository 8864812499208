// @styleguide

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import CalendarEventType from '../calendarEventType';
import messages from './cardAppointmentDayMessages';
import CardAppointmentTitle from '../CardAppointmentTitle';
import { withCalendar } from '../CalendarContext';

import * as helpers from '../calendarHelpers';
import './WeekCardAppointmentMobile.scss';

class WeekCardAppointmentMobile extends Component {
  handleRequestStatus = () => {
    if (this.props.onRequestStatusAppointment) {
      this.props.onRequestStatusAppointment(this.props);
    }
  };
  handleRequestDetails = () => {
    if (this.props.onRequestDetailsAppointment) {
      this.props.onRequestDetailsAppointment(this.props);
    }
  };
  handleRequestReassign = () => {
    if (this.props.onRequestReassignAppointment) {
      this.props.onRequestReassignAppointment(this.props);
    }
  };
  render() {
    const { type, title, discipline, content, status } = this.props;
    return (
      <div
        className={classNames('card card-calendar card-dia week-card-appointment', type.className, {
          'card-calendar-not-applied': helpers.isNotApplied(status),
        })}
        onClick={this.handleRequestDetails}
      >
        <div className="card-body">
          <div className="row">
            <div className={classNames('col-auto pr-1', type.className)}>
              <i className={classNames('icon', type.icon)} />
            </div>
            <div className={classNames('col-auto pl-1', type.className)}>
              <CardAppointmentTitle
                className="week-card-appointment-mobile--title"
                title={title}
                type={type}
              />
              <p className="week-card-appointment-mobile--discipline">{discipline}</p>
            </div>
            <div className="col-auto week-card-appointment-mobile--content">{content}</div>
          </div>
        </div>
      </div>
    );
  }
}

WeekCardAppointmentMobile.propTypes = {
  type: PropTypes.oneOf(Object.keys(CalendarEventType).map(k => CalendarEventType[k])),
};

WeekCardAppointmentMobile.defaultProps = {
  type: CalendarEventType.LESSON,
};

export default withCalendar(WeekCardAppointmentMobile);
