/**
 * Check .env files to see other variables available to the app
 * These .env files defines settings according the type of the environment (state, production, dev)
 */

const languages = ['pt', 'es', 'en'];

function getDefaultLocale() {
  const navigatorLanguages = navigator.languages || [navigator.language];
  const baseLanguages = navigatorLanguages.map(l => l.split('-')[0]);
  for (let i = 0; i < baseLanguages.length; i++) {
    const language = baseLanguages[i];
    if (languages.includes(language)) {
      return language;
    }
  }
  return languages[0];
}

export const SM_GA_ID = 'UA-78260084-4';

// export const DEFAULT_LOCALE = getDefaultLocale();
export const DEFAULT_LOCALE = getDefaultLocale();
export const UPLOAD_PATH = process.env.SM_UPLOAD_PATH;
