import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'schoolAddModal.title',
    defaultMessage: 'Add School',
  },
  backButton: {
    id: 'schoolAddModal.backButton',
    defaultMessage: 'Back',
  },
  confirmButton: {
    id: 'schoolAddModal.confirmButton',
    defaultMessage: 'Confirm',
  },
  noButton: {
    id: 'schoolAddModal.noButton',
    defaultMessage: 'No',
  },
  yesButton: {
    id: 'schoolAddModal.yesButton',
    defaultMessage: 'Yes',
  },
  message: {
    id: 'schoolAddModal.message',
    defaultMessage: 'Do you have an access code?',
  },
  insertAccessCode: {
    id: 'schoolAddModal.insertAccessCode',
    defaultMessage: 'Insert the access code',
  },
  invalidCode: {
    id: 'schoolAddModal.invalidCode',
    defaultMessage: 'Invalid code.',
  },
});
