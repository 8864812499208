import React, { Component } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, withRouter } from 'react-router-dom';
import { Loader } from 'sm-ui';
import AppRoute from './AppRoute';
import { getSidebarRoutes } from './appHelpers';

import './App.scss';
import { loginRoutes, appRoutes } from './routes';
import AppSecurityRoute from './AppSecurityRoute';
import AppPageTitle from './AppPageTitle';
import Analytics from './AppAnalytics';

class App extends Component {
  render() {
    const { user, authorizing, leaving, checkingPlans } = this.props;
    const routes = user ? loginRoutes.concat(appRoutes) : loginRoutes;
    if (authorizing || leaving || checkingPlans) {
      return <Loader show />;
    }
    return (
      <div>
        <Analytics />
        <AppPageTitle />
        <Switch>
          {routes.map((route, key) => (
            <AppRoute {...route} key={key} />
          ))}
        </Switch>
        <AppSecurityRoute />
      </div>
    );
  }
}

App.propTypes = {
  entering: PropTypes.bool,
  leaving: PropTypes.bool,
};

export default compose(
  withRouter,
  connect(({ login, app }) => ({ ...login, user: app.user })),
)(App);
