// @flow
import moment from 'moment';
import messages from './calendarMessages';

export function weekOfMonth(date: Date) {
  return Math.ceil(date.getDate() / 7);
}

export function isNotApplied(status) {
  return [2, 6].includes(parseInt(status, 0));
}

export function weeksInMonth(date: Date) {
  const endDateMonth = moment(date)
    .endOf('month')
    .toDate();
  return weekOfMonth(endDateMonth);
}

export function toFilterCoursesGroupsOptions(filterCourses) {
  return Object.keys(filterCourses).map(key => ({
    groupLabelMessageKey: key,
    value: filterCourses[key],
  }));
}

export function createCategories(intl) {
  return Array(10)
    .fill()
    .map((_, index) => intl.formatMessage(messages.hourLabel) + (index + 1));
}

export function createHours() {
  return Array(10)
    .fill()
    .map((_, index) => index + 1);
}

export function enumerateDatesBetweenDates(startDate: Date, endDate: Date) {
  const currDate = moment(startDate).startOf('day');
  const lastDate = moment(endDate).endOf('day');
  const dates = [currDate.clone().toDate()];
  while (currDate.add(1, 'days').diff(lastDate) <= 0) {
    dates.push(currDate.clone().toDate());
  }
  return dates;
}

export function enumerateDatesByMonthAndYear(month: number, year: number) {
  const startDate = moment(`${year}/${month}`, 'YYYY/MM')
    .startOf('month')
    .toDate();
  const endDate = moment(startDate)
    .endOf('month')
    .toDate();
  return enumerateDatesBetweenDates(startDate, endDate);
}

export function enumerateWorkingDatesByMonthAndYear(month: number, year: number) {
  const startDate = moment(`${year}/${month}`, 'YYYY/MM').toDate();
  const endDate = moment(startDate)
    .endOf('month')
    .toDate();
  return enumerateDatesBetweenDates(startDate, endDate).filter(d => ![0, 6].includes(d.getDay()));
}

export function createWeekDatesFromEndDate(endDate: Date) {
  const startDate = moment(endDate)
    .subtract(6, 'days')
    .toDate();
  return enumerateDatesBetweenDates(startDate, endDate);
}

export function resolveColumns(columnsData) {
  const parsedColumnKeys = Object.keys(columnsData).map(key => key);
  const parsedColumns = parsedColumnKeys.map(el => columnsData[el]);
  parsedColumns.map((col, index) => {
    col.idIndex = (index + 1).toString();
  });
  return parsedColumns;
}

export function removeTagsHtml(string) {
  return string ? string.replace(/<\/?[^>]+(>|$)/g, '') : '';
}

export function resolveRows(rowsData) {
  const parsedRows = [];
  rowsData.length > 0 &&
    rowsData.map(row => {
      const obj = row.values ? row.values : {};
      parsedRows.push(obj);
    });
  return parsedRows;
}
