import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'school.title',
    defaultMessage: 'School',
  },
  addSchool: {
    id: 'school.addCard.addSchool',
    defaultMessage: 'Add School',
  },
});
