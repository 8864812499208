// @styleguide

import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { capitalize } from 'utils/strings';
import moment from 'moment';
import * as helpers from '../calendarHelpers';
import CardMonthHeader from './CardMonthHeader';
import CardMonthOffset from './CardMonthOffset';
import CardMonthDayCell from './CardMonthDayCell';

import './CardMonth.scss';

const CardMonth = ({ month, year, onClickDate }) => {
  const dates = helpers.enumerateDatesByMonthAndYear(month, year);
  return (
    <div className="card card-month">
      <div className="card-body">
        <div className="month-name">{capitalize(moment(dates[0]).format('MMMM'))}</div>
        <div className="card-month--days">
          <CardMonthHeader />
          <CardMonthOffset startDate={dates[0]} />
          {dates.map(date => (
            <CardMonthDayCell date={date} key={date} onClick={onClickDate} />
          ))}
        </div>
      </div>
    </div>
  );
};

CardMonth.defaultProps = {
  month: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  onClickDate: PropTypes.func,
};

export default pure(CardMonth);
