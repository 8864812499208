import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SchoolAddModalForm from './SchoolAddModalForm';

import { getStates, getCities, searchSchools, selectCity } from './schoolActions';

const initialState = {
  city: undefined,
  state: undefined,
};

class SchoolAddModalFormContainer extends Component {
  state = { ...initialState };

  componentDidMount() {
    const {
      states,
      user: { idCountry },
    } = this.props;
    if (!states.length) this.props.getStates(idCountry);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.addingSchool && !this.props.addingSchool && !this.props.error.addSchool) {
      this.props.onAddSchool();
    }
  }

  handleSelectCity = city => {
    const {
      user: { idCountry },
    } = this.props;
    const { state } = this.state;
    this.setState({ city });
    this.props.searchSchools(idCountry, state, city);
  };

  handleSelectState = state => {
    const {
      user: { idCountry },
    } = this.props;
    this.setState({ state });
    this.props.getCities(idCountry, state);
  };

  render() {
    const { states, cities, schools, schoolsSearch, onSelectSchool, error } = this.props;
    return (
      <SchoolAddModalForm
        states={states}
        onSelectState={this.handleSelectState}
        onSelectCity={this.handleSelectCity}
        onSelectSchool={onSelectSchool}
        schools={schoolsSearch}
        selectedSchools={schools}
        cities={cities}
        error={error}
      />
    );
  }
}

SchoolAddModalFormContainer.propTypes = {
  states: PropTypes.array.isRequired,
  cities: PropTypes.array.isRequired,
  schoolsSearch: PropTypes.array.isRequired,
  onSelectSchool: PropTypes.func.isRequired,
  getCities: PropTypes.func.isRequired,
  getStates: PropTypes.func.isRequired,
  selectCity: PropTypes.func.isRequired,
  addingSchool: PropTypes.bool.isRequired,
  searchSchools: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
};

const mapStateToProps = ({ school, app }) => ({ ...school, user: app.user });

export default connect(
  mapStateToProps,
  { getStates, getCities, searchSchools, selectCity },
)(SchoolAddModalFormContainer);
