import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Upload from 'react-easy-upload';

import './AvatarInput.scss';

class AvatarInput extends Component {
  handleUpload = files => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(files[0]);
    }
  };
  render() {
    const { src, placeholderSrc, label, readOnly, onChange, ...rest } = this.props;
    return (
      <Upload onUpload={this.handleUpload} {...rest} multiple={false}>
        {({ requestUpload }) => (
          <div className="avatar-input-container">
            <div
              className="change-avatar"
              style={{
                background: `url(${src}) ${
                  placeholderSrc ? `, url(${placeholderSrc})` : ''
                } no-repeat center center`,
              }}
            >
              {!readOnly && (
                <a className="ca-button" onClick={requestUpload}>
                  <i className="pe-7s-camera" />
                  {label}
                </a>
              )}
            </div>
          </div>
        )}
      </Upload>
    );
  }
}

AvatarInput.propTypes = {
  src: PropTypes.string.isRequired,
  placeholderSrc: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  accept: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool.isRequired,
};

AvatarInput.defaultProps = {
  placeholderSrc: undefined,
  accept: 'image/*',
  id: undefined,
  name: undefined,
  readOnly: false,
  onChange: undefined,
};

export default AvatarInput;
