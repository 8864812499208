import { defineMessages } from 'react-intl';

export default defineMessages({
  seeMore: {
    id: 'cardAppointmentDay.seeMore',
    defaultMessage: 'See more'
  },
  reassign: {
    id: 'cardAppointmentDay.reassign',
    defaultMessage: 'Reassign'
  },
  status: {
    id: 'cardAppointmentDay.status',
    defaultMessage: 'Status'
  }
});
