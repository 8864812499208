import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Avatar from '../avatars/Avatar';
import './Navbar.scss';

class Navbar extends Component {
  state = {
    menuVisible: false,
  };

  toggleMenu = () => {
    this.setState(({ menuVisible }) => ({ menuVisible: !menuVisible }));
  };

  closeMenu = () => {
    this.setState({ menuVisible: false });
  };

  openProfile = () => {
    const { onRequestProfile } = this.props;
    if (onRequestProfile) {
      onRequestProfile();
    }
    this.closeMenu();
  };

  openConfiguration = () => {
    const { onRequestConfiguration } = this.props;
    if (onRequestConfiguration) {
      onRequestConfiguration();
    }
    this.closeMenu();
  };

  logout = () => {
    const { onRequestLogout } = this.props;
    if (onRequestLogout) {
      onRequestLogout();
    }
    this.closeMenu();
  };

  render() {
    const {
      style,
      title,
      avatarSrc,
      avatarPlaceholderSrc,
      brandSrc,
      editLabel,
      profileLabel,
      configurationLabel,
      logoutLabel,
      greeting,
      userName,
      brandAppSrc,
      country,
    } = this.props;
    const { menuVisible } = this.state;
    return (
      <nav className="navbar" style={style}>
        <div className="container-fluid justify-content-start">
          <a className="navbar-brand" href="/">
            <img src={brandSrc} alt="SM" />
          </a>
          <div className="navbar-content">
            <img className="navbar-icon" src={brandAppSrc} alt={title} />
            <span className="navbar-title">{title}</span>
          </div>
          <button className="hamburger" id="mobile-menu" onClick={this.toggleMenu}>
            <i className="fa fa-bars" />
          </button>
          <div className={classNames('user-dropdown ml-auto', { active: menuVisible })}>
            <div className="ud-greeting">
              {greeting}
              <div className="ud-username">{userName}</div>
            </div>
            <div className="ud-dropdown">
              <div className="ud-link" href="#">
                <div className="avatar-profile">
                  <Avatar src={avatarSrc} placeholderSrc={avatarPlaceholderSrc} alt="" fullSize />
                  <div className="avatar-country">{country}</div>
                </div>
                <div className="fa fa-angle-down navbar-toogle-menu" />
                {/* <a className="mobile-button btn btn-outline-light btn-sm" onClick={onRequestEdit}>
              {editLabel}
</a> */}
              </div>
              <div className="ud-menu-wrapper">
                <ul className="ud-menu">
                  <li>
                    <a onClick={this.openProfile}>{profileLabel}</a>
                  </li>
                  <li>
                    <a onClick={this.openConfiguration}>{configurationLabel}</a>
                  </li>
                  <li>
                    <a onClick={this.logout}>{logoutLabel}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

Navbar.propTypes = {
  style: PropTypes.object,
  configurationLabel: PropTypes.string.isRequired,
  logoutLabel: PropTypes.string.isRequired,
  profileLabel: PropTypes.string,
  editLabel: PropTypes.string.isRequired,
  avatarSrc: PropTypes.string.isRequired,
  avatarPlaceholderSrc: PropTypes.string.isRequired,
  brandSrc: PropTypes.string.isRequired,
  brandAppSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  greeting: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  onRequestEdit: PropTypes.func.isRequired,
  onRequestProfile: PropTypes.func,
  onRequestConfiguration: PropTypes.func.isRequired,
  onRequestLogout: PropTypes.func.isRequired,
};

Navbar.defaultProps = {
  style: undefined,
};

export default Navbar;
