import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { injectIntl } from 'react-intl';
import { Button, Loader } from 'sm-ui';
import ReportBar from './ReportBar';
import messages from './reportsMessages';
import './ReportCard.scss';
import get from 'lodash/get';

const ReportCard = props => {
  const [infoRequested, setInfoRequested] = useState(false);
  function handleClickLoad() {
    setInfoRequested(true);
    if (props.onLoad) props.onLoad();
  }

  const periods = get(props.status, '[0][0].planApplied') || {};
  return (
    <div className="card card-school report-card">
      <div className="card-body link ">
        <Row>
          <Col>
            <div
              className="initials"
              style={{ backgroundColor: 'rgb(136, 178, 214)', color: 'rgb(255, 255, 255)' }}
            >
              {props.collection.abbreviation}
            </div>
            <div className>
              <h5
                className="card-title my-3"
                style={{ color: 'rgb(136, 178, 214)', cursor: 'pointer' }}
              >
                {props.collection.name}
              </h5>
              <p>{props.catalogingPath}</p>
              <p style={{ color: 'rgb(136, 178, 214)' }}>{props.fullName}</p>
            </div>
          </Col>
          {!infoRequested && (
            <Col className="d-flex">
              <Button color="light" className="btn-load-info" onClick={handleClickLoad}>
                {props.intl.formatMessage(messages.loadInfo)}
              </Button>
            </Col>
          )}
          {infoRequested &&
            props.statusLoading && (
              <Col className="d-flex align-items-center justify-center">
                <Loader simple />
              </Col>
            )}
          {infoRequested &&
            !props.statusLoading && (
              <Col>
                <Row>
                  <Col>
                    <ReportBar
                      title={props.intl.formatMessage(messages.firstTwoMonths)}
                      percentage={Number(periods.Period_1)}
                    />
                  </Col>
                  <Col>
                    <ReportBar
                      title={props.intl.formatMessage(messages.secondTwoMonths)}
                      percentage={Number(periods.Period_2)}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <ReportBar
                      title={props.intl.formatMessage(messages.thirdTwoMonths)}
                      percentage={Number(periods.Period_3)}
                    />
                  </Col>
                  <Col>
                    <ReportBar
                      title={props.intl.formatMessage(messages.fourthTwoMonths)}
                      percentage={Number(periods.Period_4)}
                    />
                  </Col>
                </Row>
              </Col>
            )}
        </Row>
      </div>
    </div>
  );
};

ReportBar.defaultProps = {
  collection: {},
};

export default injectIntl(ReportCard);
