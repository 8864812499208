import React, { Component } from 'react';
import classNames from 'classnames';

class StatusExtended extends Component {
  state = {
    collapsed: false,
    active: false,
  };

  toggleCollapse = e => {
    e.stopPropagation();
    e.preventDefault();
    this.setState(({ collapsed }) => ({
      collapsed: !collapsed,
    }));
  };

  toggleActive = () => {
    this.setState(({ active }) => ({ active: !active }));
  };

  render() {
    const { collapsed, active } = this.state;

    return (
      <div className={classNames('fixed-actions', { active })}>
        <a onClick={this.toggleActive} style={{ textDecoration: 'none', color: '#8D8D8D' }}>
          <i className="ti-info-alt" />
          <br />
          Status
        </a>
        <div className="cm-floating p-3" id="cm-accordion" style={{ width: 250 }}>
          <div className="cm-title">
            <i className="ti-info-alt" />
            Status
          </div>
          <a
            className={classNames('accordion-title text-danger', { collapsed })}
            onClick={this.toggleCollapse}
            aria-expanded={!collapsed}
          >
            <strong>1D - Manhã: </strong>
            1º bimestre
            <i className="fa fa-chevron-down" />
          </a>
          <div className={classNames('multi-collapse collapse', { show: collapsed })} style={{}}>
            <div className="bar-chart horizontal-bars-chart my-2">
              <div className="bar-chart-wrapper">
                <span className="legend">Planejado</span>
                <div className="bc-row">
                  <div className="bc-bar bc--yellow" style={{ width: '60%' }}>
                    <div className="bc-bar-value">60%</div>
                  </div>
                </div>
                <span className="legend">Executado</span>
                <div className="bc-row">
                  <div className="bc-bar bc--red" style={{ width: '40%' }}>
                    <div className="bc-bar-value">40%</div>
                  </div>
                </div>
              </div>
            </div>
            <p>
              <strong>2º bimestre:</strong>
              Não iniciado
            </p>
            <p>
              <strong>3º bimestre:</strong>
              Não iniciado
            </p>
            <p>
              <strong>4º bimestre:</strong>
              Não iniciado
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default StatusExtended;
