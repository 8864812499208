/* eslint-disable */
import { success, failure, request } from 'app/actions';
import * as actions from './plannerActions';
import helpers from './helpers';

export const EditionState = {
  PENDING: 1,
  SAVING: 2,
  SAVED: 3,
  FAILED: 4,
};

const initialState = {
  isLoading: false,
  planner: undefined,
  table: {
    columns: [],
    rows: [],
  },
  editionState: EditionState.PENDING,
  files: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case request(actions.UPDATE_METADATA):
      return { ...state, isLoading: true };
    case success(actions.UPDATE_METADATA):
      return { ...state, isLoading: false };
    case failure(actions.UPDATE_METADATA):
      return { ...state, isLoading: false };
    case request(actions.UPDATE_CELL_WITH_FILE):
    case request(actions.UPDATE_CELL):
      return { ...state, isLoading: true };
    case success(actions.UPDATE_CELL_WITH_FILE):
    case success(actions.UPDATE_CELL):
      return { ...state, isLoading: false };
    case failure(actions.UPDATE_CELL_WITH_FILE):
    case failure(actions.UPDATE_CELL):
      return { ...state, isLoading: false };
    case actions.UPLOAD_FILE:
      return { ...state, files: action.payload.files };
    case request(actions.GET_PLANS_TABLE):
      return { ...state };
    case success(actions.GET_PLANS_TABLE): {
      const contentStringify = JSON.stringify(action.payload.content);
      const contentParsed = JSON.parse(contentStringify);
      const rows = contentParsed.table.rows
        ? contentParsed.table.rows.map(row => ({
            ...row,
            values: row.values.map(el => {
              let htmlValue = null;
              let tags = [];
              if (el.value) {
                htmlValue = document.createRange().createContextualFragment(el.value);
                if (htmlValue.querySelectorAll('strong').length > 0) {
                  tags.push('strong');
                }
                if (htmlValue.querySelectorAll('em').length > 0) {
                  tags.push('em');
                }
                if (htmlValue.querySelectorAll('s').length > 0) {
                  tags.push('s');
                }
                if (htmlValue.querySelectorAll('u').length > 0) {
                  tags.push('u');
                }
                if (htmlValue.querySelectorAll('sub').length > 0) {
                  tags.push('sub');
                }
                if (htmlValue.querySelectorAll('sup').length > 0) {
                  tags.push('sup');
                }
              }
              return {
                ...el,
                tags,
              };
            }),
          }))
        : [];
      contentParsed.table.rows = rows;
      const parsedColumns = helpers.resolveColumns(contentParsed.table.columns);
      const parsedRows = helpers.resolveRows(rows);

      return {
        ...state,
        planner: contentParsed,
        table: {
          columns: parsedColumns,
          rows: parsedRows,
        },
      };
    }
    case failure(actions.GET_PLANS_TABLE):
      return {
        ...state,
        isLoading: true,
      };
    case request(actions.SAVE_PLAN):
      return { ...state, isLoading: true, editionState: initialState.editionState };
    case success(actions.SAVE_PLAN):
      return {
        ...state,
        isLoading: false,
        editionState: EditionState.SAVED,
      };
    case failure(actions.SAVE_PLAN):
      return {
        ...state,
        isLoading: false,
        editionState: EditionState.FAILED,
      };
    case request(actions.DOWNLOAD_FILE):
      return { ...state, isLoading: true };
    case success(actions.DOWNLOAD_FILE): {
      return { ...state, isLoading: false };
    }
    case failure(actions.DOWNLOAD_FILE):
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default reducer;
