import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import get from 'lodash/get';

export const getDisciplinesStatus = statusContent => {
  if (!statusContent || !statusContent.length) return [];
  return statusContent.reduce(
    (sc1, sc2) => ({
      ...sc1,
      [sc2.idcourse]: {
        ...sc2,
      },
    }),
    {},
  );
};

export const getYears = plansSchedule => {
  if (plansSchedule) {
    const allYears = plansSchedule.map(plan => new Date(plan.scheduled).getFullYear());
    return uniq(allYears).sort((a, b) => b - a);
  }
  return [];
};

export const enhancePlansScheduled = (plansScheduled = []) =>
  plansScheduled.map(plan => ({
    ...plan,
    id: plan.id,
    title: get(plan, 'discipline.name') || get(plan, 'collection.name') || '',
    abbreviation:
      get(plan, 'discipline.abbreviation') || get(plan, 'collection.abbreviation') || '',
    description: plan.catalogingPath,
    actionLabel: '',
    actionClass: 'success',
    relatedClass: plan.fullname || null,
    icon: get(plan, 'discipline.icon') || '',
    year: new Date(plan.added).getFullYear(),
  }));

export function makeKey({ idPlan, idPlanSchedule, idsCourses }) {
  return JSON.stringify({ idPlan, idPlanSchedule, idsCourses });
}

export function getListCategories(appointments) {
  const catalogingList = appointments.map(item => ({
    id: item.idCataloging,
    value: item.idCataloging,
    label: item.discipline,
  }));
  return uniqBy(catalogingList, item => item.id);
}

export function getListClasses(appointments) {
  const classesList = appointments.map(item => ({
    id: item.idClass,
    label: item.class,
    value: item.idClass,
  }));
  return uniqBy(classesList, item => item.id);
}
