import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Checkbox from './Checkbox';

import './CheckboxList.scss';

class CheckboxList extends Component {
  static getDerivedStateFromProps(props, state) {
    if (props.values !== state.prevValues) {
      return {
        values: props.values.slice(),
        prevValues: props.values,
      };
    }
    return null;
  }

  state = {
    values: [],
  };

  handleChange = value => e => {
    const { checked } = e.target;
    const values = checked
      ? [...this.state.values, value]
      : this.state.values.filter(v => v !== value);
    this.setState({ values });
    if (this.props.onChange) {
      this.props.onChange(values, e);
    }
  };

  render() {
    const { items, label, itemLabel, itemValue, disabled } = this.props;
    const { values } = this.state;
    return (
      <div className="form-group checkbox-list">
        {label && <label>{label}</label>}
        <ul>
          {items.map(item => (
            <li key={item[itemValue]}>
              <Checkbox
                checked={values.includes(item[itemValue])}
                label={item[itemLabel]}
                disabled={disabled}
                onChange={this.handleChange(item[itemValue])}
              />
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

CheckboxList.propTypes = {
  label: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOf([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ),
  itemLabel: PropTypes.string,
  itemValue: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.oneOf([PropTypes.string, PropTypes.number])),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

CheckboxList.defaultProps = {
  values: undefined,
  items: [],
  itemLabel: 'label',
  itemValue: 'value',
  label: undefined,
  onChange: undefined,
  disabled: false,
};

export default CheckboxList;
