import React, { PureComponent, Fragment } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage, intlShape } from 'react-intl';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import find from 'lodash/find';
import unionBy from 'lodash/unionBy';
import { Input, Select, Row, Col, Alert, Button, Loader } from 'sm-ui';

import SchoolSelect from '../school/SchoolSelect';
import { EditionStatus } from './registerReducer';

import messages from './registerMessages';

import './RegisterForm.scss';

const ID_COUNTRY_BRAZIL = 14;
const idsCountries = [ID_COUNTRY_BRAZIL];

const RegisterSchema = Yup.object().shape({
  idSchool: Yup.number().required('errorRequired'),
  idCountry: Yup.number().required('errorRequired'),
  city: Yup.string().required('errorRequired'),
  state: Yup.string().required('errorRequired'),
});

const initialValues = {
  city: '',
  state: '',
  idSchool: '',
};

const enhanceForm = withFormik({
  mapPropsToValues: () => ({}),
  initialValues,
  validationSchema: RegisterSchema,
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },
});

class RegisterSchoolForm extends PureComponent {
  state = {
    school: null,
    groups: [],
    isLoading: true,
  };

  componentDidMount() {
    setTimeout(() => {
      this.handleSelectCountry();
      this.setState({ isLoading: false });
    }, 1000);
  }

  componentDidUpdate(prevProps) {
    const { intl, values } = this.props;

    if (prevProps.values.idCountry !== values.idCountry) {
      this.props.getStates(values.idCountry);
    }

    if (prevProps.values.state !== values.state) {
      this.props.getCities(values.idCountry, values.state);
    }

    if (prevProps.values.city !== values.city) {
      this.props.searchSchools(values.idCountry, values.state, values.city);
    }
  }

  getMessageError = field => {
    const { intl, errors } = this.props;
    if (errors && errors[field]) {
      return intl.formatMessage(messages[errors[field]]);
    }
    return undefined;
  };

  isValid = field => {
    const { errors, error, touched } = this.props;
    if (errors && errors[field] && touched[field]) {
      return false;
    }
    if (Object.keys(error).length > 0 && error.code !== 1) return false;
    return touched[field] ? true : undefined;
  };

  handleSelectSchool = school => {
    this.setState({ school });
    this.props.setFieldValue('idSchool', school.id);
  };

  handleSelectCountry = event => {
    let value = '14';
    // let value = this.props.values.idCountry;
    if (event) {
      // eslint-disable-next-line prefer-destructuring
      value = event.target.value;
    }

    let groups = [];

    const selectedCountry = find(this.props.selectCountries, el => el.id === value);
    const selectedCountryDetails = this.props.countries[selectedCountry.label];
    const selectedCountryGroups = unionBy(selectedCountryDetails, 'groups').find(el => el.groups);
    if (selectedCountryGroups && selectedCountryGroups.groups) {
      groups = selectedCountryGroups.groups.map(el => ({
        ...el,
        value: el.id,
        label: el.name,
      }));
    }

    this.setState({ groups });

    this.props.setFieldValue('idCountry', value);
    this.props.setFieldValue('state', initialValues.state);
    this.props.setFieldValue('city', initialValues.city);
    this.props.setFieldValue('idSchool', initialValues.idSchool);
    this.setState({ school: null });
  };

  handleSelectState = event => {
    const { value } = event.target;
    this.props.setFieldValue('state', value);
    this.props.setFieldValue('city', initialValues.city);
    this.props.setFieldValue('idSchool', initialValues.idSchool);
    this.setState({ school: null });
  };

  handleSelectCity = event => {
    const { value } = event.target;
    this.props.setFieldValue('city', value);
    this.props.setFieldValue('idSchool', initialValues.idSchool);
    this.setState({ school: null });
  };

  renderErrors() {
    const { error } = this.props;
    if (!error || !error.code) return null;
    return (
      <Alert type="danger">
        <FormattedMessage {...messages[`errorCode${error.code}`] || messages.errorCode500} />
      </Alert>
    );
  }

  renderAlerts() {
    const { status } = this.props;
    if (status !== EditionStatus.SAVED) return null;
    return (
      <Fragment>
        <Alert type="success" className="text-center">
          <FormattedMessage {...messages.saved}>{txt => <div>{txt}</div>}</FormattedMessage>
        </Alert>
        <FormattedMessage {...messages.login}>
          {txt => (
            <Button color="success" className="btn-block mt-2" onClick={this.redirectLogin}>
              {txt}
            </Button>
          )}
        </FormattedMessage>
      </Fragment>
    );
  }

  render() {
    const {
      handleSubmit,
      values,
      schoolsSearch,
      cities,
      states,
      intl,
      selectCountries,
      langCountries,
    } = this.props;
    const { school } = this.state;
    const errors = this.renderErrors();
    // const alerts = this.renderAlerts();

    let register = false;

    langCountries.forEach(el => {
      if (el.register === '1' && el.access === '2' && intl.locale === el.language) {
        register = true;
      }
    });

    return (
      <React.Fragment>
        <Loader show={this.state.isLoading} />
        <form className="col ml-auto mr-auto register-form" onSubmit={handleSubmit}>
          <Row style={{textAlign: "left"}}>
            <Col style={{display:"none"}}>
              <FormattedMessage {...messages.country}>
                {txt => (
                  <FormattedMessage {...messages.selectCountryPlaceholder}>
                    {txtPlaceholder => (
                      <Select
                        label={txt}
                        name="idCountry"
                        valid={this.isValid('idCountry')}
                        invalidFeedback={this.getMessageError('idCountry')}
                        selectLabel={txtPlaceholder}
                        value={values.idCountry}
                        options={selectCountries}
                        onChange={this.handleSelectCountry}
                        disabled
                      />
                    )}
                  </FormattedMessage>
                )}
              </FormattedMessage>
            </Col>
            <Col>
              <FormattedMessage {...messages.state}>
                {txt => (
                  <FormattedMessage {...messages.selectStatePlaceholder}>
                    {txtPlaceholder => (
                      <Select
                        label={txt}
                        selectLabel={txtPlaceholder}
                        name="state"
                        disabled={!values.idCountry}
                        value={values.state}
                        valid={this.isValid('state')}
                        invalidFeedback={this.getMessageError('state')}
                        itemValue="state"
                        itemLabel="state"
                        options={states}
                        onChange={this.handleSelectState}
                      />
                    )}
                  </FormattedMessage>
                )}
              </FormattedMessage>
            </Col>
            <Col>
              <FormattedMessage {...messages.city}>
                {txt => (
                  <FormattedMessage {...messages.selectCityPlaceholder}>
                    {txtPlaceholder => (
                      <Select
                        label={txt}
                        selectLabel={txtPlaceholder}
                        itemValue="city"
                        valid={this.isValid('city')}
                        invalidFeedback={this.getMessageError('city')}
                        disabled={!values.state}
                        value={values.city}
                        itemLabel="city"
                        name="city"
                        options={cities}
                        onChange={this.handleSelectCity}
                      />
                    )}
                  </FormattedMessage>
                )}
              </FormattedMessage>
            </Col>
          </Row>
          {values.city && (
            <Row>
              <Col>
                <FormattedMessage {...messages.school}>
                  {txt => (
                    <FormattedMessage {...messages.searchSchoolPlaceholder}>
                      {txtPlaceholder => (
                        <FormattedMessage {...messages.noSchoolFounded}>
                          {txtNoSchoolFounded => (
                            <SchoolSelect
                              label={txt}
                              value={school}
                              valid={this.isValid('idSchool')}
                              invalidFeedback={this.getMessageError('idSchool')}
                              disabled={cities.length === 0}
                              placeholder={txtPlaceholder}
                              // eslint-disable-next-line react/jsx-no-bind
                              noOptionsMessage={() => txtNoSchoolFounded}
                              itemValue="id"
                              itemLabel="name"
                              options={schoolsSearch}
                              onChange={this.handleSelectSchool}
                            />
                          )}
                        </FormattedMessage>
                      )}
                    </FormattedMessage>
                  )}
                </FormattedMessage>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <div className="school-add-modal-form-details">
                {school && (
                  <div>
                    <strong>{school.name}</strong>
                    <br />
                    <span>{school.address}</span>
                    <br />
                    <span>{school.zipcode}</span>
                    <br />
                    <span>{school.city}</span> - <span>{school.state}</span>
                  </div>
                )}
                {!school && (
                  <FormattedMessage {...messages.noSchoolSelected}>
                    {txt => <span>{txt}</span>}
                  </FormattedMessage>
                )}
              </div>
            </Col>
          </Row>
          <Button
            color="primary"
            large
            className="btn-block"
            type="submit"
            value={intl.formatMessage(messages.save)}
            style={{marginTop:"30px"}}
            // disabled={!isValid}
          >
            {intl.formatMessage(messages.save)}
          </Button>
          {errors}
          {/* {alerts} */}
        </form>
      </React.Fragment>
    );
  }
}

RegisterSchoolForm.propTypes = {
  intl: intlShape.isRequired,
};

export default compose(
  enhanceForm,
  injectIntl,
  withRouter,
)(RegisterSchoolForm);
