import React from 'react';
import { compose, pure } from 'recompose';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const loginRoutes = ['/login', '/register', '/change-password'];

const AppSecurityRoute = ({ user, location, hasPlansScheduled }) => {
  if (user) {
    if (['/'].concat(loginRoutes).includes(location.pathname)) {
      const initialPath = hasPlansScheduled ? '/schedule' : '/plans';
      return <Redirect to={initialPath} />;
    }
    return null;
  }
  if (!loginRoutes.includes(location.pathname)) return <Redirect to="/login" />;
  return null;
};

AppSecurityRoute.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
  hasPlansScheduled: PropTypes.bool,
};

const mapStateToProps = ({ app, login }) => ({
  user: app.user,
  hasPlansScheduled: login.hasPlansScheduled,
});

export default compose(
  connect(mapStateToProps),
  withRouter,
  pure,
)(AppSecurityRoute);
