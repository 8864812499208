/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { injectIntl } from 'react-intl';
import last from 'lodash/last';

import DropzoneComponent from './DropzoneComponent';
import TextEditor from './TextEditor';

import { Modal, Select } from 'sm-ui';

import messages from '../plannerMessages';

class EditModal extends React.PureComponent {
  state = {
    value: '',
    htmlValue: '',
  };

  componentWillReceiveProps(nextProps) {
    let value = nextProps.label;

    this.props.selectedElement.tags &&
      this.props.selectedElement.tags.map(el => (value = `<${el}>${value}</${el}>`));

    if (String(value).includes('&lt;br/&gt;')) {
      const topics = String(value).split('&lt;br/&gt;');
      const topicsWithNewLine = topics.map(elem => `<p>${elem}</p>`);
      const valueWithNewLine = topicsWithNewLine.join('');
      this.setState({ value: valueWithNewLine });
    } else {
      this.setState({ value });
    }
  }

  onKeyDown = event => {
    if (event.key === 'Enter') {
      const { selectedElement, label, onSubmit } = this.props;
      event.preventDefault();
      event.stopPropagation();
      onSubmit(selectedElement.id, label);
    }
  };

  handleUpdateValues = value => {
    let valueWithSpace = value;
    if (value.includes('</p><p>')) {
      const arrayOfLines = value.split('</p><p>');
      valueWithSpace = arrayOfLines.join('</p>&lt;br/&gt;<p>');
    }

    this.setState({
      value,
      htmlValue: valueWithSpace.toString(),
    });
  };

  render() {
    const {
      intl,
      isOpen,
      label,
      editionType,
      selectedColumn,
      selectedElement,
      onClose,
      onCellUpdateWithFile,
      onMetadataUpdate,
      onCellUpdate,
      onLabelChange,
    } = this.props;
    const { value } = this.state;
    const data = [];

    if (selectedColumn.options) {
      selectedColumn.options.map(el =>
        data.push({
          id: el,
          value: el,
          label: el,
        }),
      );
    }

    return (
      <Modal
        id="modalEdit___core"
        name="modalEdit___core"
        show={isOpen}
        onClose={onClose}
        buttons={[
          {
            id: 1,
            label: intl.formatMessage(messages.cancel),
            onClick: () => onClose(false),
          },
          {
            id: 2,
            label: intl.formatMessage(messages.save),
            onClick: () => {
              if (editionType === 'file') {
                const { idPlan, idLesson, idMetadata } = selectedElement;
                onCellUpdateWithFile({
                  idPlan,
                  idLesson,
                  idMetadata,
                  idFiles: selectedElement.id,
                  files: this.props.files,
                });
              }
              if (editionType === 'simple') {
                onMetadataUpdate(selectedElement.id, label);
              }
              if (editionType === 'advanced') {
                onCellUpdate(
                  selectedElement.idPlan,
                  selectedElement.idLesson,
                  selectedElement.idMetadata,
                  selectedElement.id,
                  this.state.htmlValue,
                );
              }
            },
          },
        ]}
      >
        {editionType === 'file' ? (
          <React.Fragment>
            <h4>{intl.formatMessage(messages.uploadElement)}</h4>
            {/* Dropzone for file upload */}
            <DropzoneComponent selectedElement={selectedElement} />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <h4>{intl.formatMessage(messages.editElement)}</h4>
            {/* Form for simple input */}
            {editionType === 'simple' && (
              <div>
                <input
                  id="modalEdit___input"
                  name="modalEdit___input"
                  autoFocus
                  type="text"
                  value={value}
                  onKeyDown={this.onKeyDown}
                  onChange={e => onLabelChange(e.target.value)}
                  style={{
                    padding: '5px',
                    marginTop: '20px',
                    width: '80%',
                  }}
                />
              </div>
            )}
            {/* Form for advanced input with text customization */}
            {editionType === 'advanced' && (
              <React.Fragment>
                {selectedColumn.options && (
                  <Select
                    id="modalEdit___select"
                    name="modalEdit___select"
                    options={data}
                    value={value}
                    onChange={e => this.handleUpdateValues(e.target.value)}
                  />
                )}
                {selectedColumn.type === 'text' && (
                  <div>
                    <TextEditor
                      id="modalEdit___textEditor"
                      name="modalEdit___textEditor"
                      value={value}
                      onChange={value => this.handleUpdateValues(value)}
                    />
                  </div>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = ({ planner }) => ({ files: planner.files });

export default compose(
  injectIntl,
  connect(mapStateToProps),
)(EditModal);
