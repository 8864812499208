// @styleguide

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { promisify } from 'app/actions';
import { Modal, Alert, Loader, InputGroup, Button, Row, Col, RoundButton } from 'sm-ui';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { last } from 'lodash';
import ClassManagementCoursesContainer from './ClassManagementCoursesContainer';
import ClassManagementCoursesHeader from './ClassManagementCoursesHeader';
import ClassManagementSchoolYearModal from './ClassManagementSchoolYearModal';

import actions from './classManagementActions';
import messages from './classManagementMessages';

const initialState = {
  coursesSelected: [],
  errorsMessages: [],
  isLoading: false,
  schoolYearModal: {
    isOpen: false,
  },
  idSchoolSelected: '',
};

class ClassManagementCoursesModal extends Component {
  state = initialState;

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.saved !== this.props.saved && this.props.saved) {
      this.props.resetModal();
      setTimeout(() => {
        this.handleCloseSchoolYearModal();
      }, 500);
    }
  }

  handleOpenSchoolYearModal = () => {
    if (this.state.coursesSelected.length > 0)
      this.setState({
        schoolYearModal: {
          isOpen: true,
        },
      });
  };

  handleCloseSchoolYearModal = () => {
    this.setState({
      schoolYearModal: {
        isOpen: false,
      },
    });
  };

  insertSelected = () => {
    setTimeout(() => {
      const courseAux = last(this.props.courses);
      const c = this.state.coursesSelected.slice();
      c.push(courseAux);
      this.setState({ coursesSelected: c });
    }, 500);
  };

  continue = async () => {
    this.setState({ isLoading: true });
    const { schedulePlan, idPlan, period } = this.props;
    const { coursesSelected } = this.state;
    const idsCourses = [];
    coursesSelected.forEach(el => {
      if (el.idCourse !== 'NEW_COURSE') {
        if (!idsCourses.includes(el.idCourse)) {
          idsCourses.push(el.idCourse);
        }
      }
    });
    const params = { idPlan, idsCourses, period };
    // console.log("params", params)
    try {
      await schedulePlan(params);
      this.close();
      if (idsCourses.length === 1) {
        const calendarParams = { idPlan, idCourse: idsCourses[0] };
        this.props.history.push(`/calendar?${queryString.stringify(calendarParams)}`);
      } else {
        this.props.history.push('/schedule');
      }
    } finally {
      this.setState({ isLoading: false });
    }
  };

  validateAndContinue = () => {
    const errorsMessages = [];
    const { coursesSelected } = this.state;
    if (coursesSelected.length === 0) {
      errorsMessages.push('hasErrorCourse');
    }
    coursesSelected.forEach(el => {
      if (el.idCourse !== 'NEW_COURSE') {
        // if (coursesSelected.some(course => !course.courseDaysHours)) {
        //   errorsMessages.push('hasErrorHour');
        // }
        if (!el.courseDaysHours) {
          errorsMessages.push('hasErrorHour');
        }
      }
    });
    this.setState({ errorsMessages });
    if (errorsMessages.length === 0) {
      this.continue();
    }
  };

  handleSelectCourses = coursesSelected => {
    this.setState({
      coursesSelected,
    });
  };

  close = () => {
    this.setState(initialState);
    if (this.props.resetModal) {
      this.props.resetModal();
    }
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  // eslint-disable-next-line react/sort-comp
  renderErrors() {
    const { error } = this.props;
    const { errorsMessages } = this.state;
    return (
      <div>
        {errorsMessages.map(message => (
          <Alert type="danger" className="text-left" key={message}>
            <FormattedMessage {...messages[message]} />
          </Alert>
        ))}
        {error.code && (
          <Alert type="danger" className="text-left">
            <FormattedMessage
              {...(messages[`errorCode${error.code}`] || messages.errorCode500)}
              values={error.info || {}}
            />
          </Alert>
        )}
      </div>
    );
  }

  handleSelectSchool = idSchoolSelected => this.setState({ idSchoolSelected });

  render() {
    const { addNewCourse, show, onRequestRemove, idPlan, planDetails } = this.props;
    const buttons = [
      {
        label: <FormattedMessage {...messages.cancel} />,
        onClick: this.close,
      },
      {
        id: 2,
        label: <FormattedMessage {...messages.continue} />,
        onClick: this.validateAndContinue,
        primary: true,
      },
    ];
    const errorAlerts = this.renderErrors();

    return (
      <React.Fragment>
        <Loader show={this.state.isLoading} />
        <Modal
          id="classManagementCoursesModal"
          buttons={buttons}
          show={show}
          large
          onClose={this.close}
        >
          <ClassManagementCoursesHeader onRequestAddCourse={addNewCourse} {...planDetails} />
          {errorAlerts}
          <ClassManagementCoursesContainer
            idPlan={idPlan}
            onRequestRemove={onRequestRemove}
            onSelectCourses={this.handleSelectCourses}
            handleOpenSchoolYearModal={this.handleOpenSchoolYearModal}
            handleSelectSchool={this.handleSelectSchool}
            insertSelected={this.insertSelected}
          />
        </Modal>
        <ClassManagementSchoolYearModal
          isOpen={this.state.schoolYearModal.isOpen}
          handleCloseSchoolYearModal={this.handleCloseSchoolYearModal}
          idSchoolSelected={this.state.idSchoolSelected}
        />
      </React.Fragment>
    );
  }
}

ClassManagementCoursesModal.propTypes = {};

const mapStateToProps = ({ classManagement, school }) => ({
  ...classManagement,
  saved: school.saved,
});

export default compose(
  connect(mapStateToProps, promisify(actions)),
  withRouter,
)(ClassManagementCoursesModal);
