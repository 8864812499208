import { defineMessages } from 'react-intl';

export default defineMessages({
  headerTitle: {
    id: 'calendar.header.title',
    defaultMessage: 'Edit Schedule',
  },
  viewOptionDay: {
    id: 'calendar.view.option.day',
    defaultMessage: 'Day',
  },
  viewOptionWeek: {
    id: 'calendar.view.option.week',
    defaultMessage: 'Week',
  },
  viewOptionMonth: {
    id: 'calendar.view.option.month',
    defaultMessage: 'Month',
  },
  viewOptionYear: {
    id: 'calendar.view.option.year',
    defaultMessage: 'Year',
  },
  print: {
    id: 'calendar.print',
    defaultMessage: 'Print',
  },
  tablePreviousText: {
    id: 'calendar.tablePreviousText',
    defaultMessage: 'Previous',
  },
  tableNextText: {
    id: 'calendar.tableNextText',
    defaultMessage: 'Next',
  },
  tableLoadingText: {
    id: 'calendar.tableLoadingText',
    defaultMessage: 'Loading',
  },
  tableNoDataText: {
    id: 'calendar.tableNoDataText',
    defaultMessage: 'Nothing to show..',
  },
  tablePageText: {
    id: 'calendar.tablePageText',
    defaultMessage: 'Page',
  },
  tableOfText: {
    id: 'calendar.tableOfText',
    defaultMessage: 'of',
  },
  tableRowsText: {
    id: 'calendar.tableRowsText',
    defaultMessage: '',
  },
  eventTypeClass: {
    id: 'calendar.eventType.class',
    defaultMessage: 'Class',
  },
  eventTypeSuperClass: {
    id: 'calendar.eventType.superClass',
    defaultMessage: 'Super Class',
  },
  eventTypeEvent: {
    id: 'calendar.eventType.event',
    defaultMessage: 'Event',
  },
  eventTypeAssessment: {
    id: 'calendar.eventType.assessment',
    defaultMessage: 'Assessment',
  },
  all: {
    id: 'calendar.all',
    defaultMessage: 'All',
  },
  errorReassignCode13: {
    id: 'calendar.reassign.errorCode.13',
    defaultMessage: 'Lesson already scheduled in this date',
  },
  errorReassignCode28: {
    id: 'calendar.reassign.errorCode.28',
    defaultMessage: 'Sorry, it is not posible to use the automatic reasign on dates in the past.',
  },
  errorReassignCode500: {
    id: 'calendar.reassignModal.errorCode.500',
    defaultMessage: 'An error occurred. Please try again later.',
  },
  reassignModalCancelButton: {
    id: 'calendar.reassignModal.button.cancel',
    defaultMessage: 'Cancel',
  },
  reassignModalReassignButton: {
    id: 'calendar.reassignModal.button.continue',
    defaultMessage: 'Reassign',
  },
  reassignModalPlaceholder: {
    id: 'calendar.reassignModal.observation',
    defaultMessage: 'Observation: ',
  },
  reassignModalMessage: {
    id: 'calendar.reassignModal.reassignModalMessage',
    defaultMessage: 'Select the date you want to reassign',
  },
  reassignModalAutomaticReassignment: {
    id: 'calendar.reassignModal.automaticReassignment',
    defaultMessage: 'Automatic reassignment',
  },
  reassignModalTitle: {
    id: 'calendar.reassignModal.title',
    defaultMessage: 'Reassign',
  },
  groupLabelFilterCourse_teachersCourses: {
    id: 'calendar.groupLabelFilterCourse_teachersCourses',
    defaultMessage: 'Teachers Courses',
  },
  groupLabelFilterCourse_myCourses: {
    id: 'calendar.groupLabelFilterCourse_myCourses',
    defaultMessage: 'My Courses',
  },
  classDetailModalSuperclassClose: {
    id: 'calendar.detailModal.superclass.close',
    defaultMessage: 'Close',
  },
  classDetailModalSuperclassDate: {
    id: 'calendar.detailModal.superclass.date',
    defaultMessage: 'Date',
  },
  classDetailModalSuperclassDuration: {
    id: 'calendar.detailModal.superclass.duration',
    defaultMessage: 'Duration',
  },
  classDetailModalSuperclassActivities: {
    id: 'calendar.detailModal.superclass.activities',
    defaultMessage: 'Activities',
  },
  classDetailModalSuperclassClass: {
    id: 'calendar.detailModal.superclass.class',
    defaultMessage: 'Class',
  },
  classDetailModalSuperclassClasses: {
    id: 'calendar.detailModal.superclass.classes',
    defaultMessage: 'Classes',
  },
  classDetailModalSuperclassDownload: {
    id: 'calendar.detailModal.superclass.download',
    defaultMessage: 'Click here to download',
  },
  classDetailModalSuperclassNotFounded: {
    id: 'calendar.detailModal.superclass.notFounded',
    defaultMessage: 'File not founded',
  },
  noneHour: {
    id: 'calendar.noneHour',
    defaultMessage: 'None',
  },
  hourLabel: {
    id: 'calendar.hourLabel',
    defaultMessage: 'H',
  },
  waitingCalendar: {
    id: 'calendar.waitingCalendar',
    defaultMessage: 'Please wait while your calendar data loads.',
  },
  seeMore: {
    id: 'calendar.seeMore',
    defaultMessage: 'See more',
  },
  remove: {
    id: 'calendar.remove',
    defaultMessage: 'Remove',
  },
  reassign: {
    id: 'calendar.reassign',
    defaultMessage: 'Reassign',
  },
  status: {
    id: 'calendar.status',
    defaultMessage: 'Status',
  },
});
