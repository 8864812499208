import { success, failure, request } from 'app/actions';
import * as actions from './registerActions';
import { getError } from '../app/appHelpers';

export const EditionStatus = {
  PENDING: 1,
  SAVING: 2,
  SAVED: 3,
  FAILED: 4,
};

const countryStates = [
  { value: 'AC', label: 'Acre' },
  { value: 'AL', label: 'Alagoas' },
  { value: 'AP', label: 'Amapá' },
  { value: 'AM', label: 'Amazonas' },
  { value: 'BA', label: 'Bahia' },
  { value: 'CE', label: 'Ceará' },
  { value: 'DF', label: 'Distrito Federal' },
  { value: 'ES', label: 'Espírito Santo' },
  { value: 'GO', label: 'Goiás' },
  { value: 'MA', label: 'Maranhão' },
  { value: 'MT', label: 'Mato Grosso' },
  { value: 'MS', label: 'Mato Grosso do Sul' },
  { value: 'MG', label: 'Minas Gerais' },
  { value: 'PA', label: 'Pará' },
  { value: 'PB', label: 'Paraíba' },
  { value: 'PR', label: 'Paraná' },
  { value: 'PE', label: 'Pernambuco' },
  { value: 'PI', label: 'Piauí' },
  { value: 'RJ', label: 'Rio de Janeiro' },
  { value: 'RN', label: 'Rio Grande do Norte' },
  { value: 'RS', label: 'Rio Grande do Sul' },
  { value: 'RO', label: 'Rondônia' },
  { value: 'RR', label: 'Roraima' },
  { value: 'SC', label: 'Santa Catarina' },
  { value: 'SP', label: 'São Paulo' },
  { value: 'SE', label: 'Sergipe' },
  { value: 'TO', label: 'Tocantins' },
];

const initialState = {
  schools: [],
  schoolDetails: null,
  schoolsSearch: [],
  states: [],
  cities: [],
  error: {},
  countryStates,
  status: EditionStatus.PENDING,
  countries: [],
  registeredSchool: false,
};

const schoolReducer = (state = initialState, action) => {
  switch (action.type) {
    case request(actions.COUNTRIES):
      return { ...state };
    case success(actions.COUNTRIES):
      return { ...state, countries: action.payload.content };
    case request(actions.GET_SCHOOL_DETAILS):
      return { ...state, schoolDetails: initialState.schoolDetails };
    case request(actions.GET_CITIES):
      return { ...state, schoolsSearch: initialState.schoolsSearch };
    case success(actions.GET_SCHOOLS):
      return { ...state, schools: action.payload.content };
    case success(actions.SEARCH_SCHOOLS):
      return { ...state, schoolsSearch: action.payload.content };
    case success(actions.GET_SCHOOL_DETAILS):
      return { ...state, schoolDetails: action.payload.content, error: {} };
    case request(actions.GET_STATES):
      return { ...state, cities: initialState.cities, schools: initialState.schools };
    case success(actions.GET_STATES):
      return { ...state, states: action.payload.content };
    case success(actions.GET_CITIES):
      return { ...state, cities: action.payload.content, schools: initialState.schools };
    case request(actions.REGISTER_USER):
      return { ...state, status: EditionStatus.SAVING };
    case success(actions.REGISTER_USER):
      return { ...state, status: EditionStatus.SAVED };
    case failure(actions.REGISTER_USER):
      return { ...state, status: EditionStatus.FAILED, error: getError(action) };
    case success(actions.REGISTER_SCHOOL):
      return { ...state, registeredSchool: true};
    case actions.RESET_FORM: {
      return { ...initialState };
    }
    default:
      return state;
  }
};

export default schoolReducer;
