import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { find } from 'lodash';
import { logout, manageUserTerms, auth  } from 'login/loginActions';

import AppRoute from './AppRoute';
import AppSidebar from './AppSidebar';
import AppNavbar from './AppNavbar';
import AppFooter from './AppFooter';
import ModalOptIn from './ModalOptIn';

function getActiveRoute(routes) {
  const currentRoute = window.location.pathname.split('/');
  return find(routes, route => {
    const parsedRoute = route.path.split('/');
    return parsedRoute[1] === currentRoute[1];
  });
}

function isExpanded(routes) {
  const activeRoute = getActiveRoute(routes);
  return activeRoute ? activeRoute.expanded : false;
}

const AppContainer = ({ routes, ...props }) => {
  const expanded = isExpanded(routes);

  React.useEffect(() => {
    if(props.manageUserTermsPass) {
      props.auth();
    }
  }, [props]);

  return (
    <div>
      {props.user.termsUse === null &&
        <ModalOptIn user={props.user} userName={props.user.firstName} onClose={props.logout} onSubmit={props.manageUserTerms}/>
      }
      <AppNavbar onRequestLogout={props.logout} />
      <AppSidebar routes={routes} collapsed={expanded} />
      <div className={classNames('content-wrapper', { 'content-wrapper--expanded': expanded })}>
        <div className="container">
          <Switch>
            {routes.map(route => (
              <AppRoute key={route.path} {...route} />
            ))}
          </Switch>
        </div>
      </div>
      <AppFooter />
    </div>
  );
};

AppContainer.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape({ path: PropTypes.string })).isRequired,
  logout: PropTypes.func.isRequired,
};

function mapStateToProps({ app, login }) {
  return { user: app.user, manageUserTermsPass: login.manageUserTermsPass };
}

export default compose(
  connect(mapStateToProps, { logout, manageUserTerms, auth }
))(AppContainer);
