import React, { Component } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import messages from './scheduleMessages';
import { Popover, OverlayTrigger } from 'react-bootstrap';

import ScheduleStatusPlanTooltipDetail from './ScheduleStatusPlanTooltipDetail';
import { Loader } from 'sm-ui';

class StatusPlanTooltip extends Component {
  renderContent() {
    const { content } = this.props;
    const { applied, scheduled } = content;
    return Object.keys(applied)
      .sort()
      .map((period, periodIndex) => {
        const periodNumber = periodIndex + 1;
        const key = `Period_${periodNumber}`;
        const appliedValue = applied[key];
        const scheduledValue = scheduled[key];
        return (
          <ScheduleStatusPlanTooltipDetail
            applied={appliedValue}
            scheduled={scheduledValue}
            period={periodNumber}
          />
        );
      });
  }

  render() {
    const { content } = this.props;
    const children = content ? this.renderContent() : <Loader show simple />;
    const popover = (
      <Popover>
        <Popover.Title>
          <div className="cm-title">
            <i className="ti-info-alt" /> <FormattedMessage {...messages.status} />
          </div>
        </Popover.Title>
        <Popover.Content>
          <div style={{ minHeight: 150, minWidth: 200, display: 'grid', alignItems: 'center' }}>
            {children}
          </div>
        </Popover.Content>
      </Popover>
    );

    return (
      <div className={classNames('fixed-actions')}>
        <OverlayTrigger trigger="click" overlay={popover} rootClose>
          <a style={{ textDecoration: 'none', color: '#8D8D8D' }} onClick={this.props.onShow}>
            <i className="ti-info-alt" />
            <br />
            <FormattedMessage {...messages.status} />
          </a>
        </OverlayTrigger>
      </div>
    );
  }
}

export default StatusPlanTooltip;
