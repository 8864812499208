import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const CardMenu = props => {
  const active = props.isOpen;
  const {lastCol} = props;
  const left = lastCol ? '-75px' : 'unset';
  const right = lastCol ? 'unset' : '-70px';

  return (
    <div className={classNames('fixed-actions', { active })} {...props}>
      <div className="card-menu" onClick={props.handleOpen}>
        <i className="fas fa-ellipsis-v" />
      </div>
      <div
        className={`cm-floating${props.lastCol ? ' cm-floating-last' : ''}`}
        style={{ left, right }}
      >
        {props.children}
      </div>
    </div>
  );
};

CardMenu.propTypes = {
  children: PropTypes.node,
};

export default CardMenu;
