import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import brandApp from 'common/brand-organizador-curricular.svg';
import brandSm from 'common/brand-sm.svg';

import { Loader } from 'sm-ui';
import messages from './loginMessages';
// import AppFooter from '../app/AppFooter';

import './Login.scss';

// const Login = ({ entering, children, footer }) => (
const Login = ({ entering, children }) => (
  <Fragment>
    <Loader show={entering} />
    <div className="brand-mob">
      <img src={brandApp} alt="" />
      <FormattedMessage {...messages.title}>
        {txt => <h1 className="cover-title">{txt}</h1>}
      </FormattedMessage>
    </div>
    <div className="form-wrapper form-login row align-items-center">{children}</div>
    <a className="sm-brand-mob" href="/">
      <img src={brandSm} alt="" />
    </a>
    <div className="cover-wrapper">
      <div className="brand display-none">
        <img src={brandApp} alt="" />
        <FormattedMessage {...messages.title}>
          {txt => <h1 className="cover-title">{txt}</h1>}
        </FormattedMessage>
      </div>
      <a className="sm-brand display-none" href="/">
        <img src={brandSm} alt="" />
      </a>
    </div>
    {/* {footer && 
      <AppFooter />
    } */}
  </Fragment>
);

Login.propTypes = {};

export default Login;
