/* eslint-disable */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'sm-ui';

import messages from '../plannerMessages';

const ModalAddColumn = ({ onKeyDown, onClose, onChange, isOpen, onConfirm, label, direction }) => {
  let resolvedTitle = '';
  if (direction === 'l') {
    resolvedTitle = <FormattedMessage {...messages.lAddColumnTitle} />;
  }
  if (direction === 'r') {
    resolvedTitle = <FormattedMessage {...messages.rAddColumnTitle} />;
  }

  return (
    <Modal
      show={isOpen}
      onClose={onClose}
      buttons={[
        {
          id: 1,
          label: <FormattedMessage {...messages.cancel} />,
          onClick: () => onClose(false),
        },
        {
          id: 2,
          label: <FormattedMessage {...messages.save} />,
          onClick: () => onConfirm(),
        },
      ]}
    >
      <h4>{resolvedTitle}</h4>

      <div>
        <input
          autoFocus
          type="text"
          value={label}
          onKeyDown={onKeyDown}
          onChange={e => onChange(e.target.value)}
          style={{
            padding: '5px',
            marginTop: '20px',
            width: '80%',
          }}
        />
      </div>
    </Modal>
  );
};

export default ModalAddColumn;
