import { defineMessages } from 'react-intl';

export default defineMessages({
  code: {
    id: 'schoolForm.code',
    defaultMessage: 'CODE',
  },
  schoolDays: {
    id: 'schoolForm.schoolDays',
    defaultMessage: 'School Days',
  },
  updatePhoto: {
    id: 'schoolForm.updatePhoto',
    defaultMessage: 'Update Photo',
  },
  schoolData: {
    id: 'schoolForm.schoolData',
    defaultMessage: 'School Data',
  },
  linkCoordinator: {
    id: 'schoolForm.linkCoordinator',
    defaultMessage: 'Link to Coordinator',
  },
  levelEducation: {
    id: 'schoolForm.levelEducation',
    defaultMessage: 'Level of Education',
  },
  name: {
    id: 'schoolForm.field.name',
    defaultMessage: 'Name',
  },
  address: {
    id: 'schoolForm.field.address',
    defaultMessage: 'Address',
  },
  number: {
    id: 'schoolForm.field.number',
    defaultMessage: 'Number',
  },
  complementary: {
    id: 'schoolForm.field.complementary',
    defaultMessage: 'Complementary',
  },
  city: {
    id: 'schoolForm.field.city',
    defaultMessage: 'City',
  },
  state: {
    id: 'schoolForm.field.state',
    defaultMessage: 'State',
  },
  startSchoolYear: {
    id: 'schoolForm.field.earlySchoolYear',
    defaultMessage: 'Start School Year',
  },
  startVacation: {
    id: 'schoolForm.field.startVacation',
    defaultMessage: 'Start Vacation',
  },
  endVacation: {
    id: 'schoolForm.field.endVacation',
    defaultMessage: 'End Vacation',
  },
  endSchoolYear: {
    id: 'schoolForm.field.endSchoolYear',
    defaultMessage: 'End School Year',
  },
  schoolYear: {
    id: 'schoolForm.field.schoolYear',
    defaultMessage: 'School Year',
  },
  addVacationPeriod: {
    id: 'schoolForm.addVacationPeriod',
    defaultMessage: 'Add vacation period',
  },
  teachers: {
    id: 'schoolForm.teachers',
    defaultMessage: 'Teachers',
  },
  coordinators: {
    id: 'schoolForm.coordinators',
    defaultMessage: 'Coordinators',
  },
  errorStartAfterEnd: {
    id: 'schoolForm.errorStartAfterEnd',
    defaultMessage: 'Start date must be equal or before the end date.',
  },
  errorRequired: {
    id: 'schoolForm.errorRequired',
    defaultMessage: 'Required field.',
  },
  errorMustInform: {
    id: 'schoolForm.errorMustInform',
    defaultMessage: 'Inform the date.',
  },
  save: {
    id: 'schoolForm.save',
    defaultMessage: 'Save',
  },
  successfullySaved: {
    id: 'schoolForm.successfullySaved',
    defaultMessage: 'Data was successfully saved.',
  },
});
