/* eslint-disable */
import { action } from 'app/actions';
/* eslint-enable */

// General actions
export const GET_PLANS_TABLE = 'plans-planner/GET_PLANS_TABLE';
export const REVERT_PLAN_TO_ORIGINAL = 'plans-planner/REVERT_PLAN_TO_ORIGINAL';
export const SAVE_PLAN = 'plans-planner/SAVE_PLAN';
export const DOWNLOAD_FILE = 'plans-planner/DOWNLOAD_FILE';

export const getPlanner = payload => action(GET_PLANS_TABLE, { payload });
export const revertPlanToOriginal = payload => action(REVERT_PLAN_TO_ORIGINAL, { payload });
export const savePlan = payload => action(SAVE_PLAN, { payload });
export const viewFile = payload => action(DOWNLOAD_FILE, { payload });

// Column actions
export const UPDATE_METADATA = 'plans-planner/UPDATE_METADATA';
export const ADD_COLUMN = 'plans-planner/ADD_COLUMN';
export const MOVE_COLUMN = 'plans-planner/MOVE_COLUMN';
export const REMOVE_COLUMN = 'plans-planner/REMOVE_COLUMN';

export const updateMetadata = payload => action(UPDATE_METADATA, { payload });
export const addColumn = payload => action(ADD_COLUMN, { payload });
export const moveColumn = payload => action(MOVE_COLUMN, { payload });
export const removeColumn = payload => action(REMOVE_COLUMN, { payload });

// Cell actions
export const UPDATE_CELL = 'plans-planner/UPDATE_CELL';
export const UPDATE_CELL_WITH_FILE = 'plans-planner/UPDATE_CELL_WITH_FILE';
export const ADD_ROW = 'plans-planner/ADD_ROW';
export const MOVE_ROW = 'plans-planner/MOVE_ROW';
export const REMOVE_ROW = 'plans-planner/REMOVE_ROW';

export const updateCell = payload => {
  return action(UPDATE_CELL, { payload });
};
export const updateCellWithFile = payload => {
  const { idPlan, idLesson, idMetadata, idFiles, files } = payload;

  const formData = new FormData();
  formData.append('idPlan', idPlan);
  formData.append('idLesson', idLesson);
  formData.append('idMetadata', idMetadata);
  files && formData.append('files', files);
  idFiles && formData.append('idFiles', idFiles);

  return action(UPDATE_CELL_WITH_FILE, { payload: formData });
};
export const addRow = payload => action(ADD_ROW, { payload });
export const moveRow = payload => action(MOVE_ROW, { payload });
export const removeRow = payload => action(REMOVE_ROW, { payload });

// Upload actions
export const UPLOAD_FILE = 'plans-planner/UPLOAD_FILE';

export const uploadFile = payload => action(UPLOAD_FILE, { payload });
