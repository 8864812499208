import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'sm-ui';

import messages from '../plannerMessages';

const ModalRemove = ({ isOpen, type, onClose, onConfirm }) => (
  <Modal
    show={isOpen}
    icon={'pe-7s-trash'}
    onClose={onClose}
    title={
      type === 'column' ? (
        <FormattedMessage {...messages.modalRemoveColumnTitle} />
      ) : (
        <FormattedMessage {...messages.modalRemoveRowTitle} />
      )
    }
    message={
      type === 'column' ? (
        <FormattedMessage {...messages.modalRemoveColumnMessage} />
      ) : (
        <FormattedMessage {...messages.modalRemoveRowMessage} />
      )
    }
    buttons={[
      {
        id: 1,
        label: <FormattedMessage {...messages.cancel} />,
        onClick: () => onClose(),
      },
      {
        id: 2,
        label: <FormattedMessage {...messages.confirm} />,
        onClick: id => onConfirm(id),
      },
    ]}
  />
);

export default ModalRemove;
