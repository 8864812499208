import { apiTask } from 'app/sagas';
import { api } from 'app/api';
import { take, fork, all, takeLatest, call } from 'redux-saga/effects';
import {delay} from 'redux-saga/effects';
import * as actions from './schoolActions';

/**
 * Tasks
 */
export const getSchoolsTask = apiTask(
  actions.GET_SCHOOLS,
  api.get,
  '/planner-plans/getSchoolsList.php',
);

export const getSchoolDetailsTask = apiTask(
  actions.GET_SCHOOL_DETAILS,
  api.post,
  '/planner-plans/getSchoolDetail.php',
);

export const activateAccessCodeTask = apiTask(
  actions.ACTIVATE_ACCESS_CODE,
  api.post,
  '/planner-plans/activeAccessCode.php',
);

export function* searchSchoolTask({ payload }) {
  yield delay(500);
  const task = apiTask(actions.SEARCH_SCHOOLS, api.post, '/planner-plans/schoolSearch.php');
  yield call(task, payload);
}

export const getStatesTask = apiTask(
  actions.GET_STATES,
  api.post,
  '/planner-plans/schoolSearch.php',
);

export const getCitiesTask = apiTask(
  actions.GET_CITIES,
  api.post,
  '/planner-plans/schoolSearch.php',
);

export const saveSchoolDetailsTask = apiTask(
  actions.SAVE_SCHOOL_DETAILS,
  api.post,
  '/planner-plans/saveSchoolDetail.php',
);

export const addSchoolTask = apiTask(actions.ADD_SCHOOL, api.post, '/planner-plans/addSchool.php');

/**
 * Watchers
 */
export function* watchGetSchools() {
  while (yield take(actions.GET_SCHOOLS)) {
    yield fork(getSchoolsTask);
  }
}

export function* watchActivateAccessCode() {
  while (true) {
    const { payload } = yield take(actions.ACTIVATE_ACCESS_CODE);
    yield call(activateAccessCodeTask, payload);
    yield fork(getSchoolsTask);
  }
}

export function* watchGetSchoolDetails() {
  while (true) {
    const { payload } = yield take(actions.GET_SCHOOL_DETAILS);
    yield fork(getSchoolDetailsTask, payload);
  }
}

export function* watchGetStates() {
  while (true) {
    const { payload } = yield take(actions.GET_STATES);
    yield fork(getStatesTask, payload);
  }
}

export function* watchSchoolSearch() {
  yield takeLatest(actions.SEARCH_SCHOOLS, searchSchoolTask);
}

export function* watchSaveSchoolDetails() {
  while (true) {
    const { payload } = yield take(actions.SAVE_SCHOOL_DETAILS);
    yield fork(saveSchoolDetailsTask, payload);
  }
}

export function* watchGetCities() {
  while (true) {
    const { payload } = yield take(actions.GET_CITIES);
    yield fork(getCitiesTask, payload);
  }
}

export function* watchAddSchool() {
  while (true) {
    const { payload } = yield take(actions.ADD_SCHOOL);
    yield call(addSchoolTask, payload);
    yield fork(getSchoolsTask);
  }
}

export function* sagas(): Iterable<T> {
  yield all([
    fork(watchGetSchools),
    fork(watchGetSchoolDetails),
    fork(watchActivateAccessCode),
    fork(watchGetStates),
    fork(watchGetCities),
    fork(watchSchoolSearch),
    fork(watchAddSchool),
    fork(watchSaveSchoolDetails),
  ]);
}
