import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withRouter } from 'react-router-dom';

class NavLink extends Component {
  go = () => {
    const { history, to } = this.props;
    history.push(to);
  };
  render() {
    const { location, to, children, active, className } = this.props;
    const isActive = active ? active(location, to) : location.pathname.match(new RegExp(to, 'g'));
    return (
      <li className={classNames('sidebar-nav-link', className, { active: isActive })}>
        <a to={to} onClick={this.go}>
          {children}
        </a>
      </li>
    );
  }
}

NavLink.propTypes = {
  location: PropTypes.object,
  to: PropTypes.string,
  active: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  history: PropTypes.object.isRequired,
};

export default withRouter(NavLink);
