import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import messages from './configurationMessages';
import ConfigurationForm from './ConfigurationForm';
import * as actions from './configurationActions';

const ConfigurationContainer = ({ user, ...props }) => (
  <div>
    <FormattedMessage {...messages.title}>
      {txt => <h1 className="page-title mb-5">{txt}</h1>}
    </FormattedMessage>
    {user && 
      <ConfigurationForm 
      onSubmit={props.save} 
      user={user} 
      onUpload={props.upload} 
      onRemove={props.removePhoto} 
      isLoading={props.isLoading}
      userInfo={props.userInfo}
      userInfoStatus={props.userInfoStatus}
      intl={props.intl}
    />}
  </div>
);

ConfigurationContainer.propTypes = {
  intl: intlShape.isRequired,
};

const mapStateToProps = ({ app, configuration }) => ({ 
  user: app.user,
  userInfo: configuration.userInfo,
  userInfoStatus: configuration.userInfoStatus,
  isLoading: configuration.isLoading, 
  ...app
});

export default compose(
  connect(
    mapStateToProps,
    actions
  ),
  injectIntl,
)(ConfigurationContainer);
