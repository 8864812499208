// @styleguide

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import CalendarEventType from '../calendarEventType';
import messages from './cardAppointmentDayMessages';
import CardAppointmentTitle from '../CardAppointmentTitle';
import * as helpers from '../calendarHelpers';
import './CardAppointmentDay.scss';

class CardAppointmentDay extends Component {
  handleRequestStatus = () => {
    if (this.props.onRequestStatusAppointment) {
      this.props.onRequestStatusAppointment(this.props);
    }
  };
  handleRequestDetails = () => {
    if (this.props.onRequestDetailsAppointment) {
      this.props.onRequestDetailsAppointment(this.props);
    }
  };
  handleRequestReassign = () => {
    if (this.props.onRequestReassignAppointment) {
      this.props.onRequestReassignAppointment(this.props);
    }
  };
  render() {
    const { type, title, discipline, content, status } = this.props;
    return (
      <div
        className={classNames('card card-calendar card-dia', type.className, {
          'card-calendar-not-applied': helpers.isNotApplied(status),
        })}
      >
        <div className="card-body">
          <div className="row" onClick={this.handleRequestDetails}>
            <div className={classNames('col-auto pr-1', type.className)}>
              <i className={classNames('icon', type.icon)} />
            </div>
            <div className={classNames('col-auto pl-1', type.className)}>
              <CardAppointmentTitle
                className="card-appointment-day-title"
                title={title}
                type={type}
              />
              <p className="card-appointment-day-discipline">{discipline}</p>
            </div>
            <div className="col-auto card-appointment-day-content">{content}</div>
          </div>
        </div>
        <div className="card-footer card-appointment-day d-none d-sm-flex">
          <div className="row">
            <div className="col">
              <FormattedMessage {...messages.seeMore}>
                {txt => (
                  <a className="icon-link" onClick={this.handleRequestDetails}>
                    <i className="ti-eye" /> {txt}
                  </a>
                )}
              </FormattedMessage>
            </div>
            <div className="col">
              <FormattedMessage {...messages.reassign}>
                {txt => (
                  <a className="icon-link" onClick={this.handleRequestReassign}>
                    <i className="pe-7s-repeat" /> {txt}
                  </a>
                )}
              </FormattedMessage>
            </div>
            <div className="col">
              <FormattedMessage {...messages.status}>
                {txt => (
                  <a className="icon-link" onClick={this.handleRequestStatus}>
                    <i className="pe-7s-clock" /> {txt}
                  </a>
                )}
              </FormattedMessage>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CardAppointmentDay.propTypes = {
  type: PropTypes.oneOf(Object.keys(CalendarEventType).map(k => CalendarEventType[k])),
};

CardAppointmentDay.defaultProps = {
  type: CalendarEventType.LESSON,
};

export default CardAppointmentDay;
