import React from 'react';
import { Route } from 'react-router-dom';

/* eslint-disable */
const AppRoute = route => (
  <Route
    path={route.path}
    render={props => (
      // pass the sub-routes down to keep nesting
      <route.component {...props} routes={route.routes} />
    )}
  />
);
/* eslint-enable */

export default AppRoute;
